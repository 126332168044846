export const CONTA_CORRENTE = {
  viewName: "Conta Corrente",
  serializedName: 42, // ID no banco.
};

export const CONTA_POUPANCA = {
  viewName: "Conta Poupança",
  serializedName: 43, // ID no banco.
};

export const CORRENTE_CONJUNTA = {
  viewName: "Corrente Conjunta",
  serializedName: 44, // ID no banco.
};

export default [CONTA_CORRENTE, CONTA_POUPANCA, CORRENTE_CONJUNTA];
