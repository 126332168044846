import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import createServiceFor from "../../service/Service";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification } from "../../util/FormUtils";
import SelectField from "components/form/field/SelectField/SelectField";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;
const ufIdentifierResolver = e => e.sigla;

class PaginaFormUnidadesRegionais extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/estados").query()
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/unidadesRegionais").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(([estados, unidadeRegional = {}]) => {

        if (unidadeRegional.data) {
          this.setState({
            initialValues: unidadeRegional.data,
          });
        }

        return {
          estados: estados.data,
          initialValues: unidadeRegional.data ? { ...unidadeRegional.data } : {},
        };
      })
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/veiculos")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Unidade administrativa salva com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (e.response.data && e.response.data.message) {
          store.dispatch(showGlobalNotification({
              message: e.response.data.message
          }));
        } else {
            store.dispatch(showGlobalNotification({
                message: 'Ocorreu um erro ao tentar salvar'
            }));
        }
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      sigla: this.state.initialValues?.sigla,
    });

    const formattedValues = {
      sigla: limitText(sanitizedValues?.sigla, 20),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              formattedValues?.sigla
                ? `<div class="text">Unidade administrativa</div> - <div class="text">Sigla: </div>${formattedValues.sigla}`
                : ''
            }
            ${
              this.state.initialValues?.cnpj
                ? ` - <div class="text">CNPJ:</div> ${this.state.initialValues.cnpj}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    // const disableAllFields =
    //   this.props.history.location &&
    //   this.props.history.location.state &&
    //   !this.props.history.location.state.allowEdit;

    // Apenas visualização
    const disableAllFields = true;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Unidades administrativas"
        baseUrl="/sgih/app/unidadesRegionais"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[]}
                mutators={{}}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field component="input" type="hidden"
                          name="id" value={id}/>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados da unidade administrativa">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="sigla"
                                  label="Sigla da unidade"
                                  maxLength={10}
                                  validate={requiredWithNotification('Sigla da unidade')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  maxLength={7}
                                  name="numeroLocal"
                                  label="Unidade administrativa"
                                  // mask="1111.111" // Máscara removida para aceitar numeros inválidos já presentes no banco
                                  required
                                  disabled={disableAllFields}
                                />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={SelectField}
                                    // name="idEstado"
                                    name="uf"
                                    label="Estado"
                                    elements={data.estados}
                                    valueResolver={ufIdentifierResolver}
                                    labelResolver={descriptionLabelResolver}
                                    validate={requiredWithNotification('Estado')} required
                                    disabled={disableAllFields}
                                  />
                                </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name="descricao"
                                  label="Tipo de unidade/local"
                                  maxLength={40}
                                  validate={requiredWithNotification('Tipo de unidade/local')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name="endereco"
                                  label="Endereço"
                                  maxLength={50}
                                  validate={requiredWithNotification('Endereço')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={6}>
                                {/* <Field
                                  component={SelectField}
                                  name="municipio"
                                  label="Município"
                                  validate={requiredWithNotification('Município')} required
                                  disabled={disableAllFields}
                                /> */}
                                <Field
                                  component={TextField}
                                  name="municipio"
                                  label="Município"
                                  maxLength={40}
                                  validate={requiredWithNotification('Município')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="cep"
                                  label="CEP"
                                  mask="11.111-111"
                                  validate={requiredWithNotification('CEP')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="telefoneFone"
                                  label="Telefone"
                                  mask="(11) 1111-1111"
                                  validate={requiredWithNotification('CEP')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="telefoneFax"
                                  label="Fax"
                                  mask="(11) 1111-1111"
                                  validate={requiredWithNotification('CEP')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={6}>
                                <Field
                                  component={TextField}
                                  name="cnpj"
                                  label="CNPJ"
                                  mask="11.111.111/1111-11"
                                  validate={requiredWithNotification('CNPJ')} required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={6}>
                                <Field
                                  component={TextField}
                                  name="inscricaoEstadual"
                                  label="Inscrição estadual"
                                  maxLength={20}
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormUnidadesRegionais;
