import React from "react";
import {Field} from "react-final-form";

const Fields = ({
                    names,
                    subscription,
                    fieldsState = {},
                    children,
                    originalRender,
                    accumulatedValues
                }) => {
    if (!names.length) {
        return (originalRender || children)({
            valores: accumulatedValues, estados: fieldsState
        });
    }
    const [name, ...rest] = names;
    return (
        <Field name={name} subscription={subscription}>
            {fieldState => (
                <Fields
                    names={rest}
                    subscription={subscription}
                    originalRender={originalRender || children}
                    fieldsState={{...fieldsState, [name]: fieldState}}
                    accumulatedValues={{...accumulatedValues, [name]: fieldState.input.value}}
                />
            )}
        </Field>
    );
};

export default Fields;