import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import SelectField from "../../components/form/field/SelectField";
import TextAreaField from "../../components/form/field/TextAreaField";
import DateTimeField from "../../components/form/field/DateTimeField/DateTimeField";
import NumberField from "../../components/form/field/NumberField/NumberField";
import FieldLabel from "../../components/form/field/FieldLabel";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import createDecorator from "final-form-calculate";

import "./PaginaFormEstacao";
import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import { FieldArray } from "react-final-form-arrays";
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
import "jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css";
import PromiseLoader from "components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "components/scaffold/FormPageScaffoldCommon";
import BotaoIconeExclusao from "components/botao/BotaoIconeExclusao/BotaoIconeExclusao";
import BotaoPadrao from "components/botao/BotaoPadrao/BotaoPadrao";
import InclinacaoMargem from "model/InclinacaoMargem";
import Margem from "model/Margem";
import Status, { StatusIdSerializedMap, StatusIdViewMap, StatusSerializedIdMap, StatusSerializedViewMap } from "model/Status";
import Regime from "model/Regime";
import Conformacao from "model/Conformacao";
import Datum from "model/Datum";
import Fundo from "model/Fundo";
import { montanteOuJusante } from "model/AvaliacaoQualidade";
import {
  requiredWithNotification,
  isInteger,
  isLong,
  isNumber,
  requiredWithCustomValidationAndNotification,
} from "util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import voltarParaGrid from "util/VoltarParaGrid";

import _ from 'lodash';
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

const naoEhCodigoEstacao = (value) =>
  !!value && String(value).trim()
    ? !Number.isNaN(Number(value)) && value.length === 8
      ? undefined
      : "O código da estação é inválido"
    : "O código da estação é obrigatório";

let cartaEncontrada = true;
// https://codesandbox.io/s/oq52p6v96y
const formEstacaoDecorator = createDecorator(
  {
    field: "siglaTipoEstacaoIdentificacao",
    updates: (value) => {
      if (!!value && "" !== value && value.includes("P")) {
        return {
          idRioIdentificacao: 999,
        };
      } else {
        return {};
      }
    },
  },
  {
    field: "nomeIdentificacao",
    updates: (value) => ({
      // Utilizar o mesmo nome no input também funcionaria mas com um decorator parece ser melhor para performance
      _nomeIdentificacaoCaracteristicas: value,
    }),
  },
  {
    field: "codigoFolhaIdentificacao",
    updates: (value, name, allValues) => {
      if (value && String(value).match(/^[\d]+$/)) {
        return createServiceFor("/estacoes")
          .customURL("GET", `paraCartaCartografica/${value}`)
          .then((res) => {
            cartaEncontrada = true;
            const data = res.data;
            return {
              cartaCartograficaNome: data.nome,
              cartaCartograficaNomeOrgaoExecutor: data.orgao,
              cartaCartograficaAno: data.ano,
              cartaCartograficaNumeroEscalaCartografica: data.escala,
            };
          })
          .catch(() => {
            // Carta não encontrada
            cartaEncontrada = false;
            store.dispatch(
              showGlobalNotification({
                message: "Não existe uma folha com o código " + value,
              })
            );
            return {
              cartaCartograficaNome: "",
              cartaCartograficaNomeOrgaoExecutor: "",
              cartaCartograficaAno: "",
              cartaCartograficaNumeroEscalaCartografica: "",
            };
          });
      } else {
        cartaEncontrada = false;
        return {
          codigoFolhaIdentificacao: value
            ? String(value).replace(/[^\d]/, "")
            : value,
          cartaCartograficaNome: "",
          cartaCartograficaNomeOrgaoExecutor: "",
          cartaCartograficaAno: "",
          cartaCartograficaNumeroEscalaCartografica: "",
        };
      }
    },
  },
  {
    field: "idRoteiroIdentificacao",
    updates: (value, name, allValues) => {
      if (value) {
        return createServiceFor("/roteirosServico")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;

            if (data.idUnidadeRegional) {
              return {
                idUnidadeRegional: data.idUnidadeRegional,
              };
            } else {
              store.dispatch(
                showGlobalNotification({
                  message:
                    "Unidade Regional não cadastrada para o roteiro selecionado ",
                })
              );
              return {};
            }
          })
          .catch(() => {
            store.dispatch(
              showGlobalNotification({
                message: "Não existe um roteiro com o código " + value,
              })
            );
            return {};
          });
      } else {
        return {};
      }
    },
  }
);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) =>
  e.nome || e.descricao || e.codigo || e.viewName;
const roteiroServicoLabelResolver = (e) =>
  `${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
// const equipeLabelResolver = e => e.codigo + (e.nomeUsuario ? ` - ${e.nomeUsuario}` : '');
const margemNaturezaLabelResolver = (e) => e.margensNatureza;
const tipoEstacaoLabelResolver = (e) => e.siglaTipoEstacao;
const tipoControleLabelResolver = (e) => e.tipoControle;
const unidadeRegionalLabelResolver = (e) => e.siglaLocalUasg;

const TipoEstacaoEh = ({ tipos = [], children }) => {
  return (
    <Field name="siglaTipoEstacaoIdentificacao" subscription={{ value: true }}>
      {({ input: { value } }) => {
        const estacaoEhTipOCerto =
          !!value && "" !== value && tipos.some((tipo) => value.includes(tipo));
        return children(estacaoEhTipOCerto);
      }}
    </Field>
  );
};

class PaginaFormEstacao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
      municipiosDoEstado: [],
      subbacia: [],
      rios: [],
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/roteirosServico/paraCadastros").query(),
      createServiceFor("/tiposEstacao/simples").query(),
      createServiceFor("/estados").query(),
      createServiceFor("/regioesHidrograficas/simples").query(),
      createServiceFor("/baciasHidrograficas/simples").query(),
      createServiceFor("/unidadesRegionais/simples").query(),
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/tiposServico").query(),
      createServiceFor("/margensNatureza").query(),
      createServiceFor("/tiposControle").query(),
      createServiceFor("/processosMedicao").query(),
      createServiceFor("/estacoes/simples").query(),
      createServiceFor("/municipios/porEstado").query(),
      // createServiceFor("/subBacias").query(),
      // createServiceFor("/rios").query(),
      // createServiceFor("/municipios").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/estacoes").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(
        ([
          roteirosServico,
          tiposEstacao,
          estados,
          regioesHidrografica,
          baciasHidrografica,
          unidadesRegional,
          usuarios,
          tiposServico,
          margensNatureza,
          tiposControle,
          processosMedicao,
          estacoes,
          municipiosPorEstado,
          // , subBacias
          // , rios
          // , municipiosGeral
          inspecao = {},
        ]) => ({
          roteirosServico: roteirosServico.data,
          tiposEstacao: tiposEstacao.data,
          estados: estados.data,
          regioesHidrografica: regioesHidrografica.data,
          baciasHidrografica: baciasHidrografica.data,
          unidadesRegional: unidadesRegional.data,
          usuarios: usuarios.data,
          tiposServico: tiposServico.data,
          margensNatureza: margensNatureza.data,
          tiposControle: tiposControle.data,
          processosMedicao: processosMedicao.data,
          estacoes: estacoes.data,
          municipiosPorEstado: (() => {
            const resultado = {};
            for (const estado of municipiosPorEstado.data) {
              resultado[estado.id] = estado.municipios;
            }
            return resultado;
          })(),
          subBaciasEstacao: inspecao?.data?.subBaciasEstacao
            ? inspecao.data.subBaciasEstacao
            : [],
          riosEstacao: inspecao?.data?.riosEstacao
            ? inspecao.data.riosEstacao
            : [],
          municipiosEstacao: inspecao?.data?.municipiosEstacao
            ? inspecao.data.municipiosEstacao
            : [],
          initialValues: inspecao.data ? {
            ...inspecao.data,
            statusObservacoes: StatusIdSerializedMap[inspecao.data.statusObservacoes],
          } : {},
        })
      )
      .catch((e) => {
        console.log(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      })
      .finally(() => {
        store.dispatch(stopGlobalLoading());
      });

    this.setState({
      promiseDados: promise,
      municipiosDoEstado: [],
      subbacia: [],
      rios: [],
    });
  }

  normalize(values) {
    delete values.riosEstacao;
    delete values.municipiosEstacao;
    delete values.subBaciasEstacao;

    const normalizedAltitudesOrtometricas = (
      values?.altitudesOrtometricas || []
    ).map((altitudeOrtometrica) => ({
      ...altitudeOrtometrica,
      altitudeOrtometricaZeroRegua: Number(
        (Number(altitudeOrtometrica.altitudeOrtometricaZeroRegua) || 0).toFixed(
          3
        )
      ),
    }));

    return {
      ...values,
      statusObservacoes: StatusSerializedIdMap[values.statusObservacoes],
      altitudesOrtometricas: normalizedAltitudesOrtometricas,
    };
  }

  submitForm(values) {
    const normalizedValues = this.normalize(values);

    store.dispatch(startGlobalLoading("Salvando..."));

    createServiceFor("/estacoes")
      .post(normalizedValues)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Estação salva com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (e.response.data && e.response.data.message) {
          store.dispatch(
            showGlobalNotification({
              message: e.response.data.message,
            })
          );
        } else {
          store.dispatch(
            showGlobalNotification({
              message: "Ocorreu um erro ao tentar salvar",
            })
          );
        }
      });
  }

  getSiglaTipoEstacaoPorId(tiposEstacao, idTipoEstacao) {
    return (
      (tiposEstacao || []).find((t) => t.id === Number(idTipoEstacao)) || {}
    ).siglaTipoEstacao;
  }

  tipoEstacaoEh(siglaTipos = [], siglaAtual) {
    return (
      !!siglaAtual &&
      "" !== siglaAtual &&
      siglaTipos.some((sigla) => siglaAtual.includes(sigla))
    );
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });

      if (cartaEncontrada) {
        form.submit();
      } else {
        store.dispatch(
          showGlobalNotification({
            message: "Não existe uma carta com o código informado",
          })
        );
      }
    },
  });

  WhenComboChanges = ({ field, set }) => {
    const meuThis = this;
    return (
      <Field name={set} subscription={{}}>
        {({ input: { onChange } }) => (
          <FormSpy subscription={{}}>
            {({ form }) => (
              <OnChange name={field}>
                {(value) => {
                  return createServiceFor("/estados")
                    .customURL("GET", `${value} /municipios`)
                    .then((res) => {
                      const data = res.data;
                      meuThis.setState({
                        municipiosDoEstado: data,
                      });
                      onChange(data);
                    })
                    .catch((e) => {
                      console.log(e);
                      store.dispatch(
                        showGlobalNotification({
                          message: "Estado inválido",
                        })
                      );
                      //onChange();
                    });
                }}
              </OnChange>
            )}
          </FormSpy>
        )}
      </Field>
    );
  };

  WhenComboChangesSubBacias = ({ field, set }) => {
    return (
      <Field name={set} subscription={{}}>
        {({ input: { onChange } }) => (
          <FormSpy subscription={{}}>
            {({ form }) => (
              <OnChange name={field}>
                {(value) => {
                  const numberValue = _.toNumber(value);

                  if (!numberValue) {
                    form.change('subBaciasEstacao', []);
                    this.setState({
                      subbacia: [],
                    });
                    onChange(undefined);
                    return;
                  }

                  return createServiceFor("/subBacias/regiaoHidrografica")
                    .customURL("GET", numberValue)
                    .then((res) => {
                      const data = res.data;

                      form.change('subBaciasEstacao', data);
                      this.setState({
                        subbacia: data,
                      });
                      onChange(undefined);
                    })
                    .catch((e) => {
                      console.log(e);
                      store.dispatch(
                        showGlobalNotification({
                          message: "Região hidrográfica inválida",
                        })
                      );
                      //onChange();
                    });
                }}
              </OnChange>
            )}
          </FormSpy>
        )}
      </Field>
    );
  };

  WhenComboChangesRios = ({ field, set }) => {
    return (
      <Field name={set} subscription={{}}>
        {({ input: { onChange } }) => (
          <FormSpy subscription={{}}>
            {({ form }) => (
              <OnChange name={field}>
                {(value) => {
                  const numberValue = _.toNumber(value);

                  if (!numberValue) {
                    form.change('riosEstacao', []);
                    this.setState({
                      rios: [],
                    });
                    onChange(undefined);
                    return;
                  }

                  return createServiceFor("/rios/subBacia")
                    .customURL("GET", numberValue)
                    .then((res) => {
                      const data = res.data;
                      form.change('riosEstacao', data);
                      this.setState({
                        rios: data,
                      });
                      onChange(undefined);
                    })
                    .catch((e) => {
                      console.log(e);
                      store.dispatch(
                        showGlobalNotification({
                          message: "Região hidrográfica inválida",
                        })
                      );
                    });
                }}
              </OnChange>
            )}
          </FormSpy>
        )}
      </Field>
    );
  };

  getArray = (ArrayParaModificar) => {
    return ArrayParaModificar;
  };

  getMunicipios = (id, data) => {
    return data.municipiosPorEstado[id];
  };

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      nomeIdentificacao: this.state.initialValues?.nomeIdentificacao,
    });

    const formattedValues = {
      nomeIdentificacao: limitText(sanitizedValues?.nomeIdentificacao, 60),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.codigoIdentificacao
                ? `<div class="text">Estação</div> - <div class="text">Código: </div>${this.state.initialValues.codigoIdentificacao}`
                : ''
            }
            ${
              formattedValues?.nomeIdentificacao
                ? ` - <div class="text">Nome:</div> ${formattedValues.nomeIdentificacao}`
                : ''
            }
            ${
              this.state.initialValues?.statusObservacoes
                ? ` - <div class="text">Status:</div> ${StatusSerializedViewMap[this.state.initialValues.statusObservacoes]}`
                : ''
            }
            ${
              this.state.initialValues?.siglaTipoEstacaoIdentificacao
                ? ` - <div class="text">Tipo:</div> ${this.state.initialValues.siglaTipoEstacaoIdentificacao}`
                : ''
            }
            ${
              this.state.initialValues?.idSubBaciaIdentificacao
                ? ` - <div class="text">Sub-bacia:</div> ${this.state.initialValues.idSubBaciaIdentificacao}`
                : ''
            }
            ${
              this.state.initialValues?.idRoteiroIdentificacao
                ? ` - <div class="text">Roteiro:</div> ${this.state.initialValues.idRoteiroIdentificacao}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Cadastro de estação"
        baseUrl="/sgih/app/estacoes"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[formEstacaoDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push, pop },
                  },
                  pristine,
                  form,
                  submitting,
                  values,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <this.WhenComboChangesSubBacias
                        field="idRegiaoHidrograficaIdentificacao"
                        set="idSubBaciaIdentificacao"
                      />
                      <this.WhenComboChangesRios
                        field="idSubBaciaIdentificacao"
                        set="idRioIdentificacao"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Tab.Container
                        className="pb-2 esconder-abas-desativadas"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Identificação">
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Identificação">
                                <Row>
                                  <Col className="mt-0" md={2}>
                                    {/* TODO: Avisar que código já esta sendo utilizado (realizar requisição?) */}
                                    <Field
                                      component={TextField}
                                      name="codigoIdentificacao"
                                      label="Código"
                                      minLength={8}
                                      maxLength={8}
                                      disabled={disableAllFields}
                                      validate={naoEhCodigoEstacao}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-0" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="idRoteiroIdentificacao"
                                      label="Roteiro"
                                      elements={data.roteirosServico}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={
                                        roteiroServicoLabelResolver
                                      }
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Roteiro"
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-0" md={3}>
                                    <Field
                                      component={TextField}
                                      name="nomeIdentificacao"
                                      label="Nome"
                                      maxLength={100}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-0" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="siglaTipoEstacaoIdentificacao"
                                      label="Tipo"
                                      elements={data.tiposEstacao}
                                      valueResolver={tipoEstacaoLabelResolver}
                                      labelResolver={tipoEstacaoLabelResolver}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Tipo"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Localização">
                                <Row>
                                  <Col className="mt-0" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="idEstadoIdentificacao"
                                      label="Estado"
                                      elements={data.estados}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Estado"
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-0" md={2}>
                                    <Field
                                      name="idEstadoIdentificacao"
                                      subscription={{ value: true }}
                                    >
                                      {({ input: { value } }) => {
                                        return (
                                          <Field
                                            component={SelectField}
                                            name="idMunicipioIdentificacao"
                                            label="Município"
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              descriptionLabelResolver
                                            }
                                            disabled={disableAllFields}
                                            elements={this.getMunicipios(
                                              value,
                                              data
                                            )}
                                            validate={requiredWithNotification(
                                              "Município"
                                            )}
                                            required
                                          />
                                        );
                                      }}
                                    </Field>
                                  </Col>
                                  <Col className="mt-0" md={3}>
                                    <Field
                                      component={SelectField}
                                      name="idRegiaoHidrograficaIdentificacao"
                                      label="Região hidrográfica"
                                      elements={data.regioesHidrografica}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Região hidrográfica"
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-0" md={2}>
                                    <Field
                                      name="subBaciasEstacao"
                                      subscription={{ value: true }}
                                    >
                                      {() => (
                                        <Field
                                          component={SelectField}
                                          name="idSubBaciaIdentificacao"
                                          label="Sub-bacia"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={identifierValueResolver}
                                          disabled={disableAllFields}
                                          elements={
                                            this.getArray(this.state.subbacia)
                                              .length === 0
                                              ? this.getArray(data.subBaciasEstacao).length === 0 ? values.subBaciasEstacao : data.subBaciasEstacao
                                              : this.getArray(
                                                  this.state.subbacia
                                                )
                                          }
                                          validate={requiredWithNotification(
                                            "Sub-bacia"
                                          )}
                                          required
                                        />
                                      )}
                                    </Field>
                                  </Col>
                                  <Col className="mt-0" md={3}>
                                    <Field
                                      name="riosEstacao"
                                      subscription={{ value: true }}
                                    >
                                      {() => (
                                        <TipoEstacaoEh tipos={["P"]}>
                                          {(tipoCerto) => {
                                            return (
                                              <Field
                                                component={SelectField}
                                                name="idRioIdentificacao"
                                                label="Rio"
                                                elements={
                                                  tipoCerto // Se for tipo P, selecionar rio 999
                                                    ? [
                                                        {
                                                          nome: "Estação fora do curso d'água",
                                                          id: 999,
                                                        },
                                                      ]
                                                    : this.getArray(
                                                        this.state.rios
                                                      ).length === 0
                                                    ? this.getArray(data.riosEstacao).length === 0 ? values.riosEstacao : data.riosEstacao
                                                    : this.getArray(
                                                        this.state.rios
                                                      )
                                                }
                                                showEmptyOption={!tipoCerto}
                                                valueResolver={
                                                  identifierValueResolver
                                                }
                                                labelResolver={
                                                  descriptionLabelResolver
                                                }
                                                disabled={
                                                  disableAllFields || tipoCerto
                                                }
                                                validate={requiredWithNotification(
                                                  "Rio"
                                                )}
                                                required
                                              />
                                          )}}
                                        </TipoEstacaoEh>
                                      )}
                                    </Field>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="idBaciaHidrograficaIdentificacao"
                                      label="Bacia local"
                                      elements={data.baciasHidrografica}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Bacia local"
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={NumberField}
                                      name="drenagemIdentificacao"
                                      label="Área de drenagem (Km²)"
                                      disabled={disableAllFields}
                                      max={999999999}
                                      semVirgula={true}
                                      validate={isInteger(
                                        "Área de drenagem",
                                        false
                                      )}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={NumberField}
                                      name="latitudeIdentificacao"
                                      label="Latitude (G,ggg)"
                                      min={-90}
                                      max={90}
                                      step={0.0001}
                                      decimaisAposvirgula={4}
                                      disabled={disableAllFields}
                                      validate={isNumber(
                                        "Latitude",
                                        true,
                                        -90,
                                        90
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={NumberField}
                                      name="longitudeIdentificacao"
                                      label="Longitude (G,ggg)"
                                      min={-180}
                                      max={180}
                                      step={0.0001}
                                      decimaisAposvirgula={4}
                                      disabled={disableAllFields}
                                      validate={isNumber(
                                        "Longitude",
                                        true,
                                        -180,
                                        180
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-3" md={1}>
                                    <Field
                                      component={NumberField}
                                      name="altitudeIdentificacao"
                                      label="Altitude (m)"
                                      max={999999999}
                                      semVirgula={true}
                                      validate={isNumber("Altitude", false)}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={3}>
                                    <Field
                                      component={TextField}
                                      name="instrumentoIdentificacao"
                                      label="Instrumento"
                                      maxLength={50}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Instrumento"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="datumIdentificacao"
                                      label="Datum"
                                      elements={Datum}
                                      disabled={disableAllFields}
                                      valueResolver={descriptionLabelResolver}
                                      labelResolver={descriptionLabelResolver}
                                      validate={requiredWithNotification(
                                        "Datum"
                                      )}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="codigoFolhaIdentificacao"
                                      label="Código da folha"
                                      maxLength={20}
                                      disabled={disableAllFields}
                                      validate={isLong("Código da folha", true)}
                                      required
                                    />
                                  </Col>
                                  <Col className="mt-3" md={3}>
                                    <Field
                                      component={TextField}
                                      name="cartaCartograficaNome"
                                      label="Nome da folha"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="cartaCartograficaNomeOrgaoExecutor"
                                      label="Órgão executor"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={1}>
                                    <Field
                                      component={TextField}
                                      name="cartaCartograficaAno"
                                      label="Ano"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="cartaCartograficaNumeroEscalaCartografica"
                                      label="Escala"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      name="dataExtincaoIdentificacao"
                                      subscription={{ value: true }}
                                    >
                                      {({ input: { value } }) => (
                                        <Field
                                          component={DateTimeField}
                                          name="dataInstalacaoIdentificacao"
                                          label="Data de instalação"
                                          selectsStart={!!value}
                                          endDate={value}
                                          disabled={disableAllFields}
                                        />
                                      )}
                                    </Field>
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      name="dataInstalacaoIdentificacao"
                                      subscription={{ value: true }}
                                    >
                                      {({ input: { value } }) => (
                                        <Field
                                          component={DateTimeField}
                                          name="dataExtincaoIdentificacao"
                                          label="Data de extinção"
                                          minDate={value}
                                          startDate={value}
                                          disabled={disableAllFields}
                                        />
                                      )}
                                    </Field>
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="nomeCoordenadorIdentificacao"
                                      label="Coordenador"
                                      maxLength={100}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    {/* <Field component={TextField} name='nomeOperadorIdentificacao'
                                                                    label='Operador'
                                                                    maxLength={100}
                                                                    disabled={disableAllFields}
                                                                /> */}
                                    <Field
                                      component={SelectField}
                                      name="idUnidadeRegional"
                                      label="Unidade regional"
                                      elements={data.unidadesRegional}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={
                                        unidadeRegionalLabelResolver
                                      }
                                      disabled={true}
                                      validate={requiredWithNotification(
                                        "Unidade regional"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Dados de atualização da ficha">
                                <Row>
                                  <Col md={10}>
                                    <Field
                                      component={TextField}
                                      name="usuarioUltimaAlteracao"
                                      label="Ultima alteração por "
                                      disabled={true}
                                    />
                                    {/* <Field
                                                                name="idsMembrosEquipe"
                                                                component={JqxDropDownListAdapter}
                                                                source={data.usuarios}
                                                                label="Equipe" required
                                                                checkboxes={true}
                                                                displayMember="nome"
                                                                valueMember="id"
                                                                placeHolder="Selecione a equipe..."
                                                                searchMode="startswithignorecase"
                                                                enableSelection={true}
                                                                enableHover={true}
                                                                filterable={true}
                                                                incrementalSearch={true}
                                                                animationType="none"
                                                                closeDelay={0}
                                                                filterPlaceHolder=" Busque"
                                                                disabled={disableAllFields}
                                                                validate={requiredWithNotification("Equipe")}
                                                            /> */}
                                  </Col>
                                  <Col md={2}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataAtualizacaoIdentificacao"
                                      label="Data"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab title="Localização">
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Localização">
                                <Row>
                                  <Col md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="localizacaoLocalizacao"
                                      label="Localização"
                                      className="w-100"
                                      rows={3}
                                      maxLength={1000}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Localização"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Acessibilidade">
                                <Row>
                                  <Col md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="acessibilidadeLocalizacao"
                                      label="Acessibilidade"
                                      className="w-100"
                                      rows={3}
                                      maxLength={1000}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Acessibilidade"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Altitudes Ortométricas">
                                <FieldArray name="altitudesOrtometricas">
                                  {({ fields }) => (
                                    <>
                                      {fields.length === 0 && (
                                        <Row key="header-add-first-row-container">
                                          <Col
                                            className="align-items-center"
                                            sm={6}
                                            md={5}
                                            lg={3}
                                          >
                                            <span className="text-black-50">
                                              Nenhuma altitude ortométrica
                                              adicionada
                                            </span>
                                          </Col>
                                          <Col sm={6} md={3} lg={2}>
                                            <BotaoPadrao
                                              onClick={() => {
                                                push(
                                                  "altitudesOrtometricas",
                                                  {}
                                                );
                                              }}
                                            >
                                              Adicionar Linha
                                            </BotaoPadrao>
                                          </Col>
                                        </Row>
                                      )}
                                      {fields.map((name, index) => (
                                        <>
                                          {index === 0 && (
                                            <Row key="header-container">
                                              <Col sm={6} md={3}>
                                                <FieldLabel label="Altitude ortométrica do 0 (zero) da régua (m)" />
                                              </Col>
                                              <Col sm={4} md={2}>
                                                <FieldLabel label="Data de atualização" />
                                              </Col>
                                            </Row>
                                          )}
                                          <Row
                                            key={`added-item-row-${index + 1}`}
                                          >
                                            <Col className="mt-1" sm={6} md={3}>
                                              <Field
                                                component={NumberField}
                                                name={`${name}.altitudeOrtometricaZeroRegua`}
                                                max={999999999}
                                                min={-999999999}
                                                decimaisAposvirgula={3}
                                                disabled={disableAllFields}
                                                validate={requiredWithCustomValidationAndNotification(
                                                  (value) =>
                                                    value || value === 0,
                                                  "Altitude ortométrica do 0 (zero) da régua (m)"
                                                )}
                                              />
                                            </Col>
                                            <Col className="mt-1" sm={4} md={2}>
                                              <Field
                                                component={DateTimeField}
                                                name={`${name}.dataAtualizacao`}
                                                disabled={disableAllFields}
                                                validate={requiredWithCustomValidationAndNotification(
                                                  (value) => !!value,
                                                  "Data de atualização"
                                                )}
                                              />
                                            </Col>
                                            <Col className="mt-1" md={1}>
                                              {form.getState().values
                                                ?.altitudesOrtometricas
                                                ?.length > 0 &&
                                                !disableAllFields && (
                                                  <BotaoIconeExclusao
                                                    onClick={() => {
                                                      form.change(
                                                        `altitudesOrtometricas[${index}]`,
                                                        undefined
                                                      );
                                                      fields.remove(index);
                                                    }}
                                                  />
                                                )}
                                            </Col>
                                            <Col className="mt-1" md={1}>
                                              {!disableAllFields && (
                                                <BotaoPadrao
                                                  onClick={() => {
                                                    const altitudesOrtometricas =
                                                      form.getState().values
                                                        .altitudesOrtometricas;
                                                    if (
                                                      index !==
                                                      altitudesOrtometricas.length -
                                                        1
                                                    ) {
                                                      altitudesOrtometricas.splice(
                                                        index + 1,
                                                        0,
                                                        {}
                                                      );
                                                      delete form.getState()
                                                        .values
                                                        .altitudesOrtometricas;
                                                      for (let i of altitudesOrtometricas) {
                                                        push(
                                                          "altitudesOrtometricas",
                                                          i
                                                        );
                                                      }
                                                      return;
                                                    }
                                                    push(
                                                      "altitudesOrtometricas",
                                                      {}
                                                    );
                                                  }}
                                                >
                                                  Adicionar Linha
                                                </BotaoPadrao>
                                              )}
                                            </Col>
                                          </Row>
                                        </>
                                      ))}
                                    </>
                                  )}
                                </FieldArray>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          title="Pluviometria"
                          visible={this.tipoEstacaoEh(
                            ["P"],
                            form.getState().values.siglaTipoEstacaoIdentificacao
                          )}
                        >
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Equipamentos">
                                <Row>
                                  <Col md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="equipamentoPluviometria"
                                      label="Equipamento pluviométrico instalado"
                                      maxLength={300}
                                      className="w-100"
                                      rows={3}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab
                          title="Seção de réguas"
                          visible={
                            !this.tipoEstacaoEh(
                              ["P"],
                              form.getState().values
                                .siglaTipoEstacaoIdentificacao
                            )
                          }
                        >
                          <TipoEstacaoEh tipos={["P"]}>
                            {(tipoCerto) => (
                              <Row>
                                <Col md={12}>
                                  <FormSectionWrapper title="Descrição da Estação (pluviômetro, réguas, lances, RN'S, PI e PF)">
                                    <Row>
                                      <Col className="mt-0" md={3}>
                                        <Field
                                          component={SelectField}
                                          name="margemSecaoReguas"
                                          elements={Margem}
                                          label="Margem"
                                          validate={
                                            tipoCerto
                                              ? undefined
                                              : requiredWithNotification(
                                                  "Margem"
                                                )
                                          }
                                          required
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-3" md={12}>
                                        <Field
                                          component={TextAreaField}
                                          name="reguasReguas"
                                          label="Réguas"
                                          maxLength={300}
                                          className="w-100"
                                          rows={3}
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-3" md={12}>
                                        <Field
                                          component={TextAreaField}
                                          name="rnsReguas"
                                          label="RN's"
                                          maxLength={300}
                                          className="w-100"
                                          rows={3}
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                  </FormSectionWrapper>
                                </Col>
                              </Row>
                            )}
                          </TipoEstacaoEh>
                        </Tab>
                        <Tab
                          title="Seção de medição"
                          visible={
                            !this.tipoEstacaoEh(
                              ["P"],
                              form.getState().values
                                .siglaTipoEstacaoIdentificacao
                            )
                          }
                        >
                          <TipoEstacaoEh tipos={["P"]}>
                            {(tipoCerto) => (
                              <Row>
                                <Col md={12}>
                                  <FormSectionWrapper title="Seção e processos de medição">
                                    <Row>
                                      <Col className="mt-0" md={3}>
                                        <Field
                                          component={SelectField}
                                          name="idProcessoSecaoMedicao"
                                          elements={data.processosMedicao}
                                          valueResolver={
                                            identifierValueResolver
                                          }
                                          labelResolver={
                                            descriptionLabelResolver
                                          }
                                          label="Processos"
                                          validate={
                                            tipoCerto
                                              ? undefined
                                              : requiredWithNotification(
                                                  "Processo"
                                                )
                                          }
                                          required
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="mt-3" md={12}>
                                        <Field
                                          component={TextAreaField}
                                          name="secaoSecaoMedicao"
                                          label="Seção"
                                          maxLength={300}
                                          className="w-100"
                                          rows={3}
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                  </FormSectionWrapper>
                                </Col>
                              </Row>
                            )}
                          </TipoEstacaoEh>
                        </Tab>
                        <Tab
                          title="Características hidrológicas"
                          visible={
                            !this.tipoEstacaoEh(
                              ["P"],
                              form.getState().values
                                .siglaTipoEstacaoIdentificacao
                            )
                          }
                        >
                        <TipoEstacaoEh tipos={["P"]}>
                          {(tipoCerto) => {
                            let rios = form?.getState()?.values?.riosEstacao;
                            let valor;
                            let valorPotamografia;
                            if (rios) {
                              valor = rios.filter(
                                (item) =>
                                  item.id ===
                                  form?.getState()?.values?.idRioIdentificacao
                              );
                            }
                            if (valor) {
                              valorPotamografia = valor[0]
                                ? valor[0].potamografia
                                : "";
                            }
                            return (
                              <>
                                <Row>
                                  <Col className="pr-0" md={12}>
                                    <FormSectionWrapper title="Potamografia">
                                      <Row>
                                        <Col md={12}>
                                          <Field
                                            component={TextAreaField}
                                            name="_potamograficaRioCaracteristicas"
                                            label=""
                                            className="w-100"
                                            rows={3}
                                            disabled={true}
                                            initialValue={
                                              valorPotamografia
                                                ? valorPotamografia
                                                : ""
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pr-0" md={6}>
                                    <FormSectionWrapper title="Posição em relação à rede">
                                      <Row>
                                        <Col md={4}>
                                          <Field
                                            component={SelectField}
                                            name="idEstacaoMontanteCaracteristicas"
                                            label="Montante"
                                            elements={data.estacoes}
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              descriptionLabelResolver
                                            }
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            component={TextField}
                                            name="_nomeIdentificacaoCaracteristicas"
                                            label="Estação"
                                            disabled={true}
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            component={SelectField}
                                            name="idEstacaoJustanteCaracteristicas"
                                            label="Jusante"
                                            elements={data.estacoes}
                                            valueResolver={identifierValueResolver}
                                            labelResolver={descriptionLabelResolver}
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                  <Col className="pl-0" md={6}>
                                    <FormSectionWrapper title="Características do trecho">
                                      <Row>
                                        <Col md={4}>
                                          <Field
                                            component={SelectField}
                                            name="regimeCaracteristicas"
                                            label="Regime"
                                            elements={Regime}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Regime"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            component={SelectField}
                                            name="conformacaoCaracteristicas"
                                            label="Conformação"
                                            elements={Conformacao}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Conformação"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Field
                                            component={SelectField}
                                            name="fundoCaracteristicas"
                                            label="Fundo"
                                            elements={Fundo}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Fundo"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pr-0" md={6}>
                                    <FormSectionWrapper title="Natureza e inclinação das margens">
                                      <Row>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="inclinacaoEsquerdaCaracteristicas"
                                            label="Inclinação na margem esquerda"
                                            elements={InclinacaoMargem}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Inclinação na margem esquerda"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="idNaturezaEsquerdaCaracteristicas"
                                            label="Natureza da margem esquerda"
                                            elements={data.margensNatureza}
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              margemNaturezaLabelResolver
                                            }
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Natureza da margem esquerda"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                  <Col className="pl-0" md={6}>
                                    <FormSectionWrapper title="Natureza e inclinação das margens">
                                      <Row>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="inclinacaoDireitaCaracteristicas"
                                            label="Inclinação na margem direita"
                                            elements={InclinacaoMargem}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Inclinação na margem direita"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="idNaturezaDireitaCaracteristicas"
                                            label="Natureza da margem direita"
                                            elements={data.margensNatureza}
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              margemNaturezaLabelResolver
                                            }
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Natureza da margem direita"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="pr-0" md={6}>
                                    <FormSectionWrapper title="Natureza do leito">
                                      <Row>
                                        <Col md={12}>
                                          <Field
                                            component={SelectField}
                                            name="idNaturezaLeitoCaracteristicas"
                                            label="Natureza"
                                            elements={data.margensNatureza}
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              margemNaturezaLabelResolver
                                            }
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Natureza"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                  <Col className="pl-0" md={6}>
                                    <FormSectionWrapper title="Cota de transbordamento">
                                      <Row>
                                        <Col md={6}>
                                          <Field
                                            component={NumberField}
                                            name="cotaTransbordamentoCaracteristicas"
                                            label="Cota (cm)"
                                            max={999999999}
                                            semVirgula={true}
                                            validate={isInteger(
                                              "Cota",
                                              false
                                            )}
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="cotaMargemCaracteristicas"
                                            label="Margem"
                                            elements={Margem}
                                            disabled={disableAllFields}
                                            validate={
                                              tipoCerto
                                                ? undefined
                                                : requiredWithNotification(
                                                    "Margem"
                                                  )
                                            }
                                            required
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={12}>
                                    <FormSectionWrapper title="Controle">
                                      <Row>
                                        <Col md={6}>
                                          <Field
                                            component={SelectField}
                                            name="idTipoControleCaracteristicas"
                                            elements={data.tiposControle}
                                            valueResolver={
                                              identifierValueResolver
                                            }
                                            labelResolver={
                                              tipoControleLabelResolver
                                            }
                                            label="Tipo"
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                        <Col md={3}>
                                          <Field
                                            component={SelectField}
                                            name="localizacaoControleCaracteristicas"
                                            elements={montanteOuJusante}
                                            label="Localização"
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                        <Col md={3}>
                                          <Field
                                            component={NumberField}
                                            name="distanciaControleCaracteristicas"
                                            label="Distância"
                                            max={999999999}
                                            semVirgula={true}
                                            validate={isInteger(
                                              "Distância",
                                              false
                                            )}
                                            disabled={disableAllFields}
                                          />
                                        </Col>
                                      </Row>
                                    </FormSectionWrapper>
                                  </Col>
                                </Row>
                              </>
                            );
                          }}
                          </TipoEstacaoEh>
                        </Tab>
                        <Tab title="Observador">
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Dados do Observador">
                                <Row>
                                  <Col className="mt-0 pr-0" md={1}>
                                    <Field
                                      component={NumberField}
                                      name="idObservador"
                                      label="Código"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-0 pr-0" md={4}>
                                    <Field
                                      component={TextField}
                                      name="nomeObservador"
                                      label="Nome"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-0 pr-0" md={2}>
                                    <Field
                                      component={TextField}
                                      name="cepObservador"
                                      label="CEP"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-0" md={5}>
                                    <Field
                                      component={TextField}
                                      name="enderecoObservador"
                                      label="Endereço"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3 pr-0" md={3}>
                                    <Field
                                      component={TextField}
                                      name="complementoObservador"
                                      label="Complemento"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3 pr-0" md={3}>
                                    <Field
                                      component={TextField}
                                      name="bairroObservador"
                                      label="Bairro"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3 pr-0" md={3}>
                                    <Field
                                      component={TextField}
                                      name="estadoObservador"
                                      label="Estado"
                                      disabled={true}
                                    />
                                  </Col>
                                  <Col className="mt-3 pr-0" md={3}>
                                    <Field
                                      component={TextField}
                                      name="municipioObservador"
                                      label="Município"
                                      disabled={true}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab title="Observações gerais">
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Observações gerais">
                                <Row>
                                  <Col className="mt-0" md={3}>
                                    <Field
                                      component={SelectField}
                                      name="statusObservacoes"
                                      elements={Status}
                                      label="Status"
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification(
                                        "Status"
                                      )}
                                      required
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="observacoesGeraisObservacoes"
                                      label="Observações gerais"
                                      className="w-100"
                                      rows={3}
                                      maxLength={500}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Descrição dos equipamentos instalados">
                                <Row>
                                  <Col className="mt-0" md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="equipamentoObservacoes"
                                      label=""
                                      rows={3}
                                      maxLength={500}
                                      className="w-100"
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={7}>
                              <FormSectionWrapper title="Datas de instalação e extinção">
                                <Row className="mb-1">
                                  <Col md={2}>
                                    {/* <FieldLabel label="Tipo da Estação"></FieldLabel> */}
                                  </Col>
                                  <Col md={4}>
                                    <FieldLabel label="Instalação"></FieldLabel>
                                  </Col>
                                  <Col md={4}>
                                    <FieldLabel label="Extinção"></FieldLabel>
                                  </Col>
                                </Row>
                                <FieldArray name="instalacoesMedicao">
                                  {({ fields, meta }) =>
                                    fields.map((name, index) => {
                                      const siglaTipoEstacao =
                                        form.getState().values
                                          .instalacoesMedicao[index]
                                          .siglaTipoEstacao;
                                      return (
                                        <Row key={index}>
                                          <Col
                                            className="mb-3 d-flex align-items-center"
                                            md={2}
                                          >
                                            <Field
                                              component="input"
                                              type="hidden"
                                              name={`${name}.siglaTipoEstacao`}
                                            ></Field>
                                            <p className="mb-0">
                                              {siglaTipoEstacao}
                                            </p>
                                          </Col>
                                          <Col className="mb-3" md={4}>
                                            <Field
                                              name={`${name}.dataExtincao`}
                                              subscription={{ value: true }}
                                            >
                                              {({ input: { value } }) => (
                                                <Field
                                                  component={DateTimeField}
                                                  name={`${name}.dataInstalacao`}
                                                  label=""
                                                  datePickerType="date"
                                                  selectsStart={!!value}
                                                  endDate={value}
                                                  disabled={disableAllFields}
                                                />
                                              )}
                                            </Field>
                                          </Col>
                                          <Col className="mb-3" md={4}>
                                            <Field
                                              name={`${name}.dataInstalacao`}
                                              subscription={{ value: true }}
                                            >
                                              {({ input: { value } }) => (
                                                <Field
                                                  component={DateTimeField}
                                                  name={`${name}.dataExtincao`}
                                                  label=""
                                                  datePickerType="date"
                                                  minDate={value}
                                                  startDate={value}
                                                  disabled={disableAllFields}
                                                />
                                              )}
                                            </Field>
                                          </Col>
                                          <Col md="auto" className="mb-3">
                                            {disableAllFields ? null : (
                                              <BotaoIconeExclusao
                                                onClick={() =>
                                                  fields.remove(index)
                                                }
                                              />
                                            )}
                                          </Col>
                                        </Row>
                                      );
                                    })
                                  }
                                </FieldArray>

                                {disableAllFields ? null : (
                                  <Row>
                                    <Col className="mt-4" md={3}>
                                      <Field
                                        component={SelectField}
                                        name="_instMedTipoEstacao"
                                        label="Tipo"
                                        elements={data.tiposEstacao}
                                        valueResolver={tipoEstacaoLabelResolver}
                                        labelResolver={tipoEstacaoLabelResolver}
                                        disabled={disableAllFields}
                                      />
                                    </Col>
                                    <Col className="mt-5" md={6}>
                                      <BotaoPadrao
                                        onClick={() => {
                                          const novaSiglaTipoEstacao =
                                            form.getState().values
                                              ._instMedTipoEstacao;
                                          const instMed =
                                            form.getState().values
                                              .instalacoesMedicao;
                                          if (
                                            (novaSiglaTipoEstacao &&
                                              !instMed) ||
                                            (instMed &&
                                              !instMed.some(
                                                (i) =>
                                                  i.siglaTipoEstacao ===
                                                  novaSiglaTipoEstacao
                                              ))
                                          ) {
                                            push("instalacoesMedicao", {
                                              siglaTipoEstacao:
                                                novaSiglaTipoEstacao,
                                            });
                                          }
                                        }}
                                      >
                                        Adicionar
                                      </BotaoPadrao>
                                    </Col>
                                  </Row>
                                )}
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormEstacao;
