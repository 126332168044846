import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Field} from "react-final-form";
import DateTimeField from "../../../components/form/field/DateTimeField";
import NumberField from "../../../components/form/field/NumberField";
import SelectField from "../../../components/form/field/SelectField";
import React, {useState} from "react";
import {goodRegularOrBad, tiposStatusPCD} from "../../../model/AvaliacaoQualidade";
import TipoEstacaoNaoComecaCom from "./TipoEstacaoNaoComecaCom";
import CheckSetField from "../../../components/form/field/CheckSetField";
import { requiredWithNotification } from "../../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";

// https://codesandbox.io/s/10rzowm323
//força o valor com uma casa decimal
// const normalizeOffsetSensor = (value) => {
//     if (!value) return value;
//     // verifica se existe "."
//     if(String(value).indexOf(".") !== -1){
//         // converte em String
//         var texto = String(value)
//         // verifica se tem duas casas decimais após o ","
//         if(texto.length - texto.indexOf(".") > 2){
//             // pega o ultimo valor digitado
//             var ultimoCharacter = texto[(texto.length) - 1]
//             // quebra a string onde encontra a ","
//             texto = texto.substring(0,texto.indexOf(".") + 2)
//             // retorna após a "," o ultimo caracter digitado
//             texto = texto + ultimoCharacter
//         }
//         // não sei se é necessário (força o retorno com uma casa decimal)
//         return Number(texto).toFixed(2)
//     }

//     return value
// }

function AbaSituacaoEncontrada({prefixoCampos, desativarTodosCampos, sufixoLabel, dataObrigatoria=true}) {
    const [hoje] = useState(new Date());
    return <>
        <FormSectionWrapper>
            <Row>
                <Col className="mt-3" md={3}>
                    <Field
                        component={DateTimeField}
                        name={`${prefixoCampos}.dataVisita`}
                        label="Data"
                        disabled={desativarTodosCampos}
                        maxDate={hoje}
                        required={dataObrigatoria}
                        validate={dataObrigatoria ? requiredWithNotification("Data " + sufixoLabel) : undefined}
                    />
                </Col>
                <Col className="mt-3" md={3}>
                    <Field
                        component={DateTimeField}
                        name={`${prefixoCampos}.horaVisita`}
                        label="Hora"
                        datePickerType="hour"
                        disabled={desativarTodosCampos}
                        required={dataObrigatoria}
                        validate={dataObrigatoria ? requiredWithNotification("Hora " + sufixoLabel) : undefined}
                    />
                </Col>
                <Col className="mt-3" md={3}>
                <TipoEstacaoNaoComecaCom tipo="P">
                    {deveMostrar => {
                        const habilitado = deveMostrar && !desativarTodosCampos;
                        return <Field
                            component={NumberField}
                            max={999999999}
                            min={-999999999}
                            semVirgula={true}
                            name={`${prefixoCampos}.cota`}
                            label="Cota régua (cm)"
                            forcarValorInteiro
                            disabled={!habilitado}
                            key={habilitado}
                        />
                    }}
                </TipoEstacaoNaoComecaCom>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3" md={3}>
                    <Field
                        component={CheckSetField}
                        name={`${prefixoCampos}.limpeza`}
                        label="Limpeza da estação"
                        elements={goodRegularOrBad}
                        disabled={desativarTodosCampos}
                    />
                </Col>
                <Col className="mt-3" md={3}>
                    <Field
                        component={CheckSetField}
                        name={`${prefixoCampos}.exposicao`}
                        label="Exposição do pluviômetro"
                        elements={goodRegularOrBad}
                        disabled={desativarTodosCampos}
                    />
                </Col>
                <Col className="mt-3" md={3}>
                    <Field
                        component={CheckSetField}
                        name={`${prefixoCampos}.condicaoProtecao`}
                        label="Condição de proteção da PCD"
                        elements={goodRegularOrBad}
                        disabled={desativarTodosCampos}
                    />
                </Col>
                <Col className="mt-3" md={3}>
                    <Field
                        component={SelectField}
                        name={`${prefixoCampos}.statusPCD`}
                        label="Status da PCD"
                        elements={tiposStatusPCD}
                        disabled={desativarTodosCampos}
                    />
                </Col>
            </Row>
        </FormSectionWrapper>
        <FormSectionWrapper title="Leitura instantânea dos sensores">
            <TipoEstacaoNaoComecaCom tipo="F">
                {estacaoNaoEhFlu => {
                    return <Row>
                        <Col className="mt-3" md={2}>
                            <Field
                                component={NumberField}
                                max={9999999.9}
                                min={-9999999.9}
                                decimaisAposvirgula={1}
                                name={`${prefixoCampos}.cotaSensor`}
                                label="Cota sensor de nível (cm)"
                                forcarValorInteiro
                                // step={0.1}
                                // forcarValorZerado
                                disabled={desativarTodosCampos||estacaoNaoEhFlu}
                            />
                        </Col>
                        <Col className="mt-3" md={2}>
                            <Field
                                component={NumberField}
                                name={`${prefixoCampos}.offsetSensor`}
                                label="Offset sensor de nível (cm)"
                                max={99999999999999999.99}
                                min={-99999999999999999.99}
                                decimaisAposvirgula={2}
                                // forcarValorInteiro
                                //parse={normalizeOffsetSensor}
                                step={0.1}
                                // forcarValorZerado
                                disabled={desativarTodosCampos||estacaoNaoEhFlu}
                            />
                        </Col>
                        <Col className="mt-3" md={2}>
                            <Field
                                component={NumberField}
                                max={9999999.9}
                                min={-9999999.9}
                                decimaisAposvirgula={1}
                                name={`${prefixoCampos}.pressaoBarometrica`}
                                label="Pressão barométrica (mb)"
                                // forcarValorZerado
                                disabled={desativarTodosCampos||estacaoNaoEhFlu}
                            />
                        </Col>
                        <Col className="mt-3" md={2}>
                            <Field
                                component={NumberField}
                                max={9999999.9}
                                min={-9999999.9}
                                decimaisAposvirgula={1}
                                name={`${prefixoCampos}.temperaturaAgua`}
                                label="Temperatura água (°C)"
                                // forcarValorZerado
                                disabled={desativarTodosCampos||estacaoNaoEhFlu}
                            />
                        </Col>
                    </Row>
                }}
            </TipoEstacaoNaoComecaCom>
            <Row>
                <Col className="mt-3" md={2}>
                    <Field
                        component={NumberField}
                        max={9999999.9}
                        min={-9999999.9}
                        decimaisAposvirgula={1}
                        name={`${prefixoCampos}.chuvaAcumulada`}
                        label="Chuva acumulada (mm)"
                        disabled={desativarTodosCampos}
                        forcarValorZerado={true}
                    />
                </Col>
                <Col className="mt-3" md={2}>
                    <Field
                        component={NumberField}
                        max={9999999.9}
                        min={-9999999.9}
                        decimaisAposvirgula={1}
                        name={`${prefixoCampos}.temperaturaInterna`}
                        label="Temperatura interna (°C)"
                        disabled={desativarTodosCampos}
                        // forcarValorZerado
                    />
                </Col>
                <TipoEstacaoNaoComecaCom tipo="P">
                    {estacaoNaoEhPlu => {
                        return <>
                            <Col className="mt-3" md={2}>
                                <Field
                                    component={NumberField}
                                    max={9999999.9}
                                    min={-9999999.9}
                                    decimaisAposvirgula={1}
                                    name={`${prefixoCampos}.temperaturaAr`}
                                    label="Temperatura do ar (°C)"
                                    disabled={desativarTodosCampos||estacaoNaoEhPlu}
                                    // forcarValorZerado
                                />
                            </Col>
                            <Col className="mt-3" md={2}>
                                <Field
                                    component={NumberField}
                                    max={99.9}
                                    min={0}
                                    decimaisAposvirgula={1}
                                    name={`${prefixoCampos}.umidadeRelativa`}
                                    label="Umidade relativa (%)"
                                    disabled={desativarTodosCampos||estacaoNaoEhPlu}
                                    // forcarValorZerado
                                />
                            </Col>
                        </>
                    }}
                </TipoEstacaoNaoComecaCom>
            </Row>
            <Row>
                <Col className="mt-3" md={2}>
                    <Field
                        component={NumberField}
                        min={-9999999.9}
                        max={9999999.9}
                        decimaisAposvirgula={1}
                        name={`${prefixoCampos}.tensaoBateria`}
                        label="Tensão bateria (v)"
                        disabled={desativarTodosCampos}
                        // forcarValorZerado
                    />
                </Col>
                <Col className="mt-3" md={2}>
                    <Field
                        component={NumberField}
                        min={-9999999.9}
                        max={9999999.9}
                        decimaisAposvirgula={1}
                        name={`${prefixoCampos}.tensaoBateriaMulti`}
                        label="Tensão bateria (multímetro) (v)"
                        disabled={desativarTodosCampos}
                        // forcarValorZerado
                    />
                </Col>
                <Col className="mt-3" md={2}>
                    <Field
                        component={NumberField}
                        min={-9999999.9}
                        max={9999999.9}
                        decimaisAposvirgula={1}
                        name={`${prefixoCampos}.tensaoPainel`}
                        label="Tensão painel solar (multímetro) (v)"
                        disabled={desativarTodosCampos}
                        // forcarValorZerado
                    />
                </Col>
            </Row>
        </FormSectionWrapper>
    </>
}

export {AbaSituacaoEncontrada}