import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import createServiceFor from "../../service/Service";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification, isInteger } from "../../util/FormUtils";
import NumberField from "components/form/field/NumberField/NumberField";
import SelectField from "components/form/field/SelectField/SelectField";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

const isNotVehicleSign = (value) =>
  value
    ? value.match(/[A-Z]{3}\d[A-Z\d]\d{2}/)
      ? undefined
      : "Placa inválida"
    : "Placa obrigatória";
const identifierValueResolver = (e) => e.id || e.codigo;
const unidadeRegionalLabelResolver = (e) => e.siglaLocalUasg;

class PaginaFormVeiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/unidadesRegionais/simplesComPermissao").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/veiculos").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(([unidadesRegionais, veiculo = {}]) => {
        if (veiculo.data) {
          this.setState({
            initialValues: veiculo.data,
          });
        }

        return {
          unidadesRegionais: unidadesRegionais.data,
          initialValues: veiculo.data
            ? {
                ...veiculo.data,
                kmAtual: String(veiculo.data.kmAtual),
              }
            : unidadesRegionais.data.length === 1
            ? {
                idUnidadeRegional: unidadesRegionais.data[0].id,
              }
            : {},
        };
      })
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/veiculos")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Veículo salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (e.response.data && e.response.data.message) {
          store.dispatch(
            showGlobalNotification({
              message: e.response.data.message,
            })
          );
        } else {
          store.dispatch(
            showGlobalNotification({
              message: "Ocorreu um erro ao tentar salvar",
            })
          );
        }
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      marca: this.state.initialValues?.marca,
      tipo: this.state.initialValues?.tipo,
    });

    const formattedValues = {
      placa: this.state.initialValues?.placa,
      marca: limitText(sanitizedValues?.marca, 60),
      tipo: limitText(sanitizedValues?.tipo, 60),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Veículo</div> - <div class="text">Código: </div>${this.state.initialValues.id}`
                : ''
            }
            ${
              formattedValues?.placa
                ? ` - <div class="text">Placa:</div> ${formattedValues.placa}`
                : ''
            }
            ${
              formattedValues?.marca
                ? ` - <div class="text">Marca:</div> ${formattedValues.marca}`
                : ''
            }
            ${
              formattedValues?.tipo
                ? ` - <div class="text">Modelo:</div> ${formattedValues.tipo}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Veículos"
        baseUrl="/sgih/app/veiculos"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }

            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[]}
                mutators={{}}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados do veículo">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="placa"
                                  label="Placa"
                                  mask="placaDeCarro"
                                  className="w-100"
                                  validate={isNotVehicleSign}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="marca"
                                  label="Marca"
                                  maxLength={100}
                                  validate={requiredWithNotification("Marca")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="tipo"
                                  label="Modelo"
                                  maxLength={100}
                                  validate={requiredWithNotification("Modelo")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="combustivel"
                                  label="Combustível"
                                  maxLength={50}
                                  validate={requiredWithNotification(
                                    "Combustível"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="kmAtual"
                                  label="KM atual"
                                  max={999999999}
                                  validate={isInteger(
                                    "KM atual",
                                    true,
                                    0,
                                    999999999
                                  )}
                                  decimaisAposvirgula={0}
                                  semVirgula={true}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={SelectField}
                                  name="idUnidadeRegional"
                                  label="Unidade regional"
                                  elements={data.unidadesRegionais}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={unidadeRegionalLabelResolver}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Unidade regional"
                                  )}
                                  required
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormVeiculos;
