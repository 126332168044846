import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormBacia from "./PaginaFormBacia";
import PaginaListaBacias from "./PaginaListaBacias";

class PaginaBacias extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/baciasHidrograficas"}
            component={PaginaListaBacias}
          />
          <Route
            exact
            path={"/sgih/app/baciasHidrograficas/criar"}
            component={PaginaFormBacia}
          />
          <Route path={"/sgih/app/baciasHidrograficas/:id"} component={PaginaFormBacia} />
        </Switch>
      </>
    );
  }
}

export default PaginaBacias;
