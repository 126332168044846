import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaListaEstacoes from './PaginaListaEstacoes'
import PaginaFormEstacao from "./PaginaFormEstacao";

class PaginaEstacoes extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/estacoes"} component={PaginaListaEstacoes} />
                <Route exact path={"/sgih/app/estacoes/criar"} component={PaginaFormEstacao} />
                <Route path={"/sgih/app/estacoes/:id"} component={PaginaFormEstacao} />
            </Switch>
        </>;
    }
}

export default PaginaEstacoes;