import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormEquipamentos from "./PaginaFormEquipamentos";
import PaginaListaEquipamentos from "./PaginaListaEquipamentos";

class PaginaEquipamentos extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/equipamentos"} component={PaginaListaEquipamentos} />
                <Route exact path={"/sgih/app/equipamentos/criar"} component={PaginaFormEquipamentos} />
                <Route path={"/sgih/app/equipamentos/:id"} component={PaginaFormEquipamentos} />
            </Switch>
        </>;
    }
}

export default PaginaEquipamentos;