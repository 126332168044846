import React from "react";
import { Redirect } from "react-router-dom";
import { Component } from "react";
import TextField from "../form/field/TextField";
import PasswordField from "../form/field/PasswordField";
import BotaoPadrao from "../botao/BotaoPadrao";
import { Field, Form } from "react-final-form";
import { Alert, Row, Col } from "react-bootstrap";
import { efetuarLogin } from "../../reducers/authReducers";
import { connect } from "react-redux";
import "./Login.css";

class Login extends Component {
  render() {
    if (this.props.autenticado) {
      return (
        <Redirect
          to={{
            pathname: "/sgih/app/index",
            state: {
              from: this.props.location,
            },
          }}
        />
      );
    }

    const erro =
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.erro) ||
      this.props.erro;

    return (
      <>
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            flexDirection: "column",
          }}
        >
          {erro && (
            <Alert variant="danger" style={{ width: "350px" }}>
              {erro}
            </Alert>
          )}
          <div
            style={{
              width: "600px",
              backgroundColor: "#f4f4f4",
              boxShadow: "0 0.5882rem 2.353rem 0 rgba(231,231,231,1)",
            }}
          >
            <div
              style={{
                fontSize: "24px",
                textAlign: "center",
                color: "#666",
                width: "100%",
                backgroundColor: "#fff",
                margin: "0 auto 0 auto",
                padding: "20px",
              }}
            >
              Acesse o Sistema
            </div>
            <br />
            <br />
            <div
              style={{
                width: "500px",
                backgroundColor: "#fff",
                margin: "0 auto 0 auto",
                boxShadow: "0 0.5882rem 2.353rem 0 rgba(231,231,231,1)",
                padding: "35px",
              }}
            >
              <span
                style={{
                  color: "#555",
                  Bottom: "20px",
                  fontSize: "20px",
                }}
              >
                Login
              </span>
              <br />
              <br />
              <Form
              className="login"
                onSubmit={this.enviarFormulario.bind(this)}
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <input
                        type="hidden"
                        name="redirectUrl"
                        value={
                          process.env.REACT_APP_PUBLIC_URL + "/oauth2/redirect"
                        }
                      />

                      <Field
                        component={TextField}
                        name="usuario"
                        label=""
                        placeholder="U s u á r i o"
                      />
                      <div className="mt-2" />
                      <Field
                        component={PasswordField}
                        name="senha"
                        label=""
                        type="password"
                        placeholder="Senha"
                      />
                      <Row>
                        <Col sm="6" md="6">
                          <BotaoPadrao
                            onClick={() => form.submit()}
                            largura="100%"
                            className="mt-3"
                          >
                            Acessar
                          </BotaoPadrao>
                        </Col>
                        <Col sm="6" md="6" className="arcgis">
                          <BotaoPadrao
                            onClick={this.logarViaArcGIS.bind(this)}
                            largura="100%"
                            className="mt-3"
                          >
                            Mudar para ArcGIS
                          </BotaoPadrao>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              />
            </div>
            <br />
            <br />
          </div>
        </div>
      </>
    );
  }

  enviarFormulario(valores) {
    this.props.efetuarLogin(valores.usuario, valores.senha);
  }

  logarViaArcGIS() {
    window.location =
      process.env.REACT_APP_API_URL +
      "/oauth2/authorize/arcgis?redirect_uri=" +
      process.env.REACT_APP_PUBLIC_URL +
      "/oauth2/redirect";
    //'http://localhost:8080/oauth2/authorize/arcgis?redirect_uri=http://localhost:3000/sgih/app/oauth2/redirect';
  }
}

export default connect(
  (state) => ({
    autenticado: state.auth.autenticado,
    erro: state.auth.erro,
  }),
  (dispatch) => ({
    efetuarLogin(usuario, senha) {
      dispatch(efetuarLogin({ usuario, senha }));
    },
  })
)(Login);
