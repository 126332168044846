import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormRelatorioPluviometrico from "./PaginaFormRelatorioPluviometrico";
import PaginaListaRelatoriosPluviometricos from "./PaginaListaRelatoriosPluviometricos";

class PaginaRelatoriosPluviometricos extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosPluviometricos"} component={PaginaListaRelatoriosPluviometricos} />
                <Route exact path={"/sgih/app/relatoriosPluviometricos/criar"} component={PaginaFormRelatorioPluviometrico} />
                <Route path={"/sgih/app/relatoriosPluviometricos/:id"} component={PaginaFormRelatorioPluviometrico} />
            </Switch>
        </>;
    }
}

export default PaginaRelatoriosPluviometricos;