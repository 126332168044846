export const removeHTMLTags = (strToSanitize) => {
    if (typeof strToSanitize !== 'string') {
        return strToSanitize;
    }

    try {
        const domParser = new DOMParser()
        const htmlContent = domParser.parseFromString(strToSanitize, 'text/html');

        return htmlContent?.body?.textContent || '';
    } catch (error) {
        return strToSanitize;
    }

}

export const sanitizeStringValuesFromObject = (obj = {}) => {
    return Object.keys(obj)
        .reduce((previous, key) => ({
            ...previous,
            [key]: removeHTMLTags(obj[key]),
        }), {});
};