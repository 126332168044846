import React, {useCallback} from "react";
import PropTypes from "prop-types";

import "./FileCard.css"
import {connect} from "react-redux";
import formatarBytes from "../../../util/formatarBytes";
import { mapearTipo } from "../../../util/Arquivo";

const iconesCustomizados = {
    "file": "fa-file",
    "zip": "fa-file-archive",
};

// ***************************************************************************

function Spinner({ carregando }) {
    return carregando && <div className="file-grid__spinner">
        <div />
        <div />
        <div />
        <div />
    </div>;
}

function PreviewImagem({caminho, carregando, onClickImagem}) {
    const className = `file-grid__preview ${carregando ? 'file-grid__preview--uploading' : ''}`;
    return <div className={className} onClick={onClickImagem}>
        <img className="file-grid__preview" style={{margin: "0 auto", display: "block", maxWidth: "100%"}}
             src={caminho} alt="" />
        <Spinner carregando={carregando} />
    </div>
}

function PreviewIcone({tipo, carregando, onClick}) {
    const classeContainer = `file-grid__preview--${tipo}`;
    const classeIcone = iconesCustomizados[tipo] || `fa-file-${tipo}`;
    const className = `file-grid__preview file-grid__preview--has-icon ${classeContainer}` +
        `${carregando  ? ' file-grid__preview--uploading' : ''}`;

    return <div className={className}
                onClick={onClick}>
        <i className={`file-grid__preview-icon fas ${classeIcone}`} />
        <Spinner carregando={carregando} />
    </div>
}

function ErroPreview({onClick, erro}) {
    return !!erro && <div className="file-grid__preview file-grid__preview--has-icon file-grid__preview--has-error" onClick={onClick}>
        <i className={`file-grid__preview-icon fas fa-exclamation`} />
    </div>
}

function FileCard({arquivo, chave, mostrarBotaoExclusao, mostrarBotaoDownload, onClickImagem = () => {}, onExcluir = () => {} }) {
    
    const arquivoAux = arquivo ? arquivo : { nome: '', caminho: '', tamanho: 0, tipo: '', carregando: false, baixavel: false, erro: false, arquivoInvalido: true};

    const {
        nome,
        caminho,
        tamanho,
        tipo,
        carregando = false,
        baixavel = true,
        erro,
        arquivoInvalido,
    } = arquivoAux;

    const download = useCallback((event) => {
        event.stopPropagation();
        const link = document.createElement('a');
        link.href = caminho;
        link.click();
    }, [caminho]);

    const eventoOnClickImagem = useCallback((event) => {
        event.stopPropagation();
        if (onClickImagem) onClickImagem();
    }, [onClickImagem]);

    const eventoOnClickGenerico = useCallback((event) => {
        event.stopPropagation();
    }, []);

    return arquivoInvalido ? <></> : <div className="file-grid__file">
        {erro
            ? <ErroPreview onClick={eventoOnClickGenerico} erro={erro} />
            : ("imagem" === mapearTipo(tipo)
                ? <PreviewImagem caminho={caminho} carregando={carregando} onClickImagem={eventoOnClickImagem}/>
                : <PreviewIcone tipo={mapearTipo(tipo)} carregando={carregando} onClick={download} />
            )
        }
        <div className="file-grid__infoAndActions" onClick={eventoOnClickGenerico}>
            <div className="file-grid__info">
                {!erro && <>
                    <p className="file-grid__text file-grid__file-name">{nome}</p>
                    <p className="file-grid__text file-grid__file-size">{formatarBytes(tamanho, 0)}</p>
                </>}
                {erro && <p className="file-grid__text file-grid__file-error">{erro}</p>}
            </div>
            <div className="file-grid__actions">
                {/* adicionar tooltips aqui??? */}
                {mostrarBotaoExclusao && <span className="file-grid__icon file-grid__icon--exclude"
                                               onClick={() => {
                                                   onExcluir(chave)
                                               }} />}
                {mostrarBotaoDownload && baixavel && <span className="file-grid__icon file-grid__icon--download"
                                                           onClick={download}><i className="fas fa-file-download" /></span>}
            </div>
        </div>
    </div>
}

FileCard.propTypes = {
    nomeGrid: PropTypes.string.isRequired,
    chave: PropTypes.string.isRequired,

    nome: PropTypes.string,
    hashArquivo: PropTypes.string,

    mostrarBotaoExclusao: PropTypes.bool,
    mostrarBotaoDownload: PropTypes.bool,
    onExcluir: PropTypes.func,
    onClickImagem: PropTypes.func,

    // Redux
    arquivo: PropTypes.object,
};

FileCard.defaultProps = {
    mostrarBotaoExclusao: true,
    mostrarBotaoDownload: true,
};

export default connect(
    ({files}, {nomeGrid, chave}) => {
        return {
            arquivo: files[nomeGrid][chave]
        }
    },
)(FileCard);