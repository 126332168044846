import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormUsuarios from "./PaginaFormUsuarios";
import PaginaListaUsuarios from "./PaginaListaUsuarios";

class PaginaUsuarios extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/usuarios"}
            component={PaginaListaUsuarios}
          />
          <Route
            exact
            path={"/sgih/app/usuarios/criar"}
            component={PaginaFormUsuarios}
          />
          <Route
            path={"/sgih/app/usuarios/:id"}
            component={PaginaFormUsuarios}
          />
        </Switch>
      </>
    );
  }
}

export default PaginaUsuarios;
