import React from "react";
import PropTypes from "prop-types";
import { GlobalLoader } from "../loader/Loader";
import NavBar from "../navBar/NavBar";
import Container from "react-bootstrap/Container";
import Notification from "../notification/Notification";
import { ModalAlerta } from "../modal/ModalAlerta";
import Footer from "components/footer/Footer";
import "./pageScaffold.css";
class PageScaffold extends React.Component {
  render() {
    return (
      <GlobalLoader
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <NavBar titulo={this.props.title} exibirCompleto />
        {this.props.customInfo && (
          <div className={this.props.subHeaderClass} >
          <div dangerouslySetInnerHTML={{ __html: this.props.customInfo }} />
          </div>
        )}
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          <Container fluid>
            {this.props.children}
            <Notification />
            <ModalAlerta />
          </Container>
        </div>
        <Footer />
      </GlobalLoader>
    );
  }
}

PageScaffold.propTypes = {
  title: PropTypes.string,
  subHeaderClass: PropTypes.string,
  children: PropTypes.node,
};

PageScaffold.defaultProps = {
  title: "Sistema de Gerenciamento de Informações Hidrométricas - SGIH",
  subHeaderClass: 'sub-header'
};

export default PageScaffold;
