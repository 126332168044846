import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaUnidadesRegionais extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "sigla", type: "string", map: "siglaLocalUasg" },
        { name: "cnpjUasg", type: "string", map: "cnpjUasg" },
        { name: "numeroLocalUasg", type: "number", map: "numeroLocalUasg" },
        { name: "siglaUfUasg", type: "string", map: "siglaUfUasg" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Unidade administrativa", dataField: "numeroLocalUasg",filtertype: 'number', width: 150 },
      { text: "CNPJ", dataField: "cnpjUasg", width: 135 },
      { text: "Estado", dataField: "siglaUfUasg" },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Unidades administrativas"
        tableConfig={this.tableConfig}
        baseUrl="/unidadesRegionais"
        history={this.props.history}
        tableName="unidadesRegionais"
        formLinkTitle="Nova unidade administrativa"
        showFormLink={false}
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
        shouldAddEditButton={false}
        shouldAddDeleteButton={false}
      />
    );
  }
}

export default PaginaListaUnidadesRegionais;
