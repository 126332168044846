export const BAIXA = {
  viewName: "Baixa", serializedName: "BAIXA",
};

export const MEDIA = {
  viewName: "Média", serializedName: "MEDIA",
};

export const ALTA = {
  viewName: "Alta", serializedName: "ALTA",
};

export default [
  ALTA, BAIXA, MEDIA
];