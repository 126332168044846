import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormFluxoRelatorios from "./PaginaFormFluxoRelatorios";

class PaginaFluxoRelatorios extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route path={"/sgih/app/fluxoRelatorios/:tipo/:id"} component={PaginaFormFluxoRelatorios} />
        </Switch>
      </>
    );
  }
}

export default PaginaFluxoRelatorios;
