import React, { useState} from "react";
import { SecaoFormulario } from "components/SecaoFormulario/SecaoFormulario";
import { Row, Col } from "react-bootstrap";
import { requiredWithNotification } from "util/FormUtils";
import { Field } from "react-final-form";
import TextAreaField from "components/form/field/TextAreaField";
import SelectField from "../../components/form/field/SelectField";
import Table from "../../components/table/Table";
import TextField from "components/form/field/TextField";

const labelResolver = (u) => u.nome
const valueResolver = (u) => u.id

const fonteListaAlteracoes = {
  datafields: [
    { name: "id", type: "number", map: "id" },
    { name: "data", type: "date", map: "dataAtualizacao", format: 'dd/MM/yyyy HH:mm' },
    { name: "perfil", type: "string", map: "perfil" },
    { name: "usuario", type: "string", map: "usuario" },
    { name: "descricao", type: "string", map: "descricao" },
  ],
  id: "id",
}

const colunasListaAlteracoes = [
  { text: "Data", dataField: "data", filtertype: 'date', cellsformat: 'dd/MM/yyyy HH:mm', width: 110, filterable: false, sortable: false },
  { text: "Por", dataField: "usuario" , filterable: false, sortable: false},
  { text: "Para", dataField: "perfil", width: 120 , filterable: false, sortable: false},
  { text: "Descrição", dataField: "descricao" , filterable: false, sortable: false},
];


function AbaDadosBasicos({desabilitarTodosCampos, perfisPossiveis, alteracoesRelatorio}) {
  const [contadorLetras, setContadorLetras] = useState(null);
  return <>
    <SecaoFormulario>
      <Row className="mb-3">
        <Col className="mt-3" md={2}>
            <Field
                component={TextField}
                label="Código relatório"
                name="idRelatorio"
                readOnly
            />
        </Col>
        <Col className="mt-3" md={3}>
          <Field
              component={TextField}
              label="Relatório"
              name="tipoRelatorio"
              readOnly
          />
        </Col>
        <Col className="mt-3" md={2}>
          <Field
              component={TextField}
              label="Código estação"
              name="codigoEstacaoFluxo"
              readOnly
          />
        </Col>
        <Col className="mt-3" md={5}>
          <Field
              component={TextField}
              label="Estação"
              name="nomeEstacaoFluxo"
              readOnly
          />
        </Col>
      </Row>
      <Row className="mb-3">
          
        <Col className="mt-3" md={12}>
          <Field
              component={SelectField}
              elements={perfisPossiveis}
              name="idPerfil"
              label="Novo status"
              validate={requiredWithNotification("Novo status")}
              labelResolver={labelResolver}
              valueResolver={valueResolver}
              required
              disabled={desabilitarTodosCampos}
          />
        </Col>
        <Col className="mt-3" md={12}>
          <Field
              component={TextAreaField}
              name="descricao"
              label="Descrição"
              rows={5}
              maxLength={1200}
              disabled={desabilitarTodosCampos}
              onChange={setContadorLetras}
          />
        </Col>
        <Col className="mt-3 text-right" md={12}>
          <span >
            Restam {contadorLetras ? 1200 - contadorLetras.length : 1200 } caracteres.
          </span>
        </Col>
      </Row>
    </SecaoFormulario>
    {alteracoesRelatorio && alteracoesRelatorio.length > 0 && <SecaoFormulario title="Alterações de status do relatório">
      <Table columns={colunasListaAlteracoes}
      name="fluxoRelatorios"
             source={{...fonteListaAlteracoes, localdata: alteracoesRelatorio}}
             persistirEstadoTabela={false} habilitarTooltip />
    </SecaoFormulario>}
  </>;
}

export { AbaDadosBasicos };
