import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaMunicipios extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        {
          name: "siglaEstado",
          type: "string",
          map: "siglaEstado",
        },
        {
          name: "valorPercentualISS",
          type: "number",
          map: "valorPercentualISS",
        },
        { name: "nome", type: "string", map: "nome" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: "5%" },
      { text: "Estado", dataField: "siglaEstado", width: "5%" },
      { text: "Alíquota ISS (%)", dataField: "valorPercentualISS", width: "15%", cellsrenderer(row, columnfield, value) {
        return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;">${(value || 0).toFixed(2).replace(".", ",")}</div>`
      }},
      { text: "Município", dataField: "nome" },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Municípios"
        tableConfig={this.tableConfig}
        baseUrl="/municipios"
        history={this.props.history}
        formLinkTitle="Novo município"
        tableName="municipios"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
        forcarPOSTListagem={true}
      />
    );
  }
}

export default PaginaListaMunicipios;
