import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import CheckField from "../../components/form/field/CheckField";
import SelectField from "../../components/form/field/SelectField";
import DateTimeField from "../../components/form/field/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";

import {
  goodRegularOrBad,
  gradingWithInexistent,
  OPERACAO_DE_ROTEIRO,
  tiposPagamento,
  tiposVisita,
  yesOrNo,
} from "../../model/AvaliacaoQualidade";
import Container from "react-bootstrap/Container";
import createDecorator from "final-form-calculate";
import { FieldArray } from "react-final-form-arrays";
import FormsButton from "../../components/botao/FormsButton/FormsButton"
import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";
import Error from "../../components/form/Error";
import Fields from "../../components/form/Fields";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import CheckSetField from "../../components/form/field/CheckSetField";
import BotaoIconeExclusao from "../../components/botao/BotaoIconeExclusao";
import BotaoPadrao from "../../components/botao/BotaoPadrao";
import FileGridField from "../../components/form/field/FileGridField";
import { limparArquivos } from "../../reducers/fileReducers";
import { connect } from "react-redux";
import SelectRangeNumericoField from "../../components/form/field/SelectRangeNumericoField";
import AlertaCondicional from "../../components/form/AlertaCondicional";
import {JqxDropDownListAdapter} from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import { requiredWithNotification, requiredWithNotificationCheckField } from "../../util/FormUtils";
import TextAreaField from "components/form/field/TextAreaField";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import voltarParaGrid from "util/VoltarParaGrid";

// TODO: Refactor validation functions (into util file?) to be used in other forms
const requiredWithMessage = (message) => (value) =>
  value ? undefined : message;
const atLeastOneElement = (value) =>
  value && value.length ? undefined : "Ordem de serviço obrigatório";
const tipoEstacaoDeveConter = (contain) => (value, allValues) =>
  requiredWithMessage("O campo Código é obrigatório. ")(value) ||
  (!!allValues.tipoEstacao && allValues.tipoEstacao.includes(contain)
    ? undefined
    : "Digite o código de uma estação tipo " + contain);

    let dadosEstacao;

// const requiredIfStationTypeIs = stationTypes => ifOtherValTypeIs('estacao', stationTypes, required);

// const composeValidators = (...validators) => value =>
//     validators.reduce((error, validator) => error || validator(value), undefined)

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  {
    field: "codigoEstacaoDigitado",
    updates: (value, name, allValues) => {
      if (value && value.length >= 8) {
        return createServiceFor("/relatoriosPluviometricos")
          .customURL("GET", `paraEstacao/${value}`)
          .then((res) => {
            const data = res.data;
            let dadosASubstituir = {
              codigoEstacao: value,
              estacaoSelecionada: data,
              nomeEstacao: data.nome,
              baciaEstacao: data.bacia,
              roteiroEstacao: data.roteiro,
              tipoEstacao: data.tipo,
            };
            // Se a estação mudou ou é um registro novo.
            if ("undefined" === typeof allValues.id || (allValues.id && value !== allValues.codigoEstacao)) {
              dadosASubstituir["observador"] = data.observador;
            }

            if(allValues.update){
              dadosASubstituir.tipoEstacao = allValues.tipo;

              if(allValues.codigoEstacaoDigitado != allValues.codigoEstacao)
                dadosASubstituir.tipoEstacao = data.tipo;
            }

            return dadosASubstituir;
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              estacaoSelecionada: null,
              nomeEstacao: "",
              baciaEstacao: "",
              roteiroEstacao: "",
              tipoEstacao: "",
              observador: "",
            };
          });
      }else{
        return {
          estacaoSelecionada: null,
          nomeEstacao: "",
          baciaEstacao: "",
          roteiroEstacao: "",
          tipoEstacao: "",
          observador: "",
        };
      }
      return {};
    },
  },
  {
    field: "ordensServico",
    updates: {
      idsOrdemServico: (value) => value.map((e) => e.id),
    },
  },
  {
    field: "estacaoSelecionada",
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          codigoEstacaoDigitado: value.codigo,
        };
      }

      return {};
    },
  },
  {
    field: "substituicaoObservador",
    updates: (value, name, allValues, prevValues) => {
      if (value === "NAO"){
        return {
          dataSubstituicaoObservador: null
        }
      }
      return {}
    }
  },
  {
    field: "houvePagamentoObservador",
    updates: (value, name, allValues, prevValues) => {
      if (value === "NAO"){
        return {
          tipoPagamento: null,
          inicioPeriodoPagto: null,
          fimPeriodoPagto: null
        }
      }
      return {}
    }
  },
  {
    field: "semObservador",
    updates: (value, name, allValues, prevValues) => {
      if (value){
        return {
          leituraPluviometro: null,
          recolhimentoBoletins: null,
          periodoRecolhimentoBoletins: null,
          estoqueProvetas: null,
          substituicaoObservador: null,
          dataSubstituicaoObservador: null,
          dataEnvelopes: null,
          falhasEncontradas: null,
          dataVencimentoCaderneta: null,
          avaliacaoObservadorNoPeriodo: null,
          houvePagamentoObservador: null,
          tipoPagamento: null,
          fimPeriodoPagto: null,
          inicioPeriodoPagto: null,
          observacoesHistorico: null,
          nomeObservador: null,
          observador: null,
        }
      }
      return {}
    }
  }
);

const CampoRelacionadoAHouvePagamentoObservador = ({ children }) => (
  <Field name="houvePagamentoObservador" subscription={{ value: true }}>
    {({ input: { value } }) =>  children("SIM" === value)}
  </Field>
);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;

class PaginaFormRelatorioPluviometrico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dados:dadosEstacao,
      emEdicao: false,
      fotosRemovidas: [],
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/tiposOrdemServico/pluviometricos").query(),
      createServiceFor("/estacoes/pluviometricas").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/relatoriosPluviometricos").get(
          this.props.match.params.id
        )
      );

      this.setState({
        emEdicao: true
      })
    }

    const promise = Promise.all(promises)
      .then(async ([usuarios, tiposOrdemServico, estacoes, relatorio = {}]) => {

        if(this.props.match.params.id) {
          try{
            const codigoEstacao = relatorio.data.codigoEstacao;

            const res = await createServiceFor("/relatoriosPluviometricos")
            .customURL("GET", `paraEstacao/${codigoEstacao}`);

            const estacao = res.data;

            this.setState({dados: estacao})

            if(!estacoes.data.find((e) => e.id === estacao.id)){
              estacoes.data.push(estacao);
              estacoes.data = estacoes.data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
            }
          }catch(err){}
        }

        const semObservador = (!relatorio?.data?.avaliacaoObservadorNoPeriodo || !relatorio?.data?.substituicaoObservador);

        if(relatorio.data) {
          this.setState({
            relatorio: relatorio.data || null
          });
        }

        return {
        usuarios: usuarios.data,
        tiposOrdemServico: tiposOrdemServico.data,
        estacoes: estacoes.data,
        initialValues: relatorio.data
          ? {
              ...relatorio.data,
              semObservador,
              leituraPluviometro: relatorio.data.leituraPluviometro || "0",
              ordensServico: tiposOrdemServico.data.filter((tipo) =>
                (relatorio.data.idsOrdemServico || []).includes(tipo.id)
              ),
              codigoEstacaoDigitado: relatorio.data.codigoEstacao,
              observador: relatorio.data.observadorOriginal,
            }
          : {
              semObservador: false
            },
      }})
      .catch((e) => {
        console.log(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  componentWillUnmount() {
    this.props.limparArquivos();
  }

  submitForm(values) {
    /*
    const fotos = [...(values.hashesNovasFotos ?? []), ...(values.idsFotosExistentes ?? [])];
    const totalFotos = fotos.filter((idFoto) => !this.state.fotosRemovidas.find(removida => removida == idFoto));
    if(totalFotos.length < 2){
      store.dispatch(
        showGlobalNotification({
          message: "Fotos obrigatórias não inseridas",
          forcarExibicao: true,
        })
      );
      return;
    }
    */

    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/relatoriosPluviometricos")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
        <div class="content">
          ${this.state?.relatorio && this.state.dados
            ? `
              ${
                this.state?.relatorio?.id
                  ? `<div class="text">Relatório pluviométrico</div> - <div class="text">ID: </div>${this.state.relatorio.id}`
                  : ''
              }
              ${
                this.state?.dados?.nome
                  ? ` - <div class="text">Estação: </div>${this.state.dados.nome}`
                  : ''
              }
              ${
                this.state?.relatorio?.campanha
                  ? ` - <div class="text">Campanha: </div>${this.state?.relatorio?.campanha}`
                  : ''
              }
              ${
                this.state?.relatorio?.roteiro
                  ? ` - <div class="text">Roteiro: </div>${this.state?.relatorio?.roteiro}`
                  : ''
              }
              ${
                this.props?.location?.state?.data
                  ? ` - ${this.props?.location?.state?.data?.regional}`
                  : ''
              }
          `
          : ''}
        </div>
      `
      : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;


    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Relatórios pluviométricos"
        baseUrl="/sgih/app/relatoriosPluviometricos"
        shouldAddReportFlowButton={true}
        tipoRelatorio="PLUVIOMETRICO"
        dados ={this.state.dados}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={(event) => this.submitForm(event)}
                decorators={[calculatedStationDecorator]}
                initialValues={data.initialValues}
                mutators={{ ...arrayMutators }}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                  submitting,
                  values,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>

                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      {/* <FormsButton history={this.props.history} finalForm={this.formWrapper(form)} estaEditando={estaEditando} ></FormsButton> */}
                      <Field
                        component="input"
                        type="hidden"
                        name="codigoEstacao"
                      />

                      <Tab.Container
                        className="pb-2"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true
                        )}
                      >

                        <Tab title="Dados da estação">


                          {estaEditando && <Container fluid>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                    component={TextField}
                                    name="origem"
                                    label="Origem relatório"
                                    readOnly
                                />
                              </Col>
                            </Row>
                          </Container>}
                          <FormSectionWrapper title="Dados da estação">
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="codigoEstacaoDigitado"
                                  disabled={this.state.emEdicao ? true : disableAllFields}
                                  required
                                  label="Código"
                                  validate={tipoEstacaoDeveConter("P")}
                                  minLength={8}
                                  maxLength={8}
                                  mostrarMensagemOnBlur
                                />
                                <Error name="codigoEstacaoDigitado" />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={SelectField}
                                  name="estacaoSelecionada"
                                  label="Estação"
                                  elements={data.estacoes}
                                  buscavel
                                  callbackWithOriginalValue
                                  disabled={this.state.emEdicao ? true : disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="baciaEstacao"
                                  label="Bacia"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={1}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "roteiro" : "roteiroEstacao"}
                                  label="Roteiro"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={1}>
                                <Field
                                  component={TextField}
                                  name={"tipoEstacao"}
                                  label="Tipo"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Dados da visita">
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                    name="idsMembrosEquipe"
                                    component={JqxDropDownListAdapter}
                                    source={data.usuarios}
                                    label="Equipe" required
                                    checkboxes={true}
                                    displayMember="nome"
                                    valueMember="id"
                                    placeHolder="Selecione a equipe..."
                                    searchMode="startswithignorecase"
                                    enableSelection={true}
                                    enableHover={true}
                                    filterable={true}
                                    incrementalSearch={true}
                                    animationType="none"
                                    closeDelay={0}
                                    filterPlaceHolder=" Busque"
                                    disabled={disableAllFields}
                                    validate={requiredWithNotification("Equipe")}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="tipoVisita"
                                  disabled={disableAllFields}
                                  required
                                  defaultValue={
                                    OPERACAO_DE_ROTEIRO.serializedName
                                  }
                                  label="Tipo de visita"
                                  elements={tiposVisita}
                                  initialValue="OPERACAO_DE_ROTEIRO"
                                  validate={requiredWithNotification(
                                    "Tipo de visita"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="data"
                                  label="Data"
                                  required
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Data")}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="hora"
                                  label="Hora"
                                  datePickerType="hour"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification("Hora")}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="campanha"
                                  label="Campanha"
                                  datePickerType="yearMonth"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Campanha"
                                  )}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Informações gerais">
                          <FormSectionWrapper title="Situação encontrada">
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="acessos"
                                  label="Acessos"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Acessos")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="exposicao"
                                  label="Exposição"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Exposição"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="limpeza"
                                  label="Limpeza"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Limpeza")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="gramado"
                                  disabled={disableAllFields}
                                  required
                                  label="Gramado"
                                  elements={gradingWithInexistent}
                                  validate={requiredWithNotification("Gramado")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="cercado"
                                  disabled={disableAllFields}
                                  required
                                  label="Cercado"
                                  elements={gradingWithInexistent}
                                  validate={requiredWithNotification("Cercado")}
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="servicosRealizadosSituacao"
                                  maxLength={1000}
                                  disabled={disableAllFields}
                                  label="Serviços realizados"
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Pluviômetro">
                          <FormSectionWrapper title="Situação encontrada">
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="alturaAreaCaptacaoPluviometro"
                                  initialValue={1.5}
                                  label="Altura da área de captação (m)"
                                  min={0.00}
                                  step={0.1}
                                  max={9999}
                                  decimaisAposvirgula={2}
                                  presicion={1}
                                  required
                                  forcarValorZerado
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Altura da área de captação (m)"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="suporte"
                                  label="Suporte"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Suporte")}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="nivelamento"
                                  label="Nivelamento"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Nivelamento"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="torneira"
                                  label="Torneira"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Torneira"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="conservacao"
                                  label="Conservação"
                                  required
                                  elements={goodRegularOrBad}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Convervação"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="obstrucao"
                                  label="Obstrução"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Obstrução"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="amassadura"
                                  label="Amassadura"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Amassadura"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="vazamentos"
                                  label="Vazamentos"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Vazamentos"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="servicosPluviometro"
                                  maxLength={300}
                                  disabled={disableAllFields}
                                  label="Serviços"
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="O.S.">
                          <FormSectionWrapper title="Ordens de serviço para próxima viagem">
                            <Row>
                              <Col
                                className="mt-3"
                                md={disableAllFields ? 12 : 10}
                              >
                                <Field
                                  component={SelectField}
                                  name="ordemServicoSelecionada"
                                  callbackWithOriginalValue={true}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  disabled={disableAllFields}
                                  elements={data.tiposOrdemServico}
                                />
                              </Col>
                              {!disableAllFields && (
                                <Col className="mt-3" md={2}>
                                  <BotaoPadrao
                                    onClick={() => {
                                      const workOrder = form.getState().values
                                        .ordemServicoSelecionada;
                                      if (workOrder) {
                                        push("ordensServico", workOrder);
                                      }
                                    }}
                                  >
                                    Adicionar
                                  </BotaoPadrao>
                                </Col>
                              )}
                            </Row>
                            <FieldArray
                              name="ordensServico"
                              validate={atLeastOneElement}
                            >
                              {({ fields }) => {
                                const necessarioDetalharOutrasOpcoes = (
                                  fields.value || []
                                ).some((os) => 80 === os.id);
                                return (
                                  <div>
                                    <Error name="ordensServico" />
                                    {fields.map((name, index) => {
                                      return (
                                        <Row className="mt-3" key={index}>
                                          <Col>
                                            <Field
                                              component={TextField}
                                              label=""
                                              name={`ordensServico[${index}].descricao`}
                                              disabled={disableAllFields}
                                              readOnly={true}
                                            />
                                          </Col>
                                          <Col md="auto">
                                            <BotaoIconeExclusao
                                              onClick={() =>
                                                fields.remove(index)
                                              }
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                    {necessarioDetalharOutrasOpcoes && (
                                      <div className="mt-3">
                                        <Field
                                          component={TextAreaField}
                                          name="descOSOutrasOpcoes"
                                          label="Outras descrições"
                                          maxLength={1000}
                                          rows={4}
                                          disabled={disableAllFields}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Histórico">
                          <FormSectionWrapper>
                            <Field
                              component={CheckField}
                              name="semObservador"
                              type="checkbox"
                              className="form-control"
                              label="Não existe observador"
                              disabled={disableAllFields}
                            />

                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={NumberField}
                                      name="leituraPluviometro"
                                      label="Leitura no pluviômetro (mm)"
                                      min={0.00}
                                      step={0.1}
                                      max={99999999.99}
                                      decimaisAposvirgula={2}
                                      presicion={2}
                                      disabled={value ? true : disableAllFields}
                                      required={!value}
                                      validate={requiredWithNotificationCheckField(
                                        "Leitura no pluviômetro (mm)", "semObservador"
                                      )}
                                    />
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={CheckSetField}
                                      name="recolhimentoBoletins"
                                      label="Recolhimento de boletins"
                                      disabled={value ? true : disableAllFields}
                                      required={!value}
                                      elements={yesOrNo}
                                      validate={requiredWithNotificationCheckField(
                                        "Recolhimento de boletins", "semObservador"
                                      )}
                                    />
                                  }}
                                </Field>
                                <AlertaCondicional
                                  nome="recolhimentoBoletins"
                                  valor="SIM"
                                  mensagem="Informe os meses de boletins recolhidos"
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="recolhimentoBoletins"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const disabled = value !== "SIM";
                                    return (
                                      <>
                                        <Field
                                          component={TextField}
                                          maxLength={80}
                                          name="periodoRecolhimentoBoletins"
                                          label="Período de recolhimento de boletim"
                                          validate={
                                            disabled
                                              ? undefined
                                              : requiredWithNotification(
                                                  "Período de recolhimento de boletim"
                                                )
                                          }
                                          required={!disabled}
                                          key={disabled}
                                          disabled={
                                            disableAllFields || disabled
                                          }
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    return <Field
                                      component={SelectRangeNumericoField}
                                      name="estoqueProvetas"
                                      disabled={value ? true : disableAllFields}
                                      required={!value}
                                      defaultValue={1}
                                      label="Estoque de provetas"
                                      validate={requiredWithNotificationCheckField(
                                        "Estoque de provetas", "semObservador"
                                      )}
                                      de={1}
                                      ate={4}
                                    />
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {
                                    return <>
                                      <Field
                                        component={CheckSetField}
                                        name="substituicaoObservador"
                                        label="Substituição do observador"
                                        elements={yesOrNo}
                                        disabled={value ? true : disableAllFields}
                                        required={!value}
                                        validate={requiredWithNotificationCheckField(
                                          "Substituição do observador", "semObservador"
                                        )}
                                      />
                                      <AlertaCondicional
                                        nome="substituicaoObservador"
                                        valor="SIM"
                                        mensagem="Informe o motivo da substituição do observador no campo de observações"
                                      />
                                    </>
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="substituicaoObservador"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const disabled = "SIM" !== value;
                                    return (
                                      <Field
                                        component={DateTimeField}
                                        name="dataSubstituicaoObservador"
                                        label="Data de substituição do observador"
                                        disabled={disableAllFields || disabled}
                                        key={disabled}
                                        required={!disabled}
                                        validate={
                                          disabled
                                            ? undefined
                                            : requiredWithNotification(
                                                "Data de substituição do observador"
                                              )
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>

                            <Field name="semObservador" subscription={{ value: true }}>
                              {({ input: { value } }) => {

                              return <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={DateTimeField}
                                    name="dataEnvelopes"
                                    label="Data envelopes"
                                    disabled={value ? true : disableAllFields}
                                    datePickerType="yearMonth"
                                  />
                                </Col>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={CheckSetField}
                                    name="falhasEncontradas"
                                    label="Falhas encontradas"
                                    elements={yesOrNo}
                                    disabled={value ? true : disableAllFields}
                                    required={!value}
                                    validate={requiredWithNotificationCheckField(
                                      "Falhas encontradas", "semObservador"
                                    )}
                                  />
                                </Col>
                              </Row>
                            }}
                            </Field>
                            <Field name="semObservador" subscription={{ value: true }}>
                              {({ input: { value } }) => {

                              return <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={DateTimeField}
                                    name="dataVencimentoCaderneta"
                                    label="Data caderneta"
                                    disabled={value ? true : disableAllFields}
                                    required={!value}
                                    validate={requiredWithNotificationCheckField(
                                      "Data caderneta", "semObservador"
                                    )}
                                    datePickerType="yearMonth"
                                  />
                                </Col>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={CheckSetField}
                                    name="avaliacaoObservadorNoPeriodo"
                                    label="Avaliação do observador no período"
                                    elements={goodRegularOrBad}
                                    disabled={value ? true : disableAllFields}
                                    required={!value}
                                    validate={requiredWithNotificationCheckField(
                                      "Avaliação do observador no período. ", "semObservador"
                                    )}
                                  />
                                  <AlertaCondicional
                                    nome="avaliacaoObservadorNoPeriodo"
                                    valor="BOM"
                                    inverter
                                    mensagem="Informe o motivo da sua avaliação no campo observações gerais"
                                  />
                                </Col>
                              </Row>
                             }}
                            </Field>

                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field name="semObservador" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                  return <Field
                                    component={CheckSetField}
                                    name="houvePagamentoObservador"
                                    label="Houve pagamento do observador ?"
                                    elements={yesOrNo}
                                    disabled={value ? true : disableAllFields}
                                    validate={requiredWithNotificationCheckField(
                                      "Houve pagamento do observador", "semObservador"
                                    )}
                                    required={!value}
                                  />
                                }}
                                </Field>
                              </Col>

                              <CampoRelacionadoAHouvePagamentoObservador>
                                {(habilitado) => (
                                  <Col className="mt-3" md={3}>
                                    <>
                                      <Field
                                        component={SelectField}
                                        name="tipoPagamento"
                                        disabled={disableAllFields || !habilitado}
                                        label="Tipo de pagamento"
                                        elements={tiposPagamento}
                                        validate={habilitado ? requiredWithNotification(
                                          "Tipo de pagamento"
                                        ) : undefined}
                                        key={habilitado}
                                        required={habilitado}
                                      />
                                    </>
                                  </Col>
                                )}
                              </CampoRelacionadoAHouvePagamentoObservador>

                              <CampoRelacionadoAHouvePagamentoObservador>
                                {(habilitado) => (
                                    <>
                                      <Field
                                        name="tipoPagamento"
                                        subscription={{ value: true }}
                                      >
                                        {({ input: { value } }) => {
                                          const disabled = !value;
                                          return (
                                    <>
                                      <Col className="mt-3" md={3}>
                                        <Field
                                          name="fimPeriodoPagto"
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => (
                                            <Field
                                              component={DateTimeField}
                                              name="inicioPeriodoPagto"
                                              label="Início pagamento"
                                              datePickerType="yearMonth"
                                              disabled={disableAllFields || !habilitado}
                                              selectsStart={!!value}
                                              endDate={value}
                                              key={habilitado}
                                              required={habilitado}
                                              validate={
                                                disableAllFields || !habilitado
                                                  ? undefined
                                                  : requiredWithNotification(
                                                      "Início pagamento"
                                                    )
                                              }
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                      <Col className="mt-3" md={3}>
                                        <Field
                                          name="inicioPeriodoPagto"
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => (
                                            <Field
                                              component={DateTimeField}
                                              name="fimPeriodoPagto"
                                              label="Fim pagamento"
                                              datePickerType="yearMonth"
                                              disabled={disableAllFields || !habilitado}
                                              selectsEnd={!!value}
                                              startDate={value}
                                              key={habilitado}
                                              required={habilitado}
                                              validate={
                                                disableAllFields || !habilitado
                                                  ? undefined
                                                  : requiredWithNotification(
                                                      "Fim pagamento"
                                                    )
                                              }
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                    </>
                                  );
                                }}
                              </Field>
                                    </>

                                )}
                              </CampoRelacionadoAHouvePagamentoObservador>
                            </Row>

                            <Row>

                              {/*<Field name="tipoEstacao" subscription={{value: true}}>*/}
                              {/*    {({value}) => {*/}
                              {/*    }}*/}
                              {/*</Field>*/}
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "nomeObservador" : "observador"}
                                  disabled={disableAllFields}
                                  label="Observador"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Fields
                                  subscription={{ value: true }}
                                  names={[
                                    "avaliacaoObservadorNoPeriodo",
                                    "substituicaoObservador",
                                  ]}
                                >
                                  {({
                                    valores: {
                                      avaliacaoObservadorNoPeriodo,
                                      substituicaoObservador,
                                    },
                                  }) => {
                                    const obrigatorio =
                                      "SIM" === substituicaoObservador ||
                                      (avaliacaoObservadorNoPeriodo && "BOM" !== avaliacaoObservadorNoPeriodo);

                                    const campoHabilitado = substituicaoObservador && avaliacaoObservadorNoPeriodo;
                                    return (
                                      <Field
                                        component={TextAreaField}
                                        name="observacoesHistorico"
                                        disabled={!campoHabilitado ? true : disableAllFields}
                                        required={obrigatorio}
                                        maxLength={500}
                                        key={obrigatorio}
                                        label="Observações"
                                        validate={
                                          obrigatorio
                                            ? requiredWithNotification(
                                                "Observações"
                                              )
                                            : undefined
                                        }
                                      />
                                    );
                                  }}
                                </Fields>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Fotos">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                            <div className="text-center" style={{color : 'red'}} >
                                <p>
                                  Obedeça a seguinte ordem para inserir as
                                  fotos:
                                </p>
                                <p>
                                 1 - Pluviômetro e 2 - Visão Geral.
                                </p>
                              </div>
                              <FileGridField
                                campoNovosArquivos="hashesNovasFotos"
                                campoArquivosExistentes="idsFotosExistentes"
                                chaveListaArquivos="fotosPlu"
                                bloquearTipos={true}
                                limiteArquivos={5}
                                permitirImagens={true}
                                permitirVideos={true}
                                disable={disableAllFields}
                                excluirCb={(id) => {
                                  this.setState({ fotosRemovidas: [ ...this.state.fotosRemovidas, id]})
                                }}
                              />
                              <p style={{ marginTop: 20, color: 'red', textAlign: 'center', fontSize: 14 }} >Incluir pelo menos 2 fotos (obrigatórias) conforme legenda e até 3 fotos extras (não obrigatórias), totalizando até 5 fotos.</p>
                            </div>
                          </FormSectionWrapper>

                        </Tab>

                        <Tab title="Boletins">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosBoletins"
                                campoArquivosExistentes="idsBoletinsExistentes"
                                chaveListaArquivos="boletinsPlu"
                                bloquearTipos={true}
                                permitirImagens={true}
                                permitirVideos={true}
                                disable={disableAllFields}
                                id="BOLETINS"
                              />
                            </div>
                          </FormSectionWrapper>

                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            )
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default connect(null, (dispatch) => ({
  limparArquivos() {
    dispatch(limparArquivos({ store: "fotosPlu" }));
    dispatch(limparArquivos({ store: "boletinsPlu" }));
  },
  dispatch,
}))(PaginaFormRelatorioPluviometrico);
