import React from "react";
import CrudFormPageScaffold from "../../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/form/field/TextField";
import SelectField from "../../../components/form/field/SelectField/SelectField";
import DateTimeField from "../../../components/form/field/DateTimeField/DateTimeField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../../service/Service";
import createDecorator from "final-form-calculate";
import PromiseLoader from "../../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../../components/scaffold/FormPageScaffoldCommon";
import store from "../../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../../reducers";
import { requiredWithNotification } from "../../../util/FormUtils";
import NumberField from "components/form/field/NumberField/NumberField";
import { JqxDropDownListAdapter } from "components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import TextAreaField from "components/form/field/TextAreaField";
import CheckSetField from "components/form/field/CheckSetField/CheckSetField";
import Realizado, { REALIZADO } from "model/Realizado";
import moment from "moment";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import { data } from "jquery";
import voltarParaGrid from "util/VoltarParaGrid";

const identifierValueResolver = (e) => e.id || e.codigo || e;
const descriptionLabelResolver = (e) => e.descricao || e.numero || e.nome || e;
const parametroLabelResolver = e => `${e.nomeUnidadeRegional}  -  ${e.id}`;

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  {
    field: "idUnidadeRegional",
    updates: (value, name, allValues) => {
      const parametroVazio = {
        parametroRecibo: undefined,
        fonte: "",
        unidadeAdministrativa: "",
        vinculacao:"",
        centroCusto: "",
        programaTrabalho: "",
        cargoQuemAutoriza: "",
        cargoQuemConfere: "",
        textoUm: "",
        textoDois: "",
        percentualINSSObservador: "",
        percentualINSSCPRM: ""
      };

      if(!value)
        return parametroVazio;

      if(allValues.parametroRecibo && allValues.parametros && allValues.parametros.length){
        let parametroReciboFieldValue = allValues.parametroRecibo

        let listaFiltrada = allValues.parametros.filter((item) => item.idUnidadeRegional == value);

        let selecionado = listaFiltrada.find((item) => item.id == parametroReciboFieldValue|| item.id == parametroReciboFieldValue?.id);

        if(!selecionado)
          return parametroVazio;
      }

      return {}
    }
  },
  {
    field: "codigoEstacaoDigitado",
    updates: (value, name, allValues) => {
      const id = allValues.id;
      const estaEditando = !!id;

      if (value && value.length >= 8) {

        if (estaEditando) {
          return createServiceFor("/recibos/gerenciamento")
          .customURL("GET", `${id}/estacao`)
          .then((res) => {
            const data = res.data;
            return {
              nomeEstacao: data.nome,
              estacaoSelecionada: data,
              nomeObservador: data.nomeObservador,
              valorMensal: Number(data.valorGratificacaoTipo).toFixed(2),
              valorISSPercentualFormatado: data.valorISSPercentualFormatado,
              codigoEstacao: value,
              codigoObservador: data.codigoObservador,
              idUnidadeRegional: estaEditando ? allValues.idUnidadeRegional : data.idUnidadeRegional,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Não foi possível encontrar a estação relacionada.",
              })
            );
            return {
              estacaoSelecionada: null,
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              nomeObservador: "",
              valorMensal: "",
              valorISSPercentualFormatado: "",
              codigoEstacao: "",
              idUnidadeRegional: estaEditando ? allValues.idUnidadeRegional : null,
            };
          });
        }

        return createServiceFor("/recibos/gerenciamento")
          .customURL("GET", `estacao/${value}`)
          .then((res) => {
            const data = res.data;
            return {
              nomeEstacao: data.nome,
              estacaoSelecionada: data,
              nomeObservador: data.nomeObservador,
              valorMensal: Number(data.valorGratificacaoTipo).toFixed(2),
              valorISSPercentualFormatado: data.valorISSPercentualFormatado,
              codigoEstacao: value,
              codigoObservador: data.codigoObservador,
              idUnidadeRegional: estaEditando ? allValues.idUnidadeRegional : data.idUnidadeRegional,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              estacaoSelecionada: null,
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              nomeObservador: "",
              valorMensal: "",
              valorISSPercentualFormatado: "",
              codigoEstacao: "",
              idUnidadeRegional: estaEditando ? allValues.idUnidadeRegional : null,
            };
          });
      }
      return {};
    },
  },
  {
    field: 'estacaoSelecionada',
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        if (allValues.meses && allValues.valorMensal && allValues.valorISSPercentualFormatado) {

          const vlBruto = Number(allValues.valorMensal * Number(allValues.meses).toFixed(1)).toFixed(2);
          const vlISS = Number(allValues.valorISSPercentualFormatado * vlBruto).toFixed(2);
          return {
            valorBruto: vlBruto,
            valorISS: vlISS,
            'codigoEstacaoDigitado': value.codigo,

          }
        }
        return {
          'codigoEstacaoDigitado': value.codigo,
        }
      }

      return {}
    }
  },
  {
    field: ['dataInicial', 'dataFinal'],
    updates: (value, name, allValues, prevValues) => {
      let meses = 0;
      if (allValues.dataInicial && allValues.dataFinal) {
        const dataInicial = moment(allValues.dataInicial, 'DD/MM/YYYY')
        const dataFinal = moment(allValues.dataFinal, 'DD/MM/YYYY')

        if (dataInicial.month() === dataFinal.month()) {
          const difEmDias = dataFinal.diff(dataInicial, 'days');
          meses = difEmDias / dataInicial.daysInMonth();
        } else {
          const qtdDiasMesInicial = dataInicial.daysInMonth() + 1 - dataInicial.date();
          const porcentagemMesInicialPassado = qtdDiasMesInicial / dataInicial.daysInMonth();

          const qtdDiasMesFinal = dataFinal.date();
          const porcentagemMesFinalPassado = qtdDiasMesFinal / dataFinal.daysInMonth();

          const qtdMesesDif = dataFinal.month() - dataInicial.month() - 1;

          meses = porcentagemMesFinalPassado + porcentagemMesInicialPassado + qtdMesesDif;
        }

        if(dataInicial.year() !== dataFinal.year()){
          const difEmAnos = dataFinal.year() - dataInicial.year();
          const qtdDiasMesFinal = dataFinal.date();
          const qtdMesesDif = dataFinal.month() - dataInicial.month();
          const porcentagemMesFinalPassado = qtdDiasMesFinal / dataFinal.daysInMonth();
          meses = porcentagemMesFinalPassado + qtdMesesDif + (difEmAnos * 12);
        }
      }

      const vlBruto = Number(allValues.valorMensal * Number(meses).toFixed(1)).toFixed(2);
      const vlISS = Number(allValues.valorISSPercentualFormatado * vlBruto).toFixed(2);
      const vlINSS = Number(allValues.percentualINSSObservador * vlBruto).toFixed(2);

      return {
        meses: Number(meses).toFixed(2),
        valorBruto: vlBruto,
        valorISS: vlISS,
        valorLiquido: Number(vlBruto - vlINSS - vlISS).toFixed(2),
        valorINSS: vlBruto * allValues.percentualINSSObservador,
        valorINSSCPRM: vlBruto * allValues.percentualINSSCPRM,

      }
    }
  },
  {
    // Limpar motivo do pagamento ao selecionar houvePagamento realizado
    field: 'houvePagamento',
    updates: (value) => {
      return value === 'S' ? { motivoPagamento: '' } : {};
    }
  },
  {
    field: 'parametroRecibo',
    updates: (value, name, allValues, prevValues) => {
      return createServiceFor("/recibos/parametros")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            const vlBruto = Number(allValues.valorMensal * allValues.meses).toFixed(2);
            const vlISS = Number(allValues.valorISSPercentualFormatado * vlBruto).toFixed(2);
            return {
              fonte: data.fonte,
              unidadeAdministrativa: data.unidadeAdministrativa,
              vinculacao: data.vinculacao,
              centroCusto: data.centroCusto,
              programaTrabalho: data.programaTrabalho,
              cargoQuemAutoriza: data.cargoQuemAutoriza ? data.cargoQuemAutoriza.nome : null,
              cargoQuemConfere: data.cargoQuemConfere ? data.cargoQuemConfere.nome : null,
              textoUm: data.textoUm,
              textoDois: data.textoDois,
              percentualINSSObservador: data.percentualINSSObservadorFormatado,
              percentualINSSCPRM: data.percentualINSSCPRMFormatado,
              valorISS: vlISS,
              valorLiquido: Number(vlBruto - vlBruto * allValues.percentualINSSObservador - vlISS).toFixed(2),
              valorINSS: vlBruto * allValues.percentualINSSObservador,
              valorINSSCPRM: vlBruto * allValues.percentualINSSCPRM,

            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número do parâmetro inválido",
              })
            );
            return {
              fonte: "",
              unidadeAdministrativa: "",
              vinculacao:"",
              centroCusto: "",
              programaTrabalho: "",
              cargoQuemAutoriza: "",
              cargoQuemConfere: "",
              textoUm: "",
              textoDois: "",
              percentualINSSObservador: "",
              percentualINSSCPRM: ""
            };
          });
    }
  }
);

class PaginaFormGerenciamentoRecibo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    }
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/recibos/parametros/simples").query(),
      createServiceFor("/estacoes/ativas").query(),
      createServiceFor("/usuarios/simples").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(createServiceFor("/recibos/gerenciamento").get(this.props.match.params.id));

      this.setState({
        emEdicao: true
      });

    }

    const promise = Promise.all(promises).then(([
      parametros,
      estacoes,
      usuarios,
      recibo = {},
    ]) => ({
      parametros: parametros.data,
      estacoes: estacoes.data,
      usuarios: usuarios.data,
      initialValues: recibo.data
        ? {
          ...recibo.data,
          codigoEstacaoDigitado: recibo.data.codigoEstacao,
          valorMensal: recibo.data.valorMensal,
          parametros: parametros.data
        }
        : {},
    })
    )
      .catch((e) => {
        console.log(e);
        store.dispatch(showGlobalNotification({
          message: e.message
        }))
      });

    this.setState({
      promiseDados: promise
    });
  }

  submitForm(values) {
    console.log(values)
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/recibos/gerenciamento")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Recibo salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        let erro = "Ocorreu um erro ao tentar salvar";
        if (e.response && e.response.data) {
          if (e.response.data.erros) erro = e.response.data.erros[0];
        }

        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: erro,
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Gerenciamento de recibo</div> - <div class="text">Código: </div>${this.state.initialValues?.id}`
                : ''
            }
            ${
              this.state?.initialValues?.codigoEstacao
                ? ` - <div class="text">Código da estação: </div>${this.state.initialValues?.codigoEstacao}`
                : ''
            }
            ${
              this.state?.initialValues?.unidadeAdministrativa
                ? ` - <div class="text">Regional: </div>${this.state?.initialValues?.unidadeAdministrativa}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Gerenciamento de recibos"
        baseUrl="/sgih/app/recibos/gerenciamento"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }

            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field component="input" type="hidden"
                        name="id" value={id} />
                      <Field component="input" type="hidden"
                        name="valorISSPercentualFormatado" />
                      <Field component="input" type="hidden"
                        name="percentualINSSObservador" />
                        <Field component="input" type="hidden"
                        name="percentualINSSCPRM" />
                         <Field component="input" type="hidden"
                        name="idUnidadeRegional" />
                      <Tab.Container
                        className="pb-2 esconder-abas-desativadas"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >


                        <Tab title="Identificação da estação" visible={estaEditando && !disableAllFields}>
                          <FormSectionWrapper >
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="id"
                                  label="Código do recibo"
                                  // validate={requiredWithNotification("Código do recibo")}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="codigoEstacao"
                                  label="Código da estação"
                                  // validate={requiredWithNotification("Código da estação")}
                                  // required
                                  // disabled={disableAllFields}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="nomeEstacao"
                                  label="Nome da estação"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="codigoObservador"
                                  label="Código do observador"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="nomeObservador"
                                  label="Nome do observador"
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Dados financeiros" visible={estaEditando && !disableAllFields}>
                          <FormSectionWrapper >
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field name="dataFinal" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicial"
                                      label="Data inicial"
                                      endDate={value}
                                      maxDate={value}
                                      validate={requiredWithNotification("Data inicial")}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field name="dataInicial" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataFinal"
                                      label="Data final"
                                      minDate={value}
                                      startDate={value}
                                      validate={requiredWithNotification("Data final")}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="meses"
                                  decimaisAposvirgula={1}
                                  label="Quantidade de meses"
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorMensal"
                                  label="Valor mensal (R$)"
                                  decimaisAposvirgula={2}
                                  forcarSeparadorDecimalVirgula={true}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorBruto"
                                  label="Valor Bruto (R$)"
                                  decimaisAposvirgula={2}
                                  forcarSeparadorDecimalVirgula={true}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorINSSCPRM"
                                  label="Valor INSS CPRM"
                                  decimaisAposvirgula={2}
                                  forcarSeparadorDecimalVirgula={true}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorINSS"
                                  decimaisAposvirgula={2}
                                  forcarSeparadorDecimalVirgula={true}
                                  label="Desconto INSS (R$)"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorISS"
                                  label="Desconto ISS (R$)"
                                  forcarSeparadorDecimalVirgula={true}
                                  decimaisAposvirgula={2}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorLiquido"
                                  decimaisAposvirgula={2}
                                  label="Valor líquido (R$)"
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Parâmetros do recibo" visible={estaEditando && !disableAllFields}>
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-3" md={2}>
                              <Field name="idUnidadeRegional" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    let listaFiltrada = [];

                                    if(value)
                                      listaFiltrada = data.parametros.filter((item) => item.idUnidadeRegional == value);

                                    return (
                                      <Field component={SelectField}
                                       name='parametroRecibo'
                                       label='Parâmetro do recibo'
                                       elements={listaFiltrada}
                                       disabled={disableAllFields}
                                       valueResolver={identifierValueResolver}
                                       labelResolver={parametroLabelResolver}
                                       validate={requiredWithNotification("Parâmetros do recibo")}
                                       required
                                    />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="fonte"
                                  label="Fonte"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name='unidadeAdministrativa'
                                  label="Unidade administrativa"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name='vinculacao'
                                  label="Vinculação"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="centroCusto"
                                  label="Centro de custo"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="programaTrabalho"
                                  label="Programa de trabalho"
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name='cargoQuemAutoriza'
                                  label="Cargo quem autoriza"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name='cargoQuemConfere'
                                  label="Cargo quem confere"
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name="textoUm"
                                  label="Primeiro texto"
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextField}
                                  name="textoDois"
                                  label="Segundo texto"
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Resumo do recibo" visible={estaEditando && !disableAllFields}>
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-0" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="houvePagamento"
                                  key="pagamento"
                                  elements={Realizado}
                                  label="Pagamento"
                                  validate={requiredWithNotification(
                                    "Pagamento"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                  defaultValue={!estaEditando ? REALIZADO.serializedName : null}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  name="houvePagamento"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const houvePagamento = value ? "S" === value : false;
                                    return (
                                      <Field
                                        component={TextAreaField}
                                        name="motivoPagamento"
                                        label="Motivo"
                                        key={houvePagamento}
                                        required={estaEditando && !houvePagamento}
                                        validate={estaEditando && !houvePagamento ?
                                          requiredWithNotification("Motivo")
                                          : undefined
                                        }
                                        disabled={(disableAllFields && estaEditando) || houvePagamento}
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  name="houvePagamento"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const houvePagamento = value ? "S" === value : false;
                                    return (
                                      <Field
                                        component={TextField}
                                        name="rpdrcs"
                                        label="RPD/RCS"
                                        key={houvePagamento}
                                        required={estaEditando && !houvePagamento}
                                        validate={estaEditando && !houvePagamento ?
                                          requiredWithNotification("RPD/RCS")
                                          : undefined
                                        }
                                        maxLength={200}
                                        disabled={disableAllFields && estaEditando}
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={10}>
                                <Field
                                  name="houvePagamento"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const houvePagamento = value ? "S" === value : false;
                                    return (
                                      <Field
                                        name="idsMembrosEquipe"
                                        component={JqxDropDownListAdapter}
                                        source={data.usuarios}
                                        label="Equipe"
                                        key={houvePagamento}
                                        checkboxes={true}
                                        displayMember="nome"
                                        valueMember="id"
                                        placeHolder="Selecione a equipe..."
                                        enableSelection={true}
                                        enableHover={true}
                                        filterable={true}
                                        incrementalSearch={true}
                                        animationType="none"
                                        closeDelay={0}
                                        filterPlaceHolder=" Busque"
                                        disabled={disableAllFields}
                                        required={estaEditando && !houvePagamento}
                                        validate={estaEditando && !houvePagamento ?
                                          requiredWithNotification("Equipe")
                                          : undefined
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>

                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Informações" visible={!estaEditando || disableAllFields}>
                          <FormSectionWrapper >
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="codigoEstacaoDigitado"
                                  label="Código estação"
                                  validate={requiredWithNotification("Código")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field component={SelectField} name='estacaoSelecionada'
                                  label='Estação' elements={data.estacoes} buscavel
                                  callbackWithOriginalValue disabled={disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  validate={requiredWithNotification("Estação")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="nomeObservador"
                                  label="Observador"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="valorBruto"
                                  label="Valor bruto (R$)"
                                  readOnly
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field name="idUnidadeRegional" subscription={{ value: true }}>
                                  {({ input: { value } }) => {

                                    let listaFiltrada = [];

                                    if(value)
                                      listaFiltrada = data.parametros.filter((item) => item.idUnidadeRegional == value);

                                    return (
                                      <Field component={SelectField}
                                        name='parametroRecibo'
                                        label='Parâmetro do recibo'
                                        elements={listaFiltrada}
                                        disabled={disableAllFields}
                                        valueResolver={identifierValueResolver}
                                        labelResolver={parametroLabelResolver}
                                        validate={requiredWithNotification("Parâmetros do recibo")}
                                        required
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field name="dataFinal" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicial"
                                      label="Data inicial"
                                      endDate={value}
                                      maxDate={value}
                                      validate={requiredWithNotification("Data inicial")}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field name="dataInicial" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataFinal"
                                      label="Data final"
                                      minDate={value}
                                      startDate={value}
                                      validate={requiredWithNotification("Data final")}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={NumberField}
                                  name="meses"
                                  label="Meses"
                                  decimaisAposvirgula={1}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="valorINSS"
                                  label="INSS (R$)"
                                  max={9999999999}
                                  decimaisAposvirgula={2}
                                  validate={!estaEditando ? requiredWithNotification("INSS") : undefined}
                                  required={!estaEditando}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="valorIRPF"
                                  label="IRRF (R$)"
                                  max={9999999999}
                                  decimaisAposvirgula={2}
                                  validate={!estaEditando ? requiredWithNotification("IRRF") : undefined}
                                  required={!estaEditando}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="valorISS"
                                  label="ISS (R$)"
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="valorLiquido"
                                  label="Valor líquido (R$)"
                                  readOnly
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormGerenciamentoRecibo;
