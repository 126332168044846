import React, { Component } from 'react'
import "components/navBar/navbar.css"
import MenuLateral from "../menuLateral/MenuLateral";
import { USER } from "../../constants";
export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classNameTopo2: '',
        }
    }

    render() {
        const exibirCompleto = this.props.exibirCompleto
        const exibirMenu = this.props.exibirMenu || this.props.exibirCompleto
        const estilo = this.props.style
        return (
            <nav id={`navBar${exibirCompleto ? '-completo' : ''}`} style={estilo} >

                <div id={`topo1${exibirCompleto ? '-completo' : ''}`}>
                    <div className="logos-cabecalho">
                        <div className="logo-container logo-sgih">
                            <img className="logo-cabecalho-img" src={require('../../assets/img/logo_geosbg_sgih.png')} alt="" />

                        </div>
                        <div className="logo-container logo-sgih">
                            <img id="sgih" className="logo-cabecalho-img" src={require('../../assets/img/Logo_SGIH_0507_Colorida.png')} alt="" />
                        </div>
                        <div className="logo-container logo-sgih">
                            <img id="sgb" className="logo-cabecalho-img" src={require('../../assets/img/logo_CPRM.png')} alt="" />
                        </div>
                    </div>
                </div>


                {exibirCompleto && exibirMenu && (
                    <div id={`topo2${exibirCompleto ? '-completo' : ''}`} >
                        <div id="menuContainer">
                            <div style={{ width: '43px' }}>
                                <MenuLateral />
                            </div>
                        </div>

                        <div id="tituloWrap"><h3>{this.props.titulo}</h3></div>

                        <div id='nomeUsuarioWrap'>  <span>Olá, {localStorage.getItem(USER)}</span> </div>
                    </div>
                )}
                {!exibirCompleto && exibirMenu &&
                    <div id='topo2'>
                        <div className="flex-display">
                            <div id="menuContainer">
                                <div style={{ width: '43px' }}>
                                    <MenuLateral />
                                </div>
                            </div>
                            <h2>{this.props.titulo}</h2>
                        </div>
                    </div>
                }
            </nav>
        )
    }
}
