import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormSubBacias from "./PaginaFormSubBacias";
import PaginaListaSubBacias from "./PaginaListaSubBacias";

class PaginaSubBacias extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/subBacias"}
            component={PaginaListaSubBacias}
          />
          <Route
            exact
            path={"/sgih/app/subBacias/criar"}
            component={PaginaFormSubBacias}
          />
          <Route path={"/sgih/app/subBacias/:id"} component={PaginaFormSubBacias} />
        </Switch>
      </>
    );
  }
}

export default PaginaSubBacias;
