export const VISITA = {
  viewName: "Visita",
  serializedName: 37, // ID no banco.
};

export const BANCO = {
  viewName: "Banco",
  serializedName: 40, // ID no banco.
};

export const CORREIOS = {
  viewName: "Correios",
  serializedName: 41, // ID no banco.
};

export default [BANCO, CORREIOS, VISITA];
