import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import {defaultProps, propTypes} from "./formFieldCommonProps";
import Error from "../Error";

class CheckboxField extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = (event) => {
            if (props.input) {
                props.input.onChange(event.target.checked);
            }
            if (props.onChange) {
                props.onChange(event.target.checked);
            }
        }
    }

    render() {
        const name = this.props.name || (this.props.input && this.props.input.name);
        const value = this.props.value ||  (this.props.input && this.props.input.value);

        return <>
            <Form.Check type="checkbox" label={this.props.label} value={value} disabled={this.props.disabled}
                        onChange={this.onChange} required={this.props.required} checked={value}
                        name={name} readOnly={this.props.readOnly} className={this.props.className} />

            <Error name={name} />
        </>;
    }
}

CheckboxField.propTypes = {
    ...propTypes,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

CheckboxField.defaultProps = {
    ...defaultProps,
};

export default CheckboxField;
