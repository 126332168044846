export const GOOD = {
    viewName: "Bom (a)", serializedName: "BOM",
};

export const REGULAR = {
    viewName: "Regular", serializedName: "REGULAR",
};

export const BAD = {
    viewName: "Ruim", serializedName: "RUIM",
};

export const INEXISTENT = {
    viewName: "Inexistente", serializedName: "INEXISTENTE",
};

export const YES = {
    viewName: "Sim", serializedName: "SIM",
};

export const NO = {
    viewName: "Não", serializedName: "NAO",
};


export const LIMPEZA_OK = {
    viewName: "Limpeza Ok", serializedName: "SIM",
};

export const LIMPEZA_EFETUADA = {
    viewName: "Limpeza Efetuada", serializedName: "NAO",
};

export const MONTANTE = {
    viewName: "Montante", serializedName: "MONTANTE",
};

export const JUSANTE = {
    viewName: "Jusante", serializedName: "JUSANTE",
};

export const TOTALMENTE = {
    viewName: "Totalmente", serializedName: "TOTALMENTE",
};

export const PARCIALMENTE = {
    viewName: "Parcialmente", serializedName: "PARCIALMENTE",
};

export const NAO_EXECUTADA = {
    viewName: "Não executada", serializedName: "NAO_EXECUTADA",
};

export const EXTRA = {
    viewName: "Extra", serializedName: "EXTRA",
};

export const FISCALIZACAO = {
    viewName: "Fiscalização", serializedName: "FISCALIZACAO",
};

export const OPERACAO_DE_ROTEIRO = {
    viewName: "Operação de Roteiro", serializedName: "OPERACAO_DE_ROTEIRO",
};

export const VISITA = {
    viewName: "Visita", serializedName: "VISITA",
};

export const ORDEM_BANCARIA = {
    viewName: "Ordem Bancária", serializedName: "ORDEM_BANCARIA",
};

export const NA_SECAO = {
    viewName: "Na Seção", serializedName: "NA_SECAO",
};

export const FORA_SECAO = {
    viewName: "Fora da Seção", serializedName: "FORA_SECAO",
};

export const INSTALACAO = {
    viewName: "Instalação", serializedName: "INSTALACAO",
};

export const MANUTENCAO = {
    viewName: "Manutenção", serializedName: "MANUTENCAO",
};

// tipos PCD antigo
// export const CHUVA_UMIDADE = {
//     viewName: "Chuva-Umidade Relativa-Temperatura", serializedName: "CHUVA_UMIDADE",
// };

// export const CHUVA_RADAR = {
//     viewName: "Chuva-Nivel Radar", serializedName: "CHUVA_RADAR",
// };

// export const CHUVA_PRESSAO = {
//     viewName: "Chuva-Nivel Pressão", serializedName: "CHUVA_PRESSAO",
// };

// export const CHUVA_PRESSAO_RADAR = {
//     viewName: "Chuva-Nivel Pressão/Radar", serializedName: "CHUVA_PRESSAO_RADAR",
// };

export const METEOROLOGICA = {
    viewName: "Meteorológica", serializedName: "METEOROLOGICA",
};

export const HIDROLOGICA = {
    viewName: "Hidrológica", serializedName: "HIDROLOGICA",
};

export const QUALIDADE_DE_AGUA = {
    viewName: "Qualidade de água", serializedName: "QUALIDADE_DE_AGUA",
};

export const INDETERMINADO = {
    viewName: "Indeterminado", serializedName: "INDETERMINADO",
};

export const REPARADO = {
    viewName: "Reparado", serializedName: "REPARADO",
};

export const SUBSTITUIDO = {
    viewName: "Substituído", serializedName: "SUBSTITUIDO",
};

export const RECOLHIDO = {
    viewName: "Recolhido", serializedName: "RECOLHIDO",
};

export const INSTALADO = {
    viewName: "Instalado", serializedName: "INSTALADO",
};

export const REGISTRANDO_E_TRANSMITINDO = {
    viewName: "Registrando e transmitindo", serializedName: "REGISTRANDO_E_TRANSMITINDO",
};

export const SOMENTE_REGISTRANDO = {
    viewName: "Somente registrando", serializedName: "SOMENTE_REGISTRANDO",
};

export const PARADA = {
    viewName: "Inoperante", serializedName: "PARADA",
};
export const NAO_VERIFICADO_PCD_SEM_COMUNICACAO = {
    viewName: "Não verificado (PCD sem comunicação)", serializedName: "NAO_VERIFICADO",
};

export const MEDICAO_RIO_SECO = {
    viewName: "Medição de rio seco", serializedName: "MEDICAO_RIO_SECO",
};
export const MEDICAO_RIO_CORTADO = {
    viewName: "Medição de rio cortado", serializedName: "MEDICAO_RIO_CORTADO",
};
export const MEDICAO_EFETIVA = {
    viewName: "Medição efetiva", serializedName: "MEDICAO_EFETIVA",
};

export const yesOrNo = [NO, YES];
export const goodRegularOrBad = [GOOD, REGULAR, BAD];
export const gradingWithInexistent = [GOOD, REGULAR, BAD, INEXISTENT];
export const montanteOuJusante = [JUSANTE, MONTANTE];
export const totalParcialOuNao = [NAO_EXECUTADA, PARCIALMENTE, TOTALMENTE];

export const tiposVisita = [EXTRA, FISCALIZACAO, OPERACAO_DE_ROTEIRO];
export const tiposServicoExecutadoPCD = [INSTALADO, RECOLHIDO, REPARADO, SUBSTITUIDO];
export const tiposStatusPCD = [PARADA, REGISTRANDO_E_TRANSMITINDO, SOMENTE_REGISTRANDO, NAO_VERIFICADO_PCD_SEM_COMUNICACAO];
export const tiposVisitaPCD = [INSTALACAO, MANUTENCAO];
// export const tiposPCD = [CHUVA_UMIDADE, CHUVA_RADAR, CHUVA_PRESSAO, INDETERMINADO, CHUVA_PRESSAO_RADAR];
export const tiposPCD = [HIDROLOGICA, INDETERMINADO, METEOROLOGICA, QUALIDADE_DE_AGUA];
export const tiposPagamento = [ORDEM_BANCARIA, VISITA ];
export const locaisMedicao = [FORA_SECAO, NA_SECAO ];
export const tiposMedicao = [MEDICAO_RIO_SECO, MEDICAO_RIO_CORTADO, MEDICAO_EFETIVA];

export const Limpeza = [LIMPEZA_OK, LIMPEZA_EFETUADA];

export default [
    GOOD, REGULAR, BAD, INEXISTENT, YES, NO,
];
