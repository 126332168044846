function createAutoWindowClosingDecorator(conditionalFn, onBeforeCloseFn) {
    return function (...args) {
        if (window?.history?.state?.state?.closeTabOnFinish) {
            if (onBeforeCloseFn && typeof onBeforeCloseFn === 'function') {
                onBeforeCloseFn(...args);
            }

            setTimeout(() => {
                window.close();
            }, 1700);

            return;
        }

        conditionalFn(...args);
    }
}

export { createAutoWindowClosingDecorator };