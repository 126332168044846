import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormRios from "./PaginaFormRios";
import PaginaListaRios from "./PaginaListaRios";

class PaginaRios extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/rios"}
            component={PaginaListaRios}
          />
          <Route
            exact
            path={"/sgih/app/rios/criar"}
            component={PaginaFormRios}
          />
          <Route path={"/sgih/app/rios/:id"} component={PaginaFormRios} />
        </Switch>
      </>
    );
  }
}

export default PaginaRios;
