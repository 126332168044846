import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaRios extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "codigo", type: "number", map: "codigo" },
        { name: "nome", type: "string", map: "nome" },
        { name: "subBacia", type: "string", map: "subBacia" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "codigo", width: "11.9%" },
      { text: "Nome", dataField: "nome", width: "20%"},
      { text: "Sub-bacia", dataField: "subBacia" },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Rios"
        tableConfig={this.tableConfig}
        baseUrl="/rios"
        history={this.props.history}
        formLinkTitle="Novo rio"
        tableName="rios"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
        forcarPOSTListagem={true}
      />
    );
  }
}

export default PaginaListaRios;
