export const QUALIDADE_AGUA = {
    viewName: "Qualidade de água", serializedName: "QUALIDADE_AGUA",
};

export const DESCARGA_LIQUIDA = {
    viewName: "Descarga líquida", serializedName: "DESCARGA_LIQUIDA",
};

export default [
    QUALIDADE_AGUA, DESCARGA_LIQUIDA
];
