import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import SelectField from "../../components/form/field/SelectField";
import TextAreaField from "../../components/form/field/TextAreaField";
import DateTimeField from "../../components/form/field/DateTimeField/DateTimeField";
import NumberField from "../../components/form/field/NumberField/NumberField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import createDecorator from "final-form-calculate";
import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import CpfField from "../../components/form/field/CpfField";
import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import CheckField from "../../components/form/field/CheckField";
import formatString from "format-string-by-pattern";
import Status, { StatusIdViewMap } from "../../model/Status";
import TipoConta from "../../model/TipoConta";
import TipoPagamentoObservador from "../../model/TipoPagamentoObservador";
import { deveSerCPF, deveSerCPFWithNotification, requiredWithNotification } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import swal from "sweetalert";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

let dataFimTemp = null;
const dataAtual = new Date();

// https://codesandbox.io/s/10rzowm323
const normalizeDistancia = (value) => {
  if (!value) {
    return value;
  } else if (value.length > 10) {
    return value.slice(0, 10);
  } else if (parseInt(value) < 10000000000) {
    return value.replace("-", "");
  }
};

// RN
const TipoPagamentoEh = ({ tipos = [], children }) => {
  return (
    <Field name="opcoesPagamento" subscription={{ value: true }}>
      {({ input: { value } }) => {
        const pagamentoEhTipOCerto =
          !!value && "" !== value && tipos.some((tipo) => value.includes(tipo));
        return children(pagamentoEhTipOCerto);
      }}
    </Field>
  );
};

var exibiuMensagemBancoEm = 0;

// const tipoEstacao = (tipo, name) => (value, allValues) =>
//   // Vazio ou com o tipo incluído
//   !allValues[name] || (!!allValues[name] && allValues[name].includes(tipo))
//     ? undefined
//     : "Digite o código de uma estação tipo " + tipo;

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  {
    field: "proprioObservador",
    updates: (value, name, { nome, leiturista }) => {
      if (value) {
        return {
          leiturista: nome,
        };
      }
      return {
        leiturista: leiturista,
      };
    },
  },
  {
    field: "nome",
    updates: (value, name, { proprioObservador, leiturista }) => {
      if (value && proprioObservador) {
        return {
          leiturista: value,
        };
      }
      return {
        leiturista: leiturista,
      };
    },
  },
  {
    field: "codigoEstacaoPluviometrica",
    updates: (value) => {
      if (value && value.length >= 8) {
        return createServiceFor("/estacoes")
          .customURL("GET", `codigo/${value}`)
          .then((res) => {
            const data = res.data;
            return {
              valorEstacaoPluviometrica: data.valorGratificacaoTipoEstacao,
              nomeEstacaoPluviometrica: data,
              unidadeRegional: data.unidadeRegional.id,
              tipoEstacaoPluviometrica: data.tipoEstacao.siglaTipoEstacao,
              tipoEstacaoFluviometrica: "F",
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              valorEstacaoPluviometrica: "",
              nomeEstacaoPluviometrica: "",
              unidadeRegional: "",
              tipoEstacaoPluviometrica: "",
            };
          });
      }
      return {
        valorEstacaoPluviometrica: "",
        nomeEstacaoPluviometrica: "",
        unidadeRegional: "",
        tipoEstacaoPluviometrica: "",
      };
    },
  },
  {
    field: "status",
    updates: (value) => {
      let statusDesativo = true;

      if (value)
        statusDesativo = value.toString() !== '47';

      if(statusDesativo)
        return { dataFimLeitura: null }
      else if(dataFimTemp)
        return { dataFimLeitura : dataFimTemp }
      else
        return {};
    }
  },
  {
    field: "dataFimLeitura",
    updates: (value) => {

      if(value != null)
        dataFimTemp = value;

      return {};
    }
  },
  {
    field: "codigoEstacaoFluviometrica",
    updates: (value) => {
      if (value && value.length >= 8) {
        return createServiceFor("/estacoes")
          .customURL("GET", `codigo/${value}`)
          .then((res) => {
            const data = res.data;
            return {
              valorEstacaoFluviometrica: data.valorGratificacaoTipoEstacao,
              nomeEstacaoFluviometrica: data,
              tipoEstacaoFluviometrica: data.tipoEstacao.siglaTipoEstacao,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              valorEstacaoFluviometrica: "",
              nomeEstacaoFluviometrica: "",
              tipoEstacaoFluviometrica: "",
            };
          });
      }
      return {
        valorEstacaoFluviometrica: "",
        nomeEstacaoFluviometrica: "",
        tipoEstacaoFluviometrica: "",
      };
    },
  },
  {
    field: "bancoCodigo",
    updates: (value, name, allValues) => {
      if (value) {
        const codigoBanco = value.replace('_', '');

        if (codigoBanco.length === 3) {
          return createServiceFor("/bancos")
            .customURL("GET", `${codigoBanco}`)
            .then((res) => {
              const data = res.data;
              // todo treat errors here

              return {
                bancoNome: data.nome,
              };
            })
            .catch((e) => {
              // Discard repetitive messages for 3 seconds.
              let now = new Date().getTime();
              if (now - exibiuMensagemBancoEm < 3000) {
                return { bancoNome: "" };
              }
              exibiuMensagemBancoEm = now;
              if (codigoBanco) {
                store.dispatch(
                  showGlobalNotification({
                    message: "Nenhum Banco encontrado com o código digitado",
                  })
                );
              }

              return {
                bancoNome: "",
              };
            });
        }
      }
      return {};
    },
  },
  {
    field: "tipoObservador",
    updates: (value, name) => {
      if (value) {
        return createServiceFor("/tiposObservadores")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            // todo treat errors here
            return {
              valorTipoObservador: data.valorGratificacaoTipo ? data.valorGratificacaoTipo.toFixed(2).replace('.', ',') : data.valorGratificacaoTipo,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message:
                  "Nenhum Tipo de Obsevador encontrado com o código selecionado",
              })
            );
            return {
              valorTipoObservador: "",
            };
          });
      }
      return {};
    },
  },
  {
    field: "nomeEstacaoPluviometrica",
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          codigoEstacaoPluviometrica: value.codigo,
        };
      }

      return {};
    },
  },
  {
    field: "nomeEstacaoFluviometrica",
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          codigoEstacaoFluviometrica: value.codigo,
        };
      }

      return {};
    },
  },
  // {
  //   field: "municipio",
  //   updates: (value) => {
  //       return createServiceFor("/municipios")
  //         .customURL("GET", `${value}`)
  //         .then((res) => {
  //           const data = res.data;
  //           console.log(data)
  //           return {
  //             aliquotaIss : data.valorPercentualISS
  //           };
  //         })
  //         .catch((e) => {
  //           store.dispatch(
  //             showGlobalNotification({
  //               message: "Municipio inválido",
  //             })
  //           );
  //           return {
  //             aliquotaIss : ""
  //           };
  //         });

  //   },
  // },
  {
    field: "valorEstacaoFluviometrica",
    updates: (value, name) => {
      if(value) {
        let valor = parseFloat(value).toFixed(2).replace('.', ',');
        return {valorEstacaoFluviometricaTela: valor};
      }
     return {valorEstacaoFluviometricaTela : ""};
    },
  },
  {
    field: "valorEstacaoPluviometrica",
    updates: (value, name) => {
      if(value) {
        let valor = parseFloat(value).toFixed(2).replace('.', ',');
        return {valorEstacaoPluviometricaTela: valor};
      }
     return {valorEstacaoPluviometricaTela : ""};
    },
  },
);

const identifierValueResolver = (e) => e.id || e.codigo || e;
const descriptionLabelResolver = (e) =>
  e.nome ||
  e.descricao ||
  e.numero ||
  e.siglaTipoObservador ||
  e.viewName ||
  e.id ||
  e;

class PaginaFormObservador extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
      municipiosDoEstadoDoc: [],
    };
  }

  componentDidUpdate() { }
  componentDidMount() {
    dataFimTemp = null;
    const promises = [
      createServiceFor("/grauInstrucao").query(),
      createServiceFor("/estados").query(),
      createServiceFor("/bancos").query(),
      createServiceFor("/tiposConta").query(),
      createServiceFor("/tiposObservadores/simples").query(),
      createServiceFor("/nacionalidade").query(),
      createServiceFor("/estacoes/simples").query(),
      createServiceFor("/estacoes/simples").query(),
      createServiceFor("/municipios/porEstado").query(),
      createServiceFor("/unidadesRegionais/simples").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/observadores").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true,
      });
    }

    const promise = Promise.all(promises)
      .then(([
        grauInstrucao, // equipes,
        estado, banco,
        tipoConta,
        tipoObservador,
        nacionalidade,
        estacoesPluviometricas,
        estacoesFluviometricas,
        municipiosPorEstado,
        unidadesRegionais,
        observador = {}]
      ) => {
        this.setState({
          unidadesRegionais: unidadesRegionais.data,
          tiposObservadores: tipoObservador.data,
        });

        return {
          grauInstrucao: grauInstrucao.data,
          // equipes: equipes.data,
          estado: estado.data,
          municipiosDocumentacao: observador?.data?.municipiosDocumentacao
            ? observador.data.municipiosDocumentacao
            : [],
          municipiosEndereco: observador?.data?.municipiosEndereco
            ? observador.data.municipiosEndereco
            : [],
          banco: banco.data,
          tipoConta: tipoConta.data,
          status: Status,
          tipoObservador: tipoObservador.data,
          nacionalidade: nacionalidade.data,
          estacoesPluviometricas: estacoesPluviometricas.data,
          estacoesFluviometricas: estacoesFluviometricas.data,
          municipiosPorEstado: (() => {
            const resultado = {}
            for (const estado of municipiosPorEstado.data) {
              resultado[estado.id] = estado.municipios
            }
            return resultado;
          })(),
          unidadesRegionais: unidadesRegionais.data,
          initialValues: observador.data
            ? {
              ...observador.data,
              codigoObservador: observador.data.codigoObservador,
              idObservador: observador.data.id,
            }
            : {},
        }
      })
      .catch((e) => {
        console.log(e);
        store.dispatch(showGlobalNotification(e.message));
      });

    // this.setState({
    //   dataLoaded: this.loadDataFor(this.props.match.params.id),
    // });
    this.setState({
      promiseDados: promise,
      municipiosDoEstadoDoc: [],
      municipiosDoEstadoEnd: [],
    });
  }

  submitForm(values) {
    // console.log(values)
    // Apaga atributos desnecessários
    delete values.municipiosDocumentacao; // = []
    delete values.municipiosEndereco; // = []
    delete values.valorTipoObservador;

    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/observadores")
      .post(values)
      .then(() => {
        dataFimTemp = null;
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Observador salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (e.response.data && e.response.data.message) {
          store.dispatch(
            showGlobalNotification({
              message: e.response.data.message,
            })
          );
        } else {
          store.dispatch(
            showGlobalNotification({
              message: "Ocorreu um erro ao tentar salvar",
            })
          );
        }
        // store.dispatch(
        //   showGlobalNotification({
        //     message: "Ocorreu um erro ao tentar salvar",
        //   })
        // );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: async () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });

      const formState = form.getState();
      console.log(formState.values, formState.valid)
      if (formState.valid) {

        // Verifica se já há observador na estação plu/flu e pede confirmação ao usuário
        const codigoFlu = formState.values.codigoEstacaoFluviometrica, codigoPlu = formState.values.codigoEstacaoPluviometrica;
        if (codigoFlu || codigoPlu) {
          const estacoesService = createServiceFor('/estacoes');
          const observadorFluPromise = codigoFlu ? estacoesService.get(`codigo/${codigoFlu}/observador`) : Promise.resolve()
          const observadorPluPromise = codigoPlu ? estacoesService.get(`codigo/${codigoPlu}/observador`) : Promise.resolve()

          const observadores = await Promise.all([observadorFluPromise, observadorPluPromise])
            .then(async values => values.map(v => v ? v.data.observador : null))
            .catch(console.error);
          console.log('Observadores da estações flu/plu:', observadores);

          // Erro na requisição
          if (!observadores) {
            store.dispatch(showGlobalNotification({ message: 'Ocorreu um erro ao verificar observadores associados a estação pluviométrica/fluviométrica' }));
            return;
          }

          // Pular confirmação caso não haja observador na estação plu nem na flu ou o observador associado é o atual
          if (observadores.every(o => !o || o.id === formState.values.id)) {
            form.submit();
            return;
          }

          // Pop-up de confirmação
          let text = ''
          if (observadores[1] && observadores[1].id !== formState.values.id)
            text += `Estação pluviométrica ${formState.values.nomeEstacaoPluviometrica.nome} já é observada por ${observadores[1].nome}.`
          if (observadores[0] && observadores[0].id !== formState.values.id)
            text += `\nEstação fluviométrica ${formState.values.nomeEstacaoFluviometrica.nome} já é observada por ${observadores[0].nome}.`
          await swal({
            title: "Confirmar atualização de observador(es)?",
            text: text.trim(),
            icon: "warning",
            buttons: {
              cancelar: { text: "NÃO", className: "btn-danger", },
              confirmar: { text: "OK", className: "btn-success", },
            },
            dangerMode: true,
          })
            .then((value) => {
              console.log('value:', value);
              if (value === "confirmar") {
                form.submit();
              }
            });
        } else { // Cadastro/Atualização de Observador sem nenhuma estação associada, nenhuma verificação necessária
          form.submit();
        }

      }
    },
  });

  getMunicipios = (id, data) => {
    return data.municipiosPorEstado[id];
  };

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      nome: this.state.initialValues?.nome,
    });

    const formattedValues = {
      nome: limitText(sanitizedValues?.nome, 70),
    };

    const unidadeRegional = (this.state.unidadesRegionais || [])
      .find((unidadeRegional) => unidadeRegional.id === this.state.initialValues?.idUnidadeRegional);

    const tipoObservador = (this.state.tiposObservadores || [])
      .find((tipo) => tipo.id === this.state.initialValues?.tipoObservador)

    return this.state.emEdicao
      ? `
        <div class="content">
          ${this.state?.initialValues
            ? `
              ${
                this.state.initialValues?.codigoObservador
                  ? `<div class="text">Observador</div> - <div class="text">Código: </div>${this.state.initialValues?.codigoObservador}`
                  : ''
              }
              ${
                formattedValues?.nome
                  ? `- <div class="text">Nome:</div> ${formattedValues.nome}`
                  : ''
              }
              ${
                this.state.initialValues?.status
                  ? ` - <div class="text">Status:</div> ${StatusIdViewMap[this.state.initialValues?.status]}`
                  : ''
              }
              ${
                tipoObservador?.siglaTipoObservador
                  ? ` - <div class="text">Tipo:</div> ${tipoObservador?.siglaTipoObservador}`
                  : ''
              }
              ${
                this.state.initialValues?.roteiro
                  ? ` - <div class="text">Roteiro:</div> ${this.state.initialValues.roteiro}`
                  : ''
              }
              ${
                unidadeRegional?.siglaLocalUasg
                  ? ` - ${unidadeRegional?.siglaLocalUasg}`
                  : ''
              }
            `
            : ''
          }
        </div>
      `
      : null;
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Cadastro de Observadores"
        baseUrl="/sgih/app/observadores"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }
            return (
              <Form
                subscription={{ values: true }}
                onSubmit={this.submitForm.bind(this)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Identificação">
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Informações gerais">
                                <Row>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component="input"
                                      type="hidden"
                                      name="idObservador"
                                      value=""
                                    />
                                    <Field
                                      component="input"
                                      type="hidden"
                                      name="unidadeRegional"
                                      value=""
                                    />
                                    <Field
                                      component={TextField}
                                      name="codigoObservador"
                                      label="Código"
                                      readOnly={true}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={3}>
                                    <Field
                                      component={NumberField}
                                      name="roteiro"
                                      label="Roteiro"
                                      readOnly={true}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="nome"
                                      label="Nome"
                                      maxLength={150}
                                      validate={requiredWithNotification(
                                        "Nome"
                                      )}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="apelidoObservador"
                                      label="Apelido"
                                      maxLength={100}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataNascimento"
                                      label="Data de nascimento"
                                      validate={requiredWithNotification(
                                        "Data de nascimento"
                                      )}
                                      required
                                      className="form-control"
                                      disabled={disableAllFields}
                                      yearDropdownItemNumber={80}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      maxDate={dataAtual}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="naturalidade"
                                      label="Naturalidade"
                                      maxLength={50}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="nacionalidade"
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      label="Nacionalidade"
                                      elements={data.nacionalidade}
                                      validate={requiredWithNotification(
                                        "Nacionalidade"
                                      )}
                                      disabled={disableAllFields}
                                      required
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="leiturista"
                                      label="Leiturista"
                                      maxLength={150}
                                      validate={requiredWithNotification(
                                        "Leiturista"
                                      )}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={SelectField}
                                      name="instrucao"
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      elements={data.grauInstrucao}
                                      label="Instrução"
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={TextField}
                                      name="profissao"
                                      label="Profissão"
                                      maxLength={150}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicioLeitura"
                                      label="Inicio"
                                      required
                                      validate={requiredWithNotification(
                                        "Inicio"
                                      )}
                                      disabled={disableAllFields}
                                      className="form-control"
                                    />
                                  </Col>
                                  <Col className="mt-3" md={2}>
                                    <Field
                                      name="status"
                                      subscription={{ value: true }}>
                                      {({ input: { value } }) => {
                                        let statusDesativo = true;
                                        if (value) {
                                          statusDesativo = value.toString() !== '47';
                                        }
                                        return <Field
                                          component={DateTimeField}
                                          name="dataFimLeitura"
                                          label="Fim"
                                          disabled={statusDesativo || disableAllFields}
                                          className="form-control"
                                        />
                                      }}
                                    </Field>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={3}>
                                    <Field
                                      component={CheckField}
                                      name="proprioObservador"
                                      type="checkbox"
                                      className="form-control"
                                      label="Próprio observador"
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <FormSectionWrapper title="Filiação">
                                <Row>
                                  <Col md={4}>
                                    <Field
                                      component={TextField}
                                      name="nomeMae"
                                      label="Nome da mãe"
                                      maxLength={150}
                                      validate={requiredWithNotification(
                                        "Nome da mãe"
                                      )}
                                      disabled={disableAllFields}
                                      required
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Field
                                      component={TextField}
                                      name="nomePai"
                                      label="Nome do pai"
                                      maxLength={150}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                            <Col md={12}>
                              <FormSectionWrapper title="Histórico de alteração">
                                <Row>
                                  <Col md={4}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataAtualizacao"
                                      label="Data"
                                      disabled={disableAllFields}
                                      readOnly={true}
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                          </Row>
                        </Tab>
                        <Tab title="Documentação">
                          <Row>
                            <Col className="mt-3" md={3}>
                              <FormSectionWrapper title="CPF">
                                <Row>
                                  <Col className="mt-3" md={12}>
                                    <Field
                                      component={CpfField}
                                      name="cpf"
                                      label="Inscrição n.º"
                                      validate= {deveSerCPFWithNotification(
                                        "Inscrição n.º"
                                      )}
                                      deveSerCPF
                                      disabled={disableAllFields}
                                      required
                                      parse={formatString("XXX.XXX.XXX-XX")}
                                      placeholder="___.___.___-__"
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                            <Col className="mt-3" md={9}>
                              <FormSectionWrapper title="Informações gerais">
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="rg"
                                      label="RG"
                                      maxLength={20}
                                      disabled={disableAllFields}
                                      placeholder="__.___.___-__"
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="orgaoExpeditor"
                                      label="Orgão expedidor"
                                      maxLength={20}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataEmissaoRg"
                                      label="Data da expedição"
                                      disabled={disableAllFields}
                                      className="form-control"
                                      yearDropdownItemNumber={80}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      maxDate={dataAtual}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" md={6}>
                              <FormSectionWrapper title="Carteira profissional">
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroCtps"
                                      label="Número"
                                      maxLength={20}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="serieCtps"
                                      label="Série"
                                      maxLength={10}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataEmissaoCpts"
                                      label="Data de emissão"
                                      disabled={disableAllFields}
                                      className="form-control"
                                      yearDropdownItemNumber={80}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      maxDate={dataAtual}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                            <Col className="mt-3" md={6}>
                              <FormSectionWrapper title="Título de eleitor">
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroTituloEleitor"
                                      label="Título de eleitor"
                                      maxLength={30}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="zonaEleitoral"
                                      label="Zona"
                                      maxLength={10}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="secaoEleitoral"
                                      label="Seção"
                                      maxLength={10}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mt-3" md={6}>
                              <FormSectionWrapper title="Certidão de nascimento/casamento">
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="nomeCartorioCertidao"
                                      label="Cartório"
                                      maxLength={100}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={SelectField}
                                      name="estadoCartorio"
                                      label="Estado"
                                      valueResolver={identifierValueResolver}
                                      labelResolver={descriptionLabelResolver}
                                      elements={data.estado}
                                      required
                                      validate={requiredWithNotification(
                                        "Estado em Documentação"
                                      )}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field name="estadoCartorio" subscription={{ value: true }}>
                                      {({ input: { value } }) => {
                                        return <Field component={SelectField}
                                          name="municipioCartorio"
                                          label="Município"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={descriptionLabelResolver}
                                          disabled={disableAllFields}
                                          elements={this.getMunicipios(value, data)}
                                          validate={requiredWithNotification(
                                            "Municipio em Documentação"
                                          )}
                                          required
                                        />
                                      }}
                                    </Field>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroTermoCertidao"
                                      label="Termo n.º"
                                      maxLength={10}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroLivroCertidao"
                                      label="Livro"
                                      maxLength={30}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroFolhaCertidao"
                                      label="Folha"
                                      maxLength={10}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                            <Col className="mt-3" md={6}>
                              <FormSectionWrapper title="Previdência social">
                                <Row>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="tipoInscricaoPrevSocial"
                                      label="Tipo de inscrição"
                                      maxLength={30}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={TextField}
                                      name="numeroInscricaoPrevSocial"
                                      label="N.º de inscrição"
                                      maxLength={20}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={4}>
                                    <Field
                                      component={DateTimeField}
                                      name="dataInscricaoPrevSocial"
                                      label="Data de inscrição"
                                      disabled={disableAllFields}
                                      className="form-control"
                                      yearDropdownItemNumber={80}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      maxDate={dataAtual}
                                    />
                                  </Col>
                                </Row>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab title="Endereço">
                          <FormSectionWrapper title="Endereço">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="cep"
                                    label="CEP"
                                    parse={formatString("XXXXX-XXX")}
                                    placeholder="____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="logradouro"
                                    label="Logradouro"
                                    maxLength={200}
                                    required
                                    validate={requiredWithNotification(
                                      "Logradouro"
                                    )}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="complemento"
                                    label="Complemento"
                                    maxLength={200}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="bairro"
                                    label="Bairro"
                                    maxLength={100}
                                    required
                                    validate={requiredWithNotification(
                                      "Bairro"
                                    )}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="kmDistanciaEstacao"
                                    label="Distância até a estação (m)"
                                    maxLength={10}
                                    parse={normalizeDistancia}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={SelectField}
                                    name="estado"
                                    label="Estado"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={data.estado}
                                    required
                                    validate={requiredWithNotification(
                                      "Estado em Endereço"
                                    )}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field name="estado" subscription={{ value: true }}>
                                    {({ input: { value } }) => {
                                      return <Field component={SelectField}
                                        name="municipio"
                                        label="Município"
                                        valueResolver={identifierValueResolver}
                                        labelResolver={descriptionLabelResolver}
                                        disabled={disableAllFields}
                                        elements={this.getMunicipios(value, data)}
                                        validate={requiredWithNotification(
                                          "Município em Endereço"
                                        )}
                                        required
                                      />
                                    }}
                                  </Field>
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="meioComunicacao"
                                    label="Meio de comunicação"
                                    maxLength={100}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="telefoneResidencial"
                                    label="Telefone fixo"
                                    parse={formatString("(XX)XXXX-XXXX")}
                                    placeholder="(__)____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="celular"
                                    label="Telefone celular"
                                    parse={formatString("(XX)X-XXXX-XXXX")}
                                    placeholder="(__)_-____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="telefoneComercial"
                                    label="Telefone comercial"
                                    parse={formatString("(XX)XXXX-XXXX")}
                                    placeholder="(__)____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Coordenadas geográficas da casa do observador">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={NumberField}
                                    name="latitude"
                                    label="Latitude"
                                    min={-90} max={90} step={0.0001}
                                    decimaisAposvirgula={4}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={NumberField}
                                    name="longitude"
                                    label="Longitude"
                                    min={-180} max={180} step={0.0001}
                                    decimaisAposvirgula={4}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Autorizado">
                          <FormSectionWrapper title="Dados do autorizado">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoNome"
                                    label="Nome"
                                    maxLength={200}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoApelido"
                                    label="Apelido"
                                    maxLength={200}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoParentesco"
                                    label="Grau de parentesco"
                                    maxLength={100}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={DateTimeField}
                                    name="autorizadoDataNascimento"
                                    label="Data de nascimento"
                                    disabled={disableAllFields}
                                    className="form-control"
                                    yearDropdownItemNumber={80}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    maxDate={dataAtual}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={SelectField}
                                    name="autorizadoInstrucao"
                                    label="Instrução"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={data.grauInstrucao}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoNomeMae"
                                    label="Nome da mãe"
                                    maxLength={150}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoRg"
                                    label="RG"
                                    maxLength={20}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoOrgaoExpeditor"
                                    label="Orgão emissor"
                                    maxLength={20}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={DateTimeField}
                                    name="autorizadoDataEmissaoRg"
                                    label="Data de emissão"
                                    disabled={disableAllFields}
                                    className="form-control"
                                    yearDropdownItemNumber={80}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    maxDate={dataAtual}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={CpfField}
                                    name="autorizadoCpf"
                                    label="CPF"
                                    // required
                                    // validate={requiredWithNotification("CPF")}
                                    parse={formatString("XXX.XXX.XXX-XX")}
                                    placeholder="___.___.___-__"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Endereço">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadocep"
                                    label="CEP"
                                    parse={formatString("XXXXX-XXX")}
                                    placeholder="_____-___"
                                    disabled={disableAllFields}

                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoLogradouro"
                                    label="Logradouro"
                                    maxLength={200}
                                    // required
                                    // validate={requiredWithNotification("Logradouro")}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoComplemento"
                                    label="Complemento"
                                    maxLength={200}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoBairro"
                                    label="Bairro"
                                    maxLength={100}
                                    // required
                                    // validate={requiredWithNotification("Bairro")}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadotelefoneResidencial"
                                    label="Telefone fixo"
                                    parse={formatString("(XX)XXXX-XXXX")}
                                    placeholder="(_)____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="autorizadoCelular"
                                    label="Celular"
                                    parse={formatString("(XX)X-XXXX-XXXX")}
                                    placeholder="(__)_-____-____"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Observações gerais sobre o autorizado">
                            <Col className="mt-3" md={12}>
                              <Field
                                component={TextAreaField}
                                name="autorizadoDescricaoObservador"
                                label=""
                                rows="7"
                                cols="53"
                                className="form-control"
                                disabled={disableAllFields}
                              />
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Opções de pagamento">
                          <FormSectionWrapper title="Opção de pagamento">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="opcoesPagamento"
                                    label="Opção de pagamento"
                                    valueResolver={descriptionLabelResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={TipoPagamentoObservador}
                                    required
                                    validate={requiredWithNotification(
                                      "Opção de pagamento"
                                    )}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Dados bancários">
                            <TipoPagamentoEh tipos={["Banco"]}>
                              {(tipoCerto) => (
                                <Col className="mt-3" md={12}>
                                  <Row>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={TextField}
                                        name="bancoCodigo"
                                        label="Código Banco"
                                        mask="111"
                                        disabled={disableAllFields}
                                        required={tipoCerto}
                                        key={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                              "Código Banco"
                                            )
                                            : undefined
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={TextField}
                                        name="bancoNome"
                                        label="Banco"
                                        maxLength={100}
                                        disabled={disableAllFields}
                                        readOnly={true}
                                        key={tipoCerto}
                                        required={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification("Banco")
                                            : undefined
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={SelectField}
                                        name="tipoConta"
                                        label="Tipo de conta"
                                        valueResolver={descriptionLabelResolver}
                                        labelResolver={descriptionLabelResolver}
                                        elements={TipoConta}
                                        key={tipoCerto}
                                        disabled={disableAllFields}
                                        required={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                              "Tipo de conta"
                                            )
                                            : undefined
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={TextField}
                                        name="agencia"
                                        label="Agência"
                                        maxLength={20}
                                        disabled={disableAllFields}
                                        required={tipoCerto}
                                        key={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                              "Agência"
                                            )
                                            : undefined
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={TextField}
                                        name="numeroConta"
                                        label="Número da conta"
                                        maxLength={20}
                                        disabled={disableAllFields}
                                        required={tipoCerto}
                                        key={tipoCerto}
                                        validate={
                                          tipoCerto
                                            ? requiredWithNotification(
                                              "Número da conta"
                                            )
                                            : undefined
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                            </TipoPagamentoEh>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Observações gerais">
                          <FormSectionWrapper title="Observações gerais">
                            <Col className="mt-3" md={12}>
                              <Field
                                component={TextAreaField}
                                name="observacoes"
                                label=""
                                rows="7"
                                cols="53"
                                className="form-control"
                                disabled={disableAllFields}
                              />
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Demais informações">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={SelectField}
                                    name="status"
                                    label="Status"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={Status}
                                    validate={requiredWithNotification(
                                      "Status"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="aliquotaIss"
                                    label="Alíquota ISS"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Estações observadas">
                          <FormSectionWrapper title="Estações observadas">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="idUnidadeRegional"
                                    label="Unidade regional"
                                    elements={data.unidadesRegionais}
                                    valueResolver={identifierValueResolver}
                                    labelResolver={e => e.siglaLocalUasg}
                                    validate={requiredWithNotification("Unidade regional")} required
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="codigoEstacaoPluviometrica"
                                    label="Código da estação pluviométrica"
                                    maxLength={8}
                                    // validate={requiredWithNotification("Código estação pluviométrica")}
                                    // validate={tipoEstacao("P", "tipoEstacaoPluviometrica")}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="tipoEstacaoPluviometrica"
                                  value=""
                                />
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="nomeEstacaoPluviometrica"
                                    label="Nome da estação pluviométrica"
                                    elements={data.estacoesPluviometricas}
                                    buscavel
                                    callbackWithOriginalValue
                                    disabled={disableAllFields}
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                  />
                                </Col>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="valorEstacaoPluviometrica"
                                />
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="valorEstacaoPluviometricaTela"
                                    label="Valor da gratificação (R$)"
                                    // required
                                    disabled={disableAllFields}
                                    readOnly={true}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="codigoEstacaoFluviometrica"
                                    label="Código da estação fluviométrica"
                                    maxLength={8}
                                    // validate={tipoEstacao("F", "tipoEstacaoFluviometrica")}
                                    // mostrarMensagemOnBlur
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="tipoEstacaoFluviometrica"
                                />
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="nomeEstacaoFluviometrica"
                                    label="Nome da estação fluviométrica"
                                    elements={data.estacoesFluviometricas}
                                    buscavel
                                    callbackWithOriginalValue
                                    disabled={disableAllFields}
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                  />
                                </Col>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="valorEstacaoFluviometrica"
                                />
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="valorEstacaoFluviometricaTela"
                                    label="Valor da gratificação (R$)"
                                    disabled={disableAllFields}
                                    readOnly={true}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Resumo">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={SelectField}
                                    name="tipoObservador"
                                    label="Tipo de observador"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={data.tipoObservador}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="valorTipoObservador"
                                  value=""
                                />
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="valorTipoObservador"
                                    label="Valor bruto total (R$)"
                                    disabled={disableAllFields}
                                    readOnly={true}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormObservador;
