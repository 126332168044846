import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormTiposObservadores from "./PaginaFormTiposObservadores";
import PaginaListaTiposObservadores from "./PaginaListaTiposObservadores";

class PaginaTiposObservadores extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/tiposObservadores"}
            component={PaginaListaTiposObservadores}
          />
          <Route
            exact
            path={"/sgih/app/tiposObservadores/criar"}
            component={PaginaFormTiposObservadores}
          />
          <Route
            path={"/sgih/app/tiposObservadores/:id"}
            component={PaginaFormTiposObservadores}
          />
        </Switch>
      </>
    );
  }
}

export default PaginaTiposObservadores;
