import React from "react";
import CrudFormPageScaffold from "../../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../../components/form/field/TextField";
import SelectField from "../../../components/form/field/SelectField/SelectField";
import DateTimeField from "../../../components/form/field/DateTimeField/DateTimeField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../../service/Service";
import createDecorator from "final-form-calculate";
import PromiseLoader from "../../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../../components/scaffold/FormPageScaffoldCommon";
import store from "../../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../../reducers";
import { requiredWithNotification } from "../../../util/FormUtils";
import formatString from "format-string-by-pattern";
import NumberField from "components/form/field/NumberField/NumberField";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import voltarParaGrid from "util/VoltarParaGrid";

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  {
    field: "idUnidadeAdministrativa",
    updates: (value) => {
      if (value && typeof(value) === "number") {
        return createServiceFor("/unidadesRegionais")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            const numero = data.endereco != null && data.endereco.split(",").length > 1 ? data.endereco.split(",")[1].replace(" ","") : null;
            return {
              endereco: data.endereco != null ? data.endereco : "",
              numeroEndereco: numero,
              complemento: data.complemento,
              cep: data.cep,
              estado: data.uf,
              municipio:data.municipio,
              telefoneResidencial: data.telefoneFone,
              celular: data.telefoneFax,
              cnpj: data.cnpj,
              inscricaoEstadual: data.inscricaoEstadual,
              unidadeAdministrativa : data.sigla
            };
          })
          .catch((e) => {
            console.warn(e)
            store.dispatch(
              showGlobalNotification({
                message: `Unidade Administrativa ${value} inválida ou inexistente`,
              })
            );
            return {
              estacaoSelecionada: null,
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              codigoEstacao:"",
              unidadeAdministrativa: null,
            };
          });
      }
      return {};
    },
  },
  {
    field: "projeto",
    updates: (value) => {
      if (value && typeof(value) === "object") {
        return {
          centroCusto : value.id,
          idUnidadeAdministrativa: value.centroCusto ? Number(value.centroCusto.toString().trim().slice(-3)) : null,
          fonte : value.fonteRecurso,
          programaTrabalho: value.programaTrabalho,
          projeto: value.id,
        };
      }
      return {
      };
    }

  },
  {
    field: "centroCusto",
    updates: (value) => {
      if (value && typeof(value) === "object") {
        return {
          projeto : value.id,
          idUnidadeAdministrativa: value.centroCusto ? Number(value.centroCusto.toString().trim().slice(-3)) : null,
          fonte : value.fonteRecurso,
          programaTrabalho: value.programaTrabalho,
          centroCusto: value.id,
        };
      }
      return {
      };
    }
  },

);

const identifierValueResolver = (e) => e.id || e.codigo || e;
const descriptionLabelResolver = (e) => e.descricao || e.numero || e.nome || e;
const centroCustoLabelResolver = (e) => ` ${e.centroCusto}-${e.programaTrabalho}` || e.centroCusto || e.programaTrabalho || e.fonteRecurso || e;

class PaginaFormParametrosRecibo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    }
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/cargos/simples").query(),
      createServiceFor("/projetos").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(createServiceFor("/recibos/parametros").get(this.props.match.params.id));

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises).then(([
        cargos,
        projeto,
        recibo = {},
      ]) => ({
        cargos: cargos.data,
        projeto: projeto.data,
        initialValues: recibo.data
            ? {
              ...recibo.data,
              centroCusto : recibo.data.projeto
            }
            : {},
      })
    )
    .catch((e) => {
      console.log(e);
      store.dispatch(showGlobalNotification({
        message: e.message
      }))
    });

    this.setState({
      promiseDados: promise
    });
  }

  submitForm(values) {
    console.log(values)
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/recibos/parametros")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Parâmetro de recibo salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Parâmetros de recibo</div> - <div class="text">ID: </div>${this.state.initialValues?.id}`
                : ''
            }
            ${
              this.state.initialValues?.centroCusto
                ? ` - <div class="text">Centro de custo:</div> ${this.state.initialValues.centroCusto}`
                : ''
            }
            ${
              this.state.initialValues?.fonte
                ? ` - <div class="text">Fonte:</div> ${this.state.initialValues.fonte}`
                : ''
            }
            ${
              this.state.initialValues?.programaTrabalho
                ? ` - <div class="text">Programa de Trabalho:</div> ${this.state.initialValues.programaTrabalho}`
                : ''
            }
            ${
              this.state.initialValues?.vinculacao
                ? ` - <div class="text">Vinculação:</div> ${this.state.initialValues.vinculacao}`
                : ''
            }
            ${
              this.state.initialValues?.unidadeAdministrativa
                ? ` - ${this.state.initialValues.unidadeAdministrativa}`
                : ''
            }

          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Parâmetros de recibos"
        baseUrl="/sgih/app/recibos/parametros"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field component="input" type="hidden"
                        name="id" value={id} />
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados do parâmetro">
                          <FormSectionWrapper title="Dados do projeto">
                          {estaEditando && <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                    component={TextField}
                                    name="id"
                                    label="Código do parâmetro"
                                    readOnly
                                  />
                              </Col>
                            </Row>
                          }
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField} name='projeto'
                                  label="Projeto"
                                  elements={data.projeto}
                                  buscavel
                                  callbackWithOriginalValue
                                  disabled={disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  validate={requiredWithNotification("Projeto")}
                                  required
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField} name='centroCusto'
                                  label="Centro de custo - Programa de Trabalho"
                                  elements={data.projeto}
                                  buscavel
                                  callbackWithOriginalValue
                                  disabled={disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={centroCustoLabelResolver}
                                  validate={requiredWithNotification("Centro de custo")}
                                  required
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="unidadeAdministrativa"
                                  label="Unidade administrativa"
                                  readOnly
                                  validate={requiredWithNotification("Unidade administrativa")}
                                  required
                                />
                                <Field component="input" type="hidden"
                                    name="idUnidadeAdministrativa"/>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="fonte"
                                  label="Fonte"
                                  validate={requiredWithNotification("Fonte")}
                                  required
                                  disabled={disableAllFields}
                                  readOnly
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="programaTrabalho"
                                  label="Programa de trabalho"
                                  validate={requiredWithNotification("Programa de trabalho")}
                                  required
                                  disabled={disableAllFields}
                                  readOnly
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="vinculacao"
                                  label="Vinculação"
                                  validate={requiredWithNotification("Vinculação")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField} name='cargoQuemAutoriza'
                                    label='Cargo quem autoriza' elements={data.cargos} buscavel
                                    callbackWithOriginalValue disabled={disableAllFields}
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    validate={requiredWithNotification("Cargo quem Autoriza")}
                                    required
                                  />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField} name='cargoQuemConfere'
                                    label='Cargo quem confere' elements={data.cargos} buscavel
                                    callbackWithOriginalValue disabled={disableAllFields}
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    validate={requiredWithNotification("Cargo quem Confere")}
                                    required
                                  />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Texto e endereços para o recibo">
                          <FormSectionWrapper title="Endereço">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={5}>
                                  <Field
                                    component={TextField}
                                    name="endereco"
                                    label="Endereço"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={1}>
                                  <Field
                                    component={TextField}
                                    name="numeroEndereco"
                                    label="Número"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="complemento"
                                    label="Complemento"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="cep"
                                    label="CEP"
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="estado"
                                    label="Estado"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                   <Field component={TextField}
                                        name="municipio"
                                        label="Município"
                                        disabled={true}
                                      />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="telefoneResidencial"
                                    label="Telefone fixo"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="celular"
                                    label="Telefone celular"
                                    disabled={true}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="cnpj"
                                    label="CNPJ"
                                    disabled={true}
                                    parse={formatString("XX.XXX.XXX/XXXX-XX")}
                                    placeholder="__.___.___/____-__"
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={TextField}
                                    name="inscricaoEstadual"
                                    label="Inscrição estadual"
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="IRRF/INSS">
                          <FormSectionWrapper title="IRRF">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={DateTimeField}
                                    name="dataAtualizacao"
                                    label="Data da atualização"
                                    className="form-control"
                                    disabled={disableAllFields}
                                  />
                                </Col>

                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="valorDeduzir"
                                    label="Valor a deduzir"
                                    disabled={disableAllFields}
                                    max={999999}
                                    decimaisAposvirgula={2}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="aliquotaIRPF"
                                    label="Alíquota IRRF"
                                    validate={requiredWithNotification(
                                      "Alíquota IRRF"
                                    )}
                                    required
                                    defaultValue={0}
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={NumberField}
                                    name="minimoIRPF"
                                    label="IRRF (mínimo)"
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={2}>
                                  <Field
                                    component={NumberField}
                                    name="maximoIRPF"
                                    label="IRRF (máximo)"
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="INSS">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={NumberField}
                                    name="percentualINSSCPRM"
                                    label="Percentual INSS da CPRM"
                                    validate={requiredWithNotification(
                                      "Percentual INSS da CPRM"
                                    )}
                                    required
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>

                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={NumberField}
                                    name="percentualINSSObservador"
                                    label="Percentual INSS do observador"
                                    validate={requiredWithNotification(
                                      "Percentual INSS do observador"
                                    )}
                                    required
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={NumberField}
                                    name="maximoDescontoINSS"
                                    label="Máximo desconto do INSS"
                                    max={999999}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormParametrosRecibo;
