import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import JqxLoader from "jqwidgets-scripts/jqwidgets-react-tsx/jqxloader";
import JqxTooltip from "jqwidgets-scripts/jqwidgets-react-tsx/jqxtooltip";
import swal from "sweetalert";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import createServiceFor from "../../service/Service";
import NumberField from "../../components/form/field/NumberField";
import OrigemRelatorio from 'model/OrigemRelatorio';
import { extrairPermissoes } from "reducers/authReducers";
import store from "store";
import StatusRelatorio from "model/StatusRelatorio";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
import { Modal } from "../../components/modal/Modal";
import './PaginaListaRelatoriosProducaoCampo.css';
import CustomLoader from "components/custom-loader/CustomLoader";

const estilosCamposTotalizadores = [{ style: { color: "blue" } }];

class PaginaListaRelatoriosProducaoCampo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrintModalOpen: false,
      exibir: false,
      totalPlu: 0,
      totalFlu: 0,
      totalQA: 0,
      totalPCD: 0,
      totalLevant: 0,
      totalDescLiqEfetiva: 0,
      totalDescLiqNaoEfetiva: 0,
      totalDescSol: 0,
      totalQuilometragem: 0,
    }

    this.tableRef = React.createRef();

    UNIDADEREGIONAL(
      (regionais) => {
        this.setState({
          regionais
        },
          StatusRelatorio(
            (statusRelatorio) => {
              this.setState({
                statusRelatorio
              }, () => {
                const source = {
                  date: "dd/MM/yyyy",
                  datafields: [
                    { name: "id", type: "number", map: "id" },
                    { name: "numero", type: "string", map: "id" },
                    { name: "roteiro", type: "number", map: "roteiro" },
                    { name: 'regional', type: 'string', map: 'regional' },
                    { name: "equipe", type: "string", map: "equipe" },
                    { name: "campanha", type: "date", map: "campanha" },
                    { name: "dataInicial", type: "date", map: "dataInicial" },
                    { name: "dataFinal", type: "date", map: "dataFinal" },
                    {
                      name: "numVisitasPluviometricas",
                      type: "number",
                      map: "numVisitasPluviometricas",
                    },
                    {
                      name: "numVisitasFluviometricas",
                      type: "number",
                      map: "numVisitasFluviometricas",
                    },
                    { name: "numMedicoesQA", type: "number", map: "numMedicoesQA" },
                    { name: 'numVisitasPCD', type: 'number', map: 'numVisitasPCD' },
                    {
                      name: "numMedicoesDescSolida",
                      type: "number",
                      map: "numMedicoesDescSolida",
                    },
                    {
                      name: "numMedicoesDescLiquidaEfetiva",
                      type: "number",
                      map: "numMedicoesDescLiquidaEfetiva",
                    },
                    {
                      name: "numMedicoesDescLiquidaNaoEfetiva",
                      type: "number",
                      map: "numMedicoesDescLiquidaNaoEfetiva",
                    },
                    {
                      name: "numMedicoesSecaoTransversal",
                      type: "number",
                      map: "numMedicoesSecaoTransversal",
                    },
                    { name: "origem", type: "string", map: "origem" },
                    { name: "equipeFormatada", type: "string", map: "equipeFormatada" },
                    {
                      name: "perfilUltimaAlteracaoFluxo",
                      type: "string",
                      map: "perfilUltimaAlteracaoFluxo",
                    },
                    { name: "idRoteiro", type: "number", map: "idRoteiro" },
                    {
                      name: "consumo",
                      type: "number",
                      map: "consumo",
                    },
                    {
                      name: "transporte",
                      type: "number",
                      map: "transporte",
                    },
                    {
                      name: "pessoaFisica",
                      type: "number",
                      map: "pessoaFisica",
                    },
                    {
                      name: "pessoaJuridica",
                      type: "number",
                      map: "pessoaJuridica",
                    },
                    {
                      name: "voluntario",
                      type: "number",
                      map: "voluntario",
                    },
                    {
                      name: "total",
                      type: "number",
                      map: "total",
                    },
                  ],
                  id: "id",
                };

                const columns = [
                  { text: "ID", dataField: "id", width: "3%" },
                  {
                    text: "Status Atual",
                    dataField: "perfilUltimaAlteracaoFluxo",
                    width: "7%",
                    filtertype: 'checkedlist',
                    filteritems: this.state ? this.state.statusRelatorio : []
                  },
                  { text: "Origem", dataField: "origem", filtertype: 'checkedlist', filteritems: OrigemRelatorio, width: "5%" },
                  {
                    text: "Roteiro",
                    dataField: "roteiro",
                    filtertype: "number",
                    width: "5%",
                  },
                  { text: 'Regional', dataField: 'regional', width: '6%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                  {
                    text: "Campanha",
                    filtertype: "date",
                    dataField: "campanha",
                    width: "6%",
                    cellsformat: "MM/yyyy",
                  },
                  {
                    text: "Data inicial",
                    filtertype: "date",
                    cellsformat: "dd/MM/yyyy",
                    dataField: "dataInicial",
                    width: "7%",
                  },
                  {
                    text: "Data final",
                    filtertype: "date",
                    cellsformat: "dd/MM/yyyy",
                    dataField: "dataFinal",
                    width: "7%",
                  },
                  {
                    text: "PLU",
                    dataField: "numVisitasPluviometricas",
                    width: "4%",
                    cellsalign: "right",
                  },
                  {
                    text: "FLU",
                    dataField: "numVisitasFluviometricas",
                    width: "4%",
                    cellsalign: "right",
                  },
                  {
                    text: "FQA",
                    dataField: "numMedicoesQA",
                    width: "4%",
                    cellsalign: "right",
                  },
                  {
                    text: "FQS",
                    dataField: "numMedicoesDescSolida",
                    width: "4%",
                    cellsalign: "right",
                  },
                  {
                    text: "MDL Efet.",
                    dataField: "numMedicoesDescLiquidaEfetiva",
                    width: "6%",
                    cellsalign: "right",
                  },
                  {
                    text: "MDL RS/RC",
                    dataField: "numMedicoesDescLiquidaNaoEfetiva",
                    width: "7%",
                    cellsalign: "right",
                  },
                  {
                    text: "LST",
                    dataField: "numMedicoesSecaoTransversal",
                    width: "4%",
                    cellsalign: "right",
                  },
                  { text: 'PCD', dataField: 'numVisitasPCD', width: '4%', cellsalign: 'right' },
                  {
                    text: "Consumo (3390.30)",
                    dataField: "consumo",
                    cellsalign: "right",
                    cellsformat: 'c2',
                    width: "7%",
                  },
                  {
                    text: "Transporte (3390.33)",
                    dataField: "transporte",
                    cellsalign: "right",
                    width: "7%",
                    cellsformat: 'c2',
                  },
                  {
                    text: "PF (3390.36)",
                    dataField: "pessoaFisica",
                    cellsalign: "right",
                    width: "7%",
                    cellsformat: 'c2',
                  },
                  {
                    text: "PJ (3390.39)",
                    dataField: "pessoaJuridica",
                    cellsalign: "right",
                    width: "7%",
                    cellsformat: 'c2',
                  },
                  {
                    text: "Voluntário (3390.93)",
                    dataField: "voluntario",
                    cellsalign: "right",
                    width: "7%",
                    cellsformat: 'c2',
                  },
                  {
                    text: "Total",
                    dataField: "total",
                    width: "7%",
                    cellsformat: 'c2',
                    cellsalign: "right",
                  },
                  {
                    text: "Equipe",
                    dataField: "equipe",
                    sortable: false,
                    cellsrenderer: function (
                      row,
                      columnfield,
                      value,
                      defaulthtml,
                      columnproperties,
                      rowdata
                    ) {
                      return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${rowdata.equipeFormatada}">${value}</div>`;
                    },
                  },
                ];



                this.tableConfig = { source, columns };

                this.setState({
                  exibir: true
                })
              })

            }
          )
        )
      }
    )
  }

  closePrintModal() {
    this.setState({
      isPrintModalOpen: false,
    });
  }

  iniciarBaixarProducao(tableRef) {
    this.tableRef = tableRef;

    this.setState({
      isPrintModalOpen: true,
    });
  }

  async baixarProducao(formato) {
    this.closePrintModal();

    const linhasSelecionadas = this.tableRef?.current?.getselectedrowindexes();

    if(!linhasSelecionadas || linhasSelecionadas.length === 0){
      swal({
        title: "Selecione ao menos um registro da tabela para baixar a produção",
        icon: "info",
        buttons: {
          confirm: {
            text: "OK",
            className: "btn-primary",
          },
        },
      });
      return;
    }

    const baixar = async (ids, numeroOrdem) => {
      this.setState({isLoadingDownload: true});

      const link = document.createElement("a");
      let downloadPath = ids.length > 1
        ? `/relatoriosProducaoCampo/baixarProducao/lista/${ids}/${formato}`
        : `/relatoriosProducaoCampo/baixarProducao/${ids}/${formato}/${numeroOrdem}`;

      const downloadResponse = await createServiceFor(downloadPath)
        .request('GET', '', {
          responseType: 'arraybuffer'
        });

      const dataBlob = new Blob([downloadResponse.data], {
        type: downloadResponse.headers['content-type'],
      });

      const objectURL = window.URL.createObjectURL(dataBlob);

      link.href = objectURL;
      link.download = downloadResponse.headers['x-nome-arquivo'];
      link.click();

      window.URL.revokeObjectURL(objectURL);

      this.props.encerrarCarregamento();
    }

    const quantidadeTotalRelatorios =  linhasSelecionadas.length || 0;

    for (let i = 0; i < quantidadeTotalRelatorios; i++) {
      const numeroItemAtual = i + 1;

      const itemSelecionado = this.tableRef?.current?.getrowdata(linhasSelecionadas[i]);

      if (!itemSelecionado) {
        swal({
          title: `Não foi possível identificar o relatório nº ${i+1} da seleção.`,
          icon: "warning",
          buttons: {
            confirm: {
              text: "OK",
              className: "btn-primary",
            },
          },
        });
        return;
      }

      if (itemSelecionado?.equipe == null || itemSelecionado?.equipe == undefined){
        swal({
          title: `O Relatório selecionado (ID=${itemSelecionado.id}) não possui equipe.`,
          icon: "warning",
          buttons: {
            confirm: {
              text: "OK",
              className: "btn-primary",
            },
          },
        });
        this.setState({isLoadingDownload: false});
        return;
      }

      const filtro = {
        campanhaoperator: "and",
        filtervalue0: itemSelecionado.campanha,
        filtercondition0: "EQUAL",
        filteroperator0: 0,
        filterdatafield0: "campanha",

        idRoteirooperator: "and",
        filtervalue1: itemSelecionado.idRoteiro.toString(),
        filtercondition1: "EQUAL",
        filteroperator1: 0,
        filterdatafield1: "idRoteiro",

        filterGroups: [
            {
                field: "campanha",
                filters: [
                    {
                        label: itemSelecionado.campanha,
                        value: itemSelecionado.campanha,
                        condition: "EQUAL",
                        operator: "and",
                        field: "campanha",
                        type: "datefilter"
                    }
                ]
            },
            {
                field: "idRoteiro",
                filters: [
                    {
                        labe:  itemSelecionado.idRoteiro.toString(),
                        value:  itemSelecionado.idRoteiro.toString(),
                        condition: "EQUAL",
                        operator: "and",
                        field: "idRoteiro",
                        type: "numericfilter"
                    }
                ]
            },
        ],
        filterscount: 2,
        groupscount: 0,
        sortdatafield: "campanha",
        sortorder: "",
        pagenum: 0,
        pagesize: 1000,
        recordstartindex: 0,
        recordendindex: 1000
      }

      try {
        const response = await createServiceFor("/relatoriosProducaoCampo").customURL("POST", "lista", filtro);

        const lista = response.data.dados;
        console.log(lista);
        let equipeVazia = false;
        let relatoriosSemEquipe = [];

        lista.forEach((item)=> {
          if(item.equipe == null || item.equipe == undefined) {
            relatoriosSemEquipe.push(item.id);
            equipeVazia = true
          }
        });

        if(lista && lista.length > 1) {
          const resultado = await swal({
            title: `Relatórios selecionados para baixar (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
            text: `Deseja baixar somente o selecionado (ID=${itemSelecionado.id}) ou todos os relatórios do mesmo roteiro e campanha?`,
            icon: "info",
            buttons: {
              selecionado: {
                text: "Selecionado",
                className: "btn-primary",
              },
              todos: {
                text: "Todos",
                className: "btn-primary",
              }
            },
          });

          if(!resultado){
            this.setState({isLoadingDownload: false});
            return;
          }

          if (i === 0 && quantidadeTotalRelatorios > 1) {
            this.props.exibirNotificacao({
              type: 'info',
              message: `Preparando para baixar o(s) ${quantidadeTotalRelatorios} relatório(s) selecionados...`,
            });
          }

          if (resultado === 'selecionado'){
            this.props.exibirNotificacao({
              type: 'time',
              message: `Iniciando download do relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
            });

            await baixar([itemSelecionado.id], lista.map(e => e.id).sort((a,b) => a - b).indexOf(itemSelecionado.id) + 1);

            this.props.exibirNotificacao({
              type: 'success',
              message: `Download concluído: relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
            });

            this.setState({isLoadingDownload: false});

            continue;
          }

          if(resultado === 'todos') {
            if(equipeVazia) {
              swal({
                title:`O(s) relatório(s) de produção ${relatoriosSemEquipe.join(", ")} não possui(em) equipe preenchida.`,
                icon: "warning",
                buttons: {
                  confirm: {
                    text: "OK",
                    className: "btn-primary",
                  },
                },
              });

              this.setState({isLoadingDownload: false});

              continue;
            }

            this.props.exibirNotificacao({
              type: 'time',
              message: `Iniciando download do relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
            });

            await baixar(lista.map(e => e.id), 1);

            this.props.exibirNotificacao({
              type: 'success',
              message: `Download concluído: relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
            });

            continue;
          }

          continue;
        }

        this.props.exibirNotificacao({
          type: 'time',
          message: `Iniciando download do relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
        });

        await baixar([itemSelecionado.id], 1);

        this.setState({isLoadingDownload: false});

        this.props.exibirNotificacao({
          type: 'success',
          message: `Download concluído: relatório (${numeroItemAtual}/${quantidadeTotalRelatorios})`,
        });

      } catch (error) {
        this.setState({isLoadingDownload: false});
        this.props.exibirNotificacao({
          type: 'error',
          message: `Erro ao tentar baixar o relatório (${numeroItemAtual}/${quantidadeTotalRelatorios}): ${error.message}`,
        });
      };

    }
  }

  formatarData(data) {
    let dia = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
    let mes = (data.getMonth() + 1) < 10 ? `0${(+ data.getMonth() + 1)}` : data.getMonth() + 1;
    return `${dia}/${mes}/${data.getFullYear()}`;
  }

  async fluxoRelatorioEmMassa(tableRef) {
    if (tableRef.current && tableRef.current.isBindingCompleted()) {
      const idPerfil = this.props.permissoes.idPerfil;
      const selectedrows = tableRef.current.getselectedrowindexes();
      var umaLinhaSelecionada = selectedrows.length === 1;

      if (!umaLinhaSelecionada) {
        swal({ title: "Selecione um registro da tabela para alterar o fluxo de relatórios",
          icon: "info", buttons: {confirm: { text: "OK", className: "btn-primary", },}, closeOnClickOutside: true, closeOnEsc: true,
        });
        return;
      }

      const item = tableRef?.current?.getrowdata(selectedrows[0]);

      const idProducao = item.id;
      const equipe = item.equipe;
      const dataInicial = this.formatarData(item.dataInicial);
      const dataFinal = this.formatarData(item.dataFinal);
      const roteiro = item.roteiro;
      const idRoteiro = item.idRoteiro;
      const campanhaString = item.campanha;
      const perfilUltimaAlteracaoString = item.perfilUltimaAlteracaoFluxo;
      const nomePerfil = this.props.permissoes.nomePerfil;
      const idPerfilFluxoPadrao = this.props.permissoes.idPerfilFluxoPadrao;

      if (perfilUltimaAlteracaoString !== nomePerfil) {
        swal({ title: "O registro selecionado não pode ser alterado por este perfil", icon: "warning",
          buttons: { confirm: { text: "OK", className: "btn-primary" } }, closeOnClickOutside: true, closeOnEsc: true,
        });
        return;
      }

      if (!idPerfilFluxoPadrao) {
        swal({ title: "Seu perfil de usuário não possui um fluxo padrão definido para o relatório selecionado", icon: "warning",
          buttons: { confirm: { text: "OK", className: "btn-primary" } }, closeOnClickOutside: true, closeOnEsc: true,
        });
        return;
      }

      let listaRelatorios = null;
      let mensagemRelatorios = "";
      let listaPerfis = null;
      const listaPlu = [];
      const listaFlu = [];
      const listaQA = [];
      const listaPCD = [];
      const listaProd = [];
      const linhas = [];


      try{
        store.dispatch(startGlobalLoading('Carregando relatórios...'));
        const resListaRelatorios = await createServiceFor("/relatoriosProducaoCampo/fluxoRelatoriosMassa/visualizar")
        .getWithParams({ idProducao, idRoteiro, roteiro, campanhaString, idPerfil, idsMembrosEquipe: equipe, dataInicial, dataFinal });
        const resListaPerfis = await createServiceFor("/perfis/combo").customURL('GET', '', null);
        store.dispatch(stopGlobalLoading());
        listaRelatorios = resListaRelatorios.data.lista;
        listaPerfis = resListaPerfis.data;

        const possuiRelatoriosComErro = resListaRelatorios.data.possuiRelatoriosComErro;

        if(possuiRelatoriosComErro){
          listaRelatorios = resListaRelatorios.data.lista.filter((i) => i.perfilUltimaAlteracaoFluxo != nomePerfil);

          listaRelatorios.forEach(relatorio => {
            if(relatorio.tipoRelatorio === 'Pluviométrico')
              listaPlu.push(relatorio);

            else if(relatorio.tipoRelatorio === 'Fluviométrico')
              listaFlu.push(relatorio);

            else if(relatorio.tipoRelatorio === 'Qualidade de água')
              listaQA.push(relatorio);

            else if(relatorio.tipoRelatorio === 'Produção de campo')
              listaProd.push(relatorio);

            else if(relatorio.tipoRelatorio === 'PCD')
              listaPCD.push(relatorio);
          });

          if(listaPlu.length){
            linhas.push(listaPlu.length > 1 ? `\nOs ${listaPlu.length} relatórios pluviométricos` : '\nO relatório pluviométrico:');
            listaPlu.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
          }

          if(listaFlu.length){
            linhas.push(listaFlu.length > 1 ? `\nOs ${listaFlu.length} relatórios fluviométricos` : '\nO relatório fluviométrico:');
            listaFlu.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
          }

          if(listaQA.length){
            linhas.push(listaQA.length > 1 ? `\nOs ${listaQA.length} relatórios de qualidade de água` : '\nO relatório de qualidade de água:');
            listaQA.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
          }

          if(listaProd.length){
            linhas.push(listaProd.length > 1 ? `\nOs ${listaProd.length} relatórios de produção de campo` : '\nO relatório de produção de campo:');
            listaProd.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
          }

          if(listaPCD.length){
            linhas.push(listaPCD.length > 1 ? `\nOs ${listaPCD.length} relatórios de PCD` : '\nO relatório de PCD:');
            listaPCD.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
          }


          let mensagemErro = "";
          for (var i = 0; i < linhas.length; i++)
            mensagemErro += linhas[i] + "\n";

          swal({
            title: "Relatórios com status diferente", text: `${mensagemErro}`, icon: "error",
            buttons: { confirm: { text: "OK", className: "btn-danger", }, }, closeOnClickOutside: false, className: "w-50"
          });
          return;
        }

        const proximoPerfil = listaPerfis.find((p) => p.id === idPerfilFluxoPadrao);

        listaRelatorios = listaRelatorios.filter((i) => i.perfilUltimaAlteracaoFluxo === nomePerfil);

        listaRelatorios.forEach(relatorio => {
          if(relatorio.tipoRelatorio === 'Pluviométrico')
            listaPlu.push(relatorio);

          else if(relatorio.tipoRelatorio === 'Fluviométrico')
            listaFlu.push(relatorio);

          else if(relatorio.tipoRelatorio === 'Qualidade de água')
            listaQA.push(relatorio);

          else if(relatorio.tipoRelatorio === 'Produção de campo')
            listaProd.push(relatorio);

          else if(relatorio.tipoRelatorio === 'PCD')
            listaPCD.push(relatorio);
        });

        if(listaPlu.length){
          linhas.push(listaPlu.length > 1 ? `\nOs ${listaPlu.length} relatórios pluviométricos` : '\nO relatório pluviométrico:');
          listaPlu.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
        }

        if(listaFlu.length){
          linhas.push(listaFlu.length > 1 ? `\nOs ${listaFlu.length} relatórios fluviométricos` : '\nO relatório fluviométrico:');
          listaFlu.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
        }

        if(listaQA.length){
          linhas.push(listaQA.length > 1 ? `\nOs ${listaQA.length} relatórios de qualidade de água` : '\nO relatório de qualidade de água:');
          listaQA.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
        }

        if(listaProd.length){
          linhas.push(listaProd.length > 1 ? `\nOs ${listaProd.length} relatórios de Produção de campo` : '\nO relatório de produção de campo:');
          listaProd.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
        }

        if(listaPCD.length){
          linhas.push(listaPCD.length > 1 ? `\nOs ${listaPCD.length} relatórios de PCD` : '\nO relatório de PCD:');
          listaPCD.forEach((obj) => linhas.push(`Relatório : ${obj.idRelatorio}, ${obj.codigoEstacaoFluxo ? 'Estação : ' + obj.codigoEstacaoFluxo + ',' : ''} Status : ${obj.perfilUltimaAlteracaoFluxo}`));
        }

        for (var i = 0; i < linhas.length; i++)
          mensagemRelatorios += linhas[i] + "\n";

        let mensagemCompleta = `O${listaRelatorios.length > 1? 's ' + listaRelatorios.length: ''}  relatório${listaRelatorios.length > 1? 's': ''}  com status ${nomePerfil} abaixo ${listaRelatorios.length > 1? 'serão alterados': 'sera alterado'} para o status ${proximoPerfil.nome}. Deseja continuar?`
        + '\n' + mensagemRelatorios;

        swal({ title: "Enviar relatórios em bloco", text: mensagemCompleta, icon: "info",
          buttons: { confirm: { text: "Sim", className: "btn-primary", }, cancel: "Não", },
          closeOnClickOutside: false,
          className: "w-50"
        })
        .then((resultadoModal) => {
          if(!resultadoModal) return;

          store.dispatch(startGlobalLoading('Atualizando relatórios...'));
          createServiceFor("/relatoriosProducaoCampo/fluxoRelatoriosMassa/")
          .getWithParams({ idProducao, idRoteiro, roteiro, campanhaString, idPerfil, idsMembrosEquipe: equipe, dataInicial, dataFinal })
          .then((res) => {
            store.dispatch(stopGlobalLoading());
            const data = res.data;

            if(typeof (data) === "string") {
              this.props.exibirNotificacao({
                type: "success",
                message: res.data,
              });
            } else {
              this.props.exibirNotificacao({
                type: 'error',
                message: "Não foi possivel atualizar enviar relatórios em bloco",
              });
            }

            tableRef.current.updatebounddata();
          })
          .catch((err) => {
            console.log('Erro ao enviar relatorios em bloco', err);
            store.dispatch(stopGlobalLoading());
            this.props.exibirNotificacao({
              type: 'error',
              message: "Não foi possivel atualizar enviar relatórios em bloco",
            });
          });

        });

      }
      catch(err) {
        console.log('Erro no envio de relatorios em bloco', err);
        store.dispatch(stopGlobalLoading());
        this.props.exibirNotificacao({
          type: 'error',
          message: err.message,
        });
        return;
      }

    }
  }

  resetarTotalizadores() {
    this.setState({
      totalPlu: 0,
      totalFlu: 0,
      totalQA: 0,
      totalPCD: 0,
      totalLevant: 0,
      totalDescLiqEfetiva: 0,
      totalDescLiqNaoEfetiva: 0,
      totalDescSol: 0,
      totalQuilometragem: 0,
    });
  }

  atualizarExibicaoTotalizadores() {
    if (this.tableRef.current && this.tableRef.current.isBindingCompleted()) {
      this.resetarTotalizadores();

      const activeFilter = JSON.parse(localStorage.getItem("FILTER"));

      activeFilter.pagenum = 0;
      activeFilter.pagesize = 999999;

      this.props.iniciarCarregamento();

      createServiceFor("/relatoriosProducaoCampo")
        .customURL("POST", "totalizacaoParaFiltros", activeFilter)
        .then((res) => {
          const data = res.data;

          this.setState({
            totalPlu: data.numVisitasPluviometricas,
            totalFlu: data.numVisitasFluviometricas,
            totalQA: data.numMedicoesQA,
            totalPCD: data.numVisitasPCD,
            totalLevant: data.numMedicoesSecaoTransversal,
            totalDescLiqEfetiva: data.numMedicoesDescargaLiquidaEfetiva,
            totalDescLiqNaoEfetiva: data.numMedicoesDescargaLiquidaNaoEfetiva,
            totalDescSol: data.numMedicoesDescargaSolida,
            totalQuilometragem: data.quilometragem,
          });
          this.props.encerrarCarregamento();
        })
        .catch((e) => {
          this.props.encerrarCarregamento();
          this.props.exibirNotificacao({
            type: 'error',
            message: e.message,
          });
        });
    }
  }

  exibirBotao(botao) {
    if (null !== botao) {
      botao.classList.remove("d-none");
      botao.classList.add("d-flex");
    }
  }

  ocultarBotao(botao) {
    if (null !== botao) {
      botao.classList.add("d-none");
      botao.classList.remove("d-flex");
    }
  }


  render() {
    const acaoDownloadProducao = {
      render: (key, tableRef) => {
        return (
          <JqxTooltip
            position="mouse"
            content={'<b>Dica</b>: segure o <b><code>CTRL</code></b> e <b><code>clique</code></b> nas linhas para selecionar múltiplos relatórios e baixar todos.'}
          >
            <JqxButton
              key={key}
              theme="material"
              width={120}
              onClick={() => this.iniciarBaixarProducao(tableRef)}
              className="py-2 float-right mt-3 mb-3 ml-3"
            >
              Baixar produção
            </JqxButton>
          </JqxTooltip>
        );
      },
    };
    //TODO: retonar após resposta para visualização botão
    const fluxoRelatorioMassa = {
      render: (key, tableRef) => {
        if(this.props.permissoes.permitirEnviarRelatoriosEmBloco){
          return (
            <div id="botaoFluxoRelatorio">
              <JqxButton
                key={key}
                theme="material"
                width={200}
                onClick={() => this.fluxoRelatorioEmMassa(tableRef)}
                className="py-2 float-right mt-3 mb-3 ml-3"
              >
                Enviar relatórios em bloco
              </JqxButton>
            </div>
          );
        }
      },
    };

    return this.state ? this.state.exibir ?
      <CrudListPageScaffold
        title="Relatórios de produção de campo"
        tableConfig={this.tableConfig}
        baseUrl="/relatoriosProducaoCampo"
        history={this.props.history}
        formLinkTitle="Novo relatório"
        tableName="relatoriosProdCampo"
        tipoRelatorio="PRODUCAO_CAMPO"
        preActions={[acaoDownloadProducao, fluxoRelatorioMassa]}
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/producao"
        aoTrocarFiltrosTabela={this.resetarTotalizadores.bind(this)}
        aoCarregarTabela={this.resetarTotalizadores.bind(this)}
        tableRef={this.tableRef}
      >
        <Modal
          aberto={this.state.isPrintModalOpen}
          aoFechar={this.closePrintModal.bind(this)}
          titulo="Baixar produção"
        >
          <p>Definir formato de relatório</p>
          <Row>
            <Col className="mt-3 d-flex justify-content-center">
              <JqxButton
                theme="material"
                width={100}
                className="py-2 float-right"
                onClick={() => this.baixarProducao('PDF')}
              >
                PDF
              </JqxButton>
            </Col>
            <Col className="mt-3 d-flex justify-content-center">
              <JqxButton
                theme="material"
                width={100}
                className="py-2 float-right"
                onClick={() => this.baixarProducao('TIFF')}
              >
                TIFF
              </JqxButton>
            </Col>
          </Row>
        </Modal>

        <CustomLoader
          loading={this.state.isLoadingDownload}
          content={"Download em andamento. Por favor, aguarde."}
        />

        <Row style={{ fontSize: "13px" }} className="totalizers mt-1 mb-4 pt-2">
          <Col sm={2} md={2} lg={2}>
            <Row>
              <Col md={6}>
                <NumberField
                  value={this.state.totalPlu}
                  label="Total visitas P"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
              <Col md={6}>
                <NumberField
                  value={this.state.totalFlu}
                  label="Total visitas F"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
            </Row>
          </Col>
          <Col sm={2} md={2} lg={2}>
            <Row>
              <Col md={6}>
                <NumberField
                  value={this.state.totalQA}
                  label="Total QA"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
              <Col md={6}>
                <NumberField
                  value={this.state.totalLevant}
                  label="Total FQS"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
            </Row>
          </Col>
          <Col sm={2} md={3} lg={3}>
            <Row>
              <Col sm={4} md={4}>
                <NumberField
                  value={this.state.totalDescLiqEfetiva}
                  label="Total MDL Efet."
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
              <Col sm={4} md={4}>
                <NumberField
                  value={this.state.totalDescLiqNaoEfetiva}
                  label="Total MDL RS/RC"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
              <Col sm={4} md={4}>
                <NumberField
                  value={this.state.totalDescSol}
                  label="Total LST"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
            </Row>
          </Col>
          <Col sm={2} md={3} lg={2}>
            <Row>
              <Col md={5}>
                <NumberField
                  value={this.state.totalPCD}
                  label="Total visitas PCD"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
              <Col md={7}>
                <NumberField
                  value={this.state.totalQuilometragem}
                  label="Total de KM"
                  readOnly
                  forcarValorZerado
                  customStylePerRange={estilosCamposTotalizadores}
                  accessibleHint
                />
              </Col>
            </Row>
          </Col>
          <Col className="buttonAndTooltip" sm={4} md={2} lg={3}>
            <Row>
              <Col
                className="d-flex justify-content-start tip"
                md={4}
                lg={1}
              >
                {this.props.isLoading && (
                  <JqxLoader
                    autoOpen
                    width={32}
                    height={32}
                    theme=""
                    text={''}
                  />
                )}
                {!this.props.isLoading && (
                  <JqxTooltip
                    content="Ao utilizar os filtros, será necessário calcular novamente as totalizações da pesquisa."
                    position="mouse"
                    width={264}
                    autoHide={false}
                  >
                    <span className="fa fa-info-circle fa-2x text-black-50"></span>
                  </JqxTooltip>
                )}
              </Col>
              <Col
                className="mt-3 pt-1 offset-1 d-flex justify-content-end option"
                md={7}
                lg={10}
              >
                <JqxButton
                  theme="material"
                  className="py-2 pl-3 pr-3 float-right"
                  onClick={this.atualizarExibicaoTotalizadores.bind(this)}
                  disabled={(!this.tableRef.current?.isBindingCompleted()) || this.props.isLoading}
                >
                  Calcular totalizações da pesquisa
                </JqxButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </CrudListPageScaffold>

      : <></>
      : <></>

  }
}

export default connect((state) => ({
  permissoes: extrairPermissoes(state),
  isLoading: state.global.isLoading,
}), (dispatch) => ({
  iniciarCarregamento() {
    dispatch(startGlobalLoading());
  },
  encerrarCarregamento() {
    dispatch(stopGlobalLoading());
  },
  exibirNotificacao(notificationOptions) {
    dispatch(
      showGlobalNotification(notificationOptions)
    );
  },
}))(PaginaListaRelatoriosProducaoCampo);
