import { combineReducers } from "redux";
import { globalReducer } from "./globalReducers"
import {tableReducer} from "./tableReducers";
import {fileReducer} from "./fileReducers";
import {authReducer} from "./authReducers";

export default combineReducers({
    global: globalReducer,
    tables: tableReducer,
    files: fileReducer,
    auth: authReducer,
});

export {
    startGlobalLoading, stopGlobalLoading, showGlobalNotification, dismissGlobalNotification
} from "./globalReducers"

export { replaceTableState } from "./tableReducers"
