import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import {defaultProps, propTypes} from "./formFieldCommonProps";
import FieldLabel from "./FieldLabel";
import InputMask from 'react-input-mask'
import Error from "../Error";
import {InputGroup} from "react-bootstrap";

class PasswordField extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = (event) => {
            const value = props.forcarMaiusculas
                ? event.target.value.toUpperCase()
                : event.target.value.replace(' ','').length === 0
                    ? undefined 
                    : event.target.value.replace('  ',' ') ;
            
            if (props.input) {
                props.input.onChange(value);
            }
            if (props.onChange) {
                props.onChange(value);
            }

        }
    }

    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            input, meta, forcarMaiusculas,
            showError,
            mask: propMask,
            textoPre,
            mostrarMensagemOnBlur,
            ...rest
        } = this.props;

        const name = this.props.name || (this.props.input && this.props.input.name);
        const value = this.props.value || (this.props.input && this.props.input.value);

        const mask = this.resolveMask(propMask);
        const formatChars = {
            '9': '[0-9]',
            'a': '[A-Za-z]',
            '*': '[A-Za-z0-9]',

            'A': '[A-Z]',
            'Z': '[A-Za-z0-9]',
            '1': '[0-9]',
        };

        return <>
            {this.props.label && <FieldLabel label={this.props.label} required={this.props.required} />}

            <InputGroup className="mb-0">
                {textoPre &&
                    <InputGroup.Prepend style={{height: '35px'}}>
                        <InputGroup.Text style={{fontSize: '.9rem'}}>{textoPre}</InputGroup.Text>
                    </InputGroup.Prepend>
                }
                {(() => {
                    if (mask) {
                        return <InputMask {...rest} type='text' name={name} onChange={this.onChange} value={value}
                                          formatChars={formatChars} mask={mask}>
                            {inputProps => {
                                return <Form.Control type="text" required={this.props.required} name={name}
                                                     onChange={this.onChange} disabled={this.props.disabled}
                                                     readOnly={this.props.readOnly} {...inputProps} />
                            }}
                        </InputMask>
                    } else {
                        return <Form.Control {...rest} type='password' name={name} onChange={this.onChange} value={value} />
                    }
                })()}
            </InputGroup>

            {input && showError && <Error name={name} mostrarMensagemOnBlur={mostrarMensagemOnBlur} />}
        </>;
    }

    // https://github.com/insin/inputmask-core#pattern
    resolveMask(mask) {
        if (!mask) return null;

        if ('placaDeCarro' === mask) {
            // return [/[A-Z]\d[A-Z\d]\d{2}/];
            return "aaa1Z11";
        }
        if ('codigoEstacao' === mask) {
            return "99999999";
        }

        return mask;
    }
}

PasswordField.propTypes = {
    ...propTypes,
    label: PropTypes.string,
    onChange: PropTypes.func,

    textoPre: PropTypes.string,
    mask: PropTypes.string,
    forcarMaiusculas: PropTypes.bool,
    mostrarMensagemOnBlur: PropTypes.bool,
};

PasswordField.defaultProps = {
    ...defaultProps,
    forcarMaiusculas: false,
    mostrarMensagemOnBlur: false,
    textoPre: '',
};

export default PasswordField;
