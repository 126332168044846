export const ROUBO = {
    viewName: "Roubo", serializedName: "ROUBO",
};
export const ACIDENTE = {
    viewName: "Acidente", serializedName: "ACIDENTE",
};
export const INCENDIO = {
    viewName: "Incêndio", serializedName: "INCENDIO",
};
export const DANOS_NATUREZA = {
    viewName: "Danos causados pela natureza", serializedName: "DANOS_NATUREZA",
};

export default [
    ACIDENTE, DANOS_NATUREZA, INCENDIO, ROUBO
];
