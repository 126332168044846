import Warning from "./Warning";
import React from "react";
import ValorCampoEh from "./ValorCampoEh";
import PropTypes from "prop-types";

function AlertaCondicional({ nome, valor, exibir=true, mensagem, inverter }) {
    return <ValorCampoEh nome={nome} valor={valor} inverter={inverter}>
        {valorCorreto => exibir && valorCorreto &&
            <Warning message={mensagem} />
        }
    </ValorCampoEh>;
}

AlertaCondicional.propTypes = {
    nome: PropTypes.string.isRequired,
    mensagem: PropTypes.string.isRequired,

    valor: PropTypes.any.isRequired,

    exibir: PropTypes.bool,
    inverter: PropTypes.bool,
};

AlertaCondicional.defaultProps = {
    exibir: true,
    inverter: false,
};

export default AlertaCondicional;
