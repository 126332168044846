import React from 'react';
import ReactDOMClient from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from './components/app/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material.css';
import 'assets/css/jqx.sgih.css';
import 'assets/css/style.css';
import {Provider} from "react-redux";
import store, {persistor} from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import BlockUi from "react-block-ui";
import 'web-components/tooltip';

require('dotenv').config();

const hist = createBrowserHistory();

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={<BlockUi />} persistor={persistor}>
            <Router history={hist}>
                <App />
            </Router>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
