import React from "react";
import PropTypes from "prop-types";

function BotaoIconeExclusao({ onClick, fullHeight }) {
    let wrapperStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    if (fullHeight) wrapperStyle = {
        ...wrapperStyle,
        height: '100%',
    };

    const spanStyle = {
        cursor: 'pointer',
    };

    return <div style={wrapperStyle}>
        <span style={spanStyle} role="img" aria-label="excluir"
              onClick={onClick}
        > ❌ </span>
    </div>
}

BotaoIconeExclusao.propTypes = {
    fullHeight: PropTypes.bool
};

BotaoIconeExclusao.defaultProps = {
    fullHeight: true
};

export default BotaoIconeExclusao;