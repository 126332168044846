import React from "react";
import { Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import validaCpf from "./validaCPF";

// https://emailregex.com/
// eslint-disable-next-line
const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const deveSerEmail = (value) => regexEmail.test(value) ? undefined : "Email inválido"

const deveSerCPFWithNotification = (fieldLabel) => (value) => {
  if (validaCpf(value) == true && value != null && value != undefined) {
    return;
  } else {
    return `O campo ${fieldLabel} é obrigatório e precisa ser válido`;
  }
}
const deveSerCPF = (value) => (validaCpf(value) ? undefined : "Campo está inválido");

const required = (value) => (value ? undefined : "Campo obrigatório");

const requiredWithNotification = (fieldLabel) => (value) => {
  if ((value || Number(value) === 0) && value !== null) {
    return;
  } else {
    return `O campo ${fieldLabel} é obrigatório`;
  }
};

const requiredWithNotificationCheckField = (fieldLabel, checkField) => (value, allValues) => {
  if(allValues[checkField])
    return;

  if ((value || Number(value) === 0) && value !== null) {
    return;
  } else {
    return `O campo ${fieldLabel} é obrigatório`;
  }
};

const requiredWithCustomValidationAndNotification = (validate, fieldLabel) => (value) => {
  if (validate(value)) {
    return undefined;
  }
  return `O campo ${fieldLabel} é obrigatório`;
};

const isInteger = (fieldLabel, required = true, min = 0, max = 9999999999) => value => {
  if (!value) {
    return required ? `O campo ${fieldLabel} é obrigatório` : undefined;
  } else if (Number.isNaN(Number(value))) {
    return `O campo ${fieldLabel} precisa ser numérico`;
  }
  value = Number(value)
  if (!Number.isInteger(value)) {
    return `O campo ${fieldLabel} precisa ser um número inteiro`;
  } else if (value < min) {
    return `O campo ${fieldLabel} não pode ser menor que ${min}`;
  } else if (value > max) {
    return `O campo ${fieldLabel} não pode ser maior que ${max}`;
  }
}

const isLong = (fieldLabel, required, min, max = Number.MAX_SAFE_INTEGER) => isInteger(fieldLabel, required, min, max)

const isNumber = (fieldLabel, required = true, min = 0, max = Number.MAX_SAFE_INTEGER) => value => {
  if (!value) {
    return required ? `O campo ${fieldLabel} é obrigatório` : undefined;
  } else if (Number.isNaN(Number(value))) {
    return `O campo ${fieldLabel} precisa ser numérico`;
  }
  value = Number(value)
  if (value < min) {
    return `O campo ${fieldLabel} não pode ser menor que ${min}`;
  } else if (value > max) {
    return `O campo ${fieldLabel} não pode ser maior que ${max}`;
  }
}

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {value => {
              if (value === becomes) {
                onChange(to);
              }
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

export {
  required,
  requiredWithNotification,
  requiredWithNotificationCheckField,
  requiredWithCustomValidationAndNotification,
  isInteger,
  isLong,
  isNumber,
  WhenFieldChanges,
  deveSerEmail,
  deveSerCPF,
  deveSerCPFWithNotification,
};
