import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Field} from "react-final-form";
import CheckSetField from "../../../components/form/field/CheckSetField";
import {tiposServicoExecutadoPCD, yesOrNo} from "../../../model/AvaliacaoQualidade";
import SelectField from "../../../components/form/field/SelectField";
import React, {useCallback} from "react";
import TextAreaField from "../../../components/form/field/TextAreaField";
import TextField from "../../../components/form/field/TextField";
import BotaoIconeExclusao from "../../../components/botao/BotaoIconeExclusao";
import FormSectionWrapper from "components/form/FormSectionWrapper";

const servicoExecutadoLabelResolver = (e) => e.nomeVisualizacao;
const servicoExecutadoValueResolver = (e) => e.propForm;

const selectFieldComTipoServicoExecutadoPCD = {
    componente: SelectField,
    props: {
        elements: tiposServicoExecutadoPCD,
        showEmptyOption: false,
    }
}

const selectFieldComSimNao = {
    componente: SelectField,
    props: {
        elements: yesOrNo,
        showEmptyOption: false,
    }
}

const servicosPossivelmenteExecutados = [
    {nomeVisualizacao: "Antena/cabos GOE", propForm: "antenaSatelite", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Antena/cabos GPRS", propForm: "antenaGPRS", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Bateria", propForm: "bateria", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Barômetro", propForm: "barometro", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Borbulhador", propForm: "borbulhador", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Controlador de carga da bateria", propForm: "controladorCarga", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "DataLogger", propForm: "dataLogger", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "GPS", propForm: "gps", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Limpeza cercado/abrigo", propForm: "limpezaCercado", ...selectFieldComSimNao},
    {nomeVisualizacao: "Modem GPRS", propForm: "modemGPRS", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Modem satélite", propForm: "modemSatelite", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Painel solar", propForm: "painelSolar", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Pintura cercado/torre", propForm: "pinturaCercado", ...selectFieldComSimNao},
    {nomeVisualizacao: "Pluviômetro", propForm: "pluviometro", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Radar", propForm: "radar", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Sensor de temperatura externa (°C)", propForm: "temperaturaExterna", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Sensor de temperatura interna (°C)", propForm: "temperaturaInterna", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Substituição do firmware", propForm: "versaoFirmware", ...selectFieldComSimNao},
    {nomeVisualizacao: "Substituição do programa", propForm: "versaoPrograma", ...selectFieldComSimNao},
    {nomeVisualizacao: "Teclado/display", propForm: "tecladoOuDisplay", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Transdutor pressão", propForm: "transdutorPressao", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Umidade do ar", propForm: "umidadeAr", ...selectFieldComTipoServicoExecutadoPCD},
    {nomeVisualizacao: "Outros serviços", propForm: "outrosServicos", componente: TextAreaField, md: 11,  props: {
        rows: 4, maxLength: 1000
    }},
]

function AbaServicosExecutados({desativarTodosCampos, form: {mutators: {setValue}}}) {
    const aoSelecionarServico = useCallback((val) => {
        const servicoSelecionado = servicosPossivelmenteExecutados.filter(e => e.propForm === val)[0];

        let valor;
        if (SelectField === servicoSelecionado.componente) {
            valor = servicoSelecionado.props.elements[0].serializedName
        } else {
            valor = ' '
        }

        setValue('servicosExecutados.' + val, valor)
        setValue('servicoExecutadoSelecionado', null)
    }, [setValue])
    return <FormSectionWrapper>
        <Row>
            <Col className="mt-3" md={3}>
                <Field
                    component={CheckSetField}
                    name="haItensDefeituosos"
                    label="Itens defeituosos"
                    elements={yesOrNo}
                    defaultValue={"NAO"}
                    disabled={desativarTodosCampos}
                />
            </Col>
            <Col className="mt-3" md={3}>
                <Field
                    component={CheckSetField}
                    name="servicosExecutados.limpezaComponentesPCD"
                    label="Limpeza (componentes da PCD)"
                    elements={yesOrNo}
                    disabled={desativarTodosCampos}
                />
            </Col>
        </Row>
        <Field name="haItensDefeituosos" subscription={{value: true}}>
            {({input: {value}}) => {
                const haItensDefeituosos = "SIM" === value;
                return haItensDefeituosos && <>
                    <Row>
                        <Col className="mt-3" md={6}>
                            <Field
                                component={SelectField}
                                name="servicoExecutadoSelecionado"
                                label="Serviços executados"
                                elements={servicosPossivelmenteExecutados}
                                onChange={aoSelecionarServico}
                                disabled={desativarTodosCampos}
                                valueResolver={servicoExecutadoValueResolver}
                                labelResolver={servicoExecutadoLabelResolver}
                            />
                        </Col>
                    </Row>
                    {servicosPossivelmenteExecutados.map(({nomeVisualizacao, propForm, componente, md=3, props}, idx) => {
                        const nomeCampo = "servicosExecutados." + propForm
                        return <Field key={idx}
                                      name={nomeCampo} subscription={{ value: true }}>
                            {(propsField) => {
                                const value = propsField.input.value;
                                const Componente = componente;

                                if ('' !== value) {
                                    return <Row>
                                        <Col className="mt-3" md={md}>
                                            <Componente label={nomeVisualizacao}
                                                        {...props} {...propsField} />
                                        </Col>

                                        {
                                            value === 'RECOLHIDO' || value === 'SUBSTITUIDO' || value?.serial ?
                                            <Col className="mt-3" md={md}>
                                                <Field
                                                    component={TextField}
                                                    name={nomeCampo + 'Serial'}
                                                    label="Serial"
                                                    maxLength={15}
                                                    disabled={desativarTodosCampos}
                                                />
                                            </Col> : <></>
                                        }

                                        {!desativarTodosCampos && <Col md="auto">
                                            <br /><BotaoIconeExclusao fullHeight onClick={() => setValue(nomeCampo, null)}/>
                                        </Col>}
                                    </Row>
                                }
                                return null;
                            }}
                        </Field>
                    })}
                </>
            }}
        </Field>
    </FormSectionWrapper>
}

export {AbaServicosExecutados}