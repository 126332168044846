import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormTiposEstacao from "./PaginaFormTiposEstacao";
import PaginaListaTiposEstacao from "./PaginaListaTiposEstacao";

class PaginaTiposEstacao extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/tiposEstacao"} component={PaginaListaTiposEstacao} />
                <Route exact path={"/sgih/app/tiposEstacao/criar"} component={PaginaFormTiposEstacao} />
                <Route path={"/sgih/app/tiposEstacao/:id"} component={PaginaFormTiposEstacao} />
            </Switch>
        </>;
    }
}

export default PaginaTiposEstacao;