import React, { useMemo } from "react";

import JqxLoader from "jqwidgets-scripts/jqwidgets-react-tsx/jqxloader";

import "./CustomLoader.css";

export default function CustomLoader({
    loading,
    content,
}) {

    const component = useMemo(() => {
        if (!loading) {
            return <></>;
        }

        return (
            <div className="loading-container">
                <div className="loading-box">
                    <JqxLoader
                        autoOpen
                        width={32}
                        height={32}
                        theme=""
                        text=""
                    />
                    <span className="loading-info">
                        {content}
                    </span>
                </div>
            </div>
        );
    }, [loading, content]);

    return component;
}