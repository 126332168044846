import React from "react";
import JqxDropDownList from "jqwidgets-scripts/jqwidgets-react-tsx/jqxdropdownlist";

import "./JqxDropDownListAdapter.css";
import FieldLabel from "../form/field/FieldLabel";

class JqxDropDownListAdapter extends React.Component {
  constructor(props) {
    super(props);

    this.dropDownRef = this.props.dropDownRef || React.createRef();
    this.sobrescritaJaRodou = Symbol('sobrescritaJaRodou')
  }

  onClose = () => {
    this.commitarMudancasNoEstadoDoForm();
  }

  componentDidMount() {
    if (!this.dropDownRef.current[this.sobrescritaJaRodou]) {
      const funcaoOriginal = this.dropDownRef.current.checkAll.bind(this.dropDownRef.current);
      const commitarMudancasNoEstadoDoForm = this.commitarMudancasNoEstadoDoForm;
      this.dropDownRef.current.checkAll = function() {
        funcaoOriginal();
        commitarMudancasNoEstadoDoForm();
      }
      this.dropDownRef.current[this.sobrescritaJaRodou] = true;
    }
  }

  commitarMudancasNoEstadoDoForm = () => {
    const { input } = this.props;
    const novoValor = this.dropDownRef.current.getCheckedItems().map(it => `${it.value},${it.label};`).join('')
    input.onChange(novoValor);
  }

  render() {
    const {
      input,
      name,
      source,
      valueMember,
      displayMember,
      label,
      width = "100%",
      height = 35,
      ...rest
    } = this.props;

    let sourcePreparedWithInitialData = JSON.parse(JSON.stringify(source));

    let currentValue = input.value;
    let currentValues = currentValue.split(";").filter((i) => i);
    for (let i = 0; i < currentValues.length; i++) {
      let parts = currentValues[i].split(",");
      let value = parts[0];
      for (let j = 0; j < sourcePreparedWithInitialData.length; j++) {
        // console.log("vou comparar ", parseInt(value), " com ", parseInt(sourcePreparedWithInitialData[j][valueMember]));
        if (
          parseInt(value) ===
          parseInt(sourcePreparedWithInitialData[j][valueMember])
        ) {
          sourcePreparedWithInitialData[j]["checked"] = true;
          break;
        }
      }
    }

    // console.log(input.name, " ", input.value);

    return (
      <>
        <input type="hidden" name={input.name} value={input.value} />
        {label && <FieldLabel label={label} required={this.props.required} />}
        <JqxDropDownList
          ref={this.dropDownRef}
          theme="sgih"
          onClose={this.onClose}
          source={sourcePreparedWithInitialData}
          valueMember={valueMember}
          displayMember={displayMember}
          width={width}
          height={height}
          {...rest}
        />
      </>
    );
  }
}

export { JqxDropDownListAdapter };
