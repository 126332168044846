import React, { Component } from 'react';
import {
  Route,
  Switch
} from 'react-router-dom';
import OAuth2RedirectHandler from '../oauth2/OAuth2RedirectHandler';
import PrivateRoute from './PrivateRoute';
import Login from '../login/Login';
import './App.css';
import { mainRoutes } from "../../routes.js";
import {connect} from "react-redux"
import SobreVersao from 'components/sobre/SobreVersao';

class App extends Component {
  getRoutes(routes){
    return routes.map((prop, key) => {
      let exact = true;
      if (typeof prop.exact !== "undefined") {
        exact = prop.exact;
      }
        return (
            <PrivateRoute path={prop.path} exact={exact} component={prop.component}
                          key={key} authenticated={this.props.autenticado} />
        );
    });
  }

  render() {
    return <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/sgih/app/login" component={Login} />
      <Route exact path="/sobre-versao" component={SobreVersao} />
      {this.getRoutes(mainRoutes)}
      <Route exact path="/sgih/app/oauth2/redirect" component={OAuth2RedirectHandler} />
    </Switch>
  }
}

export default connect(
  (state) => ({
    autenticado: state.auth.autenticado,
  })
)(App);
