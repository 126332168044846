import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormRegioesHidrograficas from "./PaginaFormRegioesHidrograficas";
import PaginaListaRegioesHidrograficas from "./PaginaListaRegioesHidrograficas";

class PaginaRegioesHidrograficas extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/regioesHidrograficas"}
            component={PaginaListaRegioesHidrograficas}
          />
          <Route
            exact
            path={"/sgih/app/regioesHidrograficas/criar"}
            component={PaginaFormRegioesHidrograficas}
          />
          <Route path={"/sgih/app/regioesHidrograficas/:id"} component={PaginaFormRegioesHidrograficas} />
        </Switch>
      </>
    );
  }
}

export default PaginaRegioesHidrograficas;
