import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormVeiculos from "./PaginaFormVeiculos";
import PaginaListaVeiculos from "./PaginaListaVeiculos";

class PaginaVeiculos extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/veiculos"}
            component={PaginaListaVeiculos}
          />
          <Route
            exact
            path={"/sgih/app/veiculos/criar"}
            component={PaginaFormVeiculos}
          />
          <Route path={"/sgih/app/veiculos/:id"} component={PaginaFormVeiculos} />
        </Switch>
      </>
    );
  }
}

export default PaginaVeiculos;
