export const REALIZADO = {
    viewName: "Realizado", serializedName: "S",
};

export const NAOREALIZADO = {
    viewName: "Não realizado", serializedName: "N",
};


export default [
    REALIZADO, NAOREALIZADO
];
