import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormObservadores from "./PaginaFormObservadores";
import PaginaListaObservadores from "./PaginaListaObservadores";

class PaginaObservadores extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/observadores"} component={PaginaListaObservadores} />
                <Route exact path={"/sgih/app/observadores/criar"} component={PaginaFormObservadores} />
                <Route path={"/sgih/app/observadores/:id"} component={PaginaFormObservadores} />
            </Switch>
        </>;
    }
}

export default PaginaObservadores;