import React from "react";
import PropTypes from "prop-types";
import TabContainer from "./TabContainer";

import "./Tab.css";
import {TabContext} from "./context";

class Tab extends React.Component {
    render() {
        return <TabContext.Consumer >
            {value => {
                return <div className={this.props.className} >
                    {this.props.children}
                    {value.afterContent && value.afterContent(
                        value.tabRef,
                        value.isFirst,
                        value.isLast,
                    )}
                </div>
            }}
        </TabContext.Consumer>
    }
}

Tab.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    visible: PropTypes.bool,
};

Tab.Container = TabContainer;

export default Tab;
