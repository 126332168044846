import React, { Component } from 'react'
import "components/footer/footer.css"

export default class Footer extends Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        var packageJsonInfo = require('./../../../package.json');
        const estilo = {fontSize: '14px', alignSelf:'end', paddingRight: '20px'}
        return (
            <>
                <div className="footer ">
                    <img className="pb-2" src={require('../../assets/img/FOOTER_SGIH_COMPLETA.png')} alt=""/>    
                </div>
                <div style={estilo}>v{packageJsonInfo.version}</div>  
            </>  
        )
                // <footer className="footer-logo-container pt-2" >
                //      <div className="footer-container " >
                //         <img  className="logo-footer-img pb-2"  src={require('../../assets/img/logo_RHN.png')} alt=""/> 
                //     </div>
                //     <div className="footer-container ">
                //         <img className="logo-footer-img pt-4" src={require('../../assets/img/logo_CPRM.png')} alt=""/> 
                //     </div>
                //     <div className="footer-container ">
                //         <img  className="logo-footer-img pt-3 " src={require('../../assets/img/logo_ANA.png')} alt=""/> 
                //     </div>
                //     <img className="logo-footer-img pb-2" src={require('../../assets/img/footer_CPRM_novo.png')} alt=""/> 
                // </footer>
                    
    }
}
