export const REGULAR = {
  viewName: "Regular", serializedName: "REGULAR",
};

export const IRREGULAR = {
  viewName: "Irregular", serializedName: "IRREGULAR",
};

export const ENCACHOEIRADO = {
  viewName: "Encachoeirado", serializedName: "ENCACHOEIRADO",
};

export default [
  ENCACHOEIRADO, IRREGULAR, REGULAR
];