import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormRelatorioQualidadeAgua from "./PaginaFormRelatorioQualidadeAgua";
import PaginaListaRelatoriosQualidadeAgua from "./PaginaListaRelatoriosQualidadeAgua";

class PaginaRelatoriosQualidadeAgua extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosQualidadeAgua"} component={PaginaListaRelatoriosQualidadeAgua} />
                <Route exact path={"/sgih/app/relatoriosQualidadeAgua/criar"} component={PaginaFormRelatorioQualidadeAgua} />
                <Route path={"/sgih/app/relatoriosQualidadeAgua/:id"} component={PaginaFormRelatorioQualidadeAgua} />
            </Switch>
        </>;
    }
}

export default PaginaRelatoriosQualidadeAgua;