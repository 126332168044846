import React from "react";
import {connect} from "react-redux";
import {esconderAlertaGlobal} from "../../../reducers/globalReducers";
import {Modal} from "../Modal";

function ModalAlerta({ visivel, mensagem, esconder }) {
    return <Modal aoFechar={esconder}
                  aberto={visivel}
                  titulo="Alerta">
        <p>{mensagem}</p>
    </Modal>
}

const ModalAlertaConectado = connect(
    (state) => ({
        visivel: !!state.global.alertaVisivel,
        mensagem: state.global.alertaVisivel,
    }),
    (dispatch) => ({
        esconder: () => dispatch(esconderAlertaGlobal()),
    })
)(ModalAlerta);

export {ModalAlertaConectado as ModalAlerta}