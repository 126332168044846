import UNIDADEREGIONAL from 'model/UnidadeRegional';
import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaEstacoes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                }, ()=>{
                    const source = {
                        datafields: [
                            { name: 'id', type: 'number', map: 'id' },
                            { name: 'codigo', type: 'string', map: 'codigo' },
                            { name: 'nome', type: 'string', map: 'nome' },
                            { name: 'roteiro', type: 'number', map: 'roteiro' },
                            { name: 'tipo', type: 'string', map: 'tipo' },
                            { name: 'status', type: 'string', map: 'status' },
                            { name: 'municipio', type: 'string', map: 'municipio' },
                            { name: 'estado', type: 'string', map: 'estado' },
                            { name: 'subBacia', type: 'number', map: 'subBacia' },
                            { name: 'baciaHidrografica', type: 'string', map: 'baciaHidrografica' },
                            { name: 'rio', type: 'string', map: 'rio' },
                            { name: 'coordenadasLatitude', type: 'number', map: 'coordenadasLatitude' },
                            { name: 'coordenadasLongitude', type: 'number', map: 'coordenadasLongitude' },
                            { name: 'altitude', type: 'number', map: 'altitude' },
                            { name: 'drenagem', type: 'number', map: 'drenagem' },
                            { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
                        ],
                        id: 'id',
                    };

                    const columns = [
                        { text: "Código", dataField: "codigo", width: 80 },
                        { text: "Nome", dataField: "nome", width: 180 },
                        { text: "Roteiro", dataField: "roteiro", width: 80 },
                        { text: 'Regional', dataField: 'unidadeRegional', width: 80, filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                        { text: "Tipo", dataField: "tipo", width: 100 },
                        { text: 'Status', dataField: 'status', width: "5%", filtertype: 'checkedlist', filteritems: ["Ativo", "Inativo", "Suspenso"] },
                        { text: "Município", dataField: "municipio", width: 150 },
                        { text: "Estado", dataField: "estado", width: 150 },
                        { text: "Sub-bacia", dataField: "subBacia", width: 80 },
                        { text: "Bacia hidrográfica", dataField: "baciaHidrografica", width: 130 },
                        { text: "Rio", dataField: "rio", width: 100 },
                        { text: "Latitude", dataField: "coordenadasLatitude", width: 80, cellsalign: 'right' },
                        { text: "Longitude", dataField: "coordenadasLongitude", width: 80, cellsalign: 'right' },
                        { text: "Altitude", filtertype: "number", dataField: "altitude", width: 70, cellsalign: 'right' },
                        { text: "Área de drenagem", filtertype: "number", dataField: "drenagem", width: 130, cellsalign: 'right' }
                    ];

                    this.tableConfig = { source, columns }
                    this.setState({
                        exibir:true
                    })
                })

            }
        )


    }

    render() {

        return this.state ? this.state.exibir ?
            <CrudListPageScaffold
                title="Estações"
                tableConfig={this.tableConfig}
                baseUrl="/estacoes"
                history={this.props.history}
                formLinkTitle="Novo cadastro"
                tableName="estacaoTabela"
            />
            : <></>
            : <></>
    }
}

export default PaginaListaEstacoes;
