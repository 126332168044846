import createServiceFor from "../service/Service";

// export const UNIDADEREGIONAL = async (regionais = []) =>{
//     await Promise.resolve(createServiceFor("/unidadesRegionais/simples").query())
//                 .then(
//                      (unidadesRegional) => {
//                         regionais = unidadesRegional.data.map(regional => regional.siglaLocalUasg.toString().toUpperCase())
//                         console.log("regionais", regionais)

//                        return regionais
//                     }          
//                 )
//                 .catch(e => {
//                     console.error(e)
//                 })
//    return regionais 
// } 

export const UNIDADEREGIONAL =  (callBack, regionais = []) =>{
    Promise.resolve(createServiceFor("/unidadesRegionais/simples").query())
                .then(
                     (unidadesRegional) => {
                        regionais = unidadesRegional.data.map(regional => regional.siglaLocalUasg.toString().toUpperCase())
                       if (callBack) {
                            callBack(regionais)
                       }
                    }          
                )
                .catch(e => {
                    console.error(e)
                })
 
} 



// export const UNIDADEREGIONAL = ["SEDE" ,"ERJ","LIR-CA","SUREG-MA","REPO","SUREG-BE",
//                                 "ESC.ITA","SUREG-RE","REFO","NAMO","SUREG-SA",
//                                 "DEPOSITO","SUREG-SP","P.CALDAS","SUREG-PA","DECA",
//                                 "SUREG-BH","SUREG-GO","NABA","RETE","NARO","LIR-AR"];

export default UNIDADEREGIONAL;
