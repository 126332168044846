import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormRelatoriosFluviometricos from "./PaginaFormRelatoriosFluviometricos";
import PaginaListaRelatoriosFluviometricos from "./PaginaListaRelatoriosFluviometricos";

class PaginaRelatoriosFluviometricos extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosFluviometricos"} component={PaginaListaRelatoriosFluviometricos} />
                <Route exact path={"/sgih/app/relatoriosFluviometricos/criar"} component={PaginaFormRelatoriosFluviometricos} />
                <Route path={"/sgih/app/relatoriosFluviometricos/:id"} component={PaginaFormRelatoriosFluviometricos} />
            </Switch>
        </>;
    }
}

export default PaginaRelatoriosFluviometricos;