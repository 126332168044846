import React, { Component } from 'react';
import CrudListPageScaffold from "../../../components/scaffold/CrudListPageScaffold";
import { Col, Row } from "react-bootstrap";
import NumberField from "../../../components/form/field/NumberField";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import { Modal } from "../../../components/modal/Modal";
import { connect } from "react-redux";
import { startGlobalLoading, stopGlobalLoading } from "../../../reducers";
import http from "../../../service/http-commons";
import Card from "react-bootstrap/Card";
import TextField from "../../../components/form/field/TextField";
import { Field, Form } from "react-final-form";
import { requiredWithNotification } from "../../../util/FormUtils";
import { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import store from "../../../store";
import {
  showGlobalNotification,
} from "../../../reducers";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
import createServiceFor from "../../../service/Service";
import PromiseLoader from 'components/PromiseLoader/PromiseLoader';
import SelectField from 'components/form/field/SelectField/SelectField';


const descriptionLabelResolver = (e) => `${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
const valueResolver = (e) => `${e.id} - ${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
class PaginaPrevisaoPagamentoRecibo extends Component {
  constructor(props) {
    super(props);

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                }, ()=>{
                  this.columns = [
                    { text: 'ID', dataField: 'id', width: 80 },
                    { text: 'Roteiro', dataField: 'roteiro', width: 70 },
                    { text: 'Regional', dataField: 'unidadeRegional', width: 80, filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                    { text: 'Nome', dataField: 'nome' },
                    { text: 'Tipo', dataField: 'tipo', width: 60 },
                    { text: 'Nome da estação', dataField: 'nomeEstacao', width: 200 },
                    { text: 'Status', dataField: 'status', width: 60, filtertype: 'checkedlist', filteritems: ["Ativo", "Inativo", "Suspenso"]},
                    { text: 'Valor (R$)', dataField: 'total', width: 80, cellsalign: 'right', cellsformat: "d2" },
                    { text: 'Previsão (R$)', dataField: 'previsao', width: 100, cellsalign: 'right', cellsformat: "d2", sortable: false, filterable: false },
                    { text: 'ISS (%)', dataField: 'iss', width: 80, cellsalign: 'right', cellsformat: "d2" },
                    { text: 'ISS (R$)', dataField: 'issEmReais', width: 80, cellsalign: 'right', cellsformat: "d2", sortable: false, filterable: false },
                    { text: 'Opção de pagamento', dataField: 'tipoPagamento', width: 150 },
                  ];
              
                  this.setState({
                    modalCalculoAberto: false,
                    filtro: {
                      inssCprm: 20,
                      inssObservador: 11,
                      qtdMeses: 1,
                      roteiro: null,
                    },
                    resultado: {
                      qtdObservadores: 'R$ 0,00',
                      issDeTodosOsObservadores: 'R$ 0,00',
                      inssCprmPorcentagem: 'R$ 0,00',
                      inssObservadorPorcentagem: 'R$ 0,00',
                      valorOpcaoBanco: 'R$ 0,00',
                      valorOpcaoVisita: 'R$ 0,00',
                      valorOpcaoCorreios: 'R$ 0,00',
                      valorBruto: 'R$ 0,00',
                      inssCPRM: 'R$ 0,00',
                      inssObservador: 'R$ 0,00',
                      custoTotal: 'R$ 0,00',
                      valorLiquido: 'R$ 0,00',
                      issTotal: 'R$ 0,00',
                      issBancoTotal: 'R$ 0,00',
                      inssBancoTotal: 'R$ 0,00',
                      qtdMeses: 1,
                    },
                    source: this.getSource(),
                    forcarMudancaGrid: true,
                  })
              
                  this.tableRef = React.createRef();
                  this.valoresPadraoForm = {
                    inssCprm: 20,
                    inssObservador: 11,
                    qtdMeses: 1,
                    roteiro: null,
                  }
                    // this.setState({
                    //     exibir:true
                    // })
                })
                
            }
        )

    


    this.efetuarCalculo = this.efetuarCalculo.bind(this);
    this.fecharModal = this.fecharModal.bind(this);

    this.preActions = [
      {
        render: (key) => {
          return (
            <JqxButton key={key} theme="material" width={120} onClick={() => this.props.history.push('/sgih/app/recibos/gerenciamento')} className="py-2 float-right mt-3 mb-3 ml-3">
              Voltar
            </JqxButton>
          )
        }
      }
    ]
  }



  componentDidMount() {
    const promises = [
      createServiceFor("/roteirosServico/paraRecibos").query()
    ];
    const promise = Promise.all(promises).then(([
        roteirosServico,
      ]) => ({
        roteirosServico: roteirosServico.data,
      })
    )
    .catch((e) => {
      console.log(e);
      store.dispatch(showGlobalNotification({
        message: e.message
      }))
    });

    this.setState({
      promiseDados: promise
    });
  }

  getSource = (qtdMeses = 1) => {
    return {
      date: 'dd/MM/yyyy',
      url: process.env.REACT_APP_API_URL + '/recibos/previsao/listaObservadores',
      data: {
        qtdMeses,
      },
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'roteiro', type: 'number', map: 'roteiro' },
        { name: 'status', type: 'number', map: 'status' },
        { name: 'total', type: 'number', map: 'total' },
        { name: 'previsao', type: 'number', map: 'previsao' },
        { name: 'iss', type: 'number', map: 'iss' },
        { name: 'issEmReais', type: 'number', map: 'issEmReais' },

        { name: 'nome', type: 'string', map: 'nome' },
        { name: 'nomeEstacao', type: 'string', map: 'nomeEstacao' },
        { name: 'tipo', type: 'string', map: 'tipo' },
        { name: 'tipoPagamento', type: 'string', map: 'tipoPagamento' },
        { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
      ],
      id: 'id',
    };
  }

  fecharModal() {
    this.setState({
      modalCalculoAberto: false,
    });
  }

  abrirModal() {
    this.setState({
      modalCalculoAberto: true,
    });
  }

  efetuarCalculo(values) {
    console.log("VALORES", values)
    const roteiroSplit = values.roteiro.split(" - ")
    values ={
      ...values,
      roteiro: roteiroSplit[2],
      unidadeRegional: roteiroSplit[1],
      idRoteiro: roteiroSplit[0],
    }
    console.log("VALORES", values)
    this.setState({
      modalCalculoAberto: false,
      resultado: {
        qtdMeses: values.qtdMeses
      },
      filtro: {
        qtdMeses: values.qtdMeses,
        unidadeRegional: values.unidadeRegional
      },
      source: this.getSource(values.qtdMeses, values.unidadeRegional),
      forcarMudancaGrid: false,
    }, () => {
      this.setState({
        forcarMudancaGrid: true,
      }, () => {
        this.props.iniciarCarregamento();
        console.log("VALORES DA REQUISIÇÃO", values)
        http.get('/recibos/previsao/efetuar', {
          params: values,
        })
          .then((res) => {
            if (this.tableRef.current) {
              const grupo = new jqx.filter()
              const filtro = grupo.createfilter(
                'numericfilter',
                values.roteiro,
                'equal'
              );
              grupo.addfilter('and', filtro)

              const grupoRegional = new jqx.filter()
              const filtroRegional = grupo.createfilter(
                'stringfilter',
                values.unidadeRegional,
                'equal'
              );
              grupoRegional.addfilter('and', filtroRegional)
              
              this.esperarAteTabelaDisponivel(() => {
                this.tableRef.current.clearfilters()
                this.esperarAteTabelaDisponivel(() => {
                  this.tableRef.current.addfilter('roteiro', grupo)
                  this.tableRef.current.addfilter('unidadeRegional', grupoRegional)
                  this.tableRef.current.applyfilters()
                })
              })
            }
            console.log("res.data ",res.data)
            if(res.data.observadoresSemOpcaoPagamento !== 0){
              store.dispatch(showGlobalNotification({
                message: `Existem ${res.data.observadoresSemOpcaoPagamento} observadores sem opção de pagamento definida nesse roteiro, eles serão excluídos do cálculo de previsão de pagamento`,
                duration : 6000,
              }))
                         }
            this.setState({
              resultado: {
                ...res.data,
                qtdMeses: values.qtdMeses
              },
              filtro: {
                qtdMeses: values.qtdMeses
              }
            })
            this.props.pararCarregamento();

          })
          .catch((err) => {
            console.error(err)
            this.props.pararCarregamento();
          })
      });
    });
  }

  esperarAteTabelaDisponivel(fn) {
    const id = setInterval(() => {
      if (this.tableRef.current && this.tableRef.current.isBindingCompleted()) {
        fn()
        clearInterval(id);
      }
    }, 100);
  }

  render() {


    return this.state ? this.state.forcarMudancaGrid ? 
      <CrudListPageScaffold 
          title="Previsão de pagamento"
          tableConfig={{ source: this.state.source, columns: this.columns }}
          baseUrl="/recibos/previsao" history={this.props.history}
          formLinkTitle="Novo" tableName="previsaoPagementoRecibo"
          preActions={this.preActions}
          showFormLink={false}
          shouldAddReportFlowButton={false}
          shouldAddPrintButton={false}
          shouldAddZipButton={false}
          shouldAddViewButton={false}
          shouldAddEditButton={false}
          shouldAddDeleteButton={false}
          shouldAddRefreshButton={false}
          tableRef={this.tableRef}
          ehPagamento={true}
      > 
      <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            console.log("data", data)
            return (
                  <Modal
                    tamanho="lg"
                    aberto={this.state.modalCalculoAberto}
                    aoFechar={this.fecharModal}
                    titulo="Cálculo de previsão"
                  >
                      <Form
                        onSubmit={this.efetuarCalculo}
                        initialValues={this.valoresPadraoForm}
                        render={({ handleSubmit, form }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <Row style={{ fontSize: "13px" }}>
                                <Col md={6} className="mt-3">
                                  <Field
                                    component={NumberField}
                                    label='INSS CPRM'
                                    min={0}
                                    max={100}
                                    required
                                    step={0.01}
                                    name='inssCprm'
                                    validate={requiredWithNotification("INSS CPRM")}
                                  />
                                </Col>
                                <Col md={6} className="mt-3">
                                  <Field
                                    component={NumberField}
                                    label='INSS observador'
                                    min={0}
                                    max={100}
                                    required
                                    step={0.01}
                                    name='inssObservador'
                                    validate={requiredWithNotification("INSS observador")}
                                  />
                                </Col>
                                <Col md={6} className="mt-3">
                                  <Field
                                    component={NumberField}
                                    label='Qtd. meses'
                                    min={0}
                                    max={100}
                                    required
                                    step={1}
                                    name='qtdMeses'
                                    validate={requiredWithNotification("Qtd. meses")}
                                  />
                                </Col>
                                <Col md={6} className="mt-3">
                                  <Field
                                    component={SelectField}
                                    valueResolver={valueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    elements={data.roteirosServico}
                                    label='Roteiro'
                                    min={0}
                                    step={1}
                                    required
                                    name='roteiro'
                                    validate={requiredWithNotification("Roteiro")}
                                  />
                                </Col>
                                <Col className="mt-3">
                                  <JqxButton
                                    theme="material"
                                    width={100}
                                    className="py-2 float-right"
                                    onClick={() => form.submit()}
                                  >
                                    Calcular
                                  </JqxButton>
                                </Col>
                              </Row>
                            </form>
                          );
                        }}
                      />
                    </Modal>
                  );
          }}
      />
          <Row>
            <Col>
              <JqxButton theme="material" width={120} onClick={() => this.abrirModal()} className="mt-3 mb-3">
                Calcular
              </JqxButton>
            </Col>
          </Row>
          <Row style={{ fontSize: "13px" }}>
            <Col md={3} className="mt-3">
              <NumberField value={this.state.resultado.qtdObservadores} label="Quantidade de observadores" readOnly forcarValorZerado />
            </Col>
            <Col md={3} className="mt-3">
              <NumberField value={this.state.filtro.inssCprm} label="INSS CPRM (%)" readOnly forcarValorZerado />
            </Col>
            <Col md={3} className="mt-3">
              <NumberField value={this.state.resultado.qtdMeses} label="Quantidade de meses" readOnly forcarValorZerado />
            </Col>
          </Row>
          <Row style={{ fontSize: "13px" }}>
            <Col md={3} className="mt-3 mb-5">
              <Card>
                <Card.Body>
                  <Card.Title style={{ fontSize: "18px" }}>Opção de pagamento</Card.Title>
                  <Row>
                    <Col md={12}>
                      <NumberField value={this.state.resultado.qtdMeses} label="Quantidade de meses" readOnly forcarValorZerado />
                    </Col>
                    <Col md={12} className="mt-2">
                      <TextField value={this.state.resultado.valorOpcaoBanco} label="Banco" readOnly />
                    </Col>
                    <Col md={12} className="mt-2">
                      <TextField value={this.state.resultado.valorOpcaoVisita} label="Visita" readOnly />
                    </Col>
                    <Col md={12} className="mt-2">
                      <TextField value={this.state.resultado.valorOpcaoCorreios} label="Correios" readOnly />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={9} className="mt-3 mb-5">
              <Card>
                <Card.Body>
                  <Card.Title style={{ fontSize: "18px" }}>Resumo financeiro</Card.Title>
                  <Row>
                    <Col md={4}>
                      <TextField value={this.state.resultado.valorBruto} label="Valor bruto" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.issTotal} label="ISS" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.issBancoTotal} label="ISS (banco)" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.inssCPRM} label="INSS CPRM" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.inssObservador} label="INSS observador" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.inssBancoTotal} label="INSS banco" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.custoTotal} label="Custo total" readOnly />
                    </Col>
                    <Col md={4} className="mt-2">
                      <TextField value={this.state.resultado.valorLiquido} label="Valor líquido" readOnly />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
      </CrudListPageScaffold>
      : <></> 
      : <></>     
  }
}

export default connect(
  (_) => ({}),
  dispatch => ({
    iniciarCarregamento: () => dispatch(startGlobalLoading()),
    pararCarregamento: () => dispatch(stopGlobalLoading()),
  })
)(PaginaPrevisaoPagamentoRecibo);
