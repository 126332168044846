import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import swal from "sweetalert";
import { connect } from "react-redux";
import { extrairPermissoes } from "reducers/authReducers";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";

import { AbaDadosBasicos } from "./AbaDadosBasicos";

import { construirBotoesForm } from "components/scaffold/FormPageScaffoldCommon";
import voltarParaGrid from "util/VoltarParaGrid";

class PaginaFormFluxoRelatorios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jaSubmeteuUmaVez: false,
      status:[],
    };
  }

  componentDidMount() {
    const mapaTiposRelatorio = {
        // pluviometrico: 'PLUVIOMETRICO',
        // fluviometrico: 'FLUVIOMETRICO',
        producao: 'PRODUCAO_CAMPO',
        qualidadeAgua: 'QUALIDADE_AGUA',
        // veicular: 'VEICULAR',
        // pcd: 'PCD',
    }

    const id = this.props.match.params.id;
    const tipo = this.props.match.params.tipo;
    const tipoMapeado = mapaTiposRelatorio[tipo] || tipo.toUpperCase();

    const promiseFluxo = createServiceFor('/fluxoRelatorios')
        .customURL('GET', 'paraRelatorio', {
            tipoRelatorio: tipoMapeado,
            id,
        });

    const promises = [promiseFluxo];

    const promise = Promise.all(promises)
      .then(([detalhesFluxo]) => ({
        perfisPossiveis: this.props.permissoes.permitirEnviarRelatoriosEmBloco && tipoMapeado === 'PRODUCAO_CAMPO' && this.props.permissoes.idPerfilFluxoPadrao && this.props.permissoes.permitirVisualizarAprovacaoRapida ?
         detalhesFluxo.data.perfisPossiveis.filter((p) => p.id !== this.props.permissoes.idPerfilFluxoPadrao) : detalhesFluxo.data.perfisPossiveis,
        alteracoesRelatorio: detalhesFluxo.data.alteracoesRelatorio,
        initialValues: {
          tipoRelatorio: tipoMapeado,
          idRelatorio: id,
          codigoEstacaoFluxo: detalhesFluxo.data.codigoEstacaoFluxo,
          nomeEstacaoFluxo: detalhesFluxo.data.nomeEstacaoFluxo,
        },
      }))
      .catch((e) => {
        console.log("Erro com promise em fluxo de relatórios: ", e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(
      startGlobalLoading("Salvando mudança de status de relatório...")
    );
    createServiceFor("/fluxoRelatorios")
      .customURL('POST', '', values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Fluxo de relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  meuFormWrapper = (form) => ({
    submit: async () => {
      // Isso foi necessário para exibir corretamente a mensagem de erro do combo de status
      // de permissões de relatórios.
      // Elas não estavam sendo exibidas pelo meio padrão (requiredWithNotification e required).
      // Portanto, elas foram adicionadas ao método submitForm e validate (do formulário).
      // Porém, o validate do formulário exibia a mensagem de erro logo ao alterar o campo (mesmo
      // antes de tentar submeter os dados), diferentemente do comportamento padrão.
      // Para evitar isso, foi criado um estado jaSubmeteuUmaVez que é modificado para true quando
      // o usuário clicar em submit. A partir deste momento, o validate do form passará a operar.
      this.setState({
        jaSubmeteuUmaVez: true,
      });



      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("Não validando ", field);
        }
      });

      // regra de confirmação de mudança de status
      const novoStatus = parseInt(form.getState().values.idPerfil)
      var mensagemStatus = false

      if(novoStatus){
        const status = form.getFieldState('status').data.map((x)=>{return x.perfilId});
        const statusAtual = status[status.length-1]

        if(status.indexOf(novoStatus) === -1) { // verifica se nao contem o novo status
          mensagemStatus = true

        }else {
        	var statusSemAtual = status.slice(0,status.length-1);
        	const ultimoIgualAtual = statusSemAtual.lastIndexOf(statusAtual);

        	if(ultimoIgualAtual === -1) { // verifica se existe outro valor igual ao atual
            mensagemStatus = true

        	} else {
            var statusConsiderar = statusSemAtual.slice(0,ultimoIgualAtual + 1);
        		if(statusConsiderar.indexOf(novoStatus) !== -1) {
              mensagemStatus = true
        		}
        	}
        }
      } else{
        form.submit()
      }

      if(mensagemStatus){
        await swal({
          title: "Confirma a mudança no status do relatório?",
          icon: "warning",
          buttons: {
            cancelar: {
              text: "NÃO",
              className: "btn-danger",
            },
            confirmar: {
              text: "OK",
              className: "btn-success",
            },
          },
          dangerMode: true,
        })
        .then((value) => {
          if (value === "confirmar") {
            form.submit()
          }
        })
      }else{
        form.submit()
      }

    },
  });

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    let desabilitarTodosCampos =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    // const listaPerfilsBloqueados = [6,14,1002,1003];

    //if(listaPerfilsBloqueados.find((p) => p === this.props.permissoes.idPerfil))
    //  desabilitarTodosCampos = true;

    return (
      <CrudFormPageScaffold
        baseTitle="Fluxo de relatórios"
        baseUrl="/sgih/app/fluxoRelatorios"
        history={this.props.history}
        match={this.props.match}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form,
                }) => {
                  return <>
                    <form onSubmit={handleSubmit}>
                      <Field
                        component="input"
                        type="hidden"
                        name="tipoRelatorio"
                        value={data.tipoRelatorio}
                      />
                        <Field
                        component="input"
                        type="hidden"
                        name="status"
                        data={data.alteracoesRelatorio}
                      />
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.meuFormWrapper(form),
                          estaEditando,
                          desabilitarTodosCampos,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados básicos" name="dadosBasicos">
                          <AbaDadosBasicos
                            desabilitarTodosCampos={desabilitarTodosCampos}
                            perfisPossiveis={data.perfisPossiveis}
                            alteracoesRelatorio={data.alteracoesRelatorio}
                          />
                        </Tab>
                      </Tab.Container>
                    </form>
                  </>;
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default connect((state) => ({
  permissoes: extrairPermissoes(state),
}), (dispatch) => ({})) ( PaginaFormFluxoRelatorios );
