import {Field} from "react-final-form";
import React from "react";
import PropTypes from "prop-types";

// from https://codesandbox.io/s/lm4p3m92q
function ValorCampoEh({nome, valor, children, inverter}) {
    return <Field name={nome} subscription={{value: true}}>
        {({input: {value}}) =>
            children(inverter ? valor !== value : valor === value)
        }
    </Field>;
}

ValorCampoEh.propTypes = {
    nome: PropTypes.string.isRequired,
    valor: PropTypes.any.isRequired,
    children: PropTypes.func.isRequired,

    inverter: PropTypes.bool,
};

ValorCampoEh.defaultProps = {
    inverter: false,
};

export default ValorCampoEh;