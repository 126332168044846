import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormInspecaoProducaoDeCampo from "./PaginaFormInspecaoProducaoDeCampo";
import PaginaListaRelatoriosProducaoCampo from "./PaginaListaRelatoriosProducaoCampo";

class PaginaInspecoesProducaoDeCampo extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosProducaoCampo"} component={PaginaListaRelatoriosProducaoCampo} />
                <Route exact path={"/sgih/app/relatoriosProducaoCampo/criar"} component={PaginaFormInspecaoProducaoDeCampo} />
                <Route path={"/sgih/app/relatoriosProducaoCampo/:id"} component={PaginaFormInspecaoProducaoDeCampo} />
            </Switch>
        </>;
    }
}

export default PaginaInspecoesProducaoDeCampo;