import React, { Component } from 'react';
import CrudListPageScaffold from "../../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
class PaginaListaParametrosRecibo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
          (regionais)=>{
              this.setState({
                  regionais
              },

              ()=>{
        const source = {
            date : 'dd/MM/yyyy',
            datafields: [
                { name: 'id', type: 'number', map: 'id' },
                { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
                { name: 'centroCusto', type: 'string', map: 'centroCusto' },
                { name: 'fonte', type: 'string', map: 'fonte' },
                { name: 'projeto', type: 'number', map: 'projeto' },
                { name: 'programaTrabalho', type: 'string', map: 'programaTrabalho' },
                { name: 'vinculacao', type: 'string', map: 'vinculacao' },
                { name: 'cargoAutoriza', type: 'string', map: 'cargoAutoriza' },
                { name: 'cargoConfere', type: 'string', map: 'cargoConfere' },
                { name: 'valorDeduzir', type: 'number', map: 'valorDeduzir' },
                { name: 'valorIRPFAliquota', type: 'number', map: 'valorIRPFAliquota' },
                { name: 'valorIRPFMinimo', type: 'number', map: 'valorIRPFMinimo' },
                { name: 'valorIRPFMaximo', type: 'number', map: 'valorIRPFMaximo' },
                { name: 'valorINSSCPRM', type: 'number', map: 'valorINSSCPRM' },
                { name: 'valorINSSObservador', type: 'number', map: 'valorINSSObservador' },
                { name: 'valorINSSMaximo', type: 'number', map: 'valorINSSMaximo' },

            ],
            id: 'id',
        };

        const columns = [
            { text: 'ID', dataField: 'id', width: '4%' },
            { text: 'Regional', dataField: 'unidadeRegional', width: '9%',  filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
            { text: 'Centro de custo', dataField: 'centroCusto', width: '8%' },
            { text: 'Fonte', dataField: 'fonte', width: '5%' },
            { text: 'Projeto', dataField: 'projeto', width: '5%' },
            { text: 'Programa de trabalho', dataField: 'programaTrabalho' , width: '10%'},
            { text: 'Vinculação', dataField: 'vinculacao', width: '5%' },
            { text: 'Cargo quem autoriza', dataField: 'cargoAutoriza',  width: '10%', },
            { text: 'Cargo quem confere', dataField: 'cargoConfere', width: '10%' },
            { text: 'Valor a deduzir', dataField: 'valorDeduzir', width: '7%', cellsformat:"d2", cellsalign: 'right' },
            { text: 'Aliquota IRRF', dataField: 'valorIRPFAliquota', width: '7%', cellsformat:"d2", cellsalign: 'right' },
            { text: 'IRRF (mínimo)', dataField: 'valorIRPFMinimo', width: '7%', cellsformat:"d2" , cellsalign: 'right' },
            { text: 'IRRF (máximo)', dataField: 'valorIRPFMaximo', width: '7%', cellsformat:"d2", cellsalign: 'right' },
            { text: 'INSS CPRM', dataField: 'valorINSSCPRM', width: '7%', cellsformat:"d2", cellsalign: 'right' },
            { text: 'INSS observador', dataField: 'valorINSSObservador', width: '7%' , cellsformat:"d2", cellsalign: 'right' },
            { text: 'INSS (máximo)', dataField: 'valorINSSMaximo', width: '7%', cellsformat:"d2", cellsalign: 'right' }

        ];

        this.tableConfig = { source, columns }
        this.setState({
            exibir:true
        }
        )
      }
      )})
    }

    render() {
        return this.state ? this.state.exibir ?  <CrudListPageScaffold title="Parâmetros de recibos" tableConfig={this.tableConfig}
                                     baseUrl="/recibos/parametros" history={this.props.history}
                                     formLinkTitle="Novo parâmetro" tableName="parametroRecibos"
                                     shouldAddReportFlowButton={false}
                                     shouldAddPrintButton={false}
                                     shouldAddZipButton={false}
                                     shouldAddViewButton= {true}
                                     ehPagamento={true}
                                    // shouldAddEditButton= {false}
                                    // shouldAddDeleteButton= {false}
                                    // shouldAddPrintGridButton= {false}
                                    //  urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/recibo/parametro"

                                     />
                                     : <></>
                                     : <></>
    }
}

export default PaginaListaParametrosRecibo;
