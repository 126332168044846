
const prefixo = "@global/";
/**
 * global action types
 */
export const START_GLOBAL_LOADING = prefixo + 'START_GLOBAL_LOADING';
export const STOP_GLOBAL_LOADING  = prefixo + 'STOP_GLOBAL_LOADING';
export const SHOW_NOTIFICATION    = prefixo + 'SHOW_NOTIFICATION';
export const DISMISS_NOTIFICATION = prefixo + 'DISMISS_NOTIFICATION';
export const EXIBIR_ALERTA = prefixo + 'EXIBIR_ALERTA';
export const ESCONDER_ALERTA = prefixo + 'ESCONDER_ALERTA';

/*
 * action creators
 */

export const startGlobalLoading = (message) => ({ type: START_GLOBAL_LOADING, payload: message });
export const stopGlobalLoading = () => ({ type: STOP_GLOBAL_LOADING });
export const dismissGlobalNotification = () => ({ type: DISMISS_NOTIFICATION });
export const showGlobalNotification = ({
   message = '',
   type = 'error',
   duration = 3000,
   delay = 800,
   forcarExibicao = false,
}) => ({
    type: SHOW_NOTIFICATION,
    payload: { message, type, forcarExibicao }
});

export const exibirAlertaGlobal = ({ mensagem }) => ({ type: EXIBIR_ALERTA, payload: {mensagem} })
export const esconderAlertaGlobal = () => ({ type: ESCONDER_ALERTA })

/**
 * reducers
 */

const initialState = {
    isLoading: false,
    loadingMessage: "",
    currentNotification: null,
    alertaVisivel: null,
};

export const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_GLOBAL_LOADING: return Object.assign({}, state, {
            isLoading: true,
            loadingMessage: action.payload,
        });
        case STOP_GLOBAL_LOADING: return Object.assign({}, state, {
            isLoading: false,
        });
        case SHOW_NOTIFICATION: return Object.assign({}, state, {
            currentNotification: {
                message: action.payload.message,
                type: action.payload.type,
                forcarExibicao: action.payload.forcarExibicao,
            },
        });
        case DISMISS_NOTIFICATION: return Object.assign({}, state, {
            currentNotification: null,
        });
        case EXIBIR_ALERTA: return Object.assign({}, state, {
            alertaVisivel: action.payload.mensagem,
        });
        case ESCONDER_ALERTA: return Object.assign({}, state, {
            alertaVisivel: null,
        });
        default: return state;
    }
};
