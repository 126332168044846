export const MARGEM_ESQUERDA = {
  viewName: "Margem esquerda", serializedName: "MARGEM_ESQUERDA",
};

export const MARGEM_DIREITA = {
  viewName: "Margem direita", serializedName: "MARGEM_DIREITA",
};

export const MARGEM_DIREITA_ESQUERDA = {
  viewName: "Margem direita/esquerda", serializedName: "MARGEM_DIREITA_ESQUERDA",
};

export default [
  MARGEM_ESQUERDA, MARGEM_DIREITA, MARGEM_DIREITA_ESQUERDA
];
