import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaBacias extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "nome", type: "string", map: "nome" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: "5%" },
      { text: "Nome", dataField: "nome" },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Bacias hidrográficas"
        tableConfig={this.tableConfig}
        baseUrl="/baciasHidrograficas"
        history={this.props.history}
        formLinkTitle="Nova bacia"
        tableName="baciasHidrograficas"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
      />
    );
  }
}

export default PaginaListaBacias;
