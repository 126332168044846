import React from "react";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";

export default React.memo(function BotaoPadrao({ children, onClick, className = "", largura = 120, desabilitado = false }) {
    return <JqxButton theme="material" className={`py-2 ${className}`}
                      width={largura} onClick={desabilitado ? undefined : onClick} style={{boxSizing: "border-box"}}
                      disabled={desabilitado}>
        {children}
    </JqxButton>
});
