import React from "react";
import PropTypes from "prop-types";
import {defaultProps, propTypes} from "./formFieldCommonProps";
import FieldLabel from "./FieldLabel";
import Error from "../Error";

class TextAreaField extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = (event) => {
            if (props.input) {
                props.input.onChange(event.target.value);
            }
            if (props.onChange) {
                props.onChange(event.target.value);
            }
        }
    }

    render() {
        const name = this.props.name || this.props.input.name;
        const value = this.props.value || this.props.input.value;

        return <>
            {this.props.label && <FieldLabel label={this.props.label} required={this.props.required} />}
            <textarea className={`form-control ${this.props.className}`} value={value} disabled={this.props.disabled}
                      onChange={this.onChange} required={this.props.required}
                      minLength={this.props.minlength} maxLength={this.props.maxLength}
                      name={name} readOnly={this.props.readOnly} rows={this.props.rows} cols={this.props.cols} />
            <Error name={name} />
        </>;
    }
}

TextAreaField.propTypes = {
    ...propTypes,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,

    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextAreaField.defaultProps = {
    ...defaultProps,
};

export default TextAreaField;
