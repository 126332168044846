import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormRelatorioVeicular from "./PaginaFormRelatorioVeicular";
import PaginaListaRelatoriosVeiculares from "./PaginaListaInspecoesVeiculares";

class PaginaInspecoesVeicular extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosVeiculares"} component={PaginaListaRelatoriosVeiculares} />
                <Route exact path={"/sgih/app/relatoriosVeiculares/criar"} component={PaginaFormRelatorioVeicular} />
                <Route path={"/sgih/app/relatoriosVeiculares/:id"} component={PaginaFormRelatorioVeicular} />
            </Switch>
        </>;
    }
}

export default PaginaInspecoesVeicular;