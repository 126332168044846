import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import DateTimeField from "../../components/form/field/DateTimeField/DateTimeField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import createDecorator from "final-form-calculate";
import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import { requiredWithNotification } from "../../util/FormUtils";


import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import SelectField from "components/form/field/SelectField/SelectField";
import CheckSetField from "components/form/field/CheckSetField/CheckSetField";
import TipoEquipamento from "model/TipoEquipamento";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

const dataAtual = new Date();

const identifierValueResolver = (e) => e.id || e.codigo;
const unidadeRegionalLabelResolver = e => e.siglaLocalUasg;

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(


);

class PaginaFormEquipamentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/unidadesRegionais/simplesComPermissao").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(createServiceFor("/equipamentos").get(this.props.match.params.id));

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises).then(([
      unidadesRegionais,
        inspecao = {},
      ]) => {
        if(inspecao.data) {
          this.setState({
            initialValues: inspecao.data,
          });
        }

        return {
        unidadesRegionais : unidadesRegionais.data,
        initialValues: inspecao.data
            ? {
              ...inspecao.data,
              codigoEstacaoDigitado: inspecao.data.codigoEstacao,
            }
            : unidadesRegionais.data.length === 1 ?
            {
              idUnidadeRegional:unidadesRegionais.data[0].id,
            }
            : {},
      }
    }
    )
    .catch((e) => {
      console.log(e);
      store.dispatch(showGlobalNotification({
        message: e.message
      }))
    });

    this.setState({
      promiseDados: promise
    });
  }

  submitForm(values) {
    // console.log(values)
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/equipamentos")
      .post(values)
      // .then(console.log)
      // .catch(console.error)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Equipamento salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      marca: this.state.initialValues?.marca,
      modelo: this.state.initialValues?.modelo,
    });

    const formattedValues = {
      marca: limitText(sanitizedValues?.marca, 60),
      modelo: limitText(sanitizedValues?.modelo, 40),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Equipamento</div> - <div class="text">Código: </div>${this.state.initialValues.id}`
                : ''
            }
            ${
              formattedValues?.marca
                ? ` - <div class="text">Marca:</div> ${formattedValues.marca}`
                : ''
            }
            ${
              formattedValues?.modelo
                ? ` - <div class="text">Modelo:</div> ${formattedValues.modelo}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Equipamentos"
        baseUrl="/sgih/app/equipamentos"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field component="input" type="hidden"
                        name="id" value={id} />
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Equipamento">
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field component={CheckSetField} name='tipo'
                                      label='Tipo'
                                      elements={TipoEquipamento}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification('Tipo')}
                                      required
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="numero"
                                    label="NR CPRM"
                                    maxLength={40}
                                    validate={requiredWithNotification(
                                      "NR CPRM"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="marca"
                                    label="Marca"
                                    maxLength={100}
                                    validate={requiredWithNotification(
                                      "Marca"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={TextField}
                                    name="modelo"
                                    label="Modelo"
                                    maxLength={100}
                                    validate={requiredWithNotification(
                                      "Modelo"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="numeroSerie"
                                    label="NR. de série"
                                    maxLength={20}
                                    validate={requiredWithNotification(
                                      "NR. de série"
                                    )}
                                    disabled={disableAllFields}
                                    required

                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="dataCalibracao"
                                    label="Data da calibração"
                                    validate={requiredWithNotification(
                                      "Data da calibração"
                                    )}
                                    maxDate={dataAtual}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field component={SelectField} name='idUnidadeRegional'
                                      label='Unidade regional'
                                      elements={data.unidadesRegionais}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={unidadeRegionalLabelResolver}
                                      disabled={disableAllFields}
                                      validate={requiredWithNotification('Unidade regional')}
                                      required
                                  />
                                </Col>
                              </Row>
                            </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormEquipamentos;
