import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useDropzone} from "react-dropzone";
import FileCard from "../Card";

import "./FileGrid.css"
import {connect} from "react-redux";
import {adicionarEEnviarArquivo, carregarArquivoPorId, removerArquivo} from "../../../reducers/fileReducers";

// https://css-tricks.com/drag-and-drop-file-uploading/
// http://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
// icones https://fontawesome.com/icons/file-word
// referencia https://uppy.io/examples/dashboard/
// vou precisar de uma lib de dnd? https://react-dnd.github.io/react-dnd/about

function FileGrid({nome, permitirUpload, arquivos = [], adicionarEnviarArquivo, onExcluir, onAdicionar = () => {},
                  removerDoEstado, carregarArquivos, onClickImagem, bloquearTipos,  permitirImagens, permitirAudios,
                  permitirVideos, tiposPermitidos, permitirExclusao, renderAfter, permitirZip, permitirRar,limiteArquivos}) {

    useEffect(() => {
        carregarArquivos();
    }, [carregarArquivos]);


    const onDrop = useCallback(acceptedFiles => {
            
        acceptedFiles = acceptedFiles.slice(0,limiteArquivos - arquivos.length);

        acceptedFiles.forEach((file) => {
          
            adicionarEnviarArquivo(file)
                .then((res) => {
                    onAdicionar(res)
                })
                .catch(console.error)
        })
    }, [adicionarEnviarArquivo, limiteArquivos, onAdicionar, arquivos]);

        
    const [noDrag, setNoDrag] = useState(false);
    useEffect(() => {
        if(arquivos.length >= limiteArquivos){
            setNoDrag(true);
        } else{
            setNoDrag(false);
        }
    }, [arquivos]);

    let accept = null;

    if (bloquearTipos) {
        accept = [ ...tiposPermitidos ];

        if (permitirImagens) accept.push('image/*');
        if (permitirAudios)  accept.push('audio/*');
        if (permitirVideos)  accept.push('video/*');
        if (permitirRar) {
            accept.push("application/x-rar-compressed")
            accept.push("application/vnd.rar")
        }
        if (permitirZip) {
            accept.push("application/x-zip-compressed")
            accept.push("application/zip")
            accept.push("multipart/x-zip")
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, noDrag: noDrag});

    const gridProps = permitirUpload
        ? getRootProps()
        : {};

    const inputProps = permitirUpload
        ? getInputProps()
        : {};


    const onExcluirEvent = (hash) => {
        try{
            arquivos = arquivos.filter(arquivo => arquivo[0].toString() != hash.toString());
        }
        catch(err){}
        onExcluir(hash);
        removerDoEstado(hash);
    };

    return <div className="file-grid">
        {permitirUpload &&  arquivos.length < limiteArquivos && <input type="file" name="files[]" {...inputProps} />}
        <div className="file-grid__drop-zone" {...gridProps}>
            {permitirUpload && arquivos.length < limiteArquivos &&
                <div className="file-grid__instructions">
                    {isDragActive
                        ? <p>Solte o arquivo aqui...</p>
                        : <p>Arraste um arquivo ou clique para adicionar</p>
                    }
                </div>
            }{permitirUpload && arquivos.length >= limiteArquivos &&
                <div className="file-grid__instructions">
                    {isDragActive
                        ? <p></p>
                        : <p>O limite de fotos para esse relatório foi atingido</p>
                    }
                </div>
            }
            <div className="file-grid__files">
                {arquivos.map((arq, i) => {
                   if(arquivos.length > limiteArquivos){
                    let files = []; 
                    for (var j = 0; j < limiteArquivos; j++)
                    {files.push(arquivos[j])}
                    // arquivos.setState(files)
                   }
                    if(i < limiteArquivos){
                        return <FileCard key={i} nomeGrid={nome} onExcluir={onExcluirEvent}
                                        onClickImagem={() => { onClickImagem(i, arquivos) }} chave={arq[0]} {...arq[1]}
                                        mostrarBotaoExclusao={permitirExclusao}/>
                    }
                    return <> </>
                })}
            </div>
            { renderAfter && arquivos.length < limiteArquivos && <div className="file-grid__instructions">
                {renderAfter()}
            </div> }
        </div>
    </div>
}

FileGrid.propTypes = {
    nome: PropTypes.string.isRequired,
    idsArquivosExistentes: PropTypes.arrayOf(PropTypes.number).isRequired,

    permitirUpload: PropTypes.bool,
    permitirExclusao: PropTypes.bool,

    onExcluir: PropTypes.func,
    onAdicionar: PropTypes.func,
    onClickImagem: PropTypes.func,

    // Render props
    renderAfter: PropTypes.func,

    // Tipos
    bloquearTipos: PropTypes.bool,
    permitirImagens: PropTypes.bool,
    permitirAudios: PropTypes.bool,
    permitirVideos: PropTypes.bool,
    permitirZip: PropTypes.bool,
    permitirRar: PropTypes.bool,
    tiposPermitidos: PropTypes.arrayOf(PropTypes.string),

    // Redux
    adicionarEnviarArquivo: PropTypes.func,
    arquivos: PropTypes.array,
    carregarArquivos: PropTypes.func,
    removerDoEstado: PropTypes.func,
    limiteArquivos: PropTypes.number,
};

FileGrid.defaultProps = {
    permitirUpload: true,
    permitirExclusao: true,

    // Tipos
    bloquearTipos: false,
    permitirImagens: false,
    permitirAudios: false,
    permitirVideos: false,
    permitirZip: false,
    permitirRar: false,
    limiteArquivos: Number.MAX_VALUE,
    tiposPermitidos: [],
};

export default connect(
    ({ files }, { nome }) => {
        const arquivos = [];
        for (const [key, value] of Object.entries(files[nome] || {})) {
            if (value && !value.deletado) arquivos.push([key, value]);
        }
        return { arquivos }
    },
    (dispatch, { nome, idsArquivosExistentes }) => ({
        adicionarEnviarArquivo: (file) => dispatch(adicionarEEnviarArquivo({
            store: nome,
            file,
        })),
        removerDoEstado: (chave) => {
            dispatch(removerArquivo({
                key: chave,
                store: nome,
            }))
        },
        carregarArquivos: () => {
            return (idsArquivosExistentes||[]).map(id => {
                return dispatch(carregarArquivoPorId({
                    store: nome,
                    id,
                }));
            });
        },
        dispatch,
    })
)(FileGrid);
