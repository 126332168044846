import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectField";

function SelectRangeNumericoField({ de, ate, ...rest }) {
    const [valores, setValores] = useState([]);

    useEffect(() => {
        const vals = [];

        for (let i = de; i <= ate; i++) {
            vals.push(i);
        }

        setValores(vals);
    }, [de, ate]);

    return <SelectField elements={valores}
                        valueResolver={(el) => el}
                        labelResolver={(el) => el} {...rest} />
}

SelectRangeNumericoField.propTypes = {
    de: PropTypes.number.isRequired,
    ate: PropTypes.number.isRequired,
};

export default SelectRangeNumericoField;