import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";

import { AbaPermissoesCadastros } from "./AbaPermissoesCadastros";
import { AbaPermissoesRelatorios } from "./AbaPermissoesRelatorios";
import { AbaDadosBasicos } from "./AbaDadosBasicos";

import { construirBotoesForm } from "components/scaffold/FormPageScaffoldCommon";
import { AbaPermissoesRecibo } from "./AbaPermissoesRecibo";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

// ****************************************************************************
// Decoradores.
const decorators = [];
// ****************************************************************************

class PaginaFormPerfil extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emEdicao: false,
      jaSubmeteuUmaVez: false,
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/perfis/escopos").query(),
      createServiceFor("/perfis/efeitos").query(),
      createServiceFor("/perfis/combo").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/perfis").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(([escopos, efeitos, perfis, perfil = {}]) => {
        if (perfil.data) {
          this.setState({
            initialValues: perfil.data,
          });
        }

        return {
          escopos: escopos.data,
          efeitos: efeitos.data,
          perfis: perfis.data,
          initialValues: perfil.data
            ? {
                ...perfil.data,
              }
            : {},
        };
      })
      .catch((e) => {
        console.log("Erro com promise em perfil: ", e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  // Isso foi necessário para exibir corretamente a mensagem de erro do combo de status
  // de permissões de relatórios.
  // Elas não estavam sendo exibidas pelo meio padrão (requiredWithNotification e required).
  // Portanto, elas foram adicionadas ao método submitForm e validate (do formulário).
  validarPerfis = (values) => {
    let perfilVisualizarRelatorioError = document.getElementById(
      `perfilVisualizarRelatorioError`
    );
    if (perfilVisualizarRelatorioError) {
      if (
        values.permitirVisualizarRelatorio &&
        !values.perfilVisualizarRelatorio
      ) {
        perfilVisualizarRelatorioError.classList.remove("d-none");
      } else {
        perfilVisualizarRelatorioError.classList.add("d-none");
      }
    }
    let perfilImprimirListagemRelatorioError = document.getElementById(
      `perfilImprimirListagemRelatorioError`
    );
    if (perfilImprimirListagemRelatorioError) {
      if (
        values.permitirImprimirListagemRelatorio &&
        !values.perfilImprimirListagemRelatorio
      ) {
        perfilImprimirListagemRelatorioError.classList.remove("d-none");
      } else {
        perfilImprimirListagemRelatorioError.classList.add("d-none");
      }
    }
    let perfilDownloadZIPRelatorioError = document.getElementById(
      `perfilDownloadZIPRelatorioError`
    );
    if (perfilDownloadZIPRelatorioError) {
      if (
        values.permitirDownloadZIPRelatorio &&
        !values.perfilDownloadZIPRelatorio
      ) {
        perfilDownloadZIPRelatorioError.classList.remove("d-none");
      } else {
        perfilDownloadZIPRelatorioError.classList.add("d-none");
      }
    }
    let perfilAlterarRelatorioError = document.getElementById(
      `perfilAlterarRelatorioError`
    );
    if (perfilAlterarRelatorioError) {
      if (values.permitirAlterarRelatorio && !values.perfilAlterarRelatorio) {
        perfilAlterarRelatorioError.classList.remove("d-none");
      } else {
        perfilAlterarRelatorioError.classList.add("d-none");
      }
    }
    let perfilExcluirRelatorioError = document.getElementById(
      `perfilExcluirRelatorioError`
    );
    if (perfilExcluirRelatorioError) {
      if (values.permitirExcluirRelatorio && !values.perfilExcluirRelatorio) {
        perfilExcluirRelatorioError.classList.remove("d-none");
      } else {
        perfilExcluirRelatorioError.classList.add("d-none");
      }
    }
    return;
  };

  submitForm(values) {
    let perfilTemErros = this.validarPerfis(values);
    if (perfilTemErros) {
      return perfilTemErros;
    }

    if (values.update && values.permitirFluxoRelatorio) {
      if (
        !values.permissoesMudancaStatusRelatorio ||
        !values.permissoesMudancaStatusRelatorio.length
      )
        return store.dispatch(
          showGlobalNotification({
            message: "Insira um fluxo de relatório",
            duration: 5000,
          })
        );

      const itens = values.permissoesMudancaStatusRelatorio.filter(
        (i) => i.fluxoPadrao === true
      );

      if (!itens || !itens.length)
        return store.dispatch(
          showGlobalNotification({
            message: "Selecione um fluxo padrão",
            duration: 5000,
          })
        );

      if (itens.length > 1)
        return store.dispatch(
          showGlobalNotification({
            message: "Selecione somente um fluxo padrão",
            duration: 5000,
          })
        );

      const fluxo = itens[0];

      if (fluxo.de && fluxo.de.toString() != values.id.toString())
        return store.dispatch(
          showGlobalNotification({
            message: "O fluxo padrão deve iniciar neste perfil",
            duration: 5000,
          })
        );
    }

    const sanitizedValues = {
      ...values,
      ...sanitizeStringValuesFromObject({
        nome: values?.nome,
        descricao: values?.descricao || '',
      }),
    };

    store.dispatch(startGlobalLoading("Salvando perfil..."));
    createServiceFor("/perfis")
      .post(sanitizedValues)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Perfil salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  meuFormWrapper = (form) => ({
    submit: () => {
      // Isso foi necessário para exibir corretamente a mensagem de erro do combo de status
      // de permissões de relatórios.
      // Elas não estavam sendo exibidas pelo meio padrão (requiredWithNotification e required).
      // Portanto, elas foram adicionadas ao método submitForm e validate (do formulário).
      // Porém, o validate do formulário exibia a mensagem de erro logo ao alterar o campo (mesmo
      // antes de tentar submeter os dados), diferentemente do comportamento padrão.
      // Para evitar isso, foi criado um estado jaSubmeteuUmaVez que é modificado para true quando
      // o usuário clicar em submit. A partir deste momento, o validate do form passará a operar.
      this.setState({
        jaSubmeteuUmaVez: true,
      });

      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("Não validando ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      nome: this.state.initialValues?.nome,
      descricao: this.state.initialValues?.descricao,
    });

    const formattedValues = {
      ...sanitizedValues,
      nome: limitText(sanitizedValues?.nome, 40),
      descricao: limitText(sanitizedValues?.descricao, 80),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              formattedValues?.nome
                ? `<div class="text">Perfil</div> - <div class="text">Nome: </div>${formattedValues.nome}`
                : ''
            }
            ${
              formattedValues?.descricao
                ? ` - <div class="text">Descrição:</div> ${formattedValues.descricao}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const desabilitarTodosCampos =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Perfis"
        baseUrl="/sgih/app/perfis"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={decorators}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                validate={(values) => {
                  // Isso foi necessário para exibir corretamente a mensagem de erro do combo de status
                  // de permissões de relatórios.
                  // Elas não estavam sendo exibidas pelo meio padrão (requiredWithNotification e required).
                  // Portanto, elas foram adicionadas ao método submitForm e validate (do formulário).
                  // Porém, o validate do formulário exibia a mensagem de erro logo ao alterar o campo (mesmo
                  // antes de tentar submeter os dados), diferentemente do comportamento padrão.
                  // Para evitar isso, foi criado um estado jaSubmeteuUmaVez que é modificado para true quando
                  // o usuário clicar em submit. A partir deste momento, o validate do form passará a operar.
                  if (this.state.jaSubmeteuUmaVez) {
                    let perfilTemErros = this.validarPerfis(values);
                    if (perfilTemErros) {
                      return perfilTemErros;
                    }
                  }
                }}
                render={({
                  handleSubmit,
                  form,
                  form: {
                    mutators: { push },
                  },
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.meuFormWrapper(form),
                          estaEditando,
                          desabilitarTodosCampos,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados básicos">
                          <AbaDadosBasicos
                            desabilitarTodosCampos={desabilitarTodosCampos}
                          />
                        </Tab>
                        <Tab title="Permissões de cadastros">
                          <AbaPermissoesCadastros
                            desabilitarTodosCampos={desabilitarTodosCampos}
                          ></AbaPermissoesCadastros>
                        </Tab>
                        <Tab title="Permissões de pagamentos">
                          <AbaPermissoesRecibo
                            desabilitarTodosCampos={desabilitarTodosCampos}
                          ></AbaPermissoesRecibo>
                        </Tab>
                        <Tab title="Permissões de relatórios">
                          <AbaPermissoesRelatorios
                            pushMutator={push}
                            elementosPerfil={data.perfis}
                            elementosEscopo={data.escopos}
                            elementosEfeito={data.efeitos}
                            desabilitarTodosCampos={desabilitarTodosCampos}
                          />
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export { PaginaFormPerfil };
