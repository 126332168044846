import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaTiposOrdemServico extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "descricao",type: "string", map: "descricao" },
        { name: "tipoRelatorio",type: "string", map: "tipoRelatorio" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: 70 },
      { text: "Tipo de relatório", dataField: "tipoRelatorio", width: 140  },
      { text: "Descrição", dataField: "descricao" },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Tipos de ordens de serviço"
        tableConfig={this.tableConfig}
        baseUrl="/tiposOrdemServico"
        history={this.props.history}
        formLinkTitle="Novo"
        tableName="tiposOrdemServico"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
      />
    );
  }
}

export default PaginaListaTiposOrdemServico;
