import React from "react";
import JqxGrid, { jqx } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import GridTraduzida from "../../util/GridTraduzida";
import PropTypes from "prop-types";
import deepEqual from "deep-equal";
import { ACCESS_TOKEN } from "../../constants";
import { connect } from "react-redux";
import { replaceTableState } from "../../reducers";
import "./table.css";

// https://www.jqwidgets.com/react-components-documentation/documentation/jqxgrid/reactjs-grid-api.htm?search=
// https://www.jqwidgets.com/community/topic/always-show-filter-icon/

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.mudeiAltura = true;
    this.state = {
      gridHeight: "500px",
      quantidadeLinhasExibir: props.tableState && props.tableState.pagesize
        ? props.tableState.pagesize
        : props.pagesize ,
      pageSizeOptions: props.tableState && props.tableState.pagesizeoptions
        ? props.tableState.pagesizeoptions
        : props.pagesizeoptions,
    };
  }

  updateDimensions = () => {
    let element = document.getElementsByClassName("jqx-grid")[0];
    let topo2 = document.getElementById("topo2-completo");
    // console.debug("HEIGHT 2", topo2.offsetHeight);
    if (element) {
      try{
        let alturaTopo2 = topo2 ? topo2.offsetHeight : 0;
        var space = window.innerHeight - element.offsetTop - alturaTopo2 - 10;
        let mudou = this.state.gridHeight !== `${space}px`;
        this.mudeiAltura = mudou;
        this.setState({
          gridHeight: `${space}px`,
        });
      }
      catch(err) {}
    }
  };

  render() {
    const jqxSource = new jqx.dataAdapter(this.props.source, {
      processData: (data) => {
        localStorage.setItem('FILTER', JSON.stringify(data));
        return data;
      },
      beforeSend: function (jqXHR, settings) {
        jqXHR.setRequestHeader(
          "Authorization",
          `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        );
        jqXHR.setRequestHeader(
          "Access-Control-Allow-Origin",
          process.env.REACT_APP_API_URL
        );
      },
    });
    return (
      <>
        {this.props.preActions &&
          this.props.preActions.map((ac, i) => this.buildAction(ac, i))}

        <JqxGrid
          source={jqxSource}
          columns={this.props.columns}
          localization={GridTraduzida}
          // onRowdoubleclick={(evento) => {
          //     this.props.self.visualizarObservador(evento.args.row.bounddata.id)
          // }}
          onFilter={this.onChangeFilter.bind(this)}
          onBindingcomplete={this.onTableReady.bind(this)}
          onSort={this.onChangeSorting.bind(this)}
          onPagechanged = {this.onPageChanged.bind(this)}
          pageable={true}
          sortable={true}
          columnsresize={true}
          width={"100%"}
          height={this.state.gridHeight}
          filterable={true}
          ref={this.props.wrappedComponentRef}
          virtualmode={this.props.modoVirtual}
          rendergridrows={this.props.renderGridRows}
          selectionmode="multiplerowsextended"
          enabletooltips={this.props.habilitarTooltip}
          autoshowcolumnsmenubutton={false}
          pagesize={this.state.quantidadeLinhasExibir || 20}
          pagesizeoptions={this.state.pageSizeOptions}
        />

        {this.props.actions &&
          this.props.actions.map((ac, i) => this.buildAction(ac, i))}
      </>
    );
  }

  onChangeFilter(e) {
    const filterGroups = e.args.filters;
    const filterState = {};

    for (const group of filterGroups) {
      const groupFilter = group.filter;
      const groupState = {
        operator: groupFilter.operator,
        filters: [],
      };

      for (const filter of groupFilter.getfilters()) {
        groupState.filters.push({
          operator: filter.operator,
          type: filter.type,
          value: filter.value,
          condition: filter.condition,
        });
      }

      filterState[group.datafield] = groupState;
    }

    this.currentTableState = {
      ...this.currentTableState,
      filter: filterState,
    };
    this.props.replaceTableState(this.currentTableState);

    if (this.props.aoTrocarFiltros) {
      this.props.aoTrocarFiltros({
        ref: this.props.wrappedComponentRef,
      });
    }
  }

  onChangeSorting(e) {
    const sortState = {
      column: e.args.sortinformation.sortcolumn,
      order: e.args.sortinformation.sortdirection.ascending ? "asc" : "desc",
    };

    this.currentTableState = {
      ...this.currentTableState,
      sort: sortState,
    };
    this.props.replaceTableState(this.currentTableState);
  }

  onPageChanged(e) {
    const pagesize = e.args.pagesize;
    const pager = {
      ...e.args.pager,
      pagenum: e.args.pagenum,
      pagesize: pagesize,
    };
    this.currentTableState = {
      ...this.currentTableState,
      pager: pager,
      pagenum: e.args.pagenum,
      pagesize:  pagesize,
    };
    this.props.replaceTableState(this.currentTableState);
  }

  onTableReady() {
    if (this.props.aoCarregarTabela){
      this.esperarAteTabelaDisponivel(()=> this.props.aoCarregarTabela({ ref: this.props.wrappedComponentRef }))
    }

  }

  esperarAteTabelaDisponivel(fn) {
    const id = setInterval(() => {
      if (
        this.props.wrappedComponentRef.current &&
        this.props.wrappedComponentRef.current.isBindingCompleted()
      ) {
        fn();
        clearInterval(id);
      }
    }, 50);
  }

  componentWillUnmount() {
    this.props.replaceTableState(this.currentTableState);
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.currentTableState = this.props.tableState;


    if (this.currentTableState) {
      if (this.currentTableState.filter) {
        for (const [prop, data] of Object.entries(
          this.currentTableState.filter
        )) {
          const filterGroup = new jqx.filter();
          for (const filterData of data.filters) {
            const filter = filterGroup.createfilter(
              filterData.type,
              filterData.value,
              filterData.condition
            );

            filterGroup.addfilter(filterData.operator, filter);
          }
          this.esperarAteTabelaDisponivel(() =>
            this.props.wrappedComponentRef.current.addfilter(prop, filterGroup)
          );
        }
        // Por algum motivo, é preciso chamar essa função para não perder os filtros aplicados, ao carregar a tabela pela primeira vez.
        // O filtro não é aplicado até ocorrer alguma atualização na tabela, caso haja filtro sem ordenação.
        // https://www.jqwidgets.com/jquery-widgets-documentation/documentation/jqxgrid/jquery-grid-filtering.htm
        this.esperarAteTabelaDisponivel(() =>
          this.props.wrappedComponentRef.current.applyfilters()
        );
      }

      if (this.currentTableState.sort) {
        console.log("entrei no this.currentTableState.sort")
        const { column, order } = this.currentTableState.sort;
        this.esperarAteTabelaDisponivel(() => {
          this.props.wrappedComponentRef.current.sortby(column, order);
        });
      }

      if (this.currentTableState.pagenum) {
        console.log("entrei no this.currentTableState.pagenum")
        this.esperarAteTabelaDisponivel(() => {
          this.props.wrappedComponentRef.current.gotopage(this.currentTableState.pagenum);

        });
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    let resultado =
      !deepEqual(this.currentTableState, nextProps.tableState) ||
      this.mudeiAltura;
    if (resultado) {
      this.mudeiAltura = false;
    }
    return resultado;
  }

  buildAction(action, key) {
    return action ? (
      action.render ? (
        action.render(key, this.props.wrappedComponentRef)
      ) : (
          <JqxButton
            key={key}
            theme="material"
            width={120}
            onClick={action.onClick}
            className="py-2 float-right mt-3 mb-3 ml-3"
          >
            {action.title}
          </JqxButton>
        )
    ) : null;
  }
}

Table.propTypes = {
  name: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,

  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      render: PropTypes.func,
    })
  ),
  preActions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      render: PropTypes.func,
    })
  ),

  aoTrocarFiltros: PropTypes.func,
  aoCarregarTabela: PropTypes.func,
  replaceTableState: PropTypes.func,
  persistirEstadoTabela: PropTypes.bool,
  renderGridRows: PropTypes.func,
  modoVirtual: PropTypes.bool,
  habilitarTooltip: PropTypes.bool,
  pagesize: PropTypes.number,
  pagesizeoptions: PropTypes.arrayOf(PropTypes.number),
  wrappedComponentRef: PropTypes.any,
};

Table.defaultProps = {
  actions: [],
  preActions: [],
  persistirEstadoTabela: true,
  habilitarTooltip: false,
  modoVirtual: false,
  pagesize: 20,
  pagesizeoptions: [5, 10, 20, 50, 100],
};

// https://stackoverflow.com/a/57314972
export default connect(
  (state, props) => ({
    tableState: state.tables[props.name],
  }),
  (dispatch, { persistirEstadoTabela = true, name }) => ({
    replaceTableState: (tableState) => {
      if (persistirEstadoTabela) {
        dispatch(replaceTableState(name, tableState));
      }
    },
  }),
  null,
  { forwardRef: true }
)(Table);
