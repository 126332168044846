import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import {defaultProps, propTypes} from "../formFieldCommonProps";
import FieldLabel from "../FieldLabel";
import Error from "../../Error";
import SelectSearch, { fuzzySearch } from 'react-select-search';

import "./SelectField.css"

class SelectField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.processElements(),
        };

        this.onChange = (event) => {
            const eventValue = event.target
                ? event.target.value
                : event;

            const value = this.props.callbackWithOriginalValue
                ? (
                    this.state.labelsAndValues[eventValue]
                        ? this.state.labelsAndValues[eventValue]._original
                        : null
                ) : eventValue;

            if (props.input) {
                props.input.onChange(value);
            }
            if (props.onChange) {
                props.onChange(value);
            }
        }
    }

    render() {
        const name = this.props.name || (this.props.input && this.props.input.name);
        const value = this.props.value || (this.props.input && this.props.input.value) || this.props.defaultValue;

        let processedValue = this.props.callbackWithOriginalValue && value ? this.props.valueResolver(value) : value;

        if (null != processedValue && 'undefined' !== typeof processedValue) processedValue += "";
        else processedValue = '';

        let select;

        if (this.props.buscavel) {
            select = <>
                <input type="hidden" value={processedValue||''} name={name} />
                <SelectSearch filterOptions={fuzzySearch} options={this.state.selectSearchOptions} search value={processedValue}
                              disabled={this.props.disabled} onChange={this.onChange} multiple={this.props.multiplo}
                              required={this.props.required} readOnly={this.props.readOnly}
                              placeholder={this.props.showEmptyOption ? this.props.emptyOptionLabel : ''}
                />
            </>;
        } else {
            select = <Form.Control as="select" value={processedValue} name={name}
                                   disabled={this.props.disabled} onChange={this.onChange}
                                   required={this.props.required} readOnly={this.props.readOnly}
                                   multiple={this.props.multiplo}>

                {this.props.showEmptyOption && <option value={this.props.emptyOptionValue}>
                    {this.props.emptyOptionLabel}
                </option>}

                {this.state.labelsAndValues && Object.values(this.state.labelsAndValues)
                    .sort((a, b) => a.order - b.order)
                    .map((labelAndValue, i) =>
                    <option key={i} value={labelAndValue.value}>
                        {labelAndValue.label}
                    </option>
                )}
            </Form.Control>;
        }


        return <>
            {this.props.label && <FieldLabel label={this.props.label} required={this.props.required} />}
            {select}
            {this.props.input && <Error name={name} />}
        </>;
    }

    processElements() {
        const labelsAndValues = {};
        const selectSearchOptions = [];

        if (this.props.elements) {
            for (let i = 0; i < this.props.elements.length; i++) {
                const e = this.props.elements[i];
                const label = this.props.labelResolver(e);
                const value = this.props.valueResolver(e);

                if (!label) {
                    console.warn("Elemento sem label!");
                    console.warn(e);
                }

                if (!value) {
                    console.warn("Elemento sem valor!");
                    console.warn(e);
                }

                labelsAndValues[value] = { label, value, _original: e, order: i };
                selectSearchOptions.push({ value: value+"", name: label })
            }
        }

        return {
            labelsAndValues, selectSearchOptions
        };
    }

    componentDidUpdate(previousProps) {
        if (previousProps.elements !== this.props.elements) {
            this.setState({
                ...this.processElements(),
            });
        }
    }
}

SelectField.propTypes = {
    ...propTypes,
    label: PropTypes.string,
    onChange: PropTypes.func,

    defaultValue: PropTypes.any,

    multiplo: PropTypes.bool,
    buscavel: PropTypes.bool,
    showEmptyOption: PropTypes.bool,
    emptyOptionValue: PropTypes.any,
    emptyOptionLabel: PropTypes.string,

    callbackWithOriginalValue: PropTypes.bool,

    elements: PropTypes.arrayOf(PropTypes.any),
    valueResolver: PropTypes.func,
    labelResolver: PropTypes.func,
};

SelectField.defaultProps = {
    ...defaultProps,

    valueResolver: (e) => e.serializedName,
    labelResolver: (e) => e.viewName,
    callbackWithOriginalValue: false,

    multiplo: false,
    buscavel: false,
    showEmptyOption: true,
    emptyOptionValue: "",
    emptyOptionLabel: "Selecione...",
};

export default SelectField;
