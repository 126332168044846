export const SGIHmobile = {
    nome: "SGIHmobile",
    descricao: "SGIHmobile",
    id: 78
};

export const SGIHweb = {
    nome: "SGIHweb",
    descricao: "SGIHweb",
    id: 79
};

export const ETL = {
    nome: "IMPORTADO",
    descricao: "IMPORTADO",
    id: 112
};

export default [
    SGIHmobile.nome, SGIHweb.nome, ETL.nome
];