import React, { useEffect, useState } from "react";
import BlockUi from "react-block-ui";

const PromiseLoader = ({
  promise,
  onError,
  render,
  renderOnError,
}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

    useEffect(() => {
        if (promise) {
            promise
                .then(setData)
                .catch((e) => {
                    if (onError) onError(e);
                    setError(e)
                });
        }
    }, [promise, onError]);

  return null != error ? (
    renderOnError(error)
  ) : null != data ? (
    render(data)
  ) : (
    <BlockUi blocking={true} />
  );
};

export default PromiseLoader;
