import axios from "axios";
import React, { useEffect, useState } from "react";

const Versao = () => {
 
  const sobreSgih = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const [sgihVersao, setSgihVersao] = useState();
  useEffect(() => {
    sobreSgih
      .get("/versao/sgih")
      .then((response) => setSgihVersao(response.data))
      .catch((err) => {
        console.error("Ocorreu um erro" + err);
      });
  }, [sobreSgih]);

 
  var packageJsonInfo = require('./../../../package.json');
  const estilo = { margin: '35px'}

  return (
      <div style={estilo}>
        <h1>Versões dos módulos</h1>
        <h4>Versão do FrontEnd SGIH: {packageJsonInfo.version}</h4>
        <h4>Versão do BackEnd SGIH: {sgihVersao}</h4>
      </div>
  )
}

export default React.memo(Versao);