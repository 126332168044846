import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import createServiceFor from "../../service/Service";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification } from "../../util/FormUtils";
import TextAreaField from "components/form/field/TextAreaField";
import SelectField from "components/form/field/SelectField/SelectField";
import tiposRelatorio, { TipoRelatorioMap } from "../../model/TipoRelatorio";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";
import TipoRelatorio from "../../model/TipoRelatorio";

class PaginaFormTiposOrdemServico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    };
  }

  componentDidMount() {
    const promises = [];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/tiposOrdemServico").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(([tiposOrdemServico = {}]) => {
        if (tiposOrdemServico.data) {
          this.setState({
            initialValues: tiposOrdemServico.data,
          });
        }

        return {
          initialValues: tiposOrdemServico.data,
        };
      })
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/tiposOrdemServico")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Tipo Ordem de Serviço salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error("aqui");
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const formattedValues = {
      tipoRelatorio: TipoRelatorioMap[this.state.initialValues?.tipoRelatorio],
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Tipo de ordem de serviço</div> - <div class="text">Código: </div>${this.state.initialValues.id}`
                : ''
            }
            ${
              formattedValues?.tipoRelatorio
                ? ` - <div class="text">Tipo de relatório:</div> ${formattedValues.tipoRelatorio}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Tipos de ordens de serviço"
        baseUrl="/sgih/app/tiposOrdemServico"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[]}
                mutators={{}}
                initialValues={data.initialValues}
                render={({ handleSubmit, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Tipo ordem de serviço">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Field
                                component="input"
                                type="hidden"
                                name="id"
                                value={id}
                              />
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={SelectField}
                                  name="tipoRelatorio"
                                  label="Tipo de relatório"
                                  elements={tiposRelatorio}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Tipo de relatório"
                                  )}
                                  required
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="descricao"
                                  label="Descrição"
                                  maxLength={500}
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Descrição"
                                  )}
                                  required
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormTiposOrdemServico;
