import React, { Component } from 'react';
import CrudListPageScaffold from "../../../components/scaffold/CrudListPageScaffold";
import JqxButton from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons';
import UNIDADEREGIONAL from 'model/UnidadeRegional';
import './botaoEditado.css'
// correcao ponto decimal de "." para ","
//https://www.jqwidgets.com/jquery-widgets-documentation/documentation/jqxgrid/jquery-grid-cellsformatting.htm
class PaginaListaGerenciamentoRecibos extends Component {
    constructor(props) {
        super(props);


        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                }, ()=>{
                    const source = {
                        date: 'dd/MM/yyyy',
                        datafields: [
                            { name: 'id', type: 'number', map: 'id' },
                            { name: 'roteiro', type: 'number', map: 'roteiro' },
                            { name: 'codigoEstacao', type: 'string', map: 'codigoEstacao' },
                            { name: 'nomeObservador', type: 'string', map: 'nomeObservador' },
                            { name: 'nomeEstacao', type: 'string', map: 'nomeEstacao' },
                            { name: 'parametroRecibo', type: 'number', map: 'parametroRecibo' },
                            { name: 'dataInicial', type: 'date', map: 'dataInicial' },
                            { name: 'dataFinal', type: 'date', map: 'dataFinal' },
                            { name: 'valor', type: 'number', map: 'valor' },
                            { name: 'meses', type: 'number', map: 'meses' },
                            { name: 'valorBruto', type: 'number', map: 'valorBruto' },
                            { name: 'valorINSS', type: 'number', map: 'valorINSS' },
                            { name: 'valorISS', type: 'number', map: 'valorISS' },
                            { name: 'valorIRPF', type: 'number', map: 'valorIRPF' },
                            { name: 'valorLiquido', type: 'number', map: 'valorLiquido' },
                            { name: 'houvePagamento', type: 'string', map: 'houvePagamento' },
                            { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },


                        ],
                        id: 'id',
                    };

                    const columns = [
                        { text: 'ID', dataField: 'id', width: '4%' },
                        { text: 'Roteiro', dataField: 'roteiro', width: '4%' },
                        { text: 'Regional', dataField: 'unidadeRegional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                        { text: 'Código da estação', dataField: 'codigoEstacao', width: '7%'},
                        { text: 'Observador', dataField: 'nomeObservador', width: '15%' },
                        { text: 'Nome da estação', dataField: 'nomeEstacao', width: '15%'},
                        { text: 'Parâmetro', dataField: 'parametroRecibo', width: '6%' },
                        { text: 'Data inicial', dataField: 'dataInicial', filterType: 'date', cellsformat: 'dd/MM/yyyy', width: '6%' },
                        { text: 'Data final', dataField: 'dataFinal', filterType: 'date', cellsformat: 'dd/MM/yyyy', width: '6%' },
                        { text: 'Valor (R$)', dataField: 'valor', cellsformat:"d2", width: '6%', cellsalign: 'right' },
                        { text: 'Meses', dataField: 'meses', cellsformat:"d1", width: '4%' },
                        { text: 'Bruto', dataField: 'valorBruto', cellsformat:"d2", width: '4%', cellsalign: 'right' },
                        { text: 'INSS (R$)', dataField: 'valorINSS', cellsformat:"d2", width: '6%', cellsalign: 'right' },
                        { text: 'ISS (R$)', dataField: 'valorISS', cellsformat:"d2", width: '5%', cellsalign: 'right' },
                        { text: 'IRRF (R$)', dataField: 'valorIRPF', cellsformat:"d2", width: '6%', cellsalign: 'right' },
                        { text: 'Líquido (R$)', dataField: 'valorLiquido', cellsformat:"d2", width: '7%', cellsalign: 'right' },
                        { text: 'Pago', dataField: 'houvePagamento', cellsalign: 'center', width: '5%', filtertype: 'checkedlist', filteritems: ["S", "N"]
                        // ,cellsrenderer(row, columnfield, value) {
                        //     const val = String(value).includes("N")? "Não" : "Sim";
                        //     return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;">${val}</div>`
                        // }
                     },
                    ];

                    this.tableConfig = { source, columns }
                    this.setState({
                        exibir:true
                    })
                })

            }
        )



        this.preActions = [
            {
                //
                render: (key) => {
                    return (
                      <JqxButton key={key} theme="material" width={120} onClick={() => this.irParaPrevisao()} className="py-2 float-right mt-3 mb-3 ml-3">
                          Previsão
                      </JqxButton>
                    )
                }
            }
        ]
    }

    gerarRecibosForm() {
        this.props.history.push('/sgih/app/recibos/gerarRecibos')
    }

    irParaPrevisao() {
        this.props.history.push('/sgih/app/recibos/previsao')
    }

    render() {

        return this.state ? this.state.exibir ?
            <div className={'gerenciamentoRecibo'}>
                <CrudListPageScaffold
                    title="Gerenciamento de recibos"
                    tableConfig={this.tableConfig}
                    baseUrl="/recibos/gerenciamento"
                    history={this.props.history}
                    formLinkTitle="Gerar recibo por estação"
                    tableName="gerenciamentoRecibo"
                    preActions={this.preActions}
                    shouldAddReportFlowButton={false}
                    shouldAddZipButton={true}
                    shouldAddDeleteButton= {true}
                    shouldAddRefreshButton={false}
                    shouldAddOnePDF={true}
                    ehPagamento={true}
                /></div>
            : <></>
            : <></>
    }
}

export default PaginaListaGerenciamentoRecibos;
