import OrigemRelatorio from 'model/OrigemRelatorio';
import StatusRelatorio from 'model/StatusRelatorio';
import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';


class PaginaListaRelatoriosFluviometricos extends Component {
    constructor(props) {
        super(props);


        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                },
                    StatusRelatorio(
                        (statusRelatorio)=>{
                            this.setState({
                                statusRelatorio
                            }, ()=>{
                                const source = {
                                    date: 'dd/MM/yyyy',
                                    datafields: [
                                        { name: 'id', type: 'number', map: 'id' },
                                        { name: 'codigoEstacao', type: 'string', map: 'codigoEstacao' },
                                        { name: 'dataVisita', type: 'date', map: 'dataVisitaEstacao' },
                                        { name: 'roteiro', type: 'number', map: 'roteiro' },
                                        { name: 'tipo', type: 'string', map: 'tipo' },
                                        { name: 'nomeEstacao', type: 'string', map: 'nomeEstacao' },
                                        { name: 'campanha', type: 'date', map: 'campanha' },
                                        { name: 'equipe', type: 'string', map: 'equipe' },
                                        { name: 'dataEnvelopes', type: 'date', map: 'dataEnvelopes' },
                                        { name: 'dataCadernetas', type: 'date', map: 'dataCadernetas' },
                                        { name: 'cota', type: 'string', map: 'cota' },
                                        { name: 'vazao', type: 'number', map: 'vazao' },
                                        { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
                                        { name: 'origem', type: 'string', map: 'origem' },
                                        { name: 'equipeFormatada', type: 'string', map: 'equipeFormatada' },
                                        { name: 'perfilUltimaAlteracaoFluxo', type: 'string', map: 'perfilUltimaAlteracaoFluxo' },
                                    ],
                                    id: 'id',
                                };

                                const columns = [
                                    { text: 'ID', dataField: 'id', width: '3%' },
                                    { text: 'Status Atual', dataField: 'perfilUltimaAlteracaoFluxo', width: '9%', filtertype: 'checkedlist', filteritems: this.state ? this.state.statusRelatorio : [] },
                                    { text: 'Origem', dataField: 'origem', width: '4%', filtertype: 'checkedlist', filteritems: OrigemRelatorio },
                                    { text: 'Roteiro', dataField: 'roteiro', filtertype: 'number', width: '4%' },
                                    { text: 'Regional', dataField: 'unidadeRegional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                                    { text: 'Código', dataField: 'codigoEstacao', width: '7%' },
                                    { text: 'Estação', dataField: 'nomeEstacao', width: '20%' },
                                    { text: 'Tipo', dataField: 'tipo', width: '5%' },
                                    { text: 'Data', dataField: 'dataVisita', filtertype: 'date', cellsformat: 'dd/MM/yyyy', width: '7%' },
                                    { text: 'Campanha', dataField: 'campanha', filtertype: 'date', cellsformat: 'MM/yyyy', width: '5%' },
                                    { text: 'Cadernetas', dataField: 'dataCadernetas', filtertype: 'date', cellsformat: 'MM/yyyy', width: '5%' },
                                    { text: 'Envelopes', dataField: 'dataEnvelopes', filtertype: 'date', cellsformat: 'MM/yyyy', width: '5%' },
                                    { text: 'Cota', dataField: 'cota', width: '4%', cellsalign: 'right' },
                                    { text: 'Vazão', dataField: 'vazao', width: '4%', cellsalign: 'right', cellsformat: 'f4' },
                                    /*{
                                        text: 'Vazão', dataField: 'vazao', width: '4%', cellsalign: 'right',cellsformat: 'f4',
                                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                                            let valorFormatado = value;

                                            if ( value != 0) {
                                                if(value == null || value == undefined || value == "")
                                                    valorFormatado = ""

                                                else if(Number.isInteger(parseFloat(value)))
                                                    valorFormatado = Math.trunc(parseFloat(value));

                                                else{
                                                    const lastIndex = value.toString().lastIndexOf('.');
                                                    const replacement = ',';
                                                    valorFormatado = value.toString().substring(0, lastIndex) + replacement + value.toString().substring(lastIndex + 1);
                                                }
                                            }

                                            return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;" title="${valorFormatado}">${valorFormatado}</div>`;
                                        },
                                    },*/
                                    {
                                        text: 'Equipe', dataField: 'equipe', sortable: false, width: '12.4%',
                                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                                            return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${rowdata.equipeFormatada}">${value}</div>`;
                                        },
                                    },
                                ];

                                this.tableConfig = { source, columns }
                                this.setState({
                                    exibir:true
                                })
                            })

                        }
                    )
                )
            }
        )
    }

    render() {
        return this.state ? this.state.exibir ?
                <CrudListPageScaffold
                    title="Relatórios fluviométricos"
                    tableConfig={this.tableConfig}
                    baseUrl="/relatoriosFluviometricos"
                    history={this.props.history}
                    formLinkTitle="Novo relatório"
                    tableName="relatoriosFlu"
                    shouldAddReportFlowButton={true}
                    shouldAddRefreshButton={true}
                    tipoRelatorio="FLUVIOMETRICO"
                    ehRelatorio={true}
                    urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/fluviometrico"
                />
            : <></>
            : <></>
    }
}

export default PaginaListaRelatoriosFluviometricos;
