export const WGS_84 = {
  viewName: "WGS 84", serializedName: "WGS 84", id:4326
};

export const SIRGAS_2000 = {
  viewName: "SIRGAS 2000", serializedName: "SIRGAS 2000",id:4674
};

export default [
  SIRGAS_2000,WGS_84
];