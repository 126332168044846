import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import SelectField from "../../components/form/field/SelectField";
import TextAreaField from "../../components/form/field/TextAreaField";
import TipoRelatorio, {
  OPERACAOCAMPO,
  TipoRelatorioVeicularMap,
} from "../../model/TipoRelatorioVeicular";
import DateTimeField from "../../components/form/field/DateTimeField/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import FieldLabel from "../../components/form/field/FieldLabel";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import Container from "react-bootstrap/Container";
import createDecorator from "final-form-calculate";

import "./PaginaFormRelatorioVeicular";
import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import { FieldArray } from "react-final-form-arrays";
import "jqwidgets-scripts/jqwidgets/styles/jqx.base.css";
import "jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css";
import PromiseLoader from "components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "components/scaffold/FormPageScaffoldCommon";
import BotaoIconeExclusao from "components/botao/BotaoIconeExclusao/BotaoIconeExclusao";
import BotaoPadrao from "components/botao/BotaoPadrao/BotaoPadrao";
import Fields from "components/form/Fields";
import CheckSetField from "components/form/field/CheckSetField/CheckSetField";
import { isInteger, isNumber, requiredWithNotification } from "util/FormUtils";
import { yesOrNo } from "model/AvaliacaoQualidade";
import FileGridField from "../../components/form/field/FileGridField";
import { connect } from "react-redux";
import { limparArquivos } from "../../reducers/fileReducers";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import MotivoSinistro from "model/MotivoSinistro";
import voltarParaGrid from "util/VoltarParaGrid";
import { JqxDropDownListAdapter } from "components/JqxDropDownListAdapter/JqxDropDownListAdapter";

let dadosEstacao;
let veiculoEncontrado = true;
const calculatedVehicleDecorator = createDecorator(
  {
    // Campo placa do veiculo quando preenchido traz os campos tipo de combustível, modelo e marca.
    field: "veiculoPlaca",
    updates: (value, name, allValues) => {
      if (!value) {
        return {
          idVeiculo: undefined,
          veiculoCombustivel: "",
          veiculoModelo: "",
          veiculoMarca: "",
        };
      }

      return createServiceFor("/relatoriosVeiculares")
        .customURL("GET", `paraVeiculoPorId/${value}`)
        .then((res) => {
          veiculoEncontrado = true;
          const data = res.data;
          dadosEstacao = data;
          return {
            idVeiculo: value,
            veiculoCombustivel: data.combustivel,
            veiculoModelo: data.modelo,
            veiculoMarca: data.marca,
          };
        })
        .catch(() => {
          // Veículo não encontrado (404)
          veiculoEncontrado = false;
          store.dispatch(
            showGlobalNotification({
              message: "Não existe um veículo com a placa " + value,
            })
          );
          return {
            idVeiculo: value,
            veiculoCombustivel: "",
            veiculoModelo: "",
            veiculoMarca: "",
          };
        });
    },
  },
  {
    // Campo Quilometragem Total = Os campos km final – campo km inicial (na aba informações gerais).
    field: ["kmInicial", "kmFinal"],
    updates: {
      ResumoQuilometragemTotal: (ignoredValue, allValues) => {
        if (
          Number.isNaN(Number(allValues.kmFinal)) ||
          Number.isNaN(allValues.kmInicial)
        ) {
          return "";
        }
        return String(allValues.kmFinal - allValues.kmInicial);
      },
    },
  },
  {
    // A soma da coluna de litros da aba abastecimento (tabela abastecimento)
    field: /abastecimentos\[\d+\].litros/,
    updates: {
      ResumoTotalLitros: (ignoredValue, allValues) => {
        if (
          !allValues.abastecimentos ||
          allValues.abastecimentos.some((abastecimento) =>
            Number.isNaN(Number(abastecimento.litros))
          )
        ) {
          return "";
        }
        return String(
          allValues.abastecimentos.reduce(
            (acc, abastecimento) =>
              !Number.isNaN(abastecimento.litros)
                ? acc + Number(abastecimento.litros)
                : acc,
            0
          )
        );
      },
    },
  },
  {
    // Campo Consumo no período = Campo Quilometragem Total / Campo Total de Litros.
    field: ["ResumoQuilometragemTotal", "ResumoTotalLitros"],
    updates: {
      ResumoConsumoNoPeriodo: (ignoredValue, allValues) => {
        if (
          Number.isNaN(
            allValues.ResumoQuilometragemTotal / allValues.ResumoTotalLitros
          )
        ) {
          return "";
        }

        if (
          allValues.ResumoTotalLitros &&
          allValues.ResumoTotalLitros !== "0"
        ) {
          return String(
            Math.trunc(
              (allValues.ResumoQuilometragemTotal /
                allValues.ResumoTotalLitros) *
                100
            ) / 100
          );
        }
        return "";
      },
    },
  },
  {
    field: /abastecimentos\[\d+\].valor/,
    updates: {
      ResumoTotalAbastecimento: (ignoredValue, allValues) => {
        if (
          !allValues.abastecimentos ||
          allValues.abastecimentos.some((abastecimento) =>
            Number.isNaN(Number(abastecimento.valor))
          )
        ) {
          return "";
        }
        let valorTotalAbastecimentos;
        if (allValues.abastecimentos) {
          valorTotalAbastecimentos = allValues.abastecimentos.reduce(
            (acc, abastecimento) => acc + Number(abastecimento.valor),
            0
          );
        }
        return String(Math.trunc(valorTotalAbastecimentos * 100) / 100);
      },
    },
  },
  {
    field: /servicos\[\d+\].valor/,
    updates: {
      ResumoTotalServicos: (ignoredValue, allValues) => {
        if (
          !allValues.servicos ||
          allValues.servicos.some((servico) =>
            Number.isNaN(Number(servico.valor))
          )
        ) {
          return "";
        }
        let valorTotalServicos;
        if (allValues.servicos) {
          valorTotalServicos = allValues.servicos.reduce(
            (acc, servico) => acc + Number(servico.valor),
            0
          );
        }
        return String(Math.trunc(valorTotalServicos * 100) / 100);
      },
    },
  },
  {
    field: "houveSinistro",
    updates: (value, name, allValues) => {
      if (value === "NAO") {
        return {
          descricaoSinistro: "",
        };
      } else return {};
    },
  }
);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;
// const roteiroServicoLabelResolver = (e) => `${e.numeroRoteiroServico}`;
const roteiroServicoLabelResolver = (e) =>
  `${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
// const conductorLabelResolver = (e) => `${e.nome} ${e.numeroCNH || ""}`;
const conductorLabelResolver = (e) => e.nome;
const veiculoLabelResolver = (e) => e.placa || "";

class PaginaFormRelatorioVeicular extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emEdicao: false,
    };
  }

  loadDataFor(id) {
    const promises = [
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/roteirosServico/paraVisualizarRelatorios").query(),
      createServiceFor("/roteirosServico/paraEditarRelatorios").query(),
      createServiceFor("/tiposServico").query(),
      createServiceFor("/veiculos/porUnidadeRegional").query(),
      // Promise.resolve({}), // substituto para debug
    ];

    if (id) {
      promises.push(createServiceFor("/relatoriosVeiculares").get(id));
    }

    const prepararResumo = (relatorio) => {
      let ResumoQuilometragemTotal = "";
      let ResumoTotalLitros = "";
      let ResumoConsumoNoPeriodo = "";
      let ResumoTotalAbastecimento = "";
      let ResumoTotalServicos = "";

      if (relatorio) {
        try {
          if (relatorio.kmInicial && relatorio.kmFinal)
            ResumoQuilometragemTotal = String(
              relatorio.kmFinal - relatorio.kmInicial
            );

          if (relatorio.abastecimentos && relatorio.abastecimentos.length) {
            ResumoTotalLitros = String(
              relatorio.abastecimentos.reduce(
                (acc, abastecimento) =>
                  !Number.isNaN(abastecimento.litros)
                    ? acc + Number(abastecimento.litros)
                    : acc,
                0
              )
            );
            ResumoTotalAbastecimento = String(
              Math.trunc(
                relatorio.abastecimentos.reduce(
                  (acc, abastecimento) => acc + Number(abastecimento.valor),
                  0
                ) * 100
              ) / 100
            );
          }

          if (
            !isNaN(parseFloat(ResumoQuilometragemTotal)) &&
            !isNaN(parseFloat(ResumoTotalLitros))
          ) {
            ResumoConsumoNoPeriodo = String(
              Math.trunc(
                (parseFloat(ResumoQuilometragemTotal) /
                  parseFloat(ResumoTotalLitros)) *
                  100
              ) / 100
            );
          }

          if (relatorio.servicos && relatorio.servicos.length) {
            ResumoTotalServicos = String(
              Math.trunc(
                relatorio.servicos.reduce(
                  (acc, servico) => acc + Number(servico.valor),
                  0
                ) * 100
              ) / 100
            );
          }

          relatorio.ResumoQuilometragemTotal = ResumoQuilometragemTotal;
          relatorio.ResumoTotalLitros = ResumoTotalLitros;
          relatorio.ResumoConsumoNoPeriodo = ResumoConsumoNoPeriodo;
          relatorio.ResumoTotalAbastecimento = ResumoTotalAbastecimento;
          relatorio.ResumoTotalServicos = ResumoTotalServicos;
          relatorio.placa = relatorio.veiculoPlaca;
        } catch (err) {
          console.log("Erro ao preparar resumo: ", err);
        }
      }

      return relatorio;
    };

    return Promise.all(promises)
      .then(
        ([
          condutores,
          roteirosServicoVisualizar,
          roteirosServicoEditar,
          tiposServico,
          veiculosUnidadeRegional,
          relatorio = {},
        ]) => {
          if (roteirosServicoVisualizar) {
            this.setState({
              roteirosServicoVisualizar: roteirosServicoVisualizar.data,
            });
          }

          if (relatorio.data) {
            const dadosRelatorio = relatorio.data;
            this.setState({ dados: dadosRelatorio });
          }

          return {
            condutores: condutores.data, //.sort((a, b) => a.nome.toUpperCase() < b.nome.toUpperCase() ? -1 : (a.nome.toUpperCase() > b.nome.toUpperCase() ? 1 : 0)),
            roteirosServicoVisualizar: roteirosServicoVisualizar.data,
            roteirosServicoEditar: roteirosServicoEditar.data,
            tiposServico: tiposServico.data,
            veiculosUnidadeRegional: veiculosUnidadeRegional.data,
            initialValues: prepararResumo(relatorio.data)
              ? {
                  ...prepararResumo(relatorio.data),
                  veiculoPlaca: relatorio.data?.idVeiculo,
                  roteirosServicoEditar: roteirosServicoEditar.data,
                  veiculosUnidadeRegional: veiculosUnidadeRegional.data,
                }
              : {
                  tipoRelatorio: OPERACAOCAMPO.serializedName,
                  houveSinistro: "NAO",
                  roteirosServicoEditar: roteirosServicoEditar.data,
                  veiculosUnidadeRegional: veiculosUnidadeRegional.data,
                },
          };
        }
      )
      .catch((e) => {
        console.log(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      })
      .finally(() => {
        store.dispatch(stopGlobalLoading());
      });
  }

  async componentDidMount() {
    const id = this.props.match.params.id || null;

    if (id) {
      this.setState({ emEdicao: true });
    }

    const promise = this.loadDataFor(id);

    this.setState({
      promiseDados: promise,
    });

  }

  componentWillUnmount() {
    this.props.limparArquivos();
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));

    // Remover motivoSinistro caso não haja sinistro, evitando erro de parse no backend
    if (values.houveSinistro === "NAO") {
      delete values.motivoSinistro;
    }

    createServiceFor("/relatoriosVeiculares")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  stationTypeIs(values, ...types) {
    return (
      Boolean(values) &&
      Boolean(values.tipoEstacao) &&
      types.some((type) => values.tipoEstacao.includes(type))
    );
  }

  stationTypeNameContainsAnyOf(values, ...typeSubstrings) {
    return (
      Boolean(values) &&
      Boolean(values.tipoEstacao) &&
      typeSubstrings.some((typeSubstring) =>
        values.tipoEstacao.includes(typeSubstring)
      )
    );
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });

      if (veiculoEncontrado) {
        form.submit();
      } else {
        store.dispatch(
          showGlobalNotification({
            message: "Não existe um veículo com a placa informada",
          })
        );
      }
    },
  });

  getCustomInfoSubtitle() {
    const roteiroServicoRelatorio = (this.state?.roteirosServicoVisualizar || [])
      .find((roteiro) => roteiro.id === this.state.dados?.idRoteiro);

    return this.state.emEdicao
      ? `
        <div class="content">
          ${
            this.state.dados?.id
              ? `<div class="text">Relatório veicular</div> - <div class="text">ID: </div>${this.state.dados.id}`
              : ''
          }
          ${
            this.state.dados?.tipoRelatorio
              ? ` - <div class="text">Tipo: </div>${TipoRelatorioVeicularMap[this.state.dados.tipoRelatorio]}`
              : ''
          }
          ${
            this.state.dados?.placa
              ? ` - <div class="text">Placa do Veículo: </div>${this.state.dados?.placa}`
              : ''
          }
          ${
            this.state.dados?.campanha
              ? ` - <div class="text">Campanha: </div>${this.state.dados.campanha}`
              : ''
          }
          ${
            roteiroServicoRelatorio?.unidadeRegional && roteiroServicoRelatorio?.numeroRoteiroServico
              ? ` - <div class="text">Roteiro: </div>${roteiroServicoRelatorio.numeroRoteiroServico} - ${
                roteiroServicoRelatorio.unidadeRegional
              }`
              : ''
          }
        </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Relatórios veiculares"
        baseUrl="/sgih/app/relatoriosVeiculares"
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        tipoRelatorio="VEICULAR"
        dados={this.state.dados}
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={(event) => this.submitForm(event)}
                decorators={[calculatedVehicleDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push, pop },
                  },
                  pristine,
                  form,
                  submitting,
                  values,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Tab.Container
                        className="pb-2"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true
                        )}
                      >
                        <Tab title="Informações gerais">
                          {estaEditando && (
                            <Container fluid>
                              <Row>
                                <Col className="mt-3" md={4}>
                                  <Field
                                    component={TextField}
                                    name="origem"
                                    label="Origem relatório"
                                    readOnly
                                  />
                                </Col>
                              </Row>
                            </Container>
                          )}
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-0" md={4}>
                                <Field
                                  component={CheckSetField}
                                  name="tipoRelatorio"
                                  elements={TipoRelatorio}
                                  label="Tipo de relatório"
                                  validate={requiredWithNotification(
                                    "Tipo de relatório"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="idVeiculo"
                                />
                                <Field
                                  name="veiculoPlaca"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    let list = this.state.emEdicao
                                      ? [
                                          {
                                            id: value,
                                            marca:
                                              data.initialValues.veiculoMarca,
                                            placa: data.initialValues.placa,
                                            tipo: data.initialValues
                                              .veiculoModelo,
                                            combustivel:
                                              data.initialValues
                                                .veiculoTipoCumbustivel,
                                          },
                                        ]
                                      : data.veiculosUnidadeRegional;

                                    return (
                                      <Field
                                        component={SelectField}
                                        name="veiculoPlaca"
                                        label="Placa do veículo"
                                        mask="placaDeCarro"
                                        className="w-100"
                                        valueResolver={identifierValueResolver}
                                        labelResolver={veiculoLabelResolver}
                                        elements={list}
                                        buscavel={true}
                                        validate={requiredWithNotification(
                                          "Placa do veículo"
                                        )}
                                        required
                                        disabled={
                                          this.state.emEdicao
                                            ? true
                                            : disableAllFields
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="veiculoCombustivel"
                                  label="Combustível"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="veiculoModelo"
                                  label="Modelo"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={TextField}
                                  name="veiculoMarca"
                                  label="Marca"
                                  readOnly={true}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="idsCondutores"
                                  component={JqxDropDownListAdapter}
                                  source={data.condutores}
                                  label="Condutores"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={conductorLabelResolver}
                                  required
                                  checkboxes={true}
                                  displayMember="nome"
                                  valueMember="id"
                                  placeHolder="Selecione o(s) condutor(es)..."
                                  searchMode="startswithignorecase"
                                  enableSelection={true}
                                  enableHover={true}
                                  filterable={true}
                                  incrementalSearch={true}
                                  animationType="none"
                                  closeDelay={0}
                                  filterPlaceHolder=" Busque"
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Condutores")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={SelectField}
                                  name="idRoteiro"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={roteiroServicoLabelResolver}
                                  label="Roteiro"
                                  elements={
                                    disableAllFields
                                      ? data.roteirosServicoVisualizar
                                      : data.roteirosServicoEditar
                                  }
                                  validate={requiredWithNotification("Roteiro")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  name="kmFinal"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={NumberField}
                                      name="kmInicial"
                                      label="Km inicial"
                                      step={1}
                                      max={999999999}
                                      semVirgula={true}
                                      validate={() => {
                                        if (
                                          !form.getState().values?.kmInicial
                                        ) {
                                          return "O campo Km inicial é obrigatório";
                                        }
                                        if (
                                          form.getState().values?.kmInicial &&
                                          form.getState().values?.kmFinal
                                        ) {
                                          if (
                                            form.getState().values?.kmInicial >
                                            form.getState().values?.kmFinal
                                          ) {
                                            return `O campo Km inicial não pode ser maior que ${
                                              form.getState().values?.kmFinal
                                            }`;
                                          }
                                        }
                                      }}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  name="kmInicial"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={NumberField}
                                      name="kmFinal"
                                      label="Km final"
                                      max={999999999}
                                      semVirgula={true}
                                      min={0}
                                      validate={() => {
                                        if (
                                          form.getState().values?.kmFinal &&
                                          form.getState().values?.kmInicial
                                        ) {
                                          if (
                                            form.getState().values?.kmFinal <
                                            form.getState().values?.kmInicial
                                          ) {
                                            return `O campo Km final não pode ser menor que ${
                                              form.getState().values?.kmInicial
                                            }`;
                                          }
                                        }
                                      }}
                                      step={1}
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-4" md={2}>
                                <Field
                                  name="dataRetorno"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataSaida"
                                      label="Data de saída"
                                      className="form-control"
                                      selectsStart={!!value}
                                      endDate={value}
                                      validate={requiredWithNotification(
                                        "Data de saída"
                                      )}
                                      required
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-4" md={2}>
                                <Field
                                  name="dataSaida"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataRetorno"
                                      label="Data de retorno"
                                      className="form-control"
                                      selectsEnd={!!value}
                                      startDate={value}
                                      disabled={disableAllFields}
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-4" md={4}>
                                <Field
                                  component={DateTimeField}
                                  name="campanha"
                                  label="Campanha"
                                  datePickerType="yearMonth"
                                  className="form-control"
                                  validate={requiredWithNotification(
                                    "Campanha"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Abastecimentos e serviços">
                          <Row>
                            <Col className="pr-0" md={5}>
                              <Row>
                                <Col className="pr-0" md={12}>
                                  <FormSectionWrapper title="Troca de óleo">
                                    <Row>
                                      <Col className="mt-3 pr-1" md={3}>
                                        <Fields
                                          subscription={{ value: true }}
                                          names={["dataSaida", "dataRetorno"]}
                                        >
                                          {({
                                            valores: { dataSaida, dataRetorno },
                                          }) => {
                                            return (
                                              <>
                                                <Field
                                                  component={DateTimeField}
                                                  name="trocaDeOleoData"
                                                  label="Data"
                                                  minDate={dataSaida}
                                                  maxDate={dataRetorno}
                                                  disabled={disableAllFields}
                                                />
                                              </>
                                            );
                                          }}
                                        </Fields>
                                      </Col>
                                      <Col className="mt-3 pl-1 pr-1" md={3}>
                                        <Fields
                                          subscription={{ value: true }}
                                          names={["kmInicial", "kmFinal"]}
                                        >
                                          {({
                                            valores: { kmInicial, kmFinal },
                                          }) => (
                                            <Field
                                              component={NumberField}
                                              name="trocaDeOleoQuilometragem"
                                              label="KM"
                                              min={kmInicial || 0}
                                              max={999999999}
                                              semVirgula={true}
                                              validate={isInteger(
                                                "KM",
                                                false,
                                                kmInicial
                                              )}
                                              disabled={disableAllFields}
                                            />
                                          )}
                                        </Fields>
                                      </Col>
                                      <Col className="mt-3 pl-1 pr-1" md={3}>
                                        <Field
                                          component={NumberField}
                                          name="trocaDeOleoLitros"
                                          label="Litros"
                                          step={0.01}
                                          max={99999999.99}
                                          decimaisAposvirgula={2}
                                          validate={isNumber(
                                            "Litros",
                                            false,
                                            0,
                                            9999999999
                                          )}
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                      <Col className="mt-3 pl-1" md={3}>
                                        <Field
                                          component={NumberField}
                                          name="trocaDeOleoValor"
                                          label="Valor (R$)"
                                          step={0.01}
                                          max={99999999.99}
                                          decimaisAposvirgula={2}
                                          validate={isNumber(
                                            "Valor",
                                            false,
                                            0,
                                            99999999.99
                                          )}
                                          disabled={disableAllFields}
                                        />
                                      </Col>
                                    </Row>
                                  </FormSectionWrapper>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="pr-0" md={12}>
                                  <FormSectionWrapper title="Abastecimentos">
                                    <Row>
                                      <Col className="mt-0 pl-3" md={3}>
                                        <FieldLabel label="Data"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pl-1" md={2}>
                                        <FieldLabel label="KM"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pl-1" md={2}>
                                        <FieldLabel label="Litros"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pl-1" md={2}>
                                        <FieldLabel label="Valor (R$)"></FieldLabel>
                                      </Col>
                                      <Col
                                        className="mt-0 pl-1 pr-1"
                                        md={1}
                                      ></Col>
                                    </Row>

                                    <FieldArray name="abastecimentos">
                                      {({ fields, meta }) =>
                                        fields.map((name, index) => {
                                          return (
                                            <Row key={index}>
                                              <Col
                                                className="mt-1 pr-1 pl-3"
                                                md={3}
                                              >
                                                <Fields
                                                  subscription={{ value: true }}
                                                  names={[
                                                    "dataSaida",
                                                    "dataRetorno",
                                                  ]}
                                                >
                                                  {({
                                                    valores: {
                                                      dataSaida,
                                                      dataRetorno,
                                                    },
                                                  }) => {
                                                    return (
                                                      <>
                                                        <Field
                                                          component={
                                                            DateTimeField
                                                          }
                                                          name={`${name}.data`}
                                                          className="form-control"
                                                          aria-label="Data"
                                                          datePickerType="date"
                                                          minDate={dataSaida}
                                                          maxDate={dataRetorno}
                                                          label=""
                                                          disabled={
                                                            disableAllFields
                                                          }
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Fields>
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Fields
                                                  subscription={{ value: true }}
                                                  names={[
                                                    "kmInicial",
                                                    "kmFinal",
                                                  ]}
                                                >
                                                  {({
                                                    valores: {
                                                      kmInicial,
                                                      kmFinal,
                                                    },
                                                  }) => (
                                                    <Field
                                                      component={NumberField}
                                                      name={`${name}.quilometragem`}
                                                      aria-label="Quilometragem"
                                                      className="form-control"
                                                      min={kmInicial || 0}
                                                      max={999999999}
                                                      semVirgula={true}
                                                      validate={isInteger(
                                                        "KM",
                                                        false,
                                                        kmInicial
                                                      )}
                                                      label=""
                                                      disabled={
                                                        disableAllFields
                                                      }
                                                    />
                                                  )}
                                                </Fields>
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Field
                                                  component={NumberField}
                                                  name={`${name}.litros`}
                                                  aria-label="Litros"
                                                  className="form-control"
                                                  step={0.01}
                                                  max={99999999.99}
                                                  decimaisAposvirgula={2}
                                                  validate={isNumber(
                                                    "Litros",
                                                    false,
                                                    0,
                                                    99999999999
                                                  )}
                                                  label=""
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Field
                                                  component={NumberField}
                                                  name={`${name}.valor`}
                                                  aria-label="Valor (R$)"
                                                  className="form-control"
                                                  step={0.01}
                                                  max={999999999.99}
                                                  decimaisAposvirgula={2}
                                                  validate={isNumber(
                                                    "Valor",
                                                    false,
                                                    0,
                                                    99999999999
                                                  )}
                                                  label=""
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col className="pr-1" md="auto">
                                                {disableAllFields ? null : (
                                                  <BotaoIconeExclusao
                                                    onClick={() =>
                                                      fields.remove(index)
                                                    }
                                                  />
                                                )}
                                              </Col>
                                            </Row>
                                          );
                                        })
                                      }
                                    </FieldArray>

                                    <Row>
                                      <Col className="mt-3" md={3}>
                                        {disableAllFields ? null : (
                                          <BotaoPadrao
                                            onClick={() => {
                                              if (
                                                !form.getState().values
                                                  .abastecimentos ||
                                                form.getState().values
                                                  .abastecimentos.length < 100
                                              ) {
                                                push("abastecimentos", {});
                                              }
                                            }}
                                          >
                                            Adicionar
                                          </BotaoPadrao>
                                        )}
                                      </Col>
                                    </Row>
                                  </FormSectionWrapper>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={7}>
                              <Row>
                                <Col className="pl-0" md={12}>
                                  <FormSectionWrapper title="Serviços">
                                    <Row>
                                      <Col className="mt-0 pr-1" md={2}>
                                        <FieldLabel label="Data"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pr-1 pl-1" md={3}>
                                        <FieldLabel label="Tipo de serviço"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pr-1 pl-1" md={2}>
                                        <FieldLabel label="Número da nota"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pr-1 pl-1" md={2}>
                                        <FieldLabel label="Empresa"></FieldLabel>
                                      </Col>
                                      <Col className="mt-0 pr-1 pl-1" md={2}>
                                        <FieldLabel label="Valor (R$)"></FieldLabel>
                                      </Col>
                                    </Row>

                                    <FieldArray name="servicos">
                                      {({ fields, meta }) =>
                                        fields.map((name, index) => {
                                          return (
                                            <Row key={index}>
                                              <Col className="mt-1 pr-1" md={2}>
                                                <Fields
                                                  subscription={{ value: true }}
                                                  names={[
                                                    "dataSaida",
                                                    "dataRetorno",
                                                  ]}
                                                >
                                                  {({
                                                    valores: {
                                                      dataSaida,
                                                      dataRetorno,
                                                    },
                                                  }) => {
                                                    return (
                                                      <>
                                                        <Field
                                                          component={
                                                            DateTimeField
                                                          }
                                                          name={`${name}.data`}
                                                          aria-label="Data"
                                                          datePickerType="date"
                                                          minDate={dataSaida}
                                                          maxDate={dataRetorno}
                                                          disabled={
                                                            disableAllFields
                                                          }
                                                        />
                                                      </>
                                                    );
                                                  }}
                                                </Fields>
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={3}
                                              >
                                                <Field
                                                  component={SelectField}
                                                  name={`${name}.tipoDeServico`}
                                                  valueResolver={
                                                    identifierValueResolver
                                                  }
                                                  labelResolver={
                                                    descriptionLabelResolver
                                                  }
                                                  aria-label="Tipo de serviço"
                                                  elements={data.tiposServico}
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Field
                                                  component={TextField}
                                                  name={`${name}.numeroDaNota`}
                                                  aria-label="Número da nota"
                                                  maxLength={50}
                                                  label=""
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Field
                                                  component={TextField}
                                                  name={`${name}.empresa`}
                                                  aria-label="Empresa"
                                                  maxLength={200}
                                                  label=""
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col
                                                className="mt-1 pr-1 pl-1"
                                                md={2}
                                              >
                                                <Field
                                                  component={NumberField}
                                                  name={`${name}.valor`}
                                                  aria-label="Valor (R$)"
                                                  step={0.01}
                                                  max={99999999.99}
                                                  decimaisAposvirgula={2}
                                                  validate={isNumber(
                                                    "Valor",
                                                    false,
                                                    0,
                                                    9999999999
                                                  )}
                                                  label=""
                                                  disabled={disableAllFields}
                                                />
                                              </Col>
                                              <Col md="auto">
                                                {disableAllFields ? null : (
                                                  <BotaoIconeExclusao
                                                    onClick={() =>
                                                      fields.remove(index)
                                                    }
                                                  />
                                                )}
                                              </Col>
                                            </Row>
                                          );
                                        })
                                      }
                                    </FieldArray>

                                    <Row>
                                      <Col className="mt-3" md={2}>
                                        {disableAllFields ? null : (
                                          <BotaoPadrao
                                            onClick={() => {
                                              if (
                                                !form.getState().values
                                                  .servicos ||
                                                form.getState().values.servicos
                                                  .length < 100
                                              ) {
                                                push("servicos", {});
                                              }
                                            }}
                                          >
                                            Adicionar
                                          </BotaoPadrao>
                                        )}
                                      </Col>
                                    </Row>
                                  </FormSectionWrapper>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Tab>

                        <Tab title="Solicitação de reparos e serviços">
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-0" md={12}>
                                <Field
                                  component={TextAreaField}
                                  name="comentarios"
                                  label="Observações e comentários"
                                  rows="7"
                                  maxLength={500}
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Sinistro">
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-0" md={2}>
                                <Field
                                  component={CheckSetField}
                                  name="houveSinistro"
                                  elements={yesOrNo}
                                  label="Houve sinistro?"
                                  validate={requiredWithNotification(
                                    "Houve sinistro"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  subscription={{ value: true }}
                                  name={"houveSinistro"}
                                >
                                  {({ input: { value } }) => {
                                    const houveSinistro = value === "SIM";
                                    return (
                                      <Field
                                        component={SelectField}
                                        name="motivoSinistro"
                                        label="Motivo do sinistro"
                                        elements={MotivoSinistro}
                                        disabled={
                                          disableAllFields || !houveSinistro
                                        }
                                        validate={() => {
                                          if (
                                            form.getState().values
                                              .houveSinistro === "SIM"
                                          ) {
                                            if (
                                              !form.getState().values
                                                ?.motivoSinistro ||
                                              form.getState().values
                                                ?.motivoSinistro === ""
                                            ) {
                                              return "O campo Motivo do sinistro é obrigatório";
                                            }
                                          }
                                        }}
                                        required={
                                          houveSinistro ? true : undefined
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  subscription={{ value: true }}
                                  name={"houveSinistro"}
                                >
                                  {({ input: { value } }) => {
                                    const houveSinistro = value === "SIM";
                                    return (
                                      <Field
                                        component={TextField}
                                        maxLength={500}
                                        name="descricaoSinistro"
                                        label="Protocolo"
                                        disabled={
                                          disableAllFields || !houveSinistro
                                        }
                                        validate={() => {
                                          if (
                                            form.getState().values
                                              .houveSinistro === "SIM"
                                          ) {
                                            if (
                                              !form.getState().values
                                                ?.descricaoSinistro ||
                                              form.getState().values
                                                ?.descricaoSinistro === ""
                                            ) {
                                              return "O campo Protocolo é obrigatório";
                                            }
                                          }
                                        }}
                                        required={
                                          houveSinistro ? true : undefined
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Fotos Manutenção">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <div
                                className="text-center"
                                style={{ color: "red" }}
                              >
                                <p>
                                  {" "}
                                  Permitido o cadastro de, no máximo, 6 fotos de
                                  manutenção.{" "}
                                </p>
                              </div>
                              <FileGridField
                                campoNovosArquivos="hashesNovasFotosManutencao"
                                campoArquivosExistentes="idsFotosExistentesManutencao"
                                chaveListaArquivos="fotosVeicularManutencao"
                                bloquearTipos={true}
                                permitirImagens={true}
                                permitirVideos={true}
                                limiteArquivos={6}
                                disable={disableAllFields}
                              />
                            </div>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Fotos Comprovantes">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovasFotosComprovantes"
                                campoArquivosExistentes="idsFotosExistentesComprovantes"
                                chaveListaArquivos="fotosVeicularComprovantes"
                                bloquearTipos={true}
                                permitirImagens={true}
                                permitirVideos={true}
                                disable={disableAllFields}
                              />
                            </div>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Resumo">
                          <FormSectionWrapper>
                            <Row className="justify-content-md-center">
                              <Col md={6}>
                                <Row>
                                  <Col className="mt-0" md={6}>
                                    <Field
                                      component={NumberField}
                                      name="ResumoQuilometragemTotal"
                                      label="Quilometragem total"
                                      readOnly={true}
                                      decimaisAposvirgula={2}
                                    />
                                  </Col>
                                  <Col className="mt-0" md={6}>
                                    <Field
                                      component={NumberField}
                                      name="ResumoTotalLitros"
                                      label="Total de litros"
                                      readOnly={true}
                                      decimaisAposvirgula={2}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={6}>
                                    <Field
                                      component={NumberField}
                                      name="ResumoTotalAbastecimento"
                                      label="Total em abastecimento (R$)"
                                      readOnly={true}
                                      decimaisAposvirgula={2}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={6}>
                                    <Field
                                      component={NumberField}
                                      name="ResumoConsumoNoPeriodo"
                                      label="Consumo no período (km/L)"
                                      readOnly={true}
                                      decimaisAposvirgula={2}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mt-3" md={6}>
                                    <Field
                                      component={TextField}
                                      name="ResumoTotalServicos"
                                      label="Total de serviços (R$)"
                                      readOnly={true}
                                      decimaisAposvirgula={2}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={6}>
                                    {/* <Field component={TextField} name='ResumoTotalTrocaDeOleo'
                                                                label='Total Troca de Óleo (R$)' readOnly={true} /> */}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

// export default PaginaFormRelatorioVeicular;
export default connect(null, (dispatch) => ({
  limparArquivos() {
    dispatch(limparArquivos({ store: "fotosVeicularManutencao" }));
    dispatch(limparArquivos({ store: "fotosVeicularComprovantes" }));
  },
  dispatch,
}))(PaginaFormRelatorioVeicular);
