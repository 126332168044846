export const ATIVO = {
    viewName: "Ativo", serializedName: "ATIVO", id: 13
};

export const INATIVO = {
    viewName: "Inativo", serializedName: "INATIVO", id : 47
};

export const SUSPENSO = {
    viewName: "Suspenso", serializedName: "SUSPENSO", id: 15
};

export const StatusIdViewMap = {
    [ATIVO.id]: ATIVO.viewName,
    [INATIVO.id]: INATIVO.viewName,
    [SUSPENSO.id]: SUSPENSO.viewName,
};

export const StatusIdSerializedMap = {
    [ATIVO.id]: ATIVO.serializedName,
    [INATIVO.id]: INATIVO.serializedName,
    [SUSPENSO.id]: SUSPENSO.serializedName,
};

export const StatusSerializedViewMap = {
    [ATIVO.serializedName]: ATIVO.viewName,
    [INATIVO.serializedName]: INATIVO.viewName,
    [SUSPENSO.serializedName]: SUSPENSO.viewName,
};

export const StatusSerializedIdMap = {
    [ATIVO.serializedName]: ATIVO.id,
    [INATIVO.serializedName]: INATIVO.id,
    [SUSPENSO.serializedName]: SUSPENSO.id,
};

export default [
    ATIVO, INATIVO, SUSPENSO
];