import React from "react";
import PropTypes from "prop-types";
import PageScaffold from "./PageScaffold";
import createServiceFor, { objetoParaParams } from "../../service/Service";
import { showGlobalNotification, startGlobalLoading, stopGlobalLoading } from "reducers";
import store from "store";
import { Row } from "react-bootstrap";
import swal from "sweetalert";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import { connect } from "react-redux";
import { extrairPermissoes } from "../../reducers/authReducers";
import {Col} from "react-bootstrap";
import { construirBotoesForm } from "./FormPageScaffoldCommon";
import FormsButton from "components/botao/FormsButton/FormsButton";
import voltarParaGrid from "util/VoltarParaGrid";
function create_UUID() {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }


const createIconButton = (
    iconClasses,
    onClick,
    ref,
    botaoAtivo,
    nomePerfil
  ) => (row, column, value, htmlElement) => {
    try {
      if (htmlElement.firstChild) {
        htmlElement.firstChild.remove();
      }

      const button = document.createElement("button");
      button.classList.add("btn");

      const iconEl = document.createElement("i");

      //Verifica se é o botão de alterar fluxo
      if (iconClasses[0] === "fa-stream") {
        // A linha muda para o índice da linha após o primeiro renderizacao.
        var statusAtual = row;
        if (typeof row === "object") {
          statusAtual = row.bounddata.perfilUltimaAlteracaoFluxo;
        } else if (typeof row === "number") {
          statusAtual = ref.current.getcellvalue(row, "perfilUltimaAlteracaoFluxo");
        } else {
          console.warn(`Unknown id for entity [row type=${typeof row}]`);
        }
        //Verifica se o status atual é igual ao perfil do usuario logado
        if (statusAtual === nomePerfil) {
          //altera a classe para danger
          iconEl.classList.add("fas", "fa-stream", "text-danger");
        } else {
          // segue o padrao anterior
          iconEl.classList.add("fas", ...iconClasses);
        }
      } else {
        //segue o padrao anterior
        iconEl.classList.add("fas", ...iconClasses);
      }
      button.appendChild(iconEl);

      htmlElement.appendChild(button);

      // Row changes to the line index after the first redraw. we need to fix this.
      let id = row;
      if (typeof row === "object") {
        id = row.bounddata.id;
      } else if (typeof row === "number") {
        id = ref.current.getcellvalue(row, "id");
      } else {
        console.warn(`Unknown id for entity [row type=${typeof row}]`);
      }

      const linha = ref.current.getrowdatabyid(value);
      const deveAtivar = !botaoAtivo || botaoAtivo(linha);

      if (deveAtivar) {
        button.addEventListener("click", () => onClick(id));
      } else {
        iconEl.classList.add("text-disabled");
      }
    }
    catch(error) {}
  };


  const createControlColumn = (
    onClick,
    iconClasses,
    dataField,
    ref,
    botaoAtivo,
    nomePerfil
  ) => ({
    text: "",
    menu: false,
    width: 40,
    dataField: dataField,
    createwidget: createIconButton(
      iconClasses,
      onClick,
      ref,
      botaoAtivo,
      nomePerfil
    ),
    initwidget: createIconButton(
      iconClasses,
      onClick,
      ref,
      botaoAtivo,
      nomePerfil
    ),
    sortable: false,
    resizable: false,
  });

class CrudFormPageScaffold extends React.Component {
  constructor(props) {
      super(props);

      document.title = this.props.baseTitle ? `SGIH - ${this.props.baseTitle}` : 'SGIH';

      this.preActions = Array.from(new Set(this.props.preActions)) || [];

      this.actions = this.props.actions || [];

      this.state = {
        isPrintModalOpen: false,
        currentId: null,
        printSimple: false,
        key: create_UUID(),
      };
      this.id = this.props.match.params.id;

      if (this.props.history.location.state) {
          this.allowEdit = this.props.history.location.state.allowEdit;
      }

      if ('undefined' === typeof this.allowEdit) {
          this.allowEdit = true;
      }

      if (this.props.dynamicTitle) {
          if (this.id) {
              if (this.allowEdit) {
                  this.title = this.props.editTitle || "Edição de " + this.props.baseTitle;
              } else {
                  this.title = this.props.viewTitle || "Visualização de " + this.props.baseTitle;
              }
          } else {
              this.title = this.props.createTitle || "Cadastro de " + this.props.baseTitle;
          }
      } else {
          this.title = this.props.baseTitle;
      }
  }

  componentDidMount() {
    if (this.props.history.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.history.location.search);

      if (urlSearchParams.has('d')) {
        const encodedDataQueryParamJson = urlSearchParams.get('d');

        try {
          const decodedDataQueryParamJson = window.decodeURIComponent(encodedDataQueryParamJson);
          const decryptedDataQueryParamJson = window.atob(decodedDataQueryParamJson);
          const dataQueryParamObject = JSON.parse(decryptedDataQueryParamJson);

          this.props.history.replace({
              path: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
              state: dataQueryParamObject,
          });

          if (this.props.history.location.state?.data) {
            this.setState({
              data: this.props.history.location.state.data
            });
          }
        } catch (_ignoredError) { }
      }
    }

    if (this.props.permissoes.permitirVisualizarAprovacaoRapida == true) {

      if (this.props.permissoes.permitirFluxoRelatorio && this.props.ehRelatorio) {
        if (this.props.tipoRelatorio === "PRODUCAO_CAMPO") {
          if (!this.props.permissoes.permitirEnviarRelatoriosEmBloco && this.props.history?.location?.state?.allowEdit) {
            this.preActions.unshift({
              title: "Aprovação rápida",
              onClick: () => this.aprovacaoRapida(),
            });
          }
        } else {
          if (this.props.history?.location?.state?.allowEdit) {
            this.preActions.unshift({
              title: "Aprovação rápida",
              onClick: () => this.aprovacaoRapida(),
            });
          }
        }

      }
    }
  }

  render() {
    const {children, ...rest} = this.props;

    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields = (
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit
    );

    const subHeaderClass = this.props.ehRelatorio
      ? 'sub-header-reports'
      : 'sub-header-register'

    return <>
      <PageScaffold
        title={this.title}
        subHeaderClass={subHeaderClass}
        {...rest}
      >
        <Row
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '20px',
            alignContent:'center',
            justifyItems:'center',
            marginTop: '-16px !important',
            ...(this.props.ehRelatorio
              ? {  }
              : {}
            )
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems:'center',
            }}
          >
            {estaEditando
              ? <div>{this.preActions && this.preActions.map((ac, i) => this.buildAction(ac, i))}</div>
              : <></>
            }
            {this.props.tipoRelatorio
              ? (
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <FormsButton
                    style={{display: 'flex', justifyContent: 'flex-end'}}
                    estaVisualizando={disableAllFields}
                    estaEditando={estaEditando}
                    tipo={this.props.tipoRelatorio}
                  ></FormsButton>
                </div>
              )
              : <></>
            }
          </div>
        </Row>
        {children}
        {this.actions && this.actions.map((ac, i) => this.buildAction(ac, i))}
      </PageScaffold>
    </>;
  }

  buildAction(action, key) {
    return action ? (
      action.render ? (
        action.render(key, this.props.wrappedComponentRef)
      ) : (
          <JqxButton
            key={key}
            theme="material"
            width={120}
            onClick={action.onClick}
            className="py-2 float-right mt-3 mb-3 ml-3"
          >
            {action.title}
          </JqxButton>
        )
    ) : null;
  }

  aprovacaoRapida() {
    let permissao = false;
    if (!this.props.permissoes.idPerfilFluxoPadrao) {
      swal({ title:"Seu perfil de usuário não possui um fluxo padrão definido para o relatório selecionado", icon: "info",
        buttons: { confirm: { text: "OK", className: "btn-primary", }, }, });

      return;
    }

    if (this.state.data?.perfilUltimaAlteracaoFluxo === this.props.permissoes.nomePerfil) {
      permissao = true;
    }

    if (permissao== false) {
      swal({
        title:`Selecione somente relatórios com status "${this.props.permissoes.nomePerfil}"`,
        icon: "info",
        buttons: {
          confirm: {
            text: "OK",
            className: "btn-primary",
          },
        },
      });
      return;
    }

    swal({
      title: `Confirma a mudança no status do relatório?`,
      icon: "warning",
      buttons: {
        cancel: "Não",
        confirmar: {
          text: "Sim",
          className: "btn-success",
        },
      },
      dangerMode: true,
    }).then(async (resultado) => {
      if (resultado != 'confirmar') {
        return;
      }

      store.dispatch(startGlobalLoading(`Salvando mudança de status do relatório...`));

      try {
        let body = {
          codigoEstacaoFluxo: this.props.dados.codigo,
          idPerfil: this.props.permissoes.idPerfilFluxoPadrao.toString(),
          idRelatorio: this.state.data?.uid.toString(),
          nomeEstacaoFluxo: this.props.dados.nome,
          tipoRelatorio: this.props.tipoRelatorio,
          descricao: 'Relatório aprovado.'
        };

        await createServiceFor("/fluxoRelatorios").customURL('POST', '', body);

      } catch(err){
        console.log('Erro ao enviar relatorio', err);
      }

      store.dispatch(stopGlobalLoading());
      voltarParaGrid({
        message: `Relatório salvo com sucesso!`,
        type: "success",
      });
    });
  }
}

CrudFormPageScaffold.propTypes = {
  baseTitle: PropTypes.string.isRequired,
  editTitle: PropTypes.string,
  viewTitle: PropTypes.string,
  createTitle: PropTypes.string,

  children: PropTypes.node,
  dynamicTitle: PropTypes.bool,

  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

CrudFormPageScaffold.defaultProps = {
  dynamicTitle: false,
};

export default connect(
  (state) => ({
    permissoes: extrairPermissoes(state),
  }),
  (dispatch) => ({
    dispararNotificacao(config) {
      dispatch(showGlobalNotification(config));
    },
  })
)(CrudFormPageScaffold);
