import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaPerfil extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: 'id', type: 'number', map: "id" },
        { name: "nome", type: "string", map: "nome" },
        { name: "numeroUsuarios", type: "number", map: "numeroUsuarios" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: 55 },
      { text: "Nome", dataField: "nome" },
      { text: "Número de usuários", dataField: "numeroUsuarios", width: 135 },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Perfis"
        tableConfig={this.tableConfig}
        baseUrl="/perfis"
        history={this.props.history}
        shouldAddZipButton={false}
        shouldAddPrintButton={false}
        deleteErrorMessage="Este perfil é referenciado por outros perfis ou usuários. Não é possível apagá-lo antes de remover estas dependências."
        formLinkTitle="Novo perfil"
        tableName="perfil"
      />
    );
  }
}

export default PaginaListaPerfil;
