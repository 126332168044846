import React from "react";
import PropTypes from "prop-types";
import { defaultProps, propTypes } from "../formFieldCommonProps";
import Error from "../../Error";
import CheckField from "../CheckField";

import styles from "./CheckSetField.module.css"

class CheckSetField extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                labelsAndValues: this.updateLabelsAndValues(),
            };
        }

        render() {
                const name = this.props.name || (this.props.input && this.props.input.name);
                const value = this.props.value || (this.props.input && this.props.input.value);

                return < >
                    <
                    fieldset className = { styles.fieldSet } > {
                        this.props.label && < legend className = { styles.legend } > { this.props.label } {
                            this.props.required && < span style = {
                                    { color: 'red' } } > * < /span>} <
                                /legend>} {
                                    this.state.labelsAndValues && Object.values(this.state.labelsAndValues).map((labelAndValue, i) =>
                                        <
                                        CheckField key = { i }
                                        label = { labelAndValue.label }
                                        type = { this.props.type }
                                        showError = { false }
                                        name = { name }
                                        inline = { true }
                                        onChange = { labelAndValue.onChange }
                                        checked = { value === labelAndValue.value }
                                        disabled = { this.props.disabled }
                                        />
                                    )
                                } <
                                /fieldset> {
                                    this.props.showError &&
                                        <
                                        Error name = { name }
                                    />
                                } <
                                />;
                        }

                        elementSelected(idx, event) {
                            const value = this.props.callbackWithOriginalValue ?
                                this.state.labelsAndValues[idx]._original :
                                this.state.labelsAndValues[idx].value;

                            if (this.props.input) {
                                this.props.input.onChange(value);
                            }
                            if (this.props.onChange) {
                                this.props.onChange(value);
                            }
                        }

                        updateLabelsAndValues() {
                            const labelsAndValues = {};
                            if (!this.props.elements) return labelsAndValues;

                            for (let i = 0; i < this.props.elements.length; i++) {
                                const e = this.props.elements[i];
                                const label = this.props.labelResolver(e);
                                const value = this.props.valueResolver(e);

                                if (!label) {
                                    console.warn("Elemento sem label!");
                                    console.warn(e);
                                }

                                if (!value) {
                                    console.warn("Elemento sem valor!");
                                    console.warn(e);
                                }

                                labelsAndValues[i] = {
                                    label,
                                    value,
                                    onChange: function(event) {
                                        this.elementSelected(i, event)
                                    }.bind(this),
                                    _original: e,
                                };
                            }
                            return labelsAndValues;
                        }

                        componentDidUpdate(previousProps) {
                            if (previousProps.elements !== this.props.elements) {
                                this.setState({
                                    labelsAndValues: this.updateLabelsAndValues(),
                                });
                            }
                        }
                    }

                CheckSetField.propTypes = {
                    ...propTypes,
                    type: PropTypes.oneOf(["checkbox", "radio"]),
                    label: PropTypes.string,
                    onChange: PropTypes.func,

                    callbackWithOriginalValue: PropTypes.bool,
                    defaultValue: PropTypes.any,

                    elements: PropTypes.arrayOf(PropTypes.any),
                    valueResolver: PropTypes.func,
                    labelResolver: PropTypes.func,
                };

                CheckSetField.defaultProps = {
                    ...defaultProps,

                    valueResolver: (e) => e.serializedName,
                    labelResolver: (e) => e.viewName,
                    callbackWithOriginalValue: false,

                    type: "radio",
                };

                export default CheckSetField;