import { createAutoWindowClosingDecorator } from './decorators/AutoClosingWindow';
import { dispatchGlobalNotification } from './notificationDispatcher';


function voltarParaGrid(notificationOptions) {
    let url = window.location.href;
    let corte = url.lastIndexOf('/');
    let novaURL = url.substring(0, corte);
    let partes = url.split('/');

    if(partes.find(i => i === 'gerarRecibos'))
        novaURL += '/gerenciamento';
    else if(partes.find(i => i === 'fluxoRelatorios')){
        let tipoRelatorioIndex = partes.lastIndexOf('fluxoRelatorios') + 1;
        let tipoRelatorio = partes[tipoRelatorioIndex];

        switch(tipoRelatorio) {
            case 'fluviometrico': {
                novaURL = novaURL.replace('fluxoRelatorios/fluviometrico', 'relatoriosFluviometricos');
                break;
            }
            case 'pluviometrico': {
                novaURL = novaURL.replace('fluxoRelatorios/pluviometrico', 'relatoriosPluviometricos');
                break;
            }
            case 'producao': {
                novaURL = novaURL.replace('fluxoRelatorios/producao', 'relatoriosProducaoCampo');
                break;
            }
            case 'qualidadeAgua': {
                novaURL = novaURL.replace('fluxoRelatorios/qualidadeAgua', 'relatoriosQualidadeAgua');
                break;
            }
            case 'veicular': {
                novaURL = novaURL.replace('fluxoRelatorios/veicular', 'relatoriosVeiculares');
                break;
            }
            case 'pcd': {
                novaURL = novaURL.replace('fluxoRelatorios/pcd', 'relatoriosPCD');
                break;
            }
        }
    }

    if(notificationOptions) {
        localStorage.setItem('MENSAGEM', JSON.stringify(notificationOptions));
    }

    window.location.assign(novaURL);
}

export default createAutoWindowClosingDecorator(
    voltarParaGrid,
    dispatchGlobalNotification
);