export const OPERACAOCAMPO = {
    viewName: "Operação de campo", serializedName: "OPERACAO_DE_CAMPO",
};

export const MANUTENCAOVEICULAR = {
    viewName: "Manutenção veicular", serializedName: "MANUTENCAO_VEICULAR",
};

export const TipoRelatorioVeicularMap = {
    [OPERACAOCAMPO.serializedName]: OPERACAOCAMPO.viewName,
    [MANUTENCAOVEICULAR.serializedName]: MANUTENCAOVEICULAR.viewName,
};

export default [
    OPERACAOCAMPO, MANUTENCAOVEICULAR
];;

