import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormGerenciamentoRecibo from "./PaginaFormGerenciamentoRecibo";
import PaginaListaGerenciamentoRecibos from "./PaginaListaGerenciamentoRecibos";
import PaginaFormGerarRecibos from "./PaginaFormGerarRecibos";

class PaginaGerenciamentoRecibos extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/recibos/gerenciamento"} component={PaginaListaGerenciamentoRecibos} />
                <Route exact path={"/sgih/app/recibos/gerenciamento/criar"} component={PaginaFormGerenciamentoRecibo} />
                <Route path={"/sgih/app/recibos/gerenciamento/:id"} component={PaginaFormGerenciamentoRecibo} />
                <Route exact path={"/sgih/app/recibos/gerarRecibos"} component={PaginaFormGerarRecibos} />
            </Switch>
        </>;
    }
}

export default PaginaGerenciamentoRecibos;