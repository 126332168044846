import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
import STATUSRELATORIO from "model/StatusRelatorio";
class PaginaListaUsuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibir:false
    };

  UNIDADEREGIONAL(
    (regionais)=>{
        this.setState({
            regionais
        },
        STATUSRELATORIO(
          (perfis) => {
            this.setState({
              perfis
            },
        ()=>{
    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "nome", type: "string", map: "nome" },
        { name: "perfil", type: "string", map: "perfil" },
        { name: "regional", type: "string", map: "regional" },
        { name: "login", type: "string", map: "login" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Regional", dataField: "regional", width: 100, filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
      { text: "Código", dataField: "id", width: 65 },
      { text: "Nome", dataField: "nome" },
      { text: "Perfil", dataField: "perfil", width: 220, filtertype: 'checkedlist', filteritems: this.state ? this.state.perfis : []  },
      // { text: "Login", dataField: "login", width: "15%" },
    ];

    this.tableConfig = { source, columns };
    this.setState({
      exibir:true
  }
  )
}
)}))})
  }

  render() {
    return this.state ? this.state.exibir ?
      <CrudListPageScaffold
        title="Usuários"
        tableConfig={this.tableConfig}
        baseUrl="/usuarios"
        history={this.props.history}
        tableName="usuarios"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
        shouldAddDeleteButton={false}
        shouldAddEditButton={true}
        showFormLink={false}
      />
    :<></>
    :<></>
  }
}

export default PaginaListaUsuarios;
