import React from "react";
import PropTypes from "prop-types";
import JqxTabs from "jqwidgets-scripts/jqwidgets-react-tsx/jqxtabs";

import "./TabContainer.css";
import { TabContext } from "./context";

class TabContainer extends React.Component {
  constructor(props) {
    super(props);
  
    this.tabRef = React.createRef();
    if(this.props.useEvent){
      this.props.afterTabContent(this.tabRef)
    }
   
    this.currentTabDescriptions = this.mapChildrenToTabDescriptions(
      props.children
    );
  }

   

  render() {
    
    const context = {
      
      afterContent:this.props.useEvent ? undefined : this.props.afterTabContent,
      
      tabRef: this.tabRef,
    };

    const children = React.Children.toArray(this.props.children);
    const tabsVisible = children.filter( arr => arr.props.visible);
    return (
      <JqxTabs
        ref={this.tabRef}
        width={"100%"}
        height={"100%"}
        position={"top"}
        theme={"bootstrap"}
        className={this.props.className}
      >
        <ul>
          {this.currentTabDescriptions &&
            this.currentTabDescriptions.map((ch, i) => (
              <li key={i}>{ch[0]}</li>
            ))}
        </ul>
        
        {children && children.map((child, i, arr) => {
          return (
            <TabContext.Provider
              key={i}
              value={{
                isLast: i === arr.length - 1,
                isFirst: tabsVisible[0] || i === 0,
                ...context,
              }}
            >
            {child}
           
            </TabContext.Provider>
          );
        })}
      </JqxTabs>
    );
  }

  mapChildrenToTabDescriptions(children = []) {
    // Correcao para que o codigo funcione com formularios com apenas uma (1) aba.
    let adjustedChildren = children;
    if (!Array.isArray(children)) {
        adjustedChildren = [];
        adjustedChildren.push(children);
    }
    return adjustedChildren.map((t) => [t.props.title, this.isVisible(t)]);
  }

  isVisible(tab) {
    return "undefined" === typeof tab.props.visible || tab.props.visible;
  }

  updateTabVisibility() {
    for (let i = 0; i < this.currentTabDescriptions.length; i++) {
      if (!this.currentTabDescriptions[i][1]) {
        this.tabRef.current.disableAt(i);
      } else {
        this.tabRef.current.enableAt(i);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const nextTabDescriptions = this.mapChildrenToTabDescriptions(
      nextProps.children
    );

    if (nextTabDescriptions.length !== this.currentTabDescriptions.length) {
      this.currentTabDescriptions = nextTabDescriptions;
      this.updateTabVisibility();
      return true;
    }

    for (let i = 0; i < this.currentTabDescriptions.length; i++) {
      if (this.currentTabDescriptions[i][1] !== nextTabDescriptions[i][1]) {
        this.currentTabDescriptions = nextTabDescriptions;
        this.updateTabVisibility();
        return true;
      }
    }

    return false;
  }

  componentDidMount() {
    this.updateTabVisibility();
  }

  componentDidUpdate() {
    this.updateTabVisibility();
  }
}

TabContainer.propTypes = {
  // how to force this component to have only children of type Tab??
  className: PropTypes.string,
  children: PropTypes.node,
  afterTabContent: PropTypes.func,
};

export default TabContainer;
