import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaCondutores extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        { name: "nomeUsuario", type: "string", map: "nomeUsuario" },
        { name: "numeroCNH", type: "string", map: "numeroCNH" },
        { name: "categoriaCNH", type: "string", map: "categoriaCNH" },
        { name: "dataValidadeCNH", type: "string", map: "dataValidadeCNH" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: 75 },
      { text: "Nome", dataField: "nomeUsuario" },
      { text: "CNH", dataField: "numeroCNH", width: 100 },
      { text: "Tipo", dataField: "categoriaCNH", width: 45  },
      { text: "Validade", dataField: "dataValidadeCNH", width: 85 },
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Condutores"
        tableConfig={this.tableConfig}
        baseUrl="/condutores"
        history={this.props.history}
        formLinkTitle="Novo condutor"
        tableName="condutores"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
      />
    );
  }
}

export default PaginaListaCondutores;
