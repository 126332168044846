import React from "react";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import voltarParaGrid from "util/VoltarParaGrid";

export const construirBotoesForm = (finalForm, estaEditando, estaVisualizando, history, utilizarEvento, callbackSubmit) => (tabRef, isFirst, isLast) => {
    if(utilizarEvento){

        document.addEventListener("proximo",(e)=>{
            try{
                tabRef.current.getOptions('next');
            } catch(err) {
                //
            }
        });
        document.addEventListener("voltar",(e)=>{
            try{
                tabRef.current.getOptions('previous');
            } catch(err) {
                //
            }
        });

        document.addEventListener("salvar",(e)=>{
            try{
                if(callbackSubmit){
                    callbackSubmit();
                }
                finalForm.submit();
            } catch(err) {
                //
            }
        });
        document.addEventListener("anterior",(e)=>{
            try{
                voltarParaGrid();
            } catch(err) {
                //
            }
        });
        document.addEventListener("listar",(e)=>{
            try{
                voltarParaGrid();
            } catch(err) {
                //
            }
        });

        return <></>
    }


    let texto = "Próximo";
    let btnVoltar = false;
    let onClick = () => {
        // por algum motivo esse get avança pra próxima aba...

        tabRef.current.getOptions('next');
    };
    let onClickVoltar = () => isFirst
        ? voltarParaGrid()
        : tabRef.current.getOptions('previous');

    if (estaVisualizando) {
        btnVoltar = true;
        texto = "Voltar";
        onClick = () => voltarParaGrid();
    } else if (estaEditando) {
        texto = "Atualizar";
        onClick = () => finalForm.submit();
        onClickVoltar = () => voltarParaGrid();
    } else if (isLast) {
        texto = "Salvar";
        onClick = () => finalForm.submit();
        onClickVoltar = () => voltarParaGrid();
    }

    return <Container fluid>
        <Row className="mt-2 mb-2">
            <Col>
                <div style={{ marginTop: '20px' }} className={btnVoltar ? "float-left" : "float-right"}>
                    <JqxButton theme="material" width={120} height={"100%"}
                            className={btnVoltar ? "py-2 float-left" : "py-2 float-right"} onClick={onClick}>
                        {texto}
                    </JqxButton>
                </div>

                    { !estaVisualizando ?
                    <div style={{ marginTop: '20px' }} className="float-left">
                        <JqxButton theme="material" width={120} height={"100%"}
                                className="py-2 float-left" onClick={onClickVoltar}>
                            Voltar
                        </JqxButton>
                    </div>
                    : <> </>
                        }
            </Col>
        </Row>
    </Container>
};
