import {FieldArray} from "react-final-form-arrays";
import FileGrid from "../../file/Grid";
import {Field} from "react-final-form";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Error from "../Error";
import {connect} from "react-redux";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { mapearTipo } from "util/Arquivo";

function FileGridField({ campoNovosArquivos, campoArquivosExistentes,
                         chaveListaArquivos = "arquivos_" + campoNovosArquivos, disable, todosArquivosCarregados,limiteArquivos, excluirCb, ...rest }) {
    const subscription = { initial: true, value: true, valid: true, error: true };

    const [lightbox, setLightbox] = useState(null); // Controla a Lightbox (visualização de imagens)
    
    return <>
    <Field name={campoArquivosExistentes} subscription={{value: true}}>
        {
        ({input:{value: arquivosExistentes = []}}) => {

            let arquivos = arquivosExistentes === '' ? [] : arquivosExistentes;
            //Um problema acontecia na renderização da imagem que permitia clicar atravez dela fazendo com que o foco passasse para o elemento que estava atrás
            //Dessa forma ao tentar usar o teclaado para trocar de imagem acabava trocando de aba
            //Como solução foi implementado um evento de click na imagem que retira o foco ao clicar na imagem, o setTimeout foi utilizado para dar tempo da imagem ser renderizada na tela
            //Assim evitando o bug e continuando com o funcionamento correto de passar as imagens com o uso das setas do teclado
            setTimeout(()=>{let elementImagem = document.getElementsByClassName("lb-img")[0]
            if(elementImagem){
                elementImagem.addEventListener("click",function(){document.activeElement.blur();})
            }},50)
        
            return <FieldArray name={campoNovosArquivos} subscription={subscription}
                               key={todosArquivosCarregados}
                               validate={() => {
                                   return todosArquivosCarregados ? undefined : 'Ainda há arquivos sendo carregados. Aguarde.'
                               }}>
                {({fields}) => {
                    return <>
                        {fields.map((name, i) => {
                                return  <Field key={i} component="input" type="hidden" name={name}
                                            subscription={subscription} />
                        })}
                        <FileGrid nome={chaveListaArquivos}
                                  idsArquivosExistentes={arquivos}
                                  permitirUpload={!disable}
                                  permitirExclusao={!disable}
                                  limiteArquivos={limiteArquivos}
                                  onAdicionar={(hash) => {
                                      fields.push(hash)
                                  }}
                                  onExcluir={(hash) => {
                                      if(excluirCb)
                                        excluirCb(hash);

                                      let idx = fields.value.indexOf(hash)
                                      if (idx >= 0) {
                                          fields.remove(idx)
                                      }
                                  }}
                                  onClickImagem={(i, arquivos) => {
                                    // TODO: fix FileGridField não re-renderizando algumas vezes após atualizar lightbox
                                    const images = arquivos.filter(arq => mapearTipo(arq[1].tipo) === "imagem") // Exibir apenas imagens
                                                           .map(arq => ({ url: arq[1].caminho, title: arq[1].nome }));
                                    setLightbox({
                                        startIndex: images.findIndex(img => img.url === arquivos[i][1].caminho),
                                        images: images
                                    });
                                    
                                  }}
                                  renderAfter={() => {
                                      return <Error name={campoNovosArquivos}
                                                    render={({ meta: { error, touched } }) => {
                                                        return error && touched
                                                            ? <p className="text-danger">{error}</p>
                                                            : null
                                                    }} />
                                  }}
                                  {...rest}
                        />
                    </> 
                }}
            </FieldArray>
        }}
    </Field>
    {
        lightbox
            ? (<Lightbox 
                images={lightbox.images}
                videos={lightbox.videos} 
                startIndex={lightbox.startIndex} 
                onClose={() => { setLightbox(null) }}
            />)
            : null
    }
    </>
}

FileGridField.propTypes = {
    disable: PropTypes.bool,
    campoNovosArquivos: PropTypes.string.isRequired,
    campoArquivosExistentes: PropTypes.string.isRequired,
    chaveListaArquivos: PropTypes.string.isRequired,
    limiteArquivos: PropTypes.number,

    // Redux
    todosArquivosCarregados: PropTypes.bool
};

FileGridField.defaultProps = {
    disable: false,
    limiteArquivos: Number.MAX_SAFE_INTEGER
};

export default connect(
    (state, { chaveListaArquivos }) => ({
        todosArquivosCarregados: Object.values((state.files[chaveListaArquivos]||{}))
            .every((arq => ('undefined' !== typeof arq.carregando && !arq.carregando)))
    })
)(FileGridField);
