import React, { useMemo } from 'react';
import NavBar from 'components/navBar/NavBar';
import PageScaffold from 'components/scaffold/PageScaffold';
import { GlobalLoader } from 'components/loader/Loader';
import Footer from 'components/footer/Footer';
import { useScreenDimensions } from 'util/hooks/use-screen-dimensions';

export default function Index() {
    const screenDimensions = useScreenDimensions();

    const title = useMemo(() => {
        if (screenDimensions.width < 704) {
            return 'SGIH';
        }
        return PageScaffold.defaultProps.title;
    }, [screenDimensions]);

    return (
        <GlobalLoader style={{display: "flex", flexDirection:"column", height: "100vh"}}>
            <NavBar titulo={title} exibirCompleto/>
            <div className={"p-1"} style={{ height: '100%' }}>
                <iframe
                    src={process.env.REACT_APP_IFRAME_URL}
                    style={{ border: 0, width: '100%', height: '100%' }}
                    title="Mapa"
                ></iframe>
            </div>
            <Footer/>
        </GlobalLoader>
    );
}
