

const mapeamentoTipoArquivo = {
    "word": ["doc", "docx", "odt"],
    "imagem": [(v) => v.startsWith("image/"), "png", "jpg", "jpeg", "svg", "tif", "tiff"],
    "video": [(v) => v.startsWith("video/"), "mp4", "webm", "mov", "wmv", "flv", "avi", "mpg", "mpeg"],
    "pdf": [(v) => v.includes("pdf")],
    "zip": [v => v.includes("zip"), v => v.includes("rar")]
};

const mapearTipo = (tipo) => {
    tipo = tipo.toLowerCase();
    return Object.entries(mapeamentoTipoArquivo).reduce((res, [key, value]) => {
        if (res) return res;
        if (key === tipo) return key;
        for (const alias of value) {
            if (alias === tipo) return key;
            if (typeof alias === "function" && alias(tipo)) return key;
        }
        return null;
    }, null) || "file";
};

export { mapearTipo };