import React from "react";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export default class FormsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      final: undefined,
      contador: 0,
      texto: this.props.estaEditando ? 'Atualizar' : 'Salvar',
    }
  }

  enviarEvento = (nomeEvento)=>{
   let evento = new CustomEvent(nomeEvento);
   document.dispatchEvent(evento);
  }

  render(){ 
    return(
      <Container fluid>
        <Row className="mt-2 mb-2">
          <Col style={{display: 'flex', justifyContent: 'center', gap:'15px', }}>
            {!this.props.estaVisualizando ?<>
              <div >
                  <JqxButton theme="material" width={120} height={"50%"}
                          className="py-2 float-left" onClick={()=>this.enviarEvento("anterior")}>
                      Voltar
                  </JqxButton>
              </div>
              
              <div >
                  <JqxButton theme="material" width={120} height={"50%"}
                          className="py-2 float-left" onClick={()=>this.enviarEvento("salvar")}>
                      {this.state.texto}
                  </JqxButton>
              </div>
              </>
             :
              <div >
                  <JqxButton theme="material" width={120} height={"50%"}
                          className="py-2 float-left" onClick={()=>this.enviarEvento("listar")}>
                      Voltar
                  </JqxButton>
              </div>}
              <div >
                <JqxButton theme="material" width={120} height={"50%"} 
                      className={ "py-2 float-right"} onClick={()=>this.enviarEvento("voltar")}>
                   <FontAwesomeIcon id="iconeVoltar" size={70} icon={faArrowLeft} style={{color: 'white'}} />
                </JqxButton>
              </div>
              <div>
                <JqxButton theme="material" width={120} height={"50%"} 
                      className={ "py-2 float-right"} onClick={()=>this.enviarEvento("proximo")}>
                    <FontAwesomeIcon id="iconeProximo" size={70} icon={faArrowRight} style={{color: 'white'}} />
                </JqxButton>
              </div>
          </Col>
        </Row>
      </Container>
    );
  }
}