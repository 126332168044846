import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormCondutores from "./PaginaFormCondutores";
import PaginaListaCondutores from "./PaginaListaCondutores";

class PaginaCondutores extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/condutores"}
            component={PaginaListaCondutores}
          />
          <Route
            exact
            path={"/sgih/app/condutores/criar"}
            component={PaginaFormCondutores}
          />
          <Route path={"/sgih/app/condutores/:id"} component={PaginaFormCondutores} />
        </Switch>
      </>
    );
  }
}

export default PaginaCondutores;
