import http from "service/http-commons"

// https://stackoverflow.com/a/43843090
export const objetoParaParams = (params) => Object.entries(params).map(([key, val]) => {
    if (Array.isArray(val)) {
        return `${key}=${val.join(',')}`
    } else if ('object' === typeof val) {
        return `${key}=${objetoParaParams(val)}`
    }
    return `${key}=${val}`
}).join('&');

const cache = {};

export default (path) => {
    if (cache[path]) return cache[path];

    cache[path] = {
        get: id => http.get(`${path}/${id}`),
        getWithParams: (params) => {
            return http.get(`${path}?${objetoParaParams(params)}`)
        },
        post: obj => http.post(path, obj),
        filter: obj => http.post(`${path}/filter`, obj),
        query: params => http.get(path, {params}),
        update: (id, obj) => http.put(path + '/' + id, obj),
        // update: (obj) => http.put(path, obj),
        delete: id => http.delete(`${path}/${id}`),
        activate: id => http.put(`${path}/activate/${id}`),
        inactivate: id => http.put(`${path}/inactivate/${id}`),
        upload: (formData) => http.post(`${path}/upload`, formData, {
            headers: {
                'Content-Type': undefined
            }
        }),
        download: (id, base64) => http.get(`${path}/${id}/download?base64=${base64}`),
        generate: (id, obj, base64) => http.post(`${path}/${id}/generate?base64=${base64}`, obj),
        customURL: (verb, complementPath, objSend, headers = {}) => {
            switch (verb.toUpperCase()) {
                case 'POST':
                    return http.post(`${path}/${complementPath}`, objSend, {
                        headers,
                    })
                case 'PUT':
                    return http.put(`${path}/${complementPath}`, objSend)
                case 'PATCH':
                    return http.patch(`${path}/${complementPath}`, objSend)
                case 'GET':
                    return http.get(`${path}/${complementPath}`, {
                        params: objSend,
                        headers,
                    })
                default:
                    return null;
            }
        },
        request: (method, complementPath, config = {}) => {
            const url = path.concat(complementPath);

            return http.request({
                ...config,
                method,
                url,
            });
        }
    };

    return cache[path];
}
