import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaListaPerfil from "./PaginaListaPerfis";
import {PaginaFormPerfil} from "./PaginaFormPerfis";

class PaginaPerfis extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/perfis"} component={PaginaListaPerfil} />
                <Route exact path={"/sgih/app/perfis/criar"} component={PaginaFormPerfil} />
                <Route path={"/sgih/app/perfis/:id"} component={PaginaFormPerfil} />
            </Switch>
        </>;
    }
}

export default PaginaPerfis;