import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import SelectField from "../../components/form/field/SelectField";
import TextAreaField from "../../components/form/field/TextAreaField";
import VisitTypes from "../../model/TipoVisita";
import DateTimeField from "../../components/form/field/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import CondicaoTempo from "../../model/CondicaoTempo";
import LocalDaMedicao from "../../model/LocalDaMedicao";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import Container from "react-bootstrap/Container";
import createDecorator from "final-form-calculate";
import PromiseLoader from "../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import {JqxDropDownListAdapter} from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import { requiredWithNotification } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import voltarParaGrid from "util/VoltarParaGrid";

var exibiuMensagemParaEstacaoEDataVisitaEm = 0;
// necessário para desabilitar a atualização dos campos de coleta e vazao ao visualizar
let visualizando = false;
let dadosEstacao;

// https://codesandbox.io/s/10rzowm323
const normalizePh = (value) => {
  if (!value) return value;
  // eslint-disable-next-line
  const valorDigitado = value.match(/^[0-1]?[0-3]?\.\d?\d|[0-1]?[0-4]|[0-9]$/g)
  // eslint-disable-next-line
    ? value.replace(/[^\d\.]/g, "")
    : "";
  if (valorDigitado.length > 5) return valorDigitado.slice(0, 5);
  if (parseFloat(valorDigitado) <= 14) return valorDigitado;
  else return 14;
};
const requiredWithMessage = (message) => (value) =>
  value ? undefined : message;
const tipoEstacaoDeveConter = (contain) => (value, allValues) =>
  requiredWithMessage("O campo Código é obrigatório")(value) ||
  (!!allValues.tipoEstacao && contemLista(contain, allValues.tipoEstacao)
    ? undefined
    : "Digite o código de uma estação tipo " +  contain.toString().replace(/,/g, ' e '));
const contemLista = (lista, valor) => {
    let contains = true;

    if(valor)
        lista.forEach((item) => {
            if(!valor.includes(item))
                contains = false;
        });
    else
        contains = false;
    return contains;
}

// https://codesandbox.io/s/oq52p6v96y
const calculatedStationDecorator = createDecorator(
  {
    field: "equipamentosQualidadeAgua",
    updates: (value, name, allValues) => {

      if(!value || !value.length)
        return {
          equipamentoNumeroCondutividade: null,
          equipamentoNumeroPH: null,
          equipamentoNumeroOxigenio: null,
          equipamentoNumeroTurbidez: null,
        };

        let equipamentoNumeroCondutividade = allValues.equipamentoNumeroCondutividade;
        let equipamentoNumeroPH = allValues.equipamentoNumeroPH;
        let equipamentoNumeroOxigenio = allValues.equipamentoNumeroOxigenio;
        let equipamentoNumeroTurbidez = allValues.equipamentoNumeroTurbidez;

      if(equipamentoNumeroCondutividade){
        let equipamentoSelecionado = value
        .find(item => item.id == equipamentoNumeroCondutividade || item.id == equipamentoNumeroCondutividade?.id);

        if(!equipamentoSelecionado)
          equipamentoNumeroCondutividade = null;
      }

      if(equipamentoNumeroPH){
        let equipamentoSelecionado = value
        .find(item => item.id == equipamentoNumeroPH || item.id == equipamentoNumeroPH?.id);

        if(!equipamentoSelecionado)
          equipamentoNumeroPH = null;
      }

      if(equipamentoNumeroOxigenio){
        let equipamentoSelecionado = value
        .find(item => item.id == equipamentoNumeroOxigenio || item.id == equipamentoNumeroOxigenio?.id);

        if(!equipamentoSelecionado)
          equipamentoNumeroOxigenio = null;
      }

      if(equipamentoNumeroTurbidez){
        let equipamentoSelecionado = value
        .find(item => item.id == equipamentoNumeroTurbidez || item.id == equipamentoNumeroTurbidez?.id);

        if(!equipamentoSelecionado)
          equipamentoNumeroTurbidez = null;
      }

      return {
        equipamentoNumeroCondutividade,
        equipamentoNumeroOxigenio,
        equipamentoNumeroPH,
        equipamentoNumeroTurbidez
      };
    }
  },
  {
    field: "codigoEstacaoDigitado",
    updates: async (value, name, allValues) => {

      let listaEquipamentos = [];
      let utilizarUnidadeRegionalDoRelatorio = allValues.id ? true : false;

      const getEquipamentos = async (idUnidadeRegional) => {
        try{
          const resEquipamentos = await createServiceFor(`/equipamentos/qualidadeAgua/simples/unidadeRegional/${idUnidadeRegional}`)
          .query();
          listaEquipamentos = resEquipamentos.data;
        }
        catch(err) {
          listaEquipamentos = [];
          console.log("Erro ao pegar equipamentos utilizando unidade regional do relatorio", err);
        }
      }

      if(utilizarUnidadeRegionalDoRelatorio && !allValues.equipamentosQualidadeAgua)
        await getEquipamentos(allValues.idUnidadeRegional);
      else if(utilizarUnidadeRegionalDoRelatorio && allValues.equipamentosQualidadeAgua && allValues.equipamentosQualidadeAgua.length)
        listaEquipamentos = allValues.equipamentosQualidadeAgua;

      if (value && value.length >= 8) {
        return createServiceFor("/relatoriosQualidadeAgua")
          .customURL("GET", `paraEstacao/${value}`)
          .then(async (res) => {
            const data = res.data;

            if(!utilizarUnidadeRegionalDoRelatorio)
              await getEquipamentos(data.idUnidadeRegional);

            return {
              nomeEstacao: data.nome,
              estacaoSelecionada: data,
              baciaEstacao: data.bacia,
              rioEstacao: data.rio,
              roteiroEstacao: data.roteiro,
              idUnidadeRegional: data.idUnidadeRegional,
              codigoEstacao:value,
              tipoEstacao: data.tipo,

              equipamentoMarcaCondutividade: null,
              equipamentoModeloCondutividade: null,

              equipamentoMarcaPH: null,
              equipamentoModeloPH: null,

              equipamentoMarcaOxigenio: null,
              equipamentoModeloOxigenio: null,

              equipamentoMarcaTurbidez: null,
              equipamentoModeloTurbidez: null,

              equipamentosQualidadeAgua: listaEquipamentos,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              estacaoSelecionada: null,
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              codigoEstacao:"",
              idUnidadeRegional: "",
              tipoEstacao: "",
              equipamentosQualidadeAgua: utilizarUnidadeRegionalDoRelatorio ? listaEquipamentos : []
            };
          });
      }else{
        return {
          estacaoSelecionada: null,
          nomeEstacao: "",
          baciaEstacao: "",
          rioEstacao: "",
          roteiroEstacao: "",
          codigoEstacao:"",
          idUnidadeRegional: "",
          tipoEstacao: "",
          equipamentosQualidadeAgua: utilizarUnidadeRegionalDoRelatorio ? listaEquipamentos : []
        };
      }
      return {};
    },
  },
  {
    field: "equipamentoNumeroCondutividade",
    updates: (value, name, allValues) => {
      if( value && value != null ){
        return createServiceFor("/equipamentos/qualidadeAgua/porCodigo")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            return {

              // substituido para quando alterar a o equipamento alterar os demais campos
              equipamentoNumeroCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoNumeroCondutividade : data.id,
              equipamentoMarcaCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoMarcaCondutividade : data.marca,
              equipamentoModeloCondutividade: allValues.equipamentoModeloCondutividade != null ? allValues.equipamentoModeloCondutividade : data.modelo,

              equipamentoNumeroPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoNumeroPH : data.id,
              equipamentoMarcaPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoMarcaPH : data.marca,
              equipamentoModeloPH: allValues.equipamentoModeloPH != null ? allValues.equipamentoModeloPH : data.modelo,

              equipamentoNumeroOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoNumeroOxigenio : data.id,
              equipamentoMarcaOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoMarcaOxigenio : data.marca,
              equipamentoModeloOxigenio: allValues.equipamentoModeloOxigenio != null ? allValues.equipamentoModeloOxigenio : data.modelo,

              equipamentoNumeroTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoNumeroTurbidez : data.id,
              equipamentoMarcaTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoMarcaTurbidez : data.marca,
              equipamentoModeloTurbidez: allValues.equipamentoModeloTurbidez != null ? allValues.equipamentoModeloTurbidez : data.modelo
            };
          });

      }
      return {}
    },
  },
  {
    field: "equipamentoNumeroPH",
    updates: (value, name, allValues) => {
      if( value && value != null ){
        return createServiceFor("/equipamentos/qualidadeAgua/porCodigo")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            return {

              // substituido para quando alterar a o equipamento alterar os demais campos
              equipamentoNumeroCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoNumeroCondutividade : data.id,
              equipamentoMarcaCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoMarcaCondutividade : data.marca,
              equipamentoModeloCondutividade: allValues.equipamentoModeloCondutividade != null ? allValues.equipamentoModeloCondutividade : data.modelo,

              equipamentoNumeroPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoNumeroPH : data.id,
              equipamentoMarcaPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoMarcaPH : data.marca,
              equipamentoModeloPH: allValues.equipamentoModeloPH != null ? allValues.equipamentoModeloPH : data.modelo,

              equipamentoNumeroOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoNumeroOxigenio : data.id,
              equipamentoMarcaOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoMarcaOxigenio : data.marca,
              equipamentoModeloOxigenio: allValues.equipamentoModeloOxigenio != null ? allValues.equipamentoModeloOxigenio : data.modelo,

              equipamentoNumeroTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoNumeroTurbidez : data.id,
              equipamentoMarcaTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoMarcaTurbidez : data.marca,
              equipamentoModeloTurbidez: allValues.equipamentoModeloTurbidez != null ? allValues.equipamentoModeloTurbidez : data.modelo
            };
          });

      }
      return {}
    },
  },
  {
    field: "equipamentoNumeroOxigenio",
    updates: (value, name, allValues) => {
      if( value && value != null ){
        return createServiceFor("/equipamentos/qualidadeAgua/porCodigo")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            return {

              // substituido para quando alterar a o equipamento alterar os demais campos
              equipamentoNumeroCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoNumeroCondutividade : data.id,
              equipamentoMarcaCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoMarcaCondutividade : data.marca,
              equipamentoModeloCondutividade: allValues.equipamentoModeloCondutividade != null ? allValues.equipamentoModeloCondutividade : data.modelo,

              equipamentoNumeroPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoNumeroPH : data.id,
              equipamentoMarcaPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoMarcaPH : data.marca,
              equipamentoModeloPH: allValues.equipamentoModeloPH != null ? allValues.equipamentoModeloPH : data.modelo,

              equipamentoNumeroOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoNumeroOxigenio : data.id,
              equipamentoMarcaOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoMarcaOxigenio : data.marca,
              equipamentoModeloOxigenio: allValues.equipamentoModeloOxigenio != null ? allValues.equipamentoModeloOxigenio : data.modelo,

              equipamentoNumeroTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoNumeroTurbidez : data.id,
              equipamentoMarcaTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoMarcaTurbidez : data.marca,
              equipamentoModeloTurbidez: allValues.equipamentoModeloTurbidez != null ? allValues.equipamentoModeloTurbidez : data.modelo
            };
          });

      }
      return {}
    },
  },
  {
    field: "equipamentoNumeroTurbidez",
    updates: (value, name, allValues) => {
      if( value && value != null ){
        return createServiceFor("/equipamentos/qualidadeAgua/porCodigo")
          .customURL("GET", `${value}`)
          .then((res) => {
            const data = res.data;
            return {

              // substituido para quando alterar a o equipamento alterar os demais campos
              equipamentoNumeroCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoNumeroCondutividade : data.id,
              equipamentoMarcaCondutividade: allValues.equipamentoMarcaCondutividade != null ? allValues.equipamentoMarcaCondutividade : data.marca,
              equipamentoModeloCondutividade: allValues.equipamentoModeloCondutividade != null ? allValues.equipamentoModeloCondutividade : data.modelo,

              equipamentoNumeroPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoNumeroPH : data.id,
              equipamentoMarcaPH: allValues.equipamentoMarcaPH != null ? allValues.equipamentoMarcaPH : data.marca,
              equipamentoModeloPH: allValues.equipamentoModeloPH != null ? allValues.equipamentoModeloPH : data.modelo,

              equipamentoNumeroOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoNumeroOxigenio : data.id,
              equipamentoMarcaOxigenio: allValues.equipamentoMarcaOxigenio != null ? allValues.equipamentoMarcaOxigenio : data.marca,
              equipamentoModeloOxigenio: allValues.equipamentoModeloOxigenio != null ? allValues.equipamentoModeloOxigenio : data.modelo,

              equipamentoNumeroTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoNumeroTurbidez : data.id,
              equipamentoMarcaTurbidez: allValues.equipamentoMarcaTurbidez != null ? allValues.equipamentoMarcaTurbidez : data.marca,
              equipamentoModeloTurbidez: allValues.equipamentoModeloTurbidez != null ? allValues.equipamentoModeloTurbidez : data.modelo
            };
          });

      }
      return {}
    },
  },
  {
    field: ["equipamentoDataDaUltimaCalibracaoCondutividade","equipamentoDataDaUltimaCalibracaoPH","equipamentoDataDaUltimaCalibracaoOxigenio","equipamentoDataDaUltimaCalibracaoTurbidez"],
    updates: (value, name, allValues) => {
      return {
        equipamentoDataDaUltimaCalibracaoCondutividade : allValues.equipamentoDataDaUltimaCalibracaoCondutividade != null ? allValues.equipamentoDataDaUltimaCalibracaoCondutividade : value,
        equipamentoDataDaUltimaCalibracaoPH : allValues.equipamentoDataDaUltimaCalibracaoPH != null ? allValues.equipamentoDataDaUltimaCalibracaoPH : value,
        equipamentoDataDaUltimaCalibracaoOxigenio : allValues.equipamentoDataDaUltimaCalibracaoOxigenio != null ? allValues.equipamentoDataDaUltimaCalibracaoOxigenio : value ,
        equipamentoDataDaUltimaCalibracaoTurbidez : allValues.equipamentoDataDaUltimaCalibracaoTurbidez != null ? allValues.equipamentoDataDaUltimaCalibracaoTurbidez : value
      }
    },
  },
  {
    field: "tipoVisitaSelecionado",
    updates: (value, name, allValues) => {
      return createServiceFor("/tiposCondicao")
        .customURL("GET", `${value}`)
        .then((res) => {
          const data = res.data;
          // todo treat errors here
          return {
            tipoVisita: data.id,
          };
        });
    },
  },
  {
    field: "condicoesDoTempoVisitaSelecionada",
    updates: (value, name, allValues) => {
      return createServiceFor("/tiposCondicao")
        .customURL("GET", `${value}`)
        .then((res) => {
          const data = res.data;
          // todo treat errors here
          return {
            condicaoTempo: data.id,
          };
        });
    },
  },
  {
    field: ["codigoEstacaoDigitado", "dataVisita"],
    updates: (value, name, { codigoEstacaoDigitado, dataVisita }) => {
      const codigoEstacao = codigoEstacaoDigitado;
      if (codigoEstacao && codigoEstacao.length >= 8 && dataVisita) {
        return createServiceFor(`/relatoriosQualidadeAgua/paraEstacaoEData`)
          .getWithParams({
            codigoEstacao,
            data: dataVisita,
          })
          .then((res) => {
            const data = res.data;
            if (!data.cota && !data.vazao) {
              // Discard repetitive messages for 2 seconds.
              let now = new Date().getTime();
              if (now - exibiuMensagemParaEstacaoEDataVisitaEm < 2000) {
                return {};
              }
              exibiuMensagemParaEstacaoEDataVisitaEm = now;
              store.dispatch(
                showGlobalNotification({
                  message:
                    "Não existe um relatório para a estação e data selecionada",
                })
              );
              return {};
            }

            // necessário para desabilitar a atualização dos campos de coleta e vazao ao visualizar
            //if(!visualizando){
            //  return {
            //    cotaObservacao: data.cota,
            //    vazaoObservacao: data.vazao,
            //  };
            //}
            return {}
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              cotaObservacao: "",
              vazaoObservacao: "",
            };
          });
      }
      return {};
    },
  },
  {
    field: 'estacaoSelecionada',
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          'codigoEstacaoDigitado': value.codigo,
        }
      }

      return {}
    }
  }
);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.descricao || e.nome  || e.numero || e;

class PaginaFormInspecaoQualidadeAgua extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
      dados:dadosEstacao
     }
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/processosMedicao").query(),
      createServiceFor("/equipamentos/qualidadeAgua/simples").query(),
      createServiceFor("/tiposCondicao/tipoVisita").query(),
      createServiceFor("/posicaoVertical").query(),
      createServiceFor("/posicaoHorizontal").query(),
      createServiceFor("/estacoes/qa").query(),

    ];

    if (this.props.match.params.id) {
      promises.push(createServiceFor("/relatoriosQualidadeAgua").get(this.props.match.params.id));
      this.setState({ emEdicao: true })
    }

    const promise = Promise.all(promises).then(async ([
        usuarios,
        processosMedicao,
        equipamentosQualidadeAgua,
        tiposCondicao,
        posicaoVertical,
        posicaoHorizontal,
        estacoes,
        inspecao = {},
      ]) => {
        if(this.props.match.params.id) {
          try{
            const codigoEstacao = inspecao.data.codigoEstacao;

            const res = await createServiceFor("/relatoriosQualidadeAgua")
            .customURL("GET", `paraEstacao/${codigoEstacao}`);

            const estacao = res.data;
            this.setState({dados: estacao})
            if(!estacoes.data.find((e) => e.id === estacao.id)){
              estacoes.data.push(estacao);
              estacoes.data = estacoes.data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
            }
          }catch(err){}
        }

        if (inspecao.data) {
          this.setState({
            relatorio: inspecao.data || null
          });
        }

        return {
          usuarios: usuarios.data,
          processosMedicao: processosMedicao.data,
          equipamentosQualidadeAgua: equipamentosQualidadeAgua.data,
          tiposCondicao: tiposCondicao.data,
          posicaoVertical: posicaoVertical.data,
          posicaoHorizontal: posicaoHorizontal.data,
          estacoes: estacoes.data,
          equipamentoCadastrado: [] ,
          initialValues: inspecao.data
            ? {
              ...inspecao.data,
              codigoEstacaoDigitado: inspecao.data.codigoEstacao,
            }
            : {},
      }}
    )
    .catch((e) => {
      console.log(e);
      store.dispatch(showGlobalNotification({
        message: e.message
      }))
    });

    this.setState({
      promiseDados: promise
    });
  }

  submitForm(values) {
    // console.log(values)
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/relatoriosQualidadeAgua")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });


  getArrayFiltered = (valor, arrayEnviado) => {
    var arrayFiltrado = [];
    for (var i = 0; i< arrayEnviado.length; i++) {
      if(arrayEnviado[i].idUnidadeRegional === valor){
        arrayFiltrado.push(arrayEnviado[i])
      }
    }
    return arrayFiltrado
  }

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
        <div class="content">
          ${this.state?.relatorio && this.state.dados
            ? `
              ${
                this.state?.relatorio?.id
                  ? `<div class="text">Relatório de qualidade de água</div> - <div class="text">ID: </div>${this.state.relatorio.id}`
                  : ''
              }

              ${
                this.state?.dados?.nome
                  ? ` - <div class="text">Estação: </div>${this.state.dados.nome}`
                  : ''
              }
              ${
                this.state?.relatorio?.campanhaVisita
                  ? ` - <div class="text">Campanha: </div>${this.state?.relatorio?.campanhaVisita}`
                  : ''
              }
              ${
                this.state?.relatorio?.codRoteiro
                  ? ` - <div class="text">Roteiro: </div>${this.state?.relatorio?.codRoteiro}`
                  : ''
              }
              ${
                this.props?.location?.state?.data?.regional
                  ? ` - ${this.props?.location?.state?.data?.regional}`
                  : ''
              }
              </div>
            `
            : ''
          }
        </div>
      `
      : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    // necessário para desabilitar a atualização dos campos de coleta e vazao ao visualizar
    visualizando = disableAllFields;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        dados ={this.state.dados}
        baseTitle="Relatórios de qualidade de água"
        baseUrl="/sgih/app/relatoriosQualidadeAgua"
        tipoRelatorio="QUALIDADE_AGUA"
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            if (!this.state.initialValues && data.initialValues) {
              this.setState({ initialValues: data.initialValues });
            }
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[calculatedStationDecorator]}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field component="input" type="hidden"
                        name="id" value={id} />
                      <Field component="input" type="hidden"
                        name="codigoEstacao" />
                      <Field component="input" type="hidden"
                        name="idUnidadeRegional" />

                      <Field
                        component="input"
                        type="hidden"
                        name="equipamentosQualidadeAgua"
                      />

                      <Tab.Container
                        className="pb-2"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true
                        )}
                      >
                        <Tab title="Dados da estação">
                          {estaEditando && <Container fluid>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                    component={TextField}
                                    name="origem"
                                    label="Origem relatório"
                                    readOnly
                                />
                              </Col>
                            </Row>
                          </Container>}
                          <FormSectionWrapper title="Informações gerais">
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  maxLength={8}
                                  name="codigoEstacaoDigitado"
                                  label="Código"
                                  validate={tipoEstacaoDeveConter(["F", "Q"])}
                                  required
                                  disabled={this.state.emEdicao ? true : disableAllFields}
                                  mostrarMensagemOnBlur
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField}
                                  name='estacaoSelecionada'
                                  label='Estação'
                                  elements={data.estacoes}
                                  buscavel
                                  callbackWithOriginalValue
                                  disabled={this.state.emEdicao ? true : disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  validate={requiredWithNotification("Estação")}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="baciaEstacao"
                                  label="Bacia"
                                  readOnly={true}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="rioEstacao"
                                  label="Rio"
                                  readOnly={true}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "codRoteiro" : "roteiroEstacao"}
                                  label="Roteiro"
                                  readOnly={true}
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="">
                            <Row>
                              <Col className="mt-3" md={6}>
                                <FormSectionWrapper title="Dados da visita">
                                  <Row>
                                    <Col className="mt-3" md={12}>
                                      <Field
                                          name="idsMembrosEquipe"
                                          component={JqxDropDownListAdapter}
                                          source={data.usuarios}
                                          label="Equipe" required
                                          checkboxes={true}
                                          displayMember="nome"
                                          valueMember="id"
                                          placeHolder="Selecione a equipe..."
                                          enableSelection={true}
                                          enableHover={true}
                                          filterable={true}
                                          incrementalSearch={true}
                                          animationType="none"
                                          closeDelay={0}
                                          filterPlaceHolder=" Busque"
                                          disabled={disableAllFields}
                                          validate={requiredWithNotification("Equipe")}
                                          key={disableAllFields}
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={SelectField}
                                        name="tipoVisitaSelecionado"
                                        label="Tipo de visita"
                                        elements={VisitTypes}
                                        defaultValue={
                                          "OPERAÇÃO DE ROTEIRO"
                                        }
                                        validate={requiredWithNotification(
                                          "Tipo de visita"
                                        )}
                                        disabled={disableAllFields}
                                        required
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={DateTimeField}
                                        name="dataVisita"
                                        label="Data"
                                        validate={requiredWithNotification(
                                          "Data"
                                        )}
                                        disabled={disableAllFields}
                                        required
                                      />
                                    </Col>
                                    <Col className="mt-3" md={4}>
                                      <Field
                                        component={DateTimeField}
                                        name="campanhaVisita"
                                        label="Campanha"
                                        datePickerType="yearMonth"
                                        validate={requiredWithNotification(
                                          "Campanha"
                                        )}
                                        disabled={disableAllFields}
                                        required
                                      />
                                    </Col>
                                    <Field
                                      component="input"
                                      type="hidden"
                                      name="tipoVisita"
                                      value=""
                                    />
                                  </Row>
                                </FormSectionWrapper>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <FormSectionWrapper title="Condições do tempo">
                                  <Row>
                                    <Col className="mt-3" md={12}>
                                      <Field
                                        component={SelectField}
                                        name="condicoesDoTempoVisitaSelecionada"
                                        label="Condiçoes do tempo"
                                        elements={CondicaoTempo}
                                        validate={requiredWithNotification(
                                          "Condições do tempo"
                                        )}
                                        disabled={disableAllFields}
                                        required
                                      />
                                    </Col>
                                    <Col className="mt-4" md={12}>
                                      <br />
                                      <br />
                                      <br />
                                    </Col>
                                  </Row>
                                </FormSectionWrapper>
                              </Col>
                              <Field
                                component="input"
                                type="hidden"
                                name="condicaoTempo"
                                value=""
                              />
                              <Col className="mt-3" md={3}>
                                <FormSectionWrapper title="Temperatura em °C">
                                  <Col className="mt-3" md={12}>
                                    <Field
                                      component={NumberField}
                                      name="temperaturaArVisita"
                                      label="Temperatuta do ar (​°C)"
                                      max={99999999.99}
                                      min={-999.99}
                                      decimaisAposvirgula={2}
                                      step={0.1}
                                      disabled={disableAllFields}
                                    />
                                  </Col>
                                  <Col className="mt-3" md={12}>
                                    <Field
                                      component={NumberField}
                                      name="temperaturaAmostraVisita"
                                      label="Temperatura amostra (​°C)"
                                      max={99999999.99}
                                      min={-999.99}
                                      decimaisAposvirgula={2}
                                      step={0.1}
                                      validate={requiredWithNotification(
                                        "Temperatura amostra (​°C)"
                                      )}
                                      disabled={disableAllFields}
                                      required
                                    />
                                    <br />
                                  </Col>
                                </FormSectionWrapper>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Condutividade elétrica">
                          <FormSectionWrapper>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="condutividadeVinteECinco"
                                    label="Condutividade (μS/cm a 25​ °C)"
                                    min={-9999.99}
                                    step={0.1}
                                    max={9999.99}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="condutividadeVinte"
                                    label="Condutividade a 20​ °C"
                                    min={-9999.99}
                                    step={0.1}
                                    max={9999.99}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={SelectField}
                                    name="medicaoLocalCondutividade"
                                    label="Local da medição"
                                    elements={LocalDaMedicao}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field name="equipamentosQualidadeAgua" subscription={{ value: true }}>
                                    {({ input: {value} }) => {

                                      let lista = value ? value : [];

                                      return (
                                        <Field
                                          component={SelectField}
                                          name="equipamentoNumeroCondutividade"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={descriptionLabelResolver}
                                          label="NR. do equipamento"
                                          elements={lista}
                                          disabled={disableAllFields}
                                        />
                                      )
                                    }}
                                  </Field>
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoMarcaCondutividade"
                                    label="Marca do equipamento"
                                    readOnly={true}
                                    value={data.equipamentosQualidadeAgua.marca}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoModeloCondutividade"
                                    label="Modelo do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="equipamentoDataDaUltimaCalibracaoCondutividade"
                                    label="Data da última calibração"
                                    datePickerType="date"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="pH">
                          <FormSectionWrapper>
                            <Col className="mt-3" md={6}>
                              <Row>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={NumberField}
                                    name="phSelecionado"
                                    label="pH"
                                    forcarValorZerado={estaEditando}
                                    disabled={disableAllFields}
                                    parse={normalizePh}
                                    min={0}
                                    max={14}
                                    decimaisAposvirgula={2}
                                    step={1}
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={SelectField}
                                    name="medicaoLocalPH"
                                    label="Local da medição"
                                    elements={LocalDaMedicao}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field name="equipamentosQualidadeAgua" subscription={{ value: true }}>
                                    {({ input: {value} }) => {

                                      let lista = value ? value : [];

                                      return (
                                        <Field
                                          component={SelectField}
                                          name="equipamentoNumeroPH"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={descriptionLabelResolver}
                                          label="NR. do equipamento"
                                          elements={lista}
                                          disabled={disableAllFields}
                                        />
                                      )
                                    }}
                                  </Field>
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoMarcaPH"
                                    label="Marca do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoModeloPH"
                                    label="Modelo do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="equipamentoDataDaUltimaCalibracaoPH"
                                    label="Data da última calibração"
                                    datePickerType="date"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Oxigênio dissolvido">
                          <FormSectionWrapper>
                            <Col className="mt-3" md={6}>
                              <Row>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={NumberField}
                                    name="oxigenioDissolvido"
                                    label="OD (mg/L O²)"
                                    min={-9999.99}
                                    step={0.01}
                                    max={9999.99}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={SelectField}
                                    name="medicaoLocalOxigenio"
                                    label="Local da medição"
                                    elements={LocalDaMedicao}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field name="equipamentosQualidadeAgua" subscription={{ value: true }}>
                                    {({ input: {value} }) => {

                                      let lista = value ? value : [];

                                      return (
                                        <Field
                                          component={SelectField}
                                          name="equipamentoNumeroOxigenio"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={descriptionLabelResolver}
                                          label="NR. do equipamento"
                                          elements={lista}
                                          disabled={disableAllFields}
                                        />
                                      )
                                    }}
                                  </Field>
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoMarcaOxigenio"
                                    label="Marca do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoModeloOxigenio"
                                    label="Modelo do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="equipamentoDataDaUltimaCalibracaoOxigenio"
                                    label="Data da última calibração"
                                    datePickerType="date"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Turbidez">
                          <FormSectionWrapper>
                            <Col className="mt-3" md={6}>
                              <Row>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={NumberField}
                                    name="turbidez"
                                    label="Turbidez (NTU)"
                                    min={-9999.99}
                                    step={0.01}
                                    max={9999.99}
                                    decimaisAposvirgula={2}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                 <Field name="equipamentosQualidadeAgua" subscription={{ value: true }}>
                                    {({ input: {value} }) => {

                                      let lista = value ? value : [];

                                      return (
                                        <Field
                                          component={SelectField}
                                          name="equipamentoNumeroTurbidez"
                                          valueResolver={identifierValueResolver}
                                          labelResolver={descriptionLabelResolver}
                                          label="NR. do equipamento"
                                          elements={lista}
                                          disabled={disableAllFields}
                                        />
                                      )
                                    }}
                                  </Field>
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoMarcaTurbidez"
                                    label="Marca do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={TextField}
                                    name="equipamentoModeloTurbidez"
                                    label="Modelo do equipamento"
                                    readOnly={true}
                                    disabled={disableAllFields}
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="equipamentoDataDaUltimaCalibracaoTurbidez"
                                    label="Data da última calibração"
                                    datePickerType="date"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Observações gerais">
                          <FormSectionWrapper>
                            <Col className="mt-3" md={12}>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="cotaObservacao"
                                    label="Cota (cm)"
                                    max={999999999.9}
                                    min={-999999999.9}
                                    decimaisAposvirgula={0}
                                    step={1}
                                    validate={requiredWithNotification(
                                      "Cota (cm)"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={DateTimeField}
                                    name="horaObservacao"
                                    label="Hora"
                                    datePickerType="hour"
                                    timeIntervals={1}
                                    timeCaption="Hora"
                                    validate={requiredWithNotification("Hora")}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="profundidadeDaColetaObservacao"
                                    label="Profundidade da coleta (m)"
                                    max={9999.99}
                                    min={-9999.99}
                                    decimaisAposvirgula={2}
                                    defaultValue={0.2}
                                    step={0.1}
                                    validate={requiredWithNotification(
                                      "Profundidade da coleta (m)"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                  <p><small>
                                    O valor padrão é "0,2", mas pode ser
                                    alterado se necessário
                                  </small></p>
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={NumberField}
                                    name="vazaoObservacao"
                                    label="Vazão (m³/s)"
                                    max={999999.9999}
                                    min={-99999.9999}
                                    decimaisAposvirgula={4}
                                    step={0.01}
                                    validate={requiredWithNotification("Vazão")}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={SelectField}
                                    name="posicaoHorizontalObservacao"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    label="Posição horizontal"
                                    elements={data.posicaoHorizontal}
                                    validate={requiredWithNotification(
                                      "Posição horizontal"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={3}>
                                  <Field
                                    component={SelectField}
                                    name="posicaoVerticalObservacao"
                                    valueResolver={identifierValueResolver}
                                    labelResolver={descriptionLabelResolver}
                                    label="Posição vertical"
                                    elements={data.posicaoVertical}
                                    validate={requiredWithNotification(
                                      "Posição vertical"
                                    )}
                                    disabled={disableAllFields}
                                    required
                                  />
                                </Col>
                                <Col className="mt-3" md={6}>
                                  <Field
                                    component={NumberField}
                                    max={999999999}
                                    min={-999999999}
                                    semVirgula={true}
                                    name="numeroDaMedicaoObservacao"
                                    label="Número da medição"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={TextAreaField}
                                    name="observacoesGerais"
                                    label="Observações gerais"
                                    rows="7"
                                    cols="53"
                                    className="form-control"
                                    disabled={disableAllFields}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormInspecaoQualidadeAgua;
