import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';


class PaginaListaEquipamentos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                }, ()=>{
                        const source = {
                            date : 'dd/MM/yyyy',
                            datafields: [
                                { name: 'id', type: 'number', map: 'id' },
                                { name: 'numero', type: 'string', map: 'numero' },
                                { name: 'marca', type: 'string', map: 'marca' },
                                { name: 'modelo', type: 'string', map: 'modelo' },
                                { name: 'numeroSerie', type: 'string', map: 'numeroSerie' },
                                { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
                                { name: 'dataCalibracao', type: 'date', map: 'dataCalibracao' },
                            ],
                            id: 'id',
                        };

                        const columns = [
                            { text: 'ID', dataField: 'id', width: '3%' },
                            { text: 'NR CPRM', dataField: 'numero', width: '15%' },
                            { text: 'Marca', dataField: 'marca' },
                            { text: 'Modelo', dataField: 'modelo',width: '10%' },
                            { text: 'Número de série', dataField: 'numeroSerie', width: '19%' },
                            { text: 'Regional', dataField: 'unidadeRegional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                            { text: 'Data de calibração', dataField: 'dataCalibracao', filtertype: 'date', cellsformat: 'dd/MM/yyyy',width: '14.7%'  },
                        ];

                        this.tableConfig = { source, columns }
                        this.setState({
                            exibir:true
                        })
                    })

        }
    )
}





    render() {
        return this.state ? this.state.exibir ?
         <CrudListPageScaffold title="Equipamentos"
                                     tableConfig={this.tableConfig}
                                     baseUrl="/equipamentos"
                                     history={this.props.history}
                                     shouldAddPrintButton={false}
                                     shouldAddZipButton={false}
                                     formLinkTitle="Novo equip."
                                     tableName="equipamentos"
            />
            : <></>
            : <></>
    }
}

export default PaginaListaEquipamentos;
