export function createTooltipWrapperElement(tooltipId) {
  const tooltipWrapper = document.createElement('div');
  tooltipWrapper.classList.add('tooltip-wrapper');

  tooltipWrapper.addEventListener('sgihtooltipupdate', (event) => {
    const options = event.detail || {};
    const tooltip = document.getElementById(tooltipId);

    tooltip.innerHTML = options?.content || '(sem conteúdo)';

    const boundingClientRectTooltip = tooltip.getBoundingClientRect();
    const boundingClientRectTooltipWrapper = tooltipWrapper.getBoundingClientRect();

    const left = Math.round(
      boundingClientRectTooltipWrapper.x
      + boundingClientRectTooltipWrapper.width
      + (boundingClientRectTooltip.width / 2)
    );

    const top = Math.round(
      boundingClientRectTooltipWrapper.top + (boundingClientRectTooltipWrapper.height * (1 - (1 / 3)))
    );

    if (top === 0 || left === 0) {
      return;
    }

    tooltip.style.top = `${top}px`;
    tooltip.style.left = `${left}px`;

    tooltip.classList.add('active-tooltip');
  });

  const resetTooltip = (_event) => {
    const tooltip = document.getElementById(tooltipId);

    tooltip.classList.remove('active-tooltip');
    tooltip.innerHTML = '';
  }

  tooltipWrapper.addEventListener('mouseleave', resetTooltip);

  tooltipWrapper.addEventListener('wheel', resetTooltip);

  return tooltipWrapper;
}