import OrigemRelatorio from 'model/OrigemRelatorio';
import StatusRelatorio from 'model/StatusRelatorio';
import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';

class PaginaListaRelatoriosQualidadeAgua extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                },
                    StatusRelatorio(
                        (statusRelatorio)=>{
                            this.setState({
                                statusRelatorio
                            }, ()=>{
                                const source = {
                                    date : 'dd/MM/yyyy',
                                    datafields: [
                                        { name: 'id', type: 'number', map: 'id' },
                                        { name: 'codigoEstacao', type: 'string', map: 'codigoEstacao' },
                                        { name: 'dataVisita', type: 'date', map: 'dataVisita' },
                                        { name: 'roteiro', type: 'number', map: 'roteiro' },
                                        { name: 'tipo', type: 'string', map: 'tipo' },
                                        { name: 'nomeEstacao', type: 'string', map: 'nomeEstacao' },
                                        { name: 'campanha', type: 'date', map: 'campanha' },
                                        { name: 'equipe', type: 'string', map: 'equipe' },
                                        { name: 'dataEnvelopes', type: 'date', map: 'dataEnvelopes' },
                                        { name: 'dataCadernetas', type: 'date', map: 'dataCadernetas' },
                                        { name: 'regional', type: 'string', map: 'regional' },
                                        { name: 'origem', type: 'string', map: 'origem' },
                                        { name: 'equipeFormatada', type: 'string', map: 'equipeFormatada' },
                                        { name: 'perfilUltimaAlteracaoFluxo', type: 'string', map: 'perfilUltimaAlteracaoFluxo' },
                                    ],
                                    id: 'id',
                                };

                                const columns = [
                                    { text: 'ID', dataField: 'id', width: '4%' },
                                    { text: 'Status Atual', dataField: 'perfilUltimaAlteracaoFluxo', width: '10%', filtertype: 'checkedlist', filteritems: this.state ? this.state.statusRelatorio : [] },
                                    { text: 'Origem', dataField: 'origem', width: '6%', filtertype: 'checkedlist', filteritems: OrigemRelatorio },
                                    { text: 'Roteiro', dataField: 'roteiro', filtertype:'number', width: '4%' },
                                    { text: 'Regional', dataField: 'regional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                                    { text: 'Código', dataField: 'codigoEstacao', width: '7%' },
                                    { text: 'Estação', dataField: 'nomeEstacao' },
                                    { text: 'Tipo', dataField: 'tipo', width: '5%' },
                                    { text: 'Data', dataField: 'dataVisita', filtertype: 'date', cellsformat: 'dd/MM/yyyy', width: '6%', },
                                    { text: 'Campanha', dataField: 'campanha',filtertype: 'date', cellsformat: 'MM/yyyy', width: '7%' },
                                    {
                                        text: 'Equipe', dataField: 'equipe', sortable: false,
                                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                                            return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${rowdata.equipeFormatada}">${value}</div>`;
                                        },
                                    },
                                ];

                                this.tableConfig = { source, columns }
                                this.setState({
                                    exibir:true
                                })
                            })

                        }
                    )
                )
            }
        )

    }

    render() {
        return this.state ? this.state.exibir ?
            <CrudListPageScaffold
                title="Relatórios de qualidade de água"
                tableConfig={this.tableConfig}
                baseUrl="/relatoriosQualidadeAgua"
                history={this.props.history}
                formLinkTitle="Novo relatório"
                tableName="relatoriosQA"
                shouldAddReportFlowButton={true}
                shouldAddRefreshButton={true}
                ehRelatorio={true}
                tipoRelatorio="QUALIDADE_AGUA"
                urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/qualidadeAgua"
            />
            : <></>
            : <></>
    }
}

export default PaginaListaRelatoriosQualidadeAgua;
