import {Field} from "react-final-form";
import React from "react";

const TipoEstacaoNaoComecaCom = ({tipo, children}) => {
    return <Field name="tipoEstacao">
        {({input: {value}}) => {
            const tipoEstacaoCorreto = !!value && value.startsWith(tipo);
            return children(!tipoEstacaoCorreto);
        }}
    </Field>
}

export default TipoEstacaoNaoComecaCom;