import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form, FormSpy } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import TextAreaField from "../../components/form/field/TextAreaField";
import DateTimeField from "../../components/form/field/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import FieldLabel from "../../components/form/field/FieldLabel";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import createDecorator from "final-form-calculate";
import { FieldArray } from "react-final-form-arrays";
import swal from "sweetalert";

import "./PaginaFormInspecaoProducaoDeCampo";
import store from "../../store";
import { showGlobalNotification, stopGlobalLoading } from "../../reducers";
import moment from "moment";
import PromiseLoader from "../../components/PromiseLoader";
import Warning from "../../components/form/Warning";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import BotaoPadrao from "../../components/botao/BotaoPadrao";
import {setValue as changeValue} from "../../util/mutators/ChangeValue"
import {JqxDropDownListAdapter} from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import { requiredWithNotification } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import SelectField from "components/form/field/SelectField/SelectField";
import voltarParaGrid from "util/VoltarParaGrid";

// const isNotVehicleSign = (value) =>
//   value
//     ? value.match(/[A-Z]{3}\d[A-Z\d]\d{2}/)
//       ? undefined
//       : "Placa inválida"
//     : "Placa obrigatória";
let dadosEstacao;
var exibiuMensagemEm = 0;
const identifierValueResolver = (e) => e.id || e.codigo;
const roteiroServicoLabelResolver = (e) => `${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
const veiculoLabelResolver = (e) => e.placa || '';

var temErro = false;
const ignorarCasasDecimaisAdicionais = (valorDigitado) => {
  if(valorDigitado){
    valorDigitado = valorDigitado.toString()
    if((valorDigitado.indexOf('.') !== -1)  || (valorDigitado.indexOf(',') !== -1) ){
      // verifica se o tamanho do valor digitado é maior que as casas decimais permitidas
      if(valorDigitado.length > (valorDigitado.indexOf('.') + 2 + 1)){
        // ignora o valor digitado acima das casas decimais permitidas
        valorDigitado = valorDigitado.slice(0, valorDigitado.length -1)
        // converte em numero
        valorDigitado = parseFloat(valorDigitado).toFixed(2);
      }
    }

  }
  return valorDigitado
}

const requiredWithMessage = (message) => (value) =>
  (value) ? message : undefined;
const validarReserva = (contain) => (value, allValues) =>
requiredWithMessage()(value) ||
(!!allValues.numVisitasPluviometricas && allValues.numVisitasPluviometricas >= 1
  ? undefined
  : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")

  && (!!allValues.numMedicoesDescargaLiquidaEfetiva && allValues.numMedicoesDescargaLiquidaEfetiva >= 1
    ? undefined
    : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")

  && (!!allValues.numMedicoesDescargaSolida && allValues.numMedicoesDescargaSolida >= 1
      ? undefined
      : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")

  && (!!allValues.numVisitasFluviometricas && allValues.numVisitasFluviometricas >= 1
        ? undefined
        : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")
  && (!!allValues.numMedicoesQA && allValues.numMedicoesQA >= 1
        ? undefined
        : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")
  && (!!allValues.numMedicoesSecaoTransversal && allValues.numMedicoesSecaoTransversal >= 1
        ? undefined
        : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")
  && (!!allValues.numVisitasPCD && allValues.numVisitasPCD >= 1
    ? undefined
    : "Um relatório de produção de campo deve possuir ao menos uma visita, medição ou levantamento de seção.")
  ;




// https://codesandbox.io/s/oq52p6v96y
let veiculoEncontrado = true;

/**
 * @enum {object}
 * Parâmetros com valores default para caso os valores
 * finais recuperados da API não sejam carregados
 */
const ParametrosRelatorioDefault = {
  periodoMaximoCampanhaEmDias: 45,
}

const decoradoresGlobais = createDecorator(
  {
    field: "idVeiculo",
    updates: (value, nome, { quilometragens }) => {
      // let clearValue = !!value && value.replace(`_`, "");
      // console.log("VALORES REQUEST", value)
      // if (clearValue.length >= 7) {
        return createServiceFor("/veiculos")
          .customURL("GET", `${value}`)
          .then((res) => {
            veiculoEncontrado = true;
            const data = res.data;
            const quilometragemIdx0 =
              (quilometragens &&
                quilometragens.length &&
                quilometragens[0].kmInicial) ||
                // data.quilometragemAtual;
                data.kmAtual;
            return {
              // placaVeiculo: value,
              veiculoMarca: data.marca,
              veiculoTipo: data.tipo,
              veiculoCombustivel: data.combustivel,
              // quilometragemInicial: data.quilometragemAtual,
              quilometragemInicial: data.kmAtual,
              "quilometragens[0].kmInicial": quilometragemIdx0,
            };
          })
          .catch((e) => {
            veiculoEncontrado = false;
            store.dispatch(
              showGlobalNotification({
                message: "Placa de veículo inexistente",
              })
            );
            return {
              veiculoMarca: "",
              veiculoTipo: "",
              veiculoCombustivel: "",
              quilometragemInicial: "",
            };
          });
      // }
      // return {};
    },
  },
  {
    field: ["quilometragemInicial", /quilometragens\[\d+\]/], // /kmDiario\[\d\]/, // when a field matching this pattern changes...
    updates: {
      quilometragemTotal: (
        ignoredValue,
        { quilometragemInicial = 0, quilometragens = [] }
      ) => {
        let sum = 0;
        quilometragens.forEach((linha) => {
          linha.kmDiario = (linha.kmFinal || "0") - (linha.kmInicial || "0");
          sum += linha.kmDiario;
        });
        return sum;
      },
    },
  },
  {
    field: /quilometragens\[\d+\]\.\w+/,
    updates: (valor, nome, { quilometragens }) => {
      const regex = /quilometragens\[(\d+)\]\.(\w+)/;
      const resultados = regex.exec(nome);
      const mudancas = {};

      if (quilometragens && resultados && resultados.length > 1) {
        const idx = parseInt(resultados[1]);
        const prop = resultados[2];

        if ("kmFinal" === prop && idx + 1 < quilometragens.length) {
          mudancas[`quilometragens[${idx + 1}].kmInicial`] = valor;
        }

        if ("kmInicial" === prop && idx + 1 < quilometragens.length && !valor) {
          mudancas[`quilometragens[${idx}].kmInicial`] =
            quilometragens[idx + 1].kmFinal || 0;
        }
      }

      return mudancas;
    },
  },
  {
    field: ["dataInicial", "dataFinal"],
    updates: (valor, nome, allValues) => {
      const mudancas = {};

      if (!allValues.dataInicial || !allValues.dataFinal) return 0;

      const dataFinal = moment(allValues.dataFinal, "DD/MM/yyyy");
      const dataInicial = moment(allValues.dataInicial, "DD/MM/yyyy");

      const diferencaEmDias =
        dataFinal && dataInicial ? dataFinal.diff(dataInicial, "day")  : 0;

      // correção para quando o dataFinal igual dataInicial conseiderar como 1 dia
      mudancas.totalDeDias = diferencaEmDias + 1;

      const quilometragens = allValues.quilometragens || [];
      const roteirosPercorridos = allValues.roteirosPercorridos || [];

      let roteirosAnteriores = JSON.parse(JSON.stringify(roteirosPercorridos)); // Cópia do array de roteiros, sem referências
      roteirosAnteriores = roteirosAnteriores.filter(ra => ra.roteiro) // Filtra inputs vazios

      if (quilometragens.length > diferencaEmDias) {
        quilometragens.splice(
          diferencaEmDias - 1,
          quilometragens.length - diferencaEmDias - 1
        );
      }

      if (roteirosPercorridos.length > diferencaEmDias) {
        roteirosPercorridos.splice(
          diferencaEmDias - 1,
          roteirosPercorridos.length - diferencaEmDias - 1
        );
      }
      if ( diferencaEmDias ===0) {
        roteirosPercorridos.splice(
          0,
          roteirosPercorridos.length
        );
        if (quilometragens.length > 1){
          quilometragens.splice(
            0,
            quilometragens.length
          );
        }

      }

      for (let i = 0; i < diferencaEmDias + 1; i++) {
        const dataAtual = dataInicial.add(0 === i ? 0 : 1, "days");

        if (!quilometragens[i]) quilometragens[i] = {};
        quilometragens[i].data = dataAtual.format("DD/MM/YYYY");

        if (!roteirosPercorridos[i]) roteirosPercorridos[i] = {};
        roteirosPercorridos[i].data = dataAtual.format("DD/MM/YYYY");
      }

      // Atribui o roteiro já digitado se a data do roteiro ainda estiver dentro do novo intervalo de data inicial/final
      roteirosPercorridos.forEach((rp, i) => {
        roteirosPercorridos[i].roteiro = '';
      });
      roteirosAnteriores.forEach(ra => {
        roteirosPercorridos.some((rp, i) => {
          if (rp.data === ra.data) {
            roteirosPercorridos[i].roteiro = ra.roteiro || '';
            return true;
          }
          return false;
        });
      });

      mudancas.quilometragens = quilometragens;
      mudancas.roteirosPercorridos = roteirosPercorridos;

      return mudancas;
    },
  },
  {
    field: [/adiantamento\w+/, /gasto\w+/],
    updates: {
      saldoConsumo: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais( allValues.adiantamentoConsumo) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoConsumo) || 0.0;
        return parseFloat(
          (Number(tempAdiantamento) - Number(tempGasto)).toFixed(2)
        );
      },
      saldoTransporte: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais(allValues.adiantamentoTransporte) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoTransporte) || 0.0;
        return parseFloat(
          (Number(tempAdiantamento) - Number(tempGasto)).toFixed(2)
        );
      },
      saldoPessoaFisica: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais(allValues.adiantamentoPessoaFisica) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoPessoaFisica) || 0.0;
        return parseFloat(
          (Number(tempAdiantamento) - Number(tempGasto)).toFixed(2)
        );
      },
      saldoPessoaJuridica: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais(allValues.adiantamentoPessoaJuridica) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoPessoaJuridica) || 0.0;
        return parseFloat(
          (Number(tempAdiantamento) - Number(tempGasto)).toFixed(2)
        );
      },
      saldoVoluntario: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais(allValues.adiantamentoVoluntario) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoVoluntario) || 0.0;
        return parseFloat(
          (Number(tempAdiantamento) - Number(tempGasto)).toFixed(2)
        );
      },
      adiantamentoTotal: (ignoredValue, allValues) => {
        let tempAdiantamentoConsumo = ignorarCasasDecimaisAdicionais(allValues.adiantamentoConsumo) || 0.0;
        let tempAdiantamentoTransporte = ignorarCasasDecimaisAdicionais(allValues.adiantamentoTransporte) || 0.0;
        let tempAdiantamentoPessoaFisica = ignorarCasasDecimaisAdicionais(allValues.adiantamentoPessoaFisica) || 0.0;
        let tempAdiantamentoPessoaJuridica = ignorarCasasDecimaisAdicionais(allValues.adiantamentoPessoaJuridica) || 0.0;
        let tempAdiantamentoVoluntario = ignorarCasasDecimaisAdicionais(allValues.adiantamentoVoluntario) || 0.0;
        return parseFloat(
          (
            Number(tempAdiantamentoConsumo) +
            Number(tempAdiantamentoTransporte) +
            Number(tempAdiantamentoPessoaFisica) +
            Number(tempAdiantamentoPessoaJuridica) +
            Number(tempAdiantamentoVoluntario)
          ).toFixed(2)
        );
      },
      gastoTotal: (ignoredValue, allValues) => {
        let tempGastoConsumo = ignorarCasasDecimaisAdicionais(allValues.gastoConsumo) || 0.0;
        let tempGastoTransporte = ignorarCasasDecimaisAdicionais(allValues.gastoTransporte) || 0.0;
        let tempGastoPessoaFisica = ignorarCasasDecimaisAdicionais(allValues.gastoPessoaFisica) || 0.0;
        let tempGastoPessoaJuridica = ignorarCasasDecimaisAdicionais(allValues.gastoPessoaJuridica) || 0.0;
        let tempGastoVoluntario = ignorarCasasDecimaisAdicionais(allValues.gastoVoluntario) || 0.0;
        return parseFloat(
          (
            Number(tempGastoConsumo) +
            Number(tempGastoTransporte) +
            Number(tempGastoPessoaFisica) +
            Number(tempGastoPessoaJuridica) +
            Number(tempGastoVoluntario)
          ).toFixed(2)
        );
      },
      saldoTotal: (ignoredValue, allValues) => {
        let tempAdiantamento = ignorarCasasDecimaisAdicionais(allValues.adiantamentoTotal) || 0.0;
        let tempGasto = ignorarCasasDecimaisAdicionais(allValues.gastoTotal) || 0.0;
        return parseFloat(
          (
          Number(tempAdiantamento) - Number(tempGasto)
          ).toFixed(2)
        );
      },
    },
  },
  {
    field: [
      "totalEstacoesRoteiro",
      "numVisitasFluviometricas",
      "numVisitasPluviometricas",
    ],
    updates: {
      saldoVisitasEstacoes: (
        ignoredValue,
        {
          totalEstacoesRoteiro,
          numVisitasFluviometricas,
          numVisitasPluviometricas,
        }
      ) => {
        return (
          Number(totalEstacoesRoteiro) -
          Number(numVisitasFluviometricas) -
          Number(numVisitasPluviometricas)
        );
      },
    },
  }
);

const criarDecoradorCalculoMedicoes = ($this) =>
  createDecorator({
    field: ["codigoRoteiro", "dataInicial", "dataFinal", "campanha", "idsMembrosEquipe"],
    updates: function (
      valor,
      nome,
      { id, codigoRoteiro, dataInicial, dataFinal, campanha, atividadesNaoRealizadas, idsMembrosEquipe}
    ) {
      if (codigoRoteiro && dataInicial && dataFinal && campanha && idsMembrosEquipe) {
        if (id) {
          $this.requerirResultadoFiltrosProducao({
            numeroRoteiro: codigoRoteiro,
            dataInicial,
            dataFinal,
            campanha,
            idsMembrosEquipe
          }, true)
            .then((res) => {
                $this.preencherPendencias(true, res.pendencias);
            })
          return {};
        }

        $this.requerirResultadoFiltrosProducao({
          numeroRoteiro: codigoRoteiro,
          dataInicial,
          dataFinal,
          campanha,
          idsMembrosEquipe
        })
          .then((res) => {
            const {
              numEstacoesNoRoteiro,
              numVisitasPluviometricas,
              numMedicoesDescargaLiquidaEfetiva,
              numMedicoesDescargaLiquidaNaoEfetiva,
              numMedicoesDescargaSolida,
              numVisitasFluviometricas,
              numMedicoesQA,
              numMedicoesSecaoTransversal,
              relatoriosNoFiltro,
              pendencias,
            } = res;

            //Atualiza automaticamente as estações visitadas

            // $this.preencherEstacoesVisitadas(
            //   false,
            //   false,
            //   relatoriosNoFiltro,
            //   numVisitasPluviometricas,
            //   numVisitasFluviometricas,
            //   console.log(numVisitasFluviometricas, "numVisitasFluviometricas"),
            //   numEstacoesNoRoteiro
            // );
            $this.preencherPendencias(true, pendencias);

            return {
              numVisitasPluviometricas,
              numMedicoesDescargaLiquidaEfetiva,
              numMedicoesDescargaLiquidaNaoEfetiva,
              numMedicoesDescargaSolida,
              numVisitasFluviometricas,
              numMedicoesQA,
              numMedicoesSecaoTransversal,
              totalEstacoesRoteiro: numEstacoesNoRoteiro,
            };
          })
          .catch((e) => {
            const mensagem =
              e.response.data && e.response.data.erro
                ? e.response.data.erro
                : "Ocorreu um erro ao pesquisar relatórios no período";

            store.dispatch(showGlobalNotification({ message: mensagem }));

            $this.preencherEstacoesVisitadas(true, false, [], 0, 0, 0);

            $this.preencherPendencias(true, []);

            return {
              numVisitasPluviometricas: "",
              numMedicoesDescargaLiquidaEfetiva: "",
              numMedicoesDescargaLiquidaNaoEfetiva: "",
              numMedicoesDescargaSolida: "",
              numVisitasFluviometricas: "",
              numMedicoesQA: "",
              numMedicoesSecaoTransversal: "",
            };
          });
      }
      return {};
    },
  });

const estiloCustomizadoPositivoNegativo = [
  { max: -1, style: { color: "crimson" } },
  { min: 1, style: { color: "blue" } },
];

class PaginaFormInspecaoProducaoDeCampo extends React.Component {
  constructor(props) {
    super(props);

    const decoradoresForm = [
      decoradoresGlobais,
      criarDecoradorCalculoMedicoes(this),
    ];
    // const pendencias = [];
    this.state = {
      emEdicao: false,
      decoradoresForm,
      dados:dadosEstacao,
      parametrosRelatorios: ParametrosRelatorioDefault,
      // pendencias
    };
  }

  async validarRoteiros(relatorio) {
    var naoPreenchido = false;
    var kmInicialNaoPreenchido = false;
    var kmFinalNaoPreenchido = false;
    var repetido = false;
    if(relatorio && relatorio.roteirosPercorridos && relatorio.roteirosPercorridos.length) {
      naoPreenchido= relatorio.roteirosPercorridos.find(r => !r.roteiro);
    }

    let id = this.props.match.params.id ? this.props.match.params.id : 0;
    let numeroRoteiro = relatorio?.codigoRoteiro;
    let dataInicial = relatorio?.dataInicial;
    let dataFinal = relatorio?.dataFinal;
    let campanha = relatorio?.campanha;
    let idsMembrosEquipe = relatorio?.idsMembrosEquipe;
    repetido = await createServiceFor("/relatoriosProducaoCampo/repetido")
      .getWithParams({
        id,
        numeroRoteiro,
        dataInicial,
        dataFinal,
        campanha,
        idsMembrosEquipe,
        omitirExcecoes: false,
      })
      .then((res) => {
        if (res.data) {
          store.dispatch(showGlobalNotification({ message: "Não é possível criar um relatório de Produção de Campo para um mesmo Roteiro, Equipe, Período e Campanha", forcarExibicao: true }));
          return true;
        }
        return false;
      })

    if( relatorio && relatorio.quilometragens && relatorio.quilometragens.length) {
      kmInicialNaoPreenchido = relatorio.quilometragens.find(q => q.kmInicial === 0);
      kmFinalNaoPreenchido = relatorio.quilometragens.find(q => q.kmFinal === 0)
    }
    if(naoPreenchido || repetido || !relatorio.estacoesVisitadas || kmInicialNaoPreenchido || kmFinalNaoPreenchido ){
      return  false;
    }
    return true;
  };
  forcarValidarRoteiros(relatorio) {
    if(relatorio && relatorio.roteirosPercorridos && relatorio.roteirosPercorridos.length) {
      const naoPreenchido = relatorio.roteirosPercorridos.find(r => !r.roteiro);
      if(naoPreenchido){
        store.dispatch(showGlobalNotification({ message: "Os campos de Roteiro percorrido são obrigatórios", forcarExibicao : true}));
      }
    }
    if( relatorio && relatorio.quilometragens && relatorio.quilometragens.length) {
      const kmInicialNaoPreenchido = relatorio.quilometragens.find(q => q.kmInicial === 0);
      const kmFinalNaoPreenchido = relatorio.quilometragens.find(q => q.kmFinal === 0)

      if(kmInicialNaoPreenchido || kmFinalNaoPreenchido){
        store.dispatch(showGlobalNotification({ message: "Os campos de Quilometragem diária são obrigatórios", forcarExibicao : true}));
      }
    }

    if(!relatorio.estacoesVisitadas){
      store.dispatch(showGlobalNotification({ message: "O campo Estações visitadas é obrigatório", forcarExibicao : true}));
    }
  };

  async submitForm(values) {
    if(await this.validarRoteiros(values)){
    createServiceFor("/relatoriosProducaoCampo")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
    }
  }

  async getParametrosRelatorio() {
    const response = await createServiceFor("/relatoriosProducaoCampo/parametros").query();

    const parametrosRelatorios = response?.data || ParametrosRelatorioDefault;

    this.setState({
      parametrosRelatorios,
    });
  }

  componentDidMount() {
    this.getParametrosRelatorio();

    const promises = [
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/roteirosServico/paraVisualizarRelatorios").query(),
      createServiceFor("/roteirosServico/paraEditarRelatorios").query(),
      createServiceFor("/veiculos/porUnidadeRegional").query(),
    ];

    const isUpdate = this.props.match.params.id ? true : false;
    const isViewOnly = this.props.history.location && this.props.history.location.state && !this.props.history.location.state.allowEdit;

    const habilitar = isUpdate || isViewOnly;

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/relatoriosProducaoCampo").get(
          this.props.match.params.id
        )
      );

      this.setState({
        emEdicao: true
      });
    }

    const controleBotoes = {
      habilitarCampoEstacoesVisitas: false,
      habilitarBotaoLimparEstacoesVisitas: habilitar,
      habilitarBotaoInserirEstacoesVisitadas: !habilitar,
      habilitarCampoAtividadesNaoRealizadas: false,
      habilitarBotaoLimparAtividadesNaoRealizadas: habilitar,
      habilitarBotaoExibirPendencias: !habilitar,
    }

    const promise = Promise.all(promises)
      .then(([usuarios, roteirosServicoVisualizar, roteirosServicoEditar, veiculosUnidadeRegional, inspecao = {}]) => {

        if(inspecao) {
          this.setState({
            inspecao: inspecao.data
          })
        }

        return {
        usuarios: usuarios.data,
        roteirosServicoVisualizar: roteirosServicoVisualizar.data,
        roteirosServicoEditar: roteirosServicoEditar.data,
        veiculosUnidadeRegional: veiculosUnidadeRegional.data,
        initialValues: inspecao.data ?
        {...inspecao.data,
          adiantamentoConsumo: parseFloat(inspecao.data.adiantamentoConsumo).toFixed(2) || '0.0',
          adiantamentoTransporte: parseFloat(inspecao.data.adiantamentoTransporte).toFixed(2) ||'0.0',
          adiantamentoPessoaFisica: parseFloat(inspecao.data.adiantamentoPessoaFisica).toFixed(2) ||'0.0',
          adiantamentoPessoaJuridica: parseFloat(inspecao.data.adiantamentoPessoaJuridica).toFixed(2) ||'0.0',
          adiantamentoVoluntario: parseFloat(inspecao.data.adiantamentoVoluntario).toFixed(2) ||'0.0',
          gastoConsumo: parseFloat(inspecao.data.gastoConsumo).toFixed(2) ||'0.0',
          gastoTransporte: parseFloat(inspecao.data.gastoTransporte).toFixed(2) ||'0.0',
          gastoPessoaFisica: parseFloat(inspecao.data.gastoPessoaFisica).toFixed(2) ||'0.0',
          gastoPessoaJuridica: parseFloat(inspecao.data.gastoPessoaJuridica).toFixed(2) ||'0.0',
          gastoVoluntario: parseFloat(inspecao.data.gastoVoluntario).toFixed(2) ||'0.0',
          ...controleBotoes,
        } : {
          adiantamentoConsumo: '0.0',
          adiantamentoTransporte: '0.0',
          adiantamentoPessoaFisica: '0.0',
          adiantamentoPessoaJuridica:'0.0',
          adiantamentoVoluntario:'0.0',
          gastoConsumo: '0.0',
          gastoTransporte: '0.0',
          gastoPessoaFisica: '0.0',
          gastoPessoaJuridica: '0.0',
          gastoVoluntario: '0.0',
          ...controleBotoes,
        }
      }
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  requerirResultadoFiltrosProducao({
                                     numeroRoteiro,
                                     dataInicial,
                                     dataFinal,
                                     campanha,
                                     idsMembrosEquipe
                                   },
                                   bloquearErro = false) {
    let id = this.props.match.params.id?this.props.match.params.id : 0;

    if (!numeroRoteiro) {
      return Promise.reject(
        Error('O campo roteiro é obrigatório.')
      );
    }

    if (!idsMembrosEquipe || idsMembrosEquipe.length === 0) {
      return Promise.reject(
        Error('Campo equipe deve ter ao menos um membro selecionado.')
      );
    }

    if (!(dataInicial && dataFinal)) {
      return Promise.reject(
        Error('Campo de data inicial e data final são obrigatórios.')
      );
    }

    if (!campanha) {
      return Promise.reject(
        Error('Campo campanha é obrigatório.')
      );
    }

    return createServiceFor("/relatoriosProducaoCampo/paraRoteiro")
        .getWithParams({
          id,
          numeroRoteiro,
          dataInicial,
          dataFinal,
          campanha,
          idsMembrosEquipe,
          omitirExcecoes: bloquearErro,
        })
        .then((res) => {

          if(res){
            this.setState({
              pendencias : res.pendencias,
            });
            return{totalEstacoesRoteiro: res.data.numEstacoesNoRoteiro,
              ...res.data}
          }

        })
  }

  forcarRequisicaoDeFiltrosProducao(values, changeValue) {
    this.requerirResultadoFiltrosProducao({
      numeroRoteiro: values.codigoRoteiro,
      dataInicial: values.dataInicial,
      dataFinal: values.dataFinal,
      campanha: values.campanha,
      idsMembrosEquipe: values.idsMembrosEquipe
    }, true)
        .then((res) => {
          this.preencherEstacoesVisitadas(
              true,
              true,
              res.relatoriosNoFiltro,
              res.numVisitasPluviometricas,
              res.numVisitasFluviometricas,
              res.totalEstacoesRoteiro
          )
          this.setState({
            pendencias : res.pendencias,
          });

          changeValue('numVisitasPluviometricas', res.numVisitasPluviometricas)
          changeValue('numMedicoesDescargaLiquidaEfetiva', res.numMedicoesDescargaLiquidaEfetiva)
          changeValue('numMedicoesDescargaLiquidaNaoEfetiva', res.numMedicoesDescargaLiquidaNaoEfetiva)
          changeValue('numMedicoesDescargaSolida', res.numMedicoesDescargaSolida)
          changeValue('numVisitasFluviometricas', res.numVisitasFluviometricas)
          changeValue('numMedicoesQA', res.numMedicoesQA)
          changeValue('numVisitasPCD', res.numVisitasPCD)
          changeValue('numMedicoesSecaoTransversal', res.numMedicoesSecaoTransversal)
          changeValue('totalEstacoesRoteiro', res.totalEstacoesRoteiro)
        })
      .catch((error) => {
        store.dispatch(
          showGlobalNotification({
            message: error?.message || 'Não foi possível inserir / calcular as totalizações das estações visitadas.',
            forcarExibicao: true,
          })
        );
      })
  }

  preencherEstacoesVisitadas(
    atualizarEstado = false,
    mostrarAlerta = true,
    relatoriosNoFiltro = this.state.relatoriosNoFiltro,
    numVisitasPluviometricas = this.state.numVisitasPluviometricas,
    numVisitasFluviometricas = this.state.numVisitasFluviometricas,
    numEstacoesNoRoteiro = this.state.numEstacoesNoRoteiro
  ) {
    if (atualizarEstado) {
      this.setState({
        relatoriosNoFiltro,
        numVisitasPluviometricas,
        numVisitasFluviometricas,
        numEstacoesNoRoteiro,
      });
    }

    const textoEstacoesVisitadas = (relatoriosNoFiltro || [])
      .map(
        (relatorio) =>
          `${relatorio.tipo} - ${relatorio.codigoEstacao} - ${relatorio.nomeEstacao}`
      )
      .join("\n");

    this.formInstance.change("estacoesVisitadas", textoEstacoesVisitadas);

    if (mostrarAlerta) {
      const textoAlerta =
        `Total de estações no roteiro: ${numEstacoesNoRoteiro} \n` +
        `Total de visitas fluviométricas: ${numVisitasFluviometricas} \n` +
        `Total de visitas pluviométricas: ${numVisitasPluviometricas} \n` +
        `Saldo: ${
          numEstacoesNoRoteiro -
          numVisitasFluviometricas -
          numVisitasPluviometricas
        }`;

        swal({
          title: "Informação",
          icon: "info",
          text: textoAlerta,
          buttons: {
            confirm: {
              text: "OK",
              className: "btn-success",
            },
          },
        });
    }
  }

  limparEstacoesVisitadas() {
    this.formInstance.change("estacoesVisitadas", "");
    this.formInstance.change("numVisitasPluviometricas", 0);
    this.formInstance.change("numMedicoesDescargaLiquidaEfetiva", 0);
    this.formInstance.change("numMedicoesDescargaLiquidaNaoEfetiva", 0);
    this.formInstance.change("numMedicoesDescargaSolida", 0);
    this.formInstance.change("numVisitasFluviometricas", 0);
    this.formInstance.change("numMedicoesQA", 0);
    this.formInstance.change("numMedicoesSecaoTransversal", 0);
    this.formInstance.change("totalEstacoesRoteiro", 0);
  }

  async preencherPendencias(
    atualizarEstado = false,
    pendencias,
    atualizarPendencias = false
  ) {
    const numeroRoteiro = this.formInstance.getState().values.codigoRoteiro;
    const idsMembrosEquipe = this.formInstance.getState().values.idsMembrosEquipe;
    const dataInicial = this.formInstance.getState().values.dataInicial;
    const dataFinal = this.formInstance.getState().values.dataFinal;
    const campanha = this.formInstance.getState().values.campanha;

    if (!numeroRoteiro) {
      store.dispatch(
        showGlobalNotification({
          message: 'O campo roteiro é obrigatório.',
        })
      );
      return;
    }

    if (!idsMembrosEquipe || idsMembrosEquipe.length === 0) {
      store.dispatch(
        showGlobalNotification({
          message: 'Campo equipe deve ter ao menos um membro selecionado.',
        })
      );
      return;
    }

    if (!(dataInicial && dataFinal)) {
      store.dispatch(
        showGlobalNotification({
          message: 'Campo de data inicial e data final são obrigatórios.',
        })
      );
      return;
    }

    if (!campanha) {
      store.dispatch(
        showGlobalNotification({
          message: 'Campo campanha é obrigatório.',
        })
      );
      return;
    }

    pendencias = await  createServiceFor("/relatoriosProducaoCampo/paraRoteiro")
      .getWithParams({
        id : this.formInstance.getState().values.id ? this.formInstance.getState().values.id : 0,
        numeroRoteiro : this.formInstance.getState().values.codigoRoteiro,
        dataInicial : this.formInstance.getState().values.dataInicial,
        dataFinal : this.formInstance.getState().values.dataFinal,
        campanha : this.formInstance.getState().values.campanha,
        idsMembrosEquipe: this.formInstance.getState().values.idsMembrosEquipe,
        omitirExcecoes : true
      })
      .then((res) => {
        return res.data.pendencias;
      })

    if (!pendencias) return;

    if (atualizarEstado) {
      this.setState({
        pendencias,
      });
    }

    const linhasTextoPendencias = [];

    if (pendencias.pluviometricas && pendencias.pluviometricas.length) {
      linhasTextoPendencias.push("Relatórios pluviométricos pendentes:");
      for (const pendencia of pendencias.pluviometricas) {
        linhasTextoPendencias.push(`${pendencia.codigo} - ${pendencia.nome}`);
      }
      linhasTextoPendencias.push("");
    } else {
      linhasTextoPendencias.push("Relatórios pluviométricos sem pendência.");
    }

    if (pendencias.fluviometricas && pendencias.fluviometricas.length) {
      linhasTextoPendencias.push("Relatórios fluviométricos pendentes:");
      for (const pendencia of pendencias.fluviometricas) {
        linhasTextoPendencias.push(`${pendencia.codigo} - ${pendencia.nome}`);
      }
      linhasTextoPendencias.push("");
    } else {
      linhasTextoPendencias.push("Relatórios fluviométricos sem pendência.");
    }

    if (pendencias.qualidadeAgua && pendencias.qualidadeAgua.length) {
      linhasTextoPendencias.push("Relatórios de qualidade de água pendentes:");
      for (const pendencia of pendencias.qualidadeAgua) {
        linhasTextoPendencias.push(`${pendencia.codigo} - ${pendencia.nome}`);
      }
      linhasTextoPendencias.push("");
    } else {
      linhasTextoPendencias.push(
        "Relatórios de qualidade de água sem pendência."
      );
    }

    if (pendencias.pcd && pendencias.pcd.length) {
      linhasTextoPendencias.push("Relatórios PCD pendentes:");
      for (const pendencia of pendencias.pcd) {
        linhasTextoPendencias.push(`${pendencia.codigo} - ${pendencia.nome}`);
      }
      linhasTextoPendencias.push("");
    } else {
      linhasTextoPendencias.push(
        "Relatórios PCD sem pendência."
      );
    }

    if (pendencias.relatorioVeicularExistente) {
      linhasTextoPendencias.push("Relatório veicular sem pendências.");
    } else {
      linhasTextoPendencias.push("Relatório veicular inexistente.");
    }

    const valorInicialPendencias = this.formInstance.getState().values.atividadesNaoRealizadas;
    const valorAtualizadoPendencias = linhasTextoPendencias.join("\n");

    const idFormulario = this.formInstance.getState().values.id;

    // Já é um formulário salvo
    if(idFormulario){
      // Se campo pendencias vazio e valores diferentes
      if(!valorInicialPendencias && valorInicialPendencias !== valorAtualizadoPendencias){
        let now = new Date().getTime();
        if ((now - exibiuMensagemEm) > 2000) {
          store.dispatch(
            showGlobalNotification({
              message: "As pendências estão desatualizadas",
            })
          );
        }

        exibiuMensagemEm = now;
      }
    }

    if(atualizarPendencias){
      this.formInstance.change(
        "atividadesNaoRealizadas",
        linhasTextoPendencias.join("\n")
      );
    }

  }

  limparPendencias() {
    this.formInstance.change("atividadesNaoRealizadas", "");
  }
  forcarValidacaoSalvar(form){
    try {
      const relatorio = form.getState().values;
      this.forcarValidarRoteiros(relatorio)
    } catch (error) {
      console.log(error)
    }
  }
  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      if (veiculoEncontrado) {
        form.submit();
      } else {
        store.dispatch(
          showGlobalNotification({
            message: "Não existe um veículo com a placa informada",
          })
        );
      }
    },
  });

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.inspecao
          ? `
            ${
              this.state?.inspecao?.id
                ? `<div class="text">Relatório de produção de campo</div> - <div class="text">ID: </div>${this.state.inspecao.id}`
                : ''
            }
            ${
              this.state?.inspecao?.campanha
                ? ` - <div class="text">Campanha: </div>${this.state?.inspecao?.campanha}`
                : ''
            }
            ${
              this.state?.inspecao?.codigoRoteiro
                ? ` - <div class="text">Roteiro: </div>${this.state?.inspecao?.codigoRoteiro}`
                : ''
            }
            ${
              this.props?.location?.state?.data?.regional
                ? ` - ${this.props.location.state.data.regional}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Relatórios de produção de campo"
        baseUrl="/sgih/app/relatoriosProducaoCampo"
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        tipoRelatorio="PRODUCAO_CAMPO"
        dados ={this.state.dados}
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={(event) => this.submitForm(event)}
                decorators={this.state.decoradoresForm}
                initialValues={data.initialValues}
                mutators={{ ...arrayMutators, changeValue }}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { changeValue, push, pop, setValue },
                    getState
                  },
                  form,
                }) => {
                  // não tenho orgulho do que fiz
                  this.formInstance = form;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="quilometragemInicial"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarCampoEstacoesVisitas"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarBotaoInserirEstacoesVisitadas"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarBotaoLimparEstacoesVisitas"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarCampoAtividadesNaoRealizadas"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarBotaoExibirPendencias"
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="habilitarBotaoLimparAtividadesNaoRealizadas"
                      />
                      <Tab.Container
                        className="pb-2"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true,
                          () => this.forcarValidacaoSalvar(form)
                        )}
                      >
                        <Tab title="Identificação do roteiro">
                          <FormSectionWrapper title="Identificação">
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="codigoRoteiro"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={roteiroServicoLabelResolver}
                                  label="Roteiro"
                                  elements= {disableAllFields  ? data.roteirosServicoVisualizar : data.roteirosServicoEditar }
                                  validate={requiredWithNotification("Roteiro")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={9}>
                                <Field
                                    name="idsMembrosEquipe"
                                    component={JqxDropDownListAdapter}
                                    source={data.usuarios}
                                    label="Equipe" required
                                    checkboxes={true}
                                    displayMember="nome"
                                    valueMember="id"
                                    placeHolder="Selecione a equipe..."
                                    searchMode="startswithignorecase"
                                    enableSelection={true}
                                    enableHover={true}
                                    filterable={true}
                                    incrementalSearch={true}
                                    animationType="none"
                                    closeDelay={0}
                                    filterPlaceHolder=" Busque"
                                    disabled={disableAllFields}
                                    validate={requiredWithNotification("Equipe")}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  name="dataFinal"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicial"
                                      label="Data inicial"
                                      selectsStart={!!value}
                                      endDate={value}
                                      maxDaysUntilEnd={
                                        this.state.parametrosRelatorios.periodoMaximoCampanhaEmDias - 1
                                      }
                                      validate={requiredWithNotification(
                                        "Data inicial"
                                      )}
                                      disabled={disableAllFields}
                                      required
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  name="dataInicial"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataFinal"
                                      label="Data final"
                                      selectsEnd={!!value}
                                      startDate={value}
                                      maxDaysSinceStart={(
                                        this.state.parametrosRelatorios.periodoMaximoCampanhaEmDias - 1
                                      )}
                                      validate={requiredWithNotification(
                                        "Data final"
                                      )}
                                      disabled={disableAllFields}
                                      required
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={DateTimeField}
                                  name="campanha"
                                  label="Campanha"
                                  datePickerType="yearMonth"
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification(
                                    "Campanha"
                                  )}
                                  required
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  name="dataInicial"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={TextField}
                                      name="totalDeDias"
                                      label="Total de dias"
                                      key={value}
                                      readOnly={true}
                                    />
                                    )}
                                </Field>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Resumo de execução">
                            <Row>
                              <Col className="mt-3" md={4}>
                                    <Field
                                    component={NumberField}
                                    max={2147483647}
                                    semVirgula={true}
                                    name="numVisitasPluviometricas"
                                    label="Visitas (estações pluviométricas)"
                                    required
                                    disabled
                                    initialValue={0}
                                    forcarValorZerado
                                    validate={validarReserva("")}
                                  />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={NumberField}
                                  max={2147483647}
                                  semVirgula={true}
                                  name="numMedicoesDescargaLiquidaEfetiva"
                                  label="Medições de descarga líquida efetiva"
                                  initialValue={0}
                                  forcarValorZerado
                                  required
                                  disabled
                                  validate={validarReserva("")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={NumberField}
                                  max={2147483647}
                                  semVirgula={true}
                                  name="numMedicoesDescargaLiquidaNaoEfetiva"
                                  label="Medições de descarga líquida (Rio Seco/Rio cortado)"
                                  initialValue={0}
                                  forcarValorZerado
                                  disabled
                                  required
                                  validate={validarReserva("")}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={NumberField}
                                  max={2147483647}
                                  name="numMedicoesDescargaSolida"
                                  label="Medições de descarga sólida"
                                  initialValue={0}
                                  forcarValorZerado
                                  required
                                  disabled
                                  validate={validarReserva("")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={NumberField}
                                  min={-2147483648}
                                  max={2147483647}
                                  name="numVisitasFluviometricas"
                                  label="Visitas (estações fluviométricas)"
                                  initialValue={0}
                                  forcarValorZerado
                                  required
                                  disabled
                                  validate={validarReserva("")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                  <Field
                                    component={NumberField}
                                    min={-2147483648}
                                    max={2147483647}
                                    name="numMedicoesQA"
                                    label="Medições de parâmetro de Q.A."
                                    required
                                    disabled
                                    validate={validarReserva("")}
                                    initialValue={0}
                                    forcarValorZerado
                                  />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  component={NumberField}
                                  min={-2147483648}
                                  max={2147483647}
                                  name="numMedicoesSecaoTransversal"
                                  label="Levantamentos de seção transversal"
                                  initialValue={0}
                                  forcarValorZerado
                                  required
                                  disabled
                                  validate={validarReserva("")}
                                />
                              </Col>
                              <Col className="mt-3" md={4}>
                                    <Field
                                    component={NumberField}
                                    max={2147483647}
                                    semVirgula={true}
                                    name="numVisitasPCD"
                                    label="Visitas (estações PCD)"
                                    required
                                    disabled
                                    initialValue={0}
                                    forcarValorZerado
                                    validate={validarReserva("")}
                                  />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="dataFinalPagamento"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicialPagamento"
                                      label="Data inicial pagamento "
                                      selectsStart={!!value}
                                      endDate={value}
                                      validate={requiredWithNotification(
                                        "Data inicial pagamento"
                                      )}
                                      disabled={disableAllFields}
                                      datePickerType="yearMonth"
                                      required
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={4}>
                                <Field
                                  name="dataInicialPagamento"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataFinalPagamento"
                                      label="Data final pagamento"
                                      selectsEnd={!!value}
                                      startDate={value}
                                      validate={requiredWithNotification(
                                        "Data final pagamento"
                                      )}
                                      disabled={disableAllFields}
                                      datePickerType="yearMonth"
                                      required
                                    />
                                  )}
                                </Field>
                              </Col>{" "}
                              {/*<Col className="mt-3" md={3} >*/}
                              {/*    <Field component={DateTimeField} name='pagamentoObservadoresInicio'*/}
                              {/*        label='Data ínicio do pagamento dos observadores'  datePickerType="yearMonth"  validate={requiredWithNotification("")}/>*/}
                              {/*</Col>*/}
                              {/*<Col className="mt-3" md={3} >*/}
                              {/*    <Field component={DateTimeField} name='pagamentoObservadoresFim'*/}
                              {/*        label='Data final do pagamento dos observadores'  datePickerType="yearMonth" validate={requiredWithNotification("")} />*/}
                              {/*</Col>*/}
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Dados do veículo">
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="idVeiculo"
                                  label="Placa"
                                  mask="placaDeCarro"
                                  className="w-100"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={veiculoLabelResolver}
                                  elements={data.veiculosUnidadeRegional}
                                  buscavel={true}
                                  validate={requiredWithNotification( "Placa do veículo")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="veiculoMarca"
                                  label="Marca"
                                  readOnly={true}
                                  tabIndex="-1"
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="veiculoTipo"
                                  label="Tipo"
                                  readOnly={true}
                                  tabIndex="-1"
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="veiculoCombustivel"
                                  label="Combustível"
                                  readOnly={true}
                                  tabIndex="-1"
                                />
                              </Col>
                              {/* Soma da kilometragem diaria preenchida pelo usuario */}
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={NumberField}
                                  name="quilometragemTotal"
                                  label="Quilometragem total"
                                  readOnly={true}
                                  tabIndex="-1"
                                />
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                          <FormSectionWrapper title="Quilometragem diária">
                            <Row>
                              <Col
                                className="mt-0 d-flex align-items-end"
                                md={3}
                              >
                                <FieldLabel label="Data" />
                              </Col>
                              <Col
                                className="mt-0 d-flex align-items-end"
                                md={3}
                              >
                                <FieldLabel label="KM inicial" /><span style={{ color: 'red', alignSelf: 'flex-start'}}>*</span>
                              </Col>
                              <Col
                                className="mt-0 d-flex align-items-end"
                                md={3}
                              >
                                <FieldLabel label="KM final" /><span style={{ color: 'red', alignSelf: 'flex-start'}}>*</span>
                              </Col>
                              <Col
                                className="mt-0 d-flex align-items-end"
                                md={3}
                              >
                                <FieldLabel label="KM diária" />
                              </Col>
                            </Row>
                            <FieldArray name="quilometragens">
                              {({ fields }) =>
                                fields.map((name, index) => {
                                  return (
                                    <Row key={index} className="mt-2">
                                      <Col md={3}>
                                        <Field
                                          component={DateTimeField}
                                          name={`${name}.data`}
                                          disabled={disableAllFields}
                                          tabIndex="-1"
                                          readOnly={true}
                                          datePickerType="dateAndDayWeek"
                                        />
                                      </Col>
                                      <Col md={3}>
                                        <Field
                                          name={`quilometragens[${
                                            index - 1
                                          }].kmFinal`}
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => {
                                            return (
                                              <Field
                                                component={NumberField}
                                                max={2147483647}
                                                name={`${name}.kmInicial`}
                                                disabled={disableAllFields}
                                                initialValue={0}
                                                required
                                                validate={()=>{
                                                  if(!form.getState().values?.quilometragens[index]?.kmInicial){
                                                    return "O campo Km inicial é obrigatório";
                                                  }else{
                                                    try {
                                                      if(form.getState().values?.quilometragens[index]?.kmInicial && form.getState().values?.quilometragens[index]?.kmFinal){
                                                        if (parseFloat(form.getState().values?.quilometragens[index]?.kmInicial) > parseFloat(form.getState().values?.quilometragens[index]?.kmFinal)) {
                                                          return `O campo Km inicial não pode ser maior que ${form.getState().values?.quilometragens[index]?.kmFinal}`;
                                                        }
                                                      }
                                                    } catch (error) {
                                                      console.log("Erro ao validar Km inicial: ", error)
                                                    }
                                                  }
                                                  return;
                                                }}
                                                min={value}
                                              />
                                            );
                                          }}
                                        </Field>
                                      </Col>
                                      <Col md={3}>
                                        <Field
                                          name={`${name}.kmInicial`}
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => {
                                            return (
                                              <Field
                                                component={NumberField}
                                                max={2147483647}
                                                name={`${name}.kmFinal`}
                                                disabled={disableAllFields}
                                                initialValue={0}
                                                required
                                                validate={()=>{
                                                  if(!form.getState().values?.quilometragens[index]?.kmFinal){
                                                    return "O campo Km final é obrigatório";
                                                  }else{
                                                    try {
                                                      if(form.getState().values?.quilometragens[index]?.kmInicial && form.getState().values?.quilometragens[index]?.kmFinal){
                                                        if (parseFloat(form.getState().values?.quilometragens[index]?.kmInicial) > parseFloat(form.getState().values?.quilometragens[index]?.kmFinal)) {
                                                          return `O campo Km final não pode ser menor que ${form.getState().values?.quilometragens[index]?.kmInicial}`;
                                                        }
                                                      }
                                                    } catch (error) {
                                                      console.log('Erro ao validar Km final: ', error)
                                                    }
                                                  }
                                                  return;
                                                }}
                                                min={value}
                                              />
                                            );
                                          }}
                                        </Field>
                                      </Col>
                                      <Col md={3}>
                                        <Field
                                          component={NumberField}
                                          max={2147483647}
                                          name={`${name}.kmDiario`}
                                          defaultValue={0}
                                          readOnly={true}
                                          tabIndex="-1"
                                        />
                                      </Col>
                                    </Row>
                                  );
                                })
                              }
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Roteiro percorrido">
                          <FormSectionWrapper title="Roteiro percorrido">
                            <Row>
                              <Col className="d-flex align-items-end" md={4}>
                                <FieldLabel label="Data" />
                              </Col>
                              <Col className="d-flex align-items-end" md={8}>
                                <FieldLabel label="Roteiro" required />
                              </Col>
                            </Row>

                            <FieldArray name="roteirosPercorridos">
                              {({ fields }) =>
                                fields.map((name, index) => {
                                  return (
                                    <Row key={index} className="mt-2">
                                      <Col md={4}>
                                        <Field
                                          component={DateTimeField}
                                          name={`${name}.data`}
                                          disabled={disableAllFields}
                                          readOnly={true}
                                          tabIndex={-1}
                                          datePickerType="dateAndDayWeek"
                                        />
                                      </Col>
                                      <Col md={8}>
                                        <Field
                                            component={TextField}
                                            maxLength={300}
                                            name={`${name}.roteiro`}
                                            disabled={disableAllFields}
                                            required
                                            validate={requiredWithNotification(
                                              "Roteiro"
                                            )}
                                          />
                                      </Col>
                                    </Row>
                                  );
                                })
                              }
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Estações visitadas">
                        <FormSpy subscription={{ values: true }}>
                          {({ values }) => {

                              let habilitarCampoEstacoesVisitas = values.habilitarCampoEstacoesVisitas;
                              let habilitarBotaoLimparEstacoesVisitas = values.habilitarBotaoLimparEstacoesVisitas;
                              let habilitarBotaoInserirEstacoesVisitadas = values.habilitarBotaoInserirEstacoesVisitadas;

                              return <FormSectionWrapper title="Estações visitadas">
                                <Row>
                                  <Col className="mt-3" md={12}>
                                    <Field
                                      component={TextAreaField}
                                      name="estacoesVisitadas"
                                      label="Estações visitadas"
                                      rows={10}
                                      cols={53}
                                      disabled={!habilitarCampoEstacoesVisitas}
                                      required
                                    />
                                    {
                                      disableAllFields ? <></> :
                                      <p style={{ color: "red", fontSize: 12, marginTop: 10 }}>Clique no botão ''Inserir / totalizar estações visitadas'' para exibir as estações visitadas e totalizar as visitas e medições do relatório de produção.<br></br><br></br>Caso alguma das estações do roteiro não tenha sido visitada, apague-a da lista acima.</p>
                                    }
                                  </Col>
                                </Row>
                                <br></br>
                                <div className="float-right">
                                  <BotaoPadrao
                                    desabilitado={!habilitarBotaoLimparEstacoesVisitas}
                                    onClick={() => {
                                      this.formInstance.change('habilitarCampoEstacoesVisitas', false);
                                      this.formInstance.change('habilitarBotaoInserirEstacoesVisitadas', true);
                                      this.formInstance.change('habilitarBotaoLimparEstacoesVisitas', false);
                                      this.limparEstacoesVisitadas();
                                    }}
                                  >
                                    Limpar
                                  </BotaoPadrao>
                                </div>
                                <div className="float-right" style={{marginLeft: 20, marginRight: 20 }}>
                                  <BotaoPadrao
                                    desabilitado={!habilitarBotaoInserirEstacoesVisitadas}
                                    onClick={() =>{
                                      this.formInstance.change('habilitarCampoEstacoesVisitas', true);
                                      this.formInstance.change('habilitarBotaoInserirEstacoesVisitadas', false);
                                      this.formInstance.change('habilitarBotaoLimparEstacoesVisitas', true);
                                      this.forcarRequisicaoDeFiltrosProducao(
                                        getState().values,
                                        changeValue
                                      );
                                    }}
                                    largura="100%"
                                  >
                                  Inserir / totalizar estações visitadas
                                  </BotaoPadrao>
                                </div>

                                  <div className="float-right" style={{marginLeft: 20, textAlign: 'center', marginRight: 20, width: 400 }}>
                                    <p style={{color: "red", fontSize: 10}}>
                                      {
                                        estaEditando || disableAllFields ? 'Para habilitar a edição do campo "Estações visitadas", clique em "Limpar" depois clique em "Inserir / totalizar estações visitadas"' : 'Para habilitar a edição do campo "Estações visitadas", clique em "Inserir / totalizar estações visitadas"'
                                      }
                                    </p>
                                  </div>

                                </FormSectionWrapper>
                            }}
                            </FormSpy>
                        </Tab>

                        <Tab title="Informações financeiras">
                          <Row>
                            <Col className="mt-3" md={4}>
                              <FormSectionWrapper
                                title="Adiantamento por natureza de despesa"
                                titleStyle={{ fontSize: "1.1rem" }}
                              >
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoConsumo"
                                    label="Consumo (3390.30)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoTransporte"
                                    label="Transporte (3390.33)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoPessoaFisica"
                                    label="Pessoa física (3390.36)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoPessoaJuridica"
                                    label="Pessoa jurídica (3390.39)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoVoluntario"
                                    label="Voluntário (3390.93)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="adiantamentoTotal"
                                    label="Total"
                                    min={0}
                                    disabled={disableAllFields}
                                    step={0.01}
                                    readOnly={true}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                              </FormSectionWrapper>
                            </Col>

                            <Col
                              className="mt-3"
                              md={4}
                              name="gastoNaturezaPagamento"
                            >
                              <FormSectionWrapper
                                title="Gasto por natureza de despesa"
                                titleStyle={{ fontSize: "1.1rem" }}
                              >
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoConsumo"
                                    label="Consumo (3390.30)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoTransporte"
                                    label="Transporte (3390.33)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoPessoaFisica"
                                    label="Pessoa física (3390.36)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoPessoaJuridica"
                                    label="Pessoa jurídica (3390.39)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoVoluntario"
                                    label="Voluntário (3390.93)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="gastoTotal"
                                    label="Total"
                                    readOnly={true}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                              </FormSectionWrapper>
                            </Col>

                            <Col
                              className="mt-3"
                              md={4}
                              name="saldoNaturezaPagamento"
                            >
                              <FormSectionWrapper
                                title="Saldo"
                                titleStyle={{ fontSize: "1.1rem" }}
                              >
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="saldoConsumo"
                                    label="Consumo (3390.30)"
                                    readOnly={true}
                                    step={0.01}
                                    disabled={disableAllFields}
                                    customStylePerRange={
                                      estiloCustomizadoPositivoNegativo
                                    }
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="saldoTransporte"
                                    label="Transporte (3390.33)"
                                    readOnly={true}
                                    step={0.01}

                                    disabled={disableAllFields}
                                    customStylePerRange={
                                      estiloCustomizadoPositivoNegativo
                                    }
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="saldoPessoaFisica"
                                    label="Pessoa física (3390.36)"
                                    readOnly={true}
                                    step={0.01}
                                    disabled={disableAllFields}
                                    customStylePerRange={
                                      estiloCustomizadoPositivoNegativo
                                    }
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="saldoPessoaJuridica"
                                    label="Pessoa jurídica (3390.39)"
                                    readOnly={true}
                                    step={0.01}
                                    disabled={disableAllFields}
                                    customStylePerRange={
                                      estiloCustomizadoPositivoNegativo
                                    }
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    readOnly={true}
                                    name="saldoVoluntario"
                                    label="Voluntário (3390.93)"
                                    min={0}
                                    disabled={disableAllFields}
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                                <Col className="mt-3" md={12}>
                                  <Field
                                    component={NumberField}
                                    defaultValue={0}
                                    textoPre="R$"
                                    name="saldoTotal"
                                    label="Total"
                                    readOnly={true}
                                    step={0.01}
                                    disabled={disableAllFields}
                                    customStylePerRange={
                                      estiloCustomizadoPositivoNegativo
                                    }
                                    max={9999999999.99}
                                    decimaisAposvirgula={2}
                                    forcarValorZerado
                                    forcarSeparadorDecimalVirgula
                                  />
                                </Col>
                              </FormSectionWrapper>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab title="Estações não visitadas/não previstas">
                          <FormSpy subscription={{ values: true }}>
                            {({ values }) => {

                              let habilitarCampoAtividadesNaoRealizadas = values.habilitarCampoAtividadesNaoRealizadas;
                              let habilitarBotaoLimparAtividadesNaoRealizadas = values.habilitarBotaoLimparAtividadesNaoRealizadas;
                              let habilitarBotaoExibirPendencias = values.habilitarBotaoExibirPendencias;

                              return <FormSectionWrapper>
                              <Row>
                                <Col className="mb-3" md={12}>
                                  <Field
                                    component={TextAreaField}
                                    name="atividadesNaoRealizadas"
                                    label="Atividades não realizadas/não previstas"
                                    disabled={!habilitarCampoAtividadesNaoRealizadas}
                                    rows={7}
                                    cols={53}
                                  />
                                </Col>
                              </Row>
                              <div className="float-right">
                                <BotaoPadrao
                                  desabilitado={!habilitarBotaoLimparAtividadesNaoRealizadas}
                                  onClick={() => {
                                    this.formInstance.change('habilitarCampoAtividadesNaoRealizadas', false);
                                    this.formInstance.change('habilitarBotaoExibirPendencias', true);
                                    this.formInstance.change('habilitarBotaoLimparAtividadesNaoRealizadas', false)
                                    this.limparPendencias();
                                  }}
                                >
                                  Limpar
                                </BotaoPadrao>
                              </div>
                              <div className="float-right" style={{marginLeft: 20, marginRight: 20 }}>
                                <BotaoPadrao
                                  desabilitado={!habilitarBotaoExibirPendencias}
                                  largura="100%"
                                  onClick={() =>{
                                    this.formInstance.change('habilitarCampoAtividadesNaoRealizadas', true);
                                    this.formInstance.change('habilitarBotaoExibirPendencias', false);
                                    this.formInstance.change('habilitarBotaoLimparAtividadesNaoRealizadas', true);
                                    this.preencherPendencias(true, null, true)}}
                                >
                                  Exibir pendências
                                </BotaoPadrao>
                              </div>


                                <div className="float-right" style={{marginLeft: 20, textAlign: 'center', marginRight: 20, width: 400 }}>
                                  <p style={{color: "red", fontSize: 10}}>
                                    {
                                      estaEditando || disableAllFields ? 'Para habilitar a edição do campo "Atividades não realizadas/não previstas", clique em "Limpar" depois clique em "Exibir pendências"' : 'Para habilitar a edição do campo "Atividades não realizadas/não previstas", click em "Exibir pendências"'
                                    }
                                  </p>
                                </div>

                            </FormSectionWrapper>
                            }}
                          </FormSpy>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormInspecaoProducaoDeCampo;
