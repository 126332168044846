export const INLOCO = {
    viewName: "In Loco", serializedName: "In loco",
};

export const LABORATORIO = {
    viewName: "Laboratório", serializedName: "Laboratório",
};

export default [
    INLOCO, LABORATORIO
];