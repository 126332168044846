import Index from "views/Index";
import PaginaRelatoriosPluviometricos from "./views/relatoriosPluviometricos/PaginaRelatoriosPluviometricos";
import PaginaRelatoriosFluviometricos from "./views/relatorioFluviometricos/PaginaRelatoriosFluviometricos";
import PaginaRelatoriosVeiculares from "./views/relatoriosVeiculares/PaginaRelatoriosVeiculares";
import PaginaRelatoriosQualidadeAgua from "./views/relatoriosQualidadeAgua/PaginaRelatoriosQualidadeAgua";
import PaginaInspecoesProducaoDeCampo from "./views/relatoriosProducaoCampo/PaginaInspecoesProducaoDeCampo";
import PaginaPerfis from "./views/perfis/PaginaPerfis";
import PaginaBacias from "./views/bacias/PaginaBacias";
import PaginaEstacoes from "./views/estacoes/PaginaEstacoes";
import PaginaObservadores from "./views/observadores/PaginaObservadores";
import PaginaRelatoriosPCD from "./views/relatoriosPCD/PaginaRelatoriosPCD";
import PaginaRegioesHidrograficas from "./views/regioesHidrograficas/PaginaRegioesHidrograficas";
import PaginaSubBacias from "./views/subBacias/PaginaSubBacias";
import PaginaTiposObservadores from "views/tiposObservadores/PaginaTiposObservadores";
import PaginaMunicipios from "views/municipios/PaginaMunicipios";
import PaginaRios from "./views/rios/PaginaRios";
import PaginaVeiculos from "views/veiculos/PaginaVeiculos";
import PaginaCondutores from "views/condutores/PaginaCondutores";
import PaginaEquipamentos from "./views/equipamentos/PaginaEquipamentos";
import PaginaTiposEstacao from "./views/tiposEstacao/PaginaTiposEstacao";
import PaginaFluxoRelatorios from "./views/fluxoRelatorios/PaginaFluxoRelatorios";
import PaginaUsuarios from "./views/usuarios/PaginaUsuarios";
import PaginaUnidadesRegionais from "./views/unidadesRegionais/PaginaUnidadesRegionais";
import PaginaTiposOrdemServico from "./views/tiposOrdemServico/PaginaTiposOrdemServico";
import PaginaCargos from "./views/cargos/PaginaCargos";
import PaginaParametrosRecibos from "./views/recibos/parametros/PaginaParametrosRecibos";
import PaginaGerenciamentoRecibos from "./views/recibos/gerenciamento/PaginaGerenciamentoRecibos";
import PaginaFormGerarRecibos from "./views/recibos/gerenciamento/PaginaFormGerarRecibos";
import Logoff from "./components/login/Logoff";
import PaginaPrevisaoPagamentoRecibo from "./views/recibos/previsao/PaginaPrevisaoPagamentoRecibo";
import SobreVersao from "components/sobre/SobreVersao";


export const mainRoutes = [
    {
        path: "/sgih/app",
        component: Index
    },
    {
        path: "/sgih/app/index",
        component: Index
    },
    {
        exact: false,
        path: "/sgih/app/baciasHidrograficas",
        component: PaginaBacias,
    },
    {
        exact: false,
        path: "/sgih/app/tiposObservadores",
        component: PaginaTiposObservadores,
    },
    {
        exact: false,
        path: "/sgih/app/municipios",
        component: PaginaMunicipios,
    },
    {
        exact: false,
        path: "/sgih/app/relatoriosPluviometricos",
        component: PaginaRelatoriosPluviometricos,
    },
    {
        exact: false,
        path: "/sgih/app/relatoriosFluviometricos",
        component: PaginaRelatoriosFluviometricos,
    },
    {
        exact: false,
        path: "/sgih/app/relatoriosQualidadeAgua",
        component: PaginaRelatoriosQualidadeAgua,
    },
    {
        exact: false,
        path: "/sgih/app/relatoriosVeiculares",
        component: PaginaRelatoriosVeiculares,
    },
    {
        exact: false,
        path: "/sgih/app/relatoriosProducaoCampo",
        component: PaginaInspecoesProducaoDeCampo,
    },
    {
        exact: false,
        path: "/sgih/app/perfis",
        component: PaginaPerfis,
    },
    {
        exact: false,
        path: "/sgih/app/condutores",
        component: PaginaCondutores,
    },
    {
        exact: false,
        path: "/sgih/app/estacoes",
        component: PaginaEstacoes,
    },
    {
        exact: false,
        path: "/sgih/app/observadores",
        component: PaginaObservadores,
    },
    {
        exact: false,
        path: "/sgih/app/regioesHidrograficas",
        component: PaginaRegioesHidrograficas,
    },
    {
        exact: false,
        path: "/sgih/app/subBacias",
        component: PaginaSubBacias,
    },
    {
        exact: false,
        path: "/sgih/app/rios",
        component: PaginaRios,
    },
    {
        exact: false,
        path: "/sgih/app/veiculos",
        component: PaginaVeiculos,
    },
	{
		exact: false,
		path: "/sgih/app/relatoriosPCD",
		component: PaginaRelatoriosPCD,
    },
    {
		exact: false,
		path: "/sgih/app/equipamentos",
		component: PaginaEquipamentos,
    },
    {
		exact: false,
		path: "/sgih/app/tiposEstacao",
		component: PaginaTiposEstacao,
	},
    {
		exact: false,
		path: "/sgih/app/usuarios",
		component: PaginaUsuarios,
    },
    {
		exact: false,
		path: "/sgih/app/tiposOrdemServico",
		component: PaginaTiposOrdemServico,
    },
    {
		exact: false,
		path: "/sgih/app/cargos",
		component: PaginaCargos,
	},
    {
		exact: false,
		path: "/sgih/app/unidadesRegionais",
		component: PaginaUnidadesRegionais,
	},
    {
        exact: false,
        path: "/sgih/app/fluxoRelatorios",
        component: PaginaFluxoRelatorios,
    },
    {
        exact: false,
        path: "/sgih/app/recibos/parametros",
        component: PaginaParametrosRecibos,
    },
    {
        exact: false,
        path: "/sgih/app/recibos/gerenciamento",
        component: PaginaGerenciamentoRecibos,
    },
    {
        exact: false,
        path: "/sgih/app/recibos/gerarRecibos",
        component: PaginaFormGerarRecibos,
    },
    {
        exact: false,
        path: "/sgih/app/recibos/previsao",
        component: PaginaPrevisaoPagamentoRecibo,
    },
    {
        exact: true,
        path: "/sgih/app/logout",
        component: Logoff,
    },
    {
        path: "/sobre-versao", 
        component: SobreVersao,
    },
];
