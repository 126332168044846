import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaTiposEstacao extends Component {
    constructor(props) {
        super(props);

        const source = {
            datafields: [
                { name: 'id', type: 'number', map: 'id' },
                { name: 'siglaTipoEstacao', type: 'string', map: 'siglaTipoEstacao' },
                { name: 'nomeTipoEstacao', type: 'string', map: 'nomeTipoEstacao' },
                { name: 'tipoObservador', type: 'string', map: 'tipoObservador' }
            ],
            id: 'id',
        };

        const columns = [
            { text: 'ID', dataField: 'id', width: '5%' },
            { text: 'Sigla', dataField: 'siglaTipoEstacao', width: '10%' },
            { text: 'Nome', dataField: 'nomeTipoEstacao' },
            { text: 'Tipo de observador', dataField: 'tipoObservador',width: '20%' }
        ];

        this.tableConfig = { source, columns }
    }

    render() {
        return <CrudListPageScaffold title="Tipos de estação"
                                     tableConfig={this.tableConfig}
                                     baseUrl="/tiposEstacao"
                                     history={this.props.history}
                                     shouldAddPrintButton={false}
                                     shouldAddZipButton={false}
                                     formLinkTitle="Novo"
                                     tableName="tiposEstacao" />
    }
}

export default PaginaListaTiposEstacao;
