
import { Row } from "react-bootstrap";
import Versao from "./Versao";
import React from 'react';
class SobreVersao extends React.Component {
  render() {
    return (
      
      <Row>
        <Versao></Versao>
      </Row>
      
    );
  }
}

export default SobreVersao;