import {applyMiddleware, createStore, compose} from "redux";
import rootReducer from "reducers";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const migrations = {
    2: (state) => {
        // migration to keep only device state
        return {
            ...state,
            global: {
                ...(state.global||{}),
                currentNotification: null,
            }
        }
    },
    3: (state) => {
        // migration to keep only device state
        return {
            ...state,
            global: {
                ...(state.global||{}),
                isLoading: false,
            }
        }
    },
    4: (state) => {
        // migration to reset table states
        return {
            ...state,
            tables: {
            }
        }
    }
};

const persistConfig = {
    key: 'storage_sgih',
    storage,
    version: 4,
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: ['files', 'global']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewareArr = [thunk];

if (process.env.NODE_ENV !== 'production') {
    middlewareArr.push(logger)
}

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(...middlewareArr)
    )
    
);

export const persistor = persistStore(store);

export default store;
