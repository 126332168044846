import React, {useCallback, useRef} from "react";
import { FormSpy, Field } from "react-final-form";
import CheckboxField from "components/form/field/CheckboxField";
import SelectField from "components/form/field/SelectField/SelectField";
import { Row, Col } from "react-bootstrap";

import { JqxDropDownListAdapter } from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import BotaoPadrao from "../../components/botao/BotaoPadrao";

// ****************************************************************************

let escopoLabelResolver = (escopo) => escopo.rotulo;
let escopoValueResolver = (escopo) => escopo.nome;

// ****************************************************************************

function PermissaoFuncionalidadePerfil({
  desabilitarTodosCampos,
  elementosEscopo,
  elementosPerfil,
  nomeCheckbox,
  nomeSelectEscopo,
  nomeSelectPerfis,
  rotuloFuncionalidade,
}) {
  const jqxDropDownRef = useRef();
  const selecionarTodos = useCallback(() => {
    if (jqxDropDownRef.current) {
      jqxDropDownRef.current.checkAll();
    }
  }, [jqxDropDownRef]);

  return (
    <Field name={nomeCheckbox} subscription={{ value: true }}>
      {({ input: { value } }) => {
        const desabilitado = true === desabilitarTodosCampos || !value;
        const requiredWithNotificationLocal = (fieldLabel) => (value) => {
          if (desabilitado) {
            return;
          }
          if (value) {
            return;
          } else {
            return `O campo ${fieldLabel} é obrigatório`;
          }
        };
        return (
          <>
            <Row>
              <Col className="mt-3" md={1}>
                <Field
                  component={CheckboxField}
                  name={nomeCheckbox}
                  label={rotuloFuncionalidade}
                  className="mt-2"
                  disabled={desabilitarTodosCampos}
                />
              </Col>
              <Col className="mt-3" md={11}>
                <Row>
                  <Col md={5}>
                    <Field
                      component={SelectField}
                      elements={elementosEscopo}
                      name={nomeSelectEscopo}
                      disabled={desabilitado}
                      labelResolver={escopoLabelResolver}
                      valueResolver={escopoValueResolver}
                      emptyOptionLabel="Selecione o escopo..."
                      validate={requiredWithNotificationLocal(
                        `Escopo para Permissão de ${rotuloFuncionalidade} do Relatório`
                      )}
                    />
                  </Col>
                  <Col md={5}>
                    <Field
                      name={nomeSelectPerfis}
                      component={JqxDropDownListAdapter}
                      source={elementosPerfil}
                      dropDownRef={jqxDropDownRef}
                      checkboxes={true}
                      displayMember="nome"
                      valueMember="id"
                      height={35}
                      width={"100%"}
                      style={{backgroundColor: "#fff"}}
                      placeHolder="Selecione os status..."
                      disabled={desabilitado}
                      validate={requiredWithNotificationLocal(
                        `Perfil para Permissão de ${rotuloFuncionalidade} do Relatório`
                      )}
                    />
                    <small
                      className="text-danger text-sm campo-requerido d-none"
                      id={`${nomeSelectPerfis}Error`}
                    >
                      O campo{" "}
                      {`Perfil para Permissão de ${rotuloFuncionalidade} do Relatório`}{" "}
                      é obrigatório.{" "}
                    </small>
                  </Col>
                  <Col md={2}>
                    <BotaoPadrao onClick={selecionarTodos} desabilitado={desabilitado}>Selecionar todos</BotaoPadrao>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3" md={1}></Col>
              <Col className="mt-3" md={11}>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) =>
                    getTextoExplicativoPermissaoRelatorio(
                      values[nomeCheckbox],
                      rotuloFuncionalidade,
                      values[nomeSelectEscopo],
                      values[nomeSelectPerfis]
                    )
                  }
                </FormSpy>
              </Col>
            </Row>
          </>
        );
      }}
    </Field>
  );
}

// ****************************************************************************

function getTextoExplicativoPermissaoRelatorio(
  habilitado,
  caracteristica,
  escopo,
  perfisStatus
) {
  if (habilitado) {
    const textoEscopo =
      "PROPRIOS" === escopo
        ? "nos seus próprios relatórios"
        : "REGIONAL" === escopo
        ? "nos relatórios da sua regional"
        : "TODOS" === escopo
        ? "em todos os relatórios"
        : "nenhum relatório";
    let textoPerfisStatus = "(você ainda precisa definir os status).";

    if (perfisStatus && perfisStatus.length > 0) {
      textoPerfisStatus = "";

      let perfilArray = perfisStatus.split(";").filter((perfil) => perfil);
      perfilArray.sort((a, b) => ("" + a).localeCompare(b));

      for (let i = 0; i < perfilArray.length; i++) {
        let partesPerfil = perfilArray[i].split(",");

        textoPerfisStatus += partesPerfil[1];
        if (i < perfilArray.length - 2) {
          textoPerfisStatus += ", ";
        } else if (i === perfilArray.length - 2) {
          // Penultimo elemento.
          textoPerfisStatus += " e ";
        }
      }

      textoPerfisStatus = `nos status ${textoPerfisStatus}`;
    }

    if (escopo) {
      return (
        <span>
          O perfil tem permissão para <strong>{caracteristica}</strong>{" "}
          {textoEscopo} {textoPerfisStatus}
        </span>
      );
    } else {
      if (perfisStatus && perfisStatus.length > 0) {
        return (
          <span>
            Você ainda precisar definir o escopo dos relatórios que o perfil
            pode <strong>{caracteristica}</strong> {textoPerfisStatus}
          </span>
        );
      } else {
        return (
          <span>
            Você ainda precisar definir o escopo e o status dos relatórios que o
            perfil pode <strong>{caracteristica}</strong>
          </span>
        );
      }
    }
  } else {
    return (
      <span>
        O perfil não tem permissão para <strong>{caracteristica}</strong>{" "}
        relatórios.
      </span>
    );
  }
}

// ****************************************************************************

export { PermissaoFuncionalidadePerfil };
