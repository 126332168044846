import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form, FormSpy } from "react-final-form";
import Tab from "../../components/tab/Tab";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../service/Service";
import createDecorator from "final-form-calculate";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";
import PromiseLoader from "../../components/PromiseLoader";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";
import { connect } from "react-redux";
import { limparArquivos } from "../../reducers/fileReducers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import SelectField from "../../components/form/field/SelectField";
import FileGridField from "../../components/form/field/FileGridField";
import CheckSetField from "../../components/form/field/CheckSetField";
import {
  tiposPCD,
  tiposVisitaPCD,
  yesOrNo,
} from "../../model/AvaliacaoQualidade";
import TextAreaField from "../../components/form/field/TextAreaField";
import DateTimeField from "../../components/form/field/DateTimeField";
import NumberField from "../../components/form/field/NumberField";
import BotaoPadrao from "../../components/botao/BotaoPadrao";
import { FieldArray } from "react-final-form-arrays";
import Error from "../../components/form/Error";
import BotaoIconeExclusao from "../../components/botao/BotaoIconeExclusao";
import { AbaSituacaoEncontrada } from "./componentes/AbaSituacaoEncontrada";
import { AbaServicosExecutados } from "./componentes/AbaServicosExecutados";
import TipoEstacaoNaoComecaCom from "./componentes/TipoEstacaoNaoComecaCom";
import Container from "react-bootstrap/Container";
import { JqxDropDownListAdapter } from "../../components/JqxDropDownListAdapter/JqxDropDownListAdapter";
import { requiredWithNotification } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";
import moment from "moment";
import voltarParaGrid from "util/VoltarParaGrid";

var exibiuMensagemEm = 0;
const dataAtual = new Date();
let visualizando = false;
let dadosEstacao;
const setValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, (value) => newValue);
};

const requiredWithMessage = (message) => (value) =>
  value ? undefined : message;

const tipoEstacaoDeveConter = (contain) => (value, allValues) =>
  requiredWithMessage("O campo Código é obrigatório")(value) ||
  (!!allValues.tipoEstacao && allValues.tipoEstacao.includes(contain)
    ? undefined
    : "Digite o código de uma estação tipo " + contain);


// https://codesandbox.io/s/oq52p6v96y
const decorator = createDecorator(
  {
    field: "estacaoSelecionada",
    updates: (value, name, allValues, prevValues) => {
      if (prevValues && value && value !== prevValues.estacaoSelecionada) {
        return {
          codigoEstacaoDigitado: value.codigo,
        };
      }

      return {};
    },
  },
  {
    field: "houveAlteracaoNaPCD",
    updates: (value, name, allValues, prevValues) => {
      if(value === 'NAO')
        return { configuracoesAlteradasPCD: null };

      return {};
    },
  },
  {
    field: "marcaSelecionada",
    updates: (marca) => {
      if (marca) {
        return {
          idMarcaPCD: marca.id,
          areaCaptacaoPluviometro: marca.areaCaptacao,
          volumeBasculaPluviometro: marca.bascula,
          diametroPluviometro: marca.diametroPluviometro,
          marcaModeloAfericao: marca.nome,
        };
      }

      return {
        idMarcaPCD: "",
        areaCaptacaoPluviometro: "",
        volumeBasculaPluviometro: "",
        diametroPluviometro: "",
        marcaModeloAfericao: "",
      };
    },
  },
  {
    field: "tipoSensorSelecionado",
    updates: (tipoSensor) => {
      if (tipoSensor) {
        return {
          idTipoSensor: tipoSensor.id,
          descricaoTipoSensor: tipoSensor.nome,
        };
      }

      return {
        idTipoSensor: 1,
        descricaoTipoSensor: "N/A",
      };
    },
  },
  {
    field: ["volumeAguaAfericao", "volumeBasculaPluviometro"],
    updates: (
      valor,
      nome,
      { volumeAguaAfericao, volumeBasculaPluviometro }
    ) => {
      const mudancas = {};

      const active = document.activeElement;

      if (volumeBasculaPluviometro && volumeAguaAfericao) {
        const qtdeBasculadaEsperada = (volumeAguaAfericao / volumeBasculaPluviometro).toFixed(1);
        mudancas["situacaoAposVistoria.afericao.qtdeBasculadaEsperada"] = qtdeBasculadaEsperada;
        mudancas["situacaoEncontrada.afericao.qtdeBasculadaEsperada"] = qtdeBasculadaEsperada;
      }
      if (volumeAguaAfericao) {
        if(active.name != "volumeAguaEquivalenteAfericao") {
          mudancas["volumeAguaEquivalenteAfericao"] = (volumeAguaAfericao * 0.025).toPrecision(8);
        }
      }

      return mudancas;
    },
  },
  {
    field: [
      "situacaoEncontrada.afericao.qtdeBasculada",
      "situacaoEncontrada.afericao.qtdeBasculadaEsperada",
    ],
    updates: (valor, nome, { situacaoEncontrada }) => {
      const qtdeBasculada =
        situacaoEncontrada &&
        situacaoEncontrada.afericao &&
        situacaoEncontrada.afericao.qtdeBasculada;
      const qtdeBasculadaEsperada =
        situacaoEncontrada &&
        situacaoEncontrada.afericao &&
        situacaoEncontrada.afericao.qtdeBasculadaEsperada;

      const mudancas = {};

      if (qtdeBasculada && qtdeBasculadaEsperada) {
        const erro = qtdeBasculada / qtdeBasculadaEsperada - 1;
        mudancas["situacaoEncontrada.afericao.erro"] = erro;
        mudancas.erroAntesFormatado =
          (erro * 100).toFixed(1).replace(".", ",") + "%";
      }

      return mudancas;
    },
  },
   {
     field: [

       "volumeAguaEquivalenteAfericao",
     ],
     updates: (valor, nome, {  volumeBasculaPluviometro, volumeAguaEquivalenteAfericao }
       ) => {
       const mudancas = {};

       const active = document.activeElement;


       if (volumeAguaEquivalenteAfericao && volumeBasculaPluviometro) {
          const erro = (volumeAguaEquivalenteAfericao / volumeBasculaPluviometro).toFixed(1);

          if(active.name != "volumeAguaAfericao")
            mudancas["volumeAguaAfericao"] = (volumeAguaEquivalenteAfericao / 0.025).toPrecision(8);
       }

       return mudancas;
     },
   },
  {
    field: [
      "situacaoAposVistoria.afericao.qtdeBasculada",
      "situacaoAposVistoria.afericao.qtdeBasculadaEsperada",
    ],
    updates: (valor, nome, { situacaoAposVistoria }) => {
      const qtdeBasculada =
        situacaoAposVistoria &&
        situacaoAposVistoria.afericao &&
        situacaoAposVistoria.afericao.qtdeBasculada;
      const qtdeBasculadaEsperada =
        situacaoAposVistoria &&
        situacaoAposVistoria.afericao &&
        situacaoAposVistoria.afericao.qtdeBasculadaEsperada;
      const mudancas = {};

      if (qtdeBasculada && qtdeBasculadaEsperada) {
        const erro = qtdeBasculada / qtdeBasculadaEsperada - 1;
        mudancas["situacaoAposVistoria.afericao.erro"] = erro;
        mudancas.erroDepoisFormatado =
          (erro * 100).toFixed(1).replace(".", ",") + "%";
      }

      return mudancas;
    },
  },
  {
    field: "ordensServico",
    updates: {
      idsOrdemServico: (value) => value.map((e) => e.id),
    },
  },
  {
    field: "codigoEstacaoDigitado",
    updates: (value, name, allValues) => {
      if (value && value.length >= 8 && value.replace(/_/, "").length >= 8) {
        return createServiceFor("/relatoriosPCD")
          .customURL("GET", `paraEstacao/${value}`)
          .then((res) => {
            const data = res.data;
            return {
              codigoEstacao: data.codigo,
              estacaoSelecionada: data,
              nomeEstacao: data.nome,
              baciaEstacao: data.bacia,
              rioEstacao: data.rio,
              roteiroEstacao: data.roteiro,
              tipoEstacao: data.tipo,
              operadorResponsavel: data.operadorResponsavel,
            };
          })
          .catch((e) => {
            store.dispatch(
              showGlobalNotification({
                message: "Número de estação inválido",
              })
            );
            return {
              estacaoSelecionada: null,
              codigoEstacao: "",
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              tipoEstacao: "",
              observador: "",
              operadorResponsavel: "",
            };
          });
      }else{
        return {
          estacaoSelecionada: null,
          codigoEstacao: "",
          nomeEstacao: "",
          baciaEstacao: "",
          rioEstacao: "",
          roteiroEstacao: "",
          tipoEstacao: "",
          observador: "",
          operadorResponsavel: "",
        };
      }
      return {};
    },
  },
  {
    field: "retrieve.dataInicio",
    updates: (value, name, allValues ) => {
      if(allValues.retrieve.dataFim && allValues.retrieve.dataInicio){
        const dataInicial = moment(allValues.retrieve.dataInicio, 'DD/MM/YYYY')
        const dataFinal = moment(allValues.retrieve.dataFim, 'DD/MM/YYYY')
        if(dataFinal - dataInicial < 0){
          allValues.retrieve.dataFim = allValues.retrieve.dataInicio
        }
        return {}
      }
      return {}
    },
  },
);

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;

class PaginaFormRelatoriosPCD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dados:dadosEstacao,
      emEdicao: false,
    };

    this.decoradorDePreenchimentoPorEstacao = createDecorator(
      {
        field: ["codigoEstacaoDigitado", "tipoVisita", "id"],
        updates: (value, name, { codigoEstacaoDigitado, tipoVisita, id }) => {
          const catchFn = (e) => {
            const mensagem =
              e.response && e.response.data
                ? e.response.data.erro
                : "Número de estação inválido";
            let now = new Date().getTime();

            // Discard repetitive messages for 2 seconds.
            if (now - exibiuMensagemEm < 2000) {
              return {
                estacaoSelecionada: null,
                codigoEstacao: "",
                nomeEstacao: "",
                baciaEstacao: "",
                rioEstacao: "",
                roteiroEstacao: "",
                tipoEstacao: "",
                observador: "",
                operadorResponsavel: "",
                marcaSelecionada: "",
                tipoPCD: "",
                tipoSensorSelecionado: (this.state.tiposSensores || []).filter(
                  (tipoSensor) => tipoSensor.id === 1
                )[0],
                patrimonioCPRM: "",
                configuracoesPCD: "",
              };
            }

            exibiuMensagemEm = now;

            store.dispatch(
              showGlobalNotification({
                message: mensagem,
              })
            );
            return {
              estacaoSelecionada: null,
              codigoEstacao: "",
              nomeEstacao: "",
              baciaEstacao: "",
              rioEstacao: "",
              roteiroEstacao: "",
              tipoEstacao: "",
              observador: "",
              operadorResponsavel: "",
              marcaSelecionada: "",
              tipoPCD: "",
              tipoSensorSelecionado: (this.state.tiposSensores || []).filter(
                (tipoSensor) => tipoSensor.id === 1
              )[0],
              patrimonioANA: "",
              patrimonioCPRM: "",
            };
          };

          const codigoEstacao =
            !!codigoEstacaoDigitado && codigoEstacaoDigitado.replace(/_/, "");

          if (!id && tipoVisita && codigoEstacao && codigoEstacao.length >= 8) {
            if ("MANUTENCAO" === tipoVisita) {
              return createServiceFor("/relatoriosPCD")
                .customURL("GET", `paraManutencao/${codigoEstacaoDigitado}`)
                .then((res) => {
                  const data = res.data;
                  return {
                    codigoEstacao: data.estacao.codigo,
                    estacaoSelecionada: data.estacao,
                    nomeEstacao: data.estacao.nome,
                    baciaEstacao: data.estacao.bacia,
                    rioEstacao: data.estacao.rio,
                    roteiroEstacao: data.estacao.roteiro,
                    tipoEstacao: data.estacao.tipo,
                    operadorResponsavel: data.estacao.operadorResponsavel,
                    entidadeResponsavel: data.entidadeResponsavel,

                    patrimonioANA: data.numeroPatrimonioANA,
                    patrimonioCPRM: data.numeroPatrimonioCPRM,
                    tipoPCD: data.tipoPCD,
                    idTipoSensor: data.idTipoSensor,
                    idMarcaPCD: data.idMarca,
                    idEquipe: data.equipe,
                    configuracoesPCD: data.configuracaoPCD,
                    tipoSensorSelecionado: (this.state.tiposSensores || []).filter(
                      (tipoSensor) => tipoSensor.id === data.idTipoSensor
                    )[0] || {  idTipoSensor: 1,
                      descricaoTipoSensor: "N/A"},
                    marcaSelecionada: (this.state.marcasPCD || []).filter(
                      (marca) => marca.id === data.idMarca
                    )[0],
                  };
                })
                .catch(catchFn);
            } else {
              return createServiceFor("/relatoriosPCD")
                .customURL("GET", `paraInstalacao/${codigoEstacaoDigitado}`)
                .then((res) => {
                  const data = res.data;

                  return {
                    codigoEstacao: data.codigo,
                    estacaoSelecionada: data,
                    nomeEstacao: data.nome,
                    baciaEstacao: data.bacia,
                    rioEstacao: data.rio,
                    roteiroEstacao: data.roteiro,
                    tipoEstacao: data.tipo,
                    operadorResponsavel: data.operadorResponsavel,
                  };
                })
                .catch(catchFn);
            }
          }
          return {};
        },
      },
      {
        field: "tipoVisita",
        updates: (value) => {

          const ehManutencao = "MANUTENCAO" === value;

          if(!ehManutencao)
            return { houveAlteracaoNaPCD : 'NAO' }

          return {};
        },
      }
    );
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/estacoes/simples").query(),
      createServiceFor("/usuarios/simples").query(),
      createServiceFor("/tiposOrdemServico/pcd").query(),
      createServiceFor("/marcasPCD").query(),
      createServiceFor("/tipos-sensores").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/relatoriosPCD").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(async ([
          estacoes,
          usuarios,
          tiposOrdemServico,
          marcasPCD,
          tiposSensores,
          relatorio = {},
        ]) => {
          if(this.props.match.params.id) {
            try{
              const codigoEstacao = relatorio.data.codigoEstacao;

              const res = await createServiceFor("/relatoriosPCD")
              .customURL("GET", `paraEstacao/${codigoEstacao}`);

              const estacao = res.data;

              this.setState({dados: estacao})

              if(!estacoes.data.find((e) => e.id === estacao.id)){
                estacoes.data.push(estacao);
                estacoes.data = estacoes.data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
              }
            }catch(err){}
          }

          if(relatorio.data) {
            this.setState({
              relatorio: relatorio.data || null
            });
          }

          this.setState({
            tiposSensores: tiposSensores.data,
            marcasPCD: marcasPCD.data,
            estacoes: estacoes.data,
          });
          const tiposOSMapeados = (tiposOrdemServico.data || []).map(
            (tipo) => ({
              ...tipo,
              descricao:
                tipo.descricao.toUpperCase()[0] +
                tipo.descricao.toLowerCase().substring(1),
            })
          );
          tiposOSMapeados.sort((a, b) => {
            // 100 é o id do tipo de OS "Outras  Opções", que deve ser o último da lista
            if (100 === b.id) {
              return -1;
            }
            if (100 === a.id) {
              return 1;
            }

            const desc1 = a.descricao.toUpperCase();
            const desc2 = b.descricao.toUpperCase();
            // Fora esse, os elementos devem estar em ordem alfabética
            return desc1.localeCompare(desc2);
          });

          if(relatorio.data) {
            this.setState({relatorio: relatorio.data || null })
          }

          return {
            usuarios: usuarios.data,
            tiposOrdemServico: tiposOSMapeados,
            tiposSensores: tiposSensores.data,
            marcasPCD: marcasPCD.data,
            estacoes: estacoes.data,
            initialValues: relatorio.data
              ? {
                  ...relatorio.data,
                  ordensServico: tiposOrdemServico.data.filter((tipo) =>
                    (relatorio.data.idsOrdemServico || []).includes(tipo.id)
                  ),
                  codigoEstacaoDigitado: relatorio.data.codigoEstacao,
                  estacaoSelecionada: estacoes.data.filter(
                    (est) => est.codigo === relatorio.data.codigoEstacao
                  )[0],
                  tipoSensorSelecionado: tiposSensores.data.filter(
                    (tipoSensor) => tipoSensor.id === relatorio.data.idTipoSensor
                  )[0] || {  idTipoSensor: 1,
                    descricaoTipoSensor: "N/A"},
                  marcaSelecionada: marcasPCD.data.filter(
                    (marca) => marca.id === relatorio.data.idMarcaPCD
                  )[0],
                  haItensDefeituosos: Object.entries(
                    relatorio.data.servicosExecutados
                  ).some(([key, val]) => {
                    return (
                      "limpezaComponentesPCD" !== key &&
                      "undefined" !== typeof val &&
                      null !== val &&
                      "" !== val
                    );
                  })
                    ? "SIM"
                    : "NAO",
                }
              : {tipoSensorSelecionado: tiposSensores.data.filter(
                (tipoSensor) => tipoSensor.id === 1
              )[0]},
          };
        }
      )
      .catch((e) => {
        console.log(e);
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  componentWillUnmount() {
    this.props.limparArquivos();
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/relatoriosPCD")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Relatório salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          let error = form.getFieldState(field).error;
          if (error && "" !== error) {
            store.dispatch(
              showGlobalNotification({
                message: error,
              })
            );
          }
        }
      });
      form.submit();
    },
  });

  getArray = (ArrayParaModificar) => {
    return ArrayParaModificar;
  };

  filtrarEstacoesPorTipoVisita(estacoes, tipoVisita) {
    if (!tipoVisita) {
      return [];
    }

    if (this.state.emEdicao) {
      return estacoes.filter(e => e.codigo === this.state.dados.codigo);
    }

    if (tipoVisita === "MANUTENCAO") {
      return estacoes.filter(e => e.temInstalacao);
    }

    if (tipoVisita === "INSTALACAO") {
      return estacoes.filter(e => !e.temInstalacao);
    }

    return estacoes;
  }

  getCustomInfoSubtitle() {
    return this.state.emEdicao
      ? `
        <div class="content">
          ${this.state?.relatorio && this.state.dados
            ? `
              ${
                this.state?.relatorio?.id
                  ? `<div class="text">Relatório PCD</div> - <div class="text">ID: </div>${this.state.relatorio.id}`
                  : ''
              }

              ${
                this.state?.dados?.nome
                  ? ` - <div class="text">Estação: </div>${this.state.dados.nome}`
                  : ''
              }
              ${
                this.state?.relatorio?.campanhaVisita
                  ? ` - <div class="text">Campanha: </div>${this.state?.relatorio?.campanhaVisita}`
                  : ''
              }
              ${
                this.state?.relatorio?.codRoteiro
                  ? ` - <div class="text">Roteiro: </div>${this.state?.relatorio?.codRoteiro}`
                  : ''
              }
              ${
                this.props?.location?.state?.data?.regional
                  ? ` - ${this.props?.location?.state?.data?.regional}`
                  : ''
              }
              </div>
            `
            : ''
          }
        </div>
      `
      : null
  }

  render() {
    const id = this.props.match.params.id;
    const tipoEstacao = this.props.tipo;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;
    visualizando = disableAllFields;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Relatórios PCD"
        dados ={this.state.dados}
        tipoRelatorio="PCD"
        baseUrl="/sgih/app/relatoriosPCD"
        shouldAddReportFlowButton={true}
        shouldAddRefreshButton={true}
        ehRelatorio={true}
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={(event) => this.submitForm(event)}
                decorators={[
                  decorator,
                  this.decoradorDePreenchimentoPorEstacao,
                ]}
                mutators={{ ...arrayMutators, setValue }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <Field
                        component="input"
                        type="hidden"
                        name="id"
                        value={id}
                      />
                      <Field
                        component="input"
                        type="hidden"
                        name="codigoEstacao"
                      />
                      <Tab.Container
                        className="pb-2"
                        useEvent={true}
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history,
                          true
                        )}
                      >
                        <Tab title="Identificação da estação">
                          {estaEditando && <Container fluid>
                            <Row>
                              <Col className="mt-3" md={4}>
                                <Field
                                    component={TextField}
                                    name="origem"
                                    label="Origem relatório"
                                    readOnly
                                />
                              </Col>
                            </Row>
                          </Container>}
                          <FormSectionWrapper>
                            <Row>
                              <Col md={12}>
                                <Field
                                  component={CheckSetField}
                                  name="tipoVisita"
                                  label="Tipo de visita"
                                  elements={tiposVisitaPCD}
                                  disabled={disableAllFields || estaEditando}
                                  required
                                  validate={requiredWithNotification(
                                    "Tipo de visita"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="codigoEstacaoDigitado"
                                  required
                                  label="Código"
                                  disabled={disableAllFields || estaEditando}
                                  validate={tipoEstacaoDeveConter("T")}
                                  mostrarMensagemOnBlur
                                  minLength={8}
                                  maxLength={8}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  name="tipoVisita"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const listaEstacoes = this.filtrarEstacoesPorTipoVisita(data.estacoes, value);

                                    return (
                                      <Field
                                        component={SelectField}
                                        name="estacaoSelecionada"
                                        label="Estação"
                                        elements={listaEstacoes}
                                        buscavel
                                        callbackWithOriginalValue
                                        required
                                        disabled={
                                          disableAllFields || estaEditando
                                        }
                                        valueResolver={identifierValueResolver}
                                        labelResolver={descriptionLabelResolver}
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="baciaEstacao"
                                  label="Bacia"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="rioEstacao"
                                  label="Rio"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "codRoteiro" : "roteiroEstacao"}
                                  label="Roteiro"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name={estaEditando ? "siglaTipoEstacao" : "tipoEstacao"}
                                  label="Tipo"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="operadorResponsavel"
                                  label="Entidade operadora/unidade"
                                  readOnly={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={DateTimeField}
                                  name="campanhaVisita"
                                  label="Campanha"
                                  datePickerType="yearMonth"
                                  validate={requiredWithNotification(
                                    "Campanha"
                                  )}
                                  disabled={disableAllFields}
                                  required
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="marcaSelecionada"
                                  label="Marca PCD"
                                  elements={data.marcasPCD}
                                  callbackWithOriginalValue
                                  disabled={disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  required
                                  validate={requiredWithNotification(
                                    "Marca PCD"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="tipoPCD"
                                  label="Tipo PCD"
                                  elements={tiposPCD}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Tipo PCD"
                                  )}
                                />
                              </Col>
                              {console.log(data)}
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="tipoSensorSelecionado"
                                  label="Tipo de sensor"
                                  callbackWithOriginalValue
                                  elements={data.tiposSensores}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Tipo de sensor"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  maxLength={30}
                                  name="patrimonioCPRM"
                                  label="NR SGB/CPRM"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Número patrimônio CPRM"
                                  )}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  maxLength={20}
                                  name="patrimonioANA"
                                  label="Patrimônio (ER)"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Número patrimônio ANA"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  maxLength={50}
                                  name="entidadeResponsavel"
                                  label="Entidade responsável (ER)"
                                  disabled={disableAllFields}
                                  required
                                  validate={requiredWithNotification(
                                    "Entidade responsável"
                                  )}
                                />
                              </Col>
                              <Col className="mt-3" md={6}>
                                <Field
                                  name="idsMembrosEquipe"
                                  component={JqxDropDownListAdapter}
                                  source={data.usuarios}
                                  label="Equipe"
                                  required
                                  checkboxes={true}
                                  displayMember="nome"
                                  valueMember="id"
                                  placeHolder="Selecione a equipe..."
                                  searchMode="startswithignorecase"
                                  enableSelection={true}
                                  enableHover={true}
                                  filterable={true}
                                  incrementalSearch={true}
                                  animationType="none"
                                  closeDelay={0}
                                  filterPlaceHolder=" Busque"
                                  disabled={disableAllFields}
                                  validate={requiredWithNotification("Equipe")}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <FormSpy subscription={{ values: true }}>
                                  {({ values }) => {
                                    const houveAlteracao = "SIM" === values.houveAlteracaoNaPCD;
                                    const ehManutencao = values.tipoVisita === "MANUTENCAO";
                                    let habilitarCampo = false;

                                    if(!ehManutencao || (ehManutencao && houveAlteracao))
                                      habilitarCampo = true;

                                    return <Field
                                      component={TextAreaField}
                                      maxLength={2000}
                                      name="configuracoesPCD"
                                      label="Configurações da PCD"
                                      rows={4}
                                      disabled={disableAllFields || !habilitarCampo}
                                      required
                                      validate={requiredWithNotification(
                                        "Configurações da PCD"
                                      )}
                                    />
                                  }}
                                </FormSpy>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                              <Field
                                  name="tipoVisita"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const ehManutencao = "MANUTENCAO" === value;
                                    return (
                                      <Field
                                        component={CheckSetField}
                                        name="houveAlteracaoNaPCD"
                                        label="Houve alteração na configuração da PCD?"
                                        elements={yesOrNo}
                                        defaultValue={"NAO"}
                                        disabled={disableAllFields  || !ehManutencao}
                                        validate={requiredWithNotification(
                                          "Houve alteração na configuração da PCD"
                                        )}
                                        required
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mt-3" md={12}>
                                <Field
                                  name="houveAlteracaoNaPCD"
                                  subscription={{ value: true }}
                                >
                                  {({ input: { value } }) => {
                                    const houveAlteracao = "SIM" === value;
                                    const campoBloqueado = !houveAlteracao;
                                    return (
                                      <>
                                      <Field
                                        component={TextAreaField}
                                        name="configuracoesAlteradasPCD"
                                        label="Configurações alteradas"
                                        rows={4}
                                        maxLength={1000}
                                        disabled={
                                          disableAllFields || campoBloqueado
                                        }
                                        key={houveAlteracao}
                                        required={houveAlteracao}
                                        validate={
                                          houveAlteracao
                                            ? requiredWithNotification(
                                                "Configurações alteradas"
                                              )
                                            : undefined
                                        }
                                      />
                                      {
                                        houveAlteracao ? <><br></br><span style={{color: 'red'}}>
                                        Esse campo se destina a anotar as informações de configurações alteradas da PCD. É necessário atualizar as informações anotadas aqui no campo "Configurações da PCD:" Para que na próxima visita a estação, essas informações estejam visíveis, no formulário
                                        </span></> : <></>
                                      }</>
                                    );
                                  }}
                                </Field>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Situação encontrada">
                          <AbaSituacaoEncontrada
                            desativarTodosCampos={disableAllFields}
                            prefixoCampos="situacaoEncontrada"
                            sufixoLabel="encontrado"
                          />
                        </Tab>
                        <Tab title="Serviços executados">
                          <AbaServicosExecutados
                            desativarTodosCampos={disableAllFields}
                            form={form}
                          />
                        </Tab>
                        <Tab title="Aferição">
                          <FormSectionWrapper>
                            <Row>
                              <Col md={4} >
                                <FormSectionWrapper title="Características do pluviômetro" md={4}>
                                  <Row>
                                    <Col md={12}>
                                      <Field
                                        component={TextField}
                                        name="marcaModeloAfericao"
                                        label="Marca/modelo"
                                        readOnly={true}
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Field
                                        component={NumberField}
                                        name="diametroPluviometro"
                                        label="Diâmetro (cm)"
                                        readOnly={true}
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Field
                                        component={NumberField}
                                        name="areaCaptacaoPluviometro"
                                        label="Área captação (cm²)"
                                        readOnly={true}
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Field
                                        component={NumberField}
                                        name="volumeBasculaPluviometro"
                                        label="Volume da báscula (cm³)"
                                        className="mt-3"
                                        readOnly={true}
                                      />
                                    </Col>
                                  </Row>
                                </FormSectionWrapper>
                              </Col>
                              <Col md={4} >
                                <FormSectionWrapper title="Antes da manutenção">
                                  <Row>
                                    <Col md={12}>
                                      <Field
                                        component={NumberField}
                                        max={9999999.9}
                                        min={-9999999.9}
                                        decimaisAposvirgula={1}
                                        name="situacaoEncontrada.afericao.qtdeBasculada"
                                        label="Qtde. basculada (und)"
                                        disabled={disableAllFields}
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Field
                                        component={NumberField}
                                        max={99999.9}
                                        min={-99999.9}
                                        decimaisAposvirgula={1}
                                        name="situacaoEncontrada.afericao.qtdeBasculadaEsperada"
                                        label="Qtde. basculada esperada (und)"
                                        readOnly={true}
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Field
                                        component={TextField}
                                        name="erroAntesFormatado"
                                        label="Erro (%)"
                                        readOnly={true}
                                      />
                                    </Col>
                                  </Row>
                                </FormSectionWrapper>
                              </Col>
                              <Col md={4} >
                                <FormSectionWrapper title="Depois da manutenção">
                                  <Field
                                    name="situacaoEncontrada.afericao.erro"
                                    subscription={{ value: true }}
                                  >
                                    {({ input: { value } }) => {
                                      return (
                                        <Row>
                                          <Col md={12}>
                                            <Field
                                              component={NumberField}
                                              max={9999999.9}
                                              min={-9999999.9}
                                              decimaisAposvirgula={1}
                                              name="situacaoAposVistoria.afericao.qtdeBasculada"
                                              label="Qtde. basculada (und)"
                                              disabled={
                                                disableAllFields
                                              }
                                            />
                                          </Col>
                                          <Col md={12} className="mt-3">
                                            <Field
                                              component={NumberField}
                                              name="situacaoAposVistoria.afericao.qtdeBasculadaEsperada"
                                              label="Qtde. basculada esperada (und)"
                                              readOnly={true}
                                            />
                                          </Col>
                                          <Col md={12} className="mt-3">
                                            <Field
                                              component={TextField}
                                              name="erroDepoisFormatado"
                                              label="Erro (%)"
                                              readOnly={true}
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    }}
                                  </Field>
                                </FormSectionWrapper>
                              </Col>
                            </Row>
                            <Row >
                              <Col md={4} >
                                <FormSectionWrapper cardStyle={{border: "none"}}>
                                  <Row>
                                    <Col className="mt-3" md={12}>
                                      <Field
                                        component={NumberField}
                                        max={9999999.9}
                                        min={-9999999.9}
                                        decimaisAposvirgula={1}
                                        step={0.1}
                                        name="volumeAguaAfericao"
                                        label="Volume de água na garrafa de aferição (ml)"
                                        disabled={disableAllFields}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="mt-3" md={12}>
                                      <Field
                                        component={NumberField}
                                        max={9999999.9}
                                        min={-9999999.9}
                                        decimaisAposvirgula={1}
                                        step={0.1}
                                        name="volumeAguaEquivalenteAfericao"
                                        label="Volume(ml) equivalente a “mm” na proveta"
                                        disabled={disableAllFields}
                                      />
                                    </Col>
                                  </Row>
                                </FormSectionWrapper>
                              </Col>
                            </Row>
                          </FormSectionWrapper>
                        </Tab>

                        <Tab title="Situação após a vistoria">
                          <AbaSituacaoEncontrada
                            desativarTodosCampos={disableAllFields}
                            prefixoCampos="situacaoAposVistoria"
                            sufixoLabel="após vistoria"
                            dataObrigatoria={false}
                          />
                        </Tab>
                        <Tab title="Retrieve">
                          <FormSectionWrapper>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={CheckSetField}
                                  name="retrieve.houveRetrieve"
                                  label="Houve retrieve"
                                  elements={yesOrNo}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Field
                                name="retrieve.houveRetrieve"
                                subscription={{ value: true }}
                              >
                                {({ input: { value } }) => {
                                  const exibirRetrieve =
                                    "" !== value && "SIM" === value;
                                  const camposBloqueados = !exibirRetrieve;
                                  return (
                                    <>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={TextField}
                                          maxLength={200}
                                          name="retrieve.nomeArquivo"
                                          label="Nome do arquivo de retrieve"
                                          key={exibirRetrieve}
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                        />
                                      </Col>
                                    </>
                                  );
                                }}
                              </Field>
                            </Row>
                            <FormSpy subscription={{ values: true }}>
                              {({ values }) => {
                                let tipoLegenda = '';

                                const exibirRetrieve = values.retrieve && values.retrieve.houveRetrieve &&
                                    "" !== values.retrieve.houveRetrieve && "SIM" === values.retrieve.houveRetrieve;

                                const camposBloqueados = !exibirRetrieve;

                                let bloquerCamposTemperatura = true;

                                if(values.tipoEstacao){
                                  bloquerCamposTemperatura = values.tipoEstacao.toUpperCase().includes('F') || camposBloqueados;
                                  if(values.tipoEstacao.toUpperCase().includes('F'))
                                    tipoLegenda = 'F';
                                  else if(values.tipoEstacao.toUpperCase().includes('P'))
                                    tipoLegenda = 'P';
                                }
                                return (
                                  <>
                                    <Row>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          name="retrieve.dataFim"
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => (
                                            <Field
                                              component={DateTimeField}
                                              name="retrieve.dataInicio"
                                              label="Início retrieve"
                                              datePickerType="date"
                                              disabled={
                                                disableAllFields ||
                                                camposBloqueados
                                              }
                                              // selectsStart={!!value}
                                              key={exibirRetrieve}
                                              // endDate={value}
                                              maxDate={dataAtual}
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          name="retrieve.dataInicio"
                                          subscription={{ value: true }}
                                        >
                                          {({ input: { value } }) => (
                                            <Field
                                              component={DateTimeField}
                                              name="retrieve.dataFim"
                                              label="Fim retrieve"
                                              datePickerType="date"
                                              disabled={
                                                disableAllFields ||
                                                camposBloqueados
                                              }
                                              // selectsEnd={!!value}
                                              key={exibirRetrieve}
                                              // startDate={value}
                                              minDate={value}
                                              maxDate={dataAtual}
                                            />
                                          )}
                                        </Field>
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.voltagemMinimaBateria"
                                          label="Voltagem mínima da bateria (v)"
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.voltagemMaximaBateria"
                                          label="Voltagem máxima da bateria (v)"
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.temperaturaMinimaAr"
                                          label="Temperatura mínima do ar (°C)"
                                          disabled={
                                            disableAllFields || bloquerCamposTemperatura
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.temperaturaMaximaAr"
                                          label="Temperatura máxima do ar (°C)"
                                          disabled={
                                            disableAllFields || bloquerCamposTemperatura
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.temperaturaInternaMinima"
                                          label="Temperatura interna mínima (°C)"
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={9999999.9}
                                          min={-9999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.temperaturaInternaMaxima"
                                          label="Temperatura interna máxima (°C)"
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                      <TipoEstacaoNaoComecaCom tipo="P">
                                        {(deveMostrar) => {
                                          const habilitado =
                                            deveMostrar &&
                                            !disableAllFields &&
                                            !camposBloqueados;
                                          return (
                                            <>
                                              <Col md={3} className="mt-3">
                                                <Field
                                                  component={NumberField}
                                                  max={9999999.9}
                                                  min={-9999999.9}
                                                  decimaisAposvirgula={1}
                                                  name="retrieve.pressaoAtmosfericaMinima"
                                                  label="Pressão atmosférica mínima (mb)"
                                                  disabled={!habilitado}
                                                  key={habilitado}
                                                />
                                              </Col>
                                              <Col md={3} className="mt-3">
                                                <Field
                                                  component={NumberField}
                                                  max={9999999.9}
                                                  min={-9999999.9}
                                                  decimaisAposvirgula={1}
                                                  name="retrieve.pressaoAtmosfericaMaxima"
                                                  label="Pressão atmosférica máxima (mb)"
                                                  disabled={!habilitado}
                                                  key={habilitado}
                                                />
                                              </Col>
                                              <Col md={3} className="mt-3">
                                                <Field
                                                  component={NumberField}
                                                  max={999999999}
                                                  min={-999999999}
                                                  semVirgula={true}
                                                  name="retrieve.cotaMinimaRegistrada"
                                                  label="Cota mínima registrada (cm)"
                                                  forcarValorInteiro
                                                  disabled={!habilitado}
                                                  key={habilitado}
                                                />
                                              </Col>
                                              <Col md={3} className="mt-3">
                                                <Field
                                                  component={NumberField}
                                                  min={0}
                                                  max={999999999}
                                                  semVirgula={true}
                                                  name="retrieve.cotaMaximaRegistrada"
                                                  label="Cota máxima registrada (cm)"
                                                  forcarValorInteiro
                                                  disabled={!habilitado}
                                                  key={habilitado}
                                                />
                                              </Col>
                                            </>
                                          );
                                        }}
                                      </TipoEstacaoNaoComecaCom>
                                      <Col md={3} className="mt-3">
                                        <Field
                                          component={NumberField}
                                          max={99999999.9}
                                          min={-99999999.9}
                                          decimaisAposvirgula={1}
                                          name="retrieve.chuvaAcumulada"
                                          label="Chuva acumulada para período de retrieve (mm)"
                                          disabled={
                                            disableAllFields || camposBloqueados
                                          }
                                          key={exibirRetrieve}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                );
                              }}
                            </FormSpy>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Observações">
                          <FormSectionWrapper title="Observações gerais">
                            <Field
                              component={TextAreaField}
                              label=""
                              name="observacoesGerais"
                              rows={8}
                              maxLength={500}
                              disabled={disableAllFields}
                            />
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="O.S.">
                          <FormSectionWrapper title="Equipamentos a serem providenciados para a próxima visita (substituição)">
                            <Row>
                              <Col
                                className="mt-3"
                                md={disableAllFields ? 12 : 10}
                              >
                                <Field
                                  component={SelectField}
                                  name="ordemServicoSelecionada"
                                  callbackWithOriginalValue={true}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  disabled={disableAllFields}
                                  elements={data.tiposOrdemServico}
                                />
                              </Col>
                              {!disableAllFields && (
                                <Col className="mt-3" md={2}>
                                  <BotaoPadrao
                                    onClick={() => {
                                      const workOrder = form.getState().values
                                        .ordemServicoSelecionada;
                                      if (workOrder) {
                                        push("ordensServico", workOrder);
                                      }
                                    }}
                                  >
                                    Adicionar
                                  </BotaoPadrao>
                                </Col>
                              )}
                            </Row>
                            <FieldArray
                              name="ordensServico"
                              // validate={atLeastOneElement(
                              //     'Caso não exista planejamento para a próxima visita, selecione a opção "Estação em perfeitas condições de operação"'
                              // )}
                            >
                              {({ fields }) => {
                                // 100 é o ID do tipo de OS "Outras Opções"
                                const necessarioDetalharOutrasOpcoes = (
                                  fields.value || []
                                ).some((os) => 100 === os.id);
                                return (
                                  <div>
                                    <Error name="ordensServico" />
                                    {fields.map((name, index) => {
                                      return (
                                        <Row className="mt-3" key={index}>
                                          <Col>
                                            <Field
                                              component={TextField}
                                              label=""
                                              name={`ordensServico[${index}].descricao`}
                                              readOnly={true}
                                            />
                                          </Col>
                                          {!disableAllFields && (
                                            <Col md="auto">
                                              <BotaoIconeExclusao
                                                onClick={() =>
                                                  fields.remove(index)
                                                }
                                              />
                                            </Col>
                                          )}
                                        </Row>
                                      );
                                    })}

                                    {necessarioDetalharOutrasOpcoes && (
                                      <div className="mt-3">
                                        <Field
                                          component={TextAreaField}
                                          name="descOSOutrasOpcoes"
                                          label="Outras descrições"
                                          rows={4}
                                          disabled={disableAllFields}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            </FieldArray>
                          </FormSectionWrapper>
                        </Tab>
                        <Tab title="Mídias e Fotos">
                          <FormSpy subscription={{ values: true }}>
                            {({ values }) => {
                              let tipoLegenda = '';

                              if(values.tipoEstacao){
                                if(values.tipoEstacao.toUpperCase().includes('F'))
                                  tipoLegenda = 'F';
                                else if(values.tipoEstacao.toUpperCase().includes('P'))
                                  tipoLegenda = 'P';
                              }

                              return <FormSectionWrapper>
                              <div
                                    className="text-center"
                                    style={{ color: "red" }}
                                  >
                                    {tipoLegenda === "P" ? (
                                      <p>
                                        <p>
                                          Obedeça a seguinte ordem para inserir
                                          as fotos: Vista geral, PCD aberta,
                                          Pluviometro, Higrotermografo, Painel
                                          Solar, NR do SGB/CPRM.
                                        </p>
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {tipoLegenda === "F" ? (
                                      <p>
                                        <p>
                                          Obedeça a seguinte ordem para inserir
                                          as fotos: Vista geral, PCD aberta,
                                          Painel Solar, Pluviometro, Sensor de
                                          nível, NR do SGB/CPRM.
                                        </p>
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                  <div
                                    className="text-center"
                                    style={{ color: "black" }}
                                  >
                                    {tipoLegenda === "P" ? (
                                      <p>

                                        Você também pode inserir até mais 4 arquivos de mídia (vídeo, áudio, fotos).

                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {tipoLegenda === "F" ? (
                                      <p>

                                        Você também pode inserir até mais 4 arquivos de mídia (vídeo, áudio, fotos).

                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                  </div>
                                <div className="mx-3 p-2">
                                  <FileGridField
                                    campoNovosArquivos="hashesNovasFotos"
                                    campoArquivosExistentes="idsFotosExistentes"
                                    chaveListaArquivos="fotosPCD"
                                    bloquearTipos={true}
                                    permitirImagens={true}
                                    permitirVideos={true}
                                    permitirAudios={true}
                                    limiteArquivos={10}
                                    disable={disableAllFields}
                                  />
                                </div>
                              </FormSectionWrapper>

                            }}
                          </FormSpy>
                        </Tab>
                        <Tab title="Arquivos">
                          <FormSectionWrapper>
                            <div className="mx-3 p-2">
                              <FileGridField
                                campoNovosArquivos="hashesNovosArquivos"
                                campoArquivosExistentes="idsArquivosExistentes"
                                chaveListaArquivos="arquivosPCD"
                                disable={disableAllFields}
                                bloquearTipos
                                permitirRar
                                permitirZip
                              />
                            </div>
                          </FormSectionWrapper>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default connect(null, (dispatch) => ({
  limparArquivos() {
    dispatch(limparArquivos({ store: "fotosPCD" }));
    dispatch(limparArquivos({ store: "arquivosPCD" }));
  },
  dispatch,
}))(PaginaFormRelatoriosPCD);
