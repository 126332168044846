export const EXTRA = {
    viewName: "Extra", serializedName: "EXTRA",
};

export const FISCALIZATION = {
    viewName: "Fiscalização", serializedName: "FISCALIZAÇÃO",
};

export const ROUTINE = {
    viewName: "Operação de Roteiro", serializedName: "OPERAÇÃO DE ROTEIRO",
};

export default [
    EXTRA, FISCALIZATION, ROUTINE
];