import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormTiposOrdemServico from "./PaginaFormTiposOrdemServico";
import PaginaListaTiposOrdemServico from "./PaginaListaTiposOrdemServico";

class PaginaTiposOrdemServico extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/tiposOrdemServico"}
            component={PaginaListaTiposOrdemServico}
          />
          <Route
            exact
            path={"/sgih/app/tiposOrdemServico/criar"}
            component={PaginaFormTiposOrdemServico}
          />
          <Route
            path={"/sgih/app/tiposOrdemServico/:id"}
            component={PaginaFormTiposOrdemServico}
          />
        </Switch>
      </>
    );
  }
}

export default PaginaTiposOrdemServico;
