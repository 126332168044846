import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormCargo from "./PaginaFormCargo";
import PaginaListaCargos from "./PaginaListaCargos";

class PaginaCargos extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/cargos"}
            component={PaginaListaCargos}
          />
          <Route
            exact
            path={"/sgih/app/cargos/criar"}
            component={PaginaFormCargo}
          />
          <Route path={"/sgih/app/cargos/:id"} component={PaginaFormCargo} />
        </Switch>
      </>
    );
  }
}

export default PaginaCargos;
