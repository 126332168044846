import { useState, useEffect } from 'react';

export function useScreenDimensions() {
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
  };

  useEffect(() => {
    window.addEventListener(
        'resize',
        handleResize
    );

    return () => {
        window.removeEventListener(
            'resize',
            handleResize
        );
    };
  }, []);

  return screenDimensions;
}