import OrigemRelatorio from 'model/OrigemRelatorio';
import StatusRelatorio from 'model/StatusRelatorio';
import React, {Component} from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';

class PaginaListaRelatoriosVeiculares extends Component {
    constructor(props) {
        super(props);

        this.setState({
            exibir: true
        });

          UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                },

                StatusRelatorio(
                    (statusRelatorio)=>{
                        this.setState({
                            statusRelatorio
                        },
                        ()=>{
                        const source = {
                            date : 'dd/MM/yyyy',
                            datafields: [
                                { name: 'id', type: 'number', map: 'id' },
                                { name: 'placa', type: 'string', map: 'placa' },
                                { name: 'origem', type: 'string', map: 'origem' },
                                { name: 'motivoSinistro', type: 'string', map: 'motivoSinistro' },
                                { name: 'modelo', type: 'string', map: 'modelo' },
                                { name: 'condutores', type: 'string', map: 'condutores' },
                                { name: 'condutoresFormatado', type: 'string', map: 'condutoresFormatado' },
                                { name: 'criador', type: 'string', map: 'criador' },
                                { name: 'roteiro', type: 'number', map: 'roteiro' },
                                { name: 'protocolo', type: 'string', map: 'protocolo' },
                                { name: 'houveSinistro', type: 'string', map: 'houveSinistro' },
                                { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },
                                { name: 'dataSaida', type: 'date', map: 'dataSaida' },
                                { name: 'dataRetorno', type: 'date', map: 'dataRetorno' },
                                { name: 'kmInicial', type: 'number', map: 'kmInicial' },
                                { name: 'kmFinal', type: 'number', map: 'kmFinal' },
                                { name: 'kmTotal', type: 'number', map: 'kmTotal' },
                                { name: 'perfilUltimaAlteracaoFluxo', type: 'string', map: 'perfilUltimaAlteracaoFluxo' },
                            ],
                            id: 'id',
                        };

                        const columns = [
                            { text: 'ID', dataField: 'id', width: '3%' },
                            { text: 'Status Atual', width: '6%', dataField: 'perfilUltimaAlteracaoFluxo', filtertype: 'checkedlist', filteritems: this.state ? this.state.statusRelatorio : [] },
                            { text: 'Origem', dataField: 'origem', width: '4%', filtertype: 'checkedlist', filteritems: OrigemRelatorio },
                            { text: 'Roteiro', width: '5%', dataField: 'roteiro', filtertype:'number' },
                            { text: 'Regional', dataField: 'unidadeRegional', width: '5%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                            { text: 'Placa', dataField: 'placa', width: '4%' },
                            { text: 'Modelo', dataField: 'modelo' },
                            {
                                text: 'Condutores', dataField: 'condutores', width: '6%', sortable: false,
                                cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                                    return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${rowdata.condutoresFormatado}">${value}</div>`;
                                },
                            },
                            { text: 'Criador', dataField: 'criador', width: '6%' },
                            { text: 'Data saída', dataField: 'dataSaida', filtertype: 'date', cellsformat: 'dd/MM/yyyy', width: '6%' },
                            { text: 'Data retorno', dataField: 'dataRetorno', filtertype: 'date', cellsformat: 'dd/MM/yyyy', width: '6%' },
                            { text: 'KM saída', dataField: 'kmInicial', cellsalign: 'right', width: '5%' },
                            { text: 'KM retorno', dataField: 'kmFinal', cellsalign: 'right', width: '5%' },
                            { text: 'KM total', dataField: 'kmTotal', cellsalign: 'right', width: '5%' },
                            { text: 'Sinistro', dataField: 'houveSinistro', width: '5%', filtertype: 'checkedlist', filteritems:["Sim", "Não"] },
                            { text: 'Motivo do sinistro', dataField: 'motivoSinistro', width: '7%', filtertype: 'checkedlist', filteritems: ["Acidente", "Danos causados pela natureza", "Incêndio", "Roubo", "Não informado"] },
                            { text: 'Protocolo', dataField: 'protocolo', width: '6%' },
                        ];

                        this.tableConfig = { source, columns }
                        this.setState({
                            exibir:true
                        })
                    })
                })
            )
        })
    }

    render() {

        return this.state ? this.state.exibir ?
            <CrudListPageScaffold
                title="Relatórios veiculares"
                tableConfig={this.tableConfig}
                baseUrl="/relatoriosVeiculares"
                history={this.props.history}
                formLinkTitle="Novo relatório"
                tableName="relatorioVeic"
                shouldAddReportFlowButton={true}
                shouldAddRefreshButton={true}
                ehRelatorio={true}
                tipoRelatorio="VEICULAR"
                urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/veicular"
            />
            : <></>
            : <></>
    }
}

export default PaginaListaRelatoriosVeiculares;
