import React from "react";
import Form from "react-bootstrap/Form";
import PropTypes from "prop-types";
import {defaultProps, propTypes} from "./formFieldCommonProps";
import Error from "../Error";

class CheckField extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = (event) => {
            if (props.input) {
                props.input.onChange(event.target.checked);
            }
            if (props.onChange) {
                props.onChange(event.target.checked);
            }
        }
    }

    render() {
        const name = this.props.name || (this.props.input && this.props.input.name);
        const value = this.props.value || (this.props.input && this.props.input.value);
        const onChange = this.props.onChange || this.onChange;
        const checked =  this.props?.input ? this.props?.input?.checked 
        : 'undefined' === typeof this.props.checked ? value : this.props.checked;

        return <>
            <Form.Check type={this.props.type} value={value} disabled={this.props.disabled}
                        onChange={onChange} required={this.props.required} name={name} checked={checked}
                        readOnly={this.props.readOnly} label={this.props.label} inline={this.props.inline} />
            {this.props.showError && <Error name={name} />}
        </>;
        
    }
}

CheckField.propTypes = {
    ...propTypes,

    type: PropTypes.oneOf(["checkbox", "radio"]),
    label: PropTypes.string,
    inline: PropTypes.bool,
};

CheckField.defaultProps = {
    ...defaultProps,

    type: "checkbox",
    inline: false,
};

export default CheckField;
