/**
 * global action types
 */
export const REPLACE_TABLE_STATE = 'REPLACE_TABLE_STATE';

/*
 * action creators
 */

export const replaceTableState = (tableName, state) => ({ type: REPLACE_TABLE_STATE, payload: {name: tableName, state} });

/**
 * reducers
 */

const initialState = {
};

export const tableReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPLACE_TABLE_STATE: return {
            ...state,
            [action.payload.name]: action.payload.state,
        };
        default: return state;
    }
};
