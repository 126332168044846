import React, { Component } from "react";
import JqxMenu from "jqwidgets-scripts/jqwidgets-react-tsx/jqxmenu";
import "./menuLateral.css";
import { extrairPermissoes} from "../../reducers/authReducers";
import {connect} from "react-redux";
import OutsideClick from "./OutsideCLick";
import {Modal} from "../modal/Modal";
import NavBar from "components/navBar/NavBar";
import Footer from "components/footer/Footer";


class MenuLateral extends Component {
  constructor(props) {
    super(props);

    // console.log(props)
    this.ref = React.createRef();

    this.state = {
      ref: this.ref,
      menuAberto: false,
      modalInstitucionalAberto: false,
      modalTecnicosAberto:false,
    };

    this.fecharModal = this.fecharModal.bind(this);
  }

  fecharModal() {
    this.setState({
      modalInstitucionalAberto: false,
      modalTecnicosAberto: false
    })
  }

  abrirModal(modalparaAbrir) {
    if(modalparaAbrir === "Institucionais"){
      this.setState({
      modalInstitucionalAberto: true,
    });
    } else if(modalparaAbrir === "Técnicos"){
      this.setState({
        modalTecnicosAberto: true,
      });
    }

  }


  render() {
    const navbar = <NavBar exibirCompleto={false} exibirMenu={false} style={{padding: "unset",borderBottom: "none", height: "117px"}}/>
    const footer = <Footer />
    return ( <>
      <OutsideClick>
        <div style={{ position: "relative" }}>
          <div
            onClick={() => {
              this.setState((prevState) => ({
                menuAberto: !prevState.menuAberto,
              }));
            }}
            className="jqx-widget-header jqx-rc-all jqx-menu-minimized jqx-widget jqx-menu jqx-menu-vertical"
            role="menubar"
            style={{ outline: "currentcolor none medium", height: "auto", paddingRight: "5px", paddingBottom: "2px"  }}
          >
            <div className="jqx-item jqx-menu-item-top jqx-menu-minimized-button"></div>
            <div style={{ clear: "both" }}></div>
          </div>
          {this.state.menuAberto && (
            <JqxMenu
              ref={this.state.ref}
              mode={"vertical"}
              className="menuLateral"
              width={200}
              onItemclick={(e)=> {
                let clicado = e.args.textContent;
                if(clicado === "Institucionais"){
                  this.abrirModal("Institucionais");
                }else if(clicado === "Técnicos"){
                  this.abrirModal("Técnicos")
                }
                }
               }
            >
              <ul style={{ width: "200px !important" }}>
                <li>
                  <a href="/sgih/app">Início</a>
                </li>
                <li type="separator" />
                  {this.props.exibirCadastros && <>
                    <li>
                      Cadastros
                      <ul style={{ width: "200px !important" }}>
                        <li><a href="/sgih/app/baciasHidrograficas">Bacias</a></li>
                        <li><a href="/sgih/app/cargos">Cargos</a></li>
                        {/* <li><a href="/sgih/app/condutores">Condutores</a></li> */}
                        <li><a href="/sgih/app/equipamentos">Equipamentos</a></li>
                        <li><a href="/sgih/app/estacoes">Estações</a></li>
                        <li><a href="/sgih/app/municipios">Municípios</a></li>
                        <li><a href="/sgih/app/observadores">Observadores</a></li>
                         {/* somente admin */}
                        {this.props.idPerfil === 11 && <>
                          <li><a href="/sgih/app/perfis">Perfis</a></li>
                        </>}
                        <li><a href="/sgih/app/regioesHidrograficas">Regiões hidrográficas</a></li>
                        <li><a href="/sgih/app/rios">Rios</a></li>
                        <li><a href="/sgih/app/subBacias">Sub-bacias</a></li>
                        <li><a href="/sgih/app/tiposEstacao">Tipos de estação</a></li>
                        <li><a href="/sgih/app/tiposObservadores">Tipos de observadores</a></li>
                        <li><a href="/sgih/app/tiposOrdemServico">Tipos de ordens de serviço</a></li>
                          <li>
                              <a href="/sgih/app/unidadesRegionais">Unidades administrativas</a>
                          </li>
                        <li><a href="/sgih/app/usuarios">Usuários</a></li>
                        <li><a href="/sgih/app/veiculos">Veículos</a></li>
                      </ul>
                    </li>
                    <li type="separator" />
                  </>}
                  {this.props.exibirPagamentos && <>
                    <li>
                      Pagamentos
                      <ul style={{ width: "200px !important" }}>
                        <li> <a href="/sgih/app/recibos/gerenciamento">Gerenciamento de recibos</a></li>
                        <li> <a href="/sgih/app/recibos/parametros">Parâmetros</a></li>
                      </ul>
                    </li>
                    <li type="separator" />
                  </>}
                {this.props.exibirRelatorios && <>
                    <li>
                        Relatórios
                        <ul style={{ width: "200px !important" }}>
                            <li>
                                <a href="/sgih/app/relatoriosFluviometricos">Fluviométrico</a>
                            </li>
                            <li>
                                <a href="/sgih/app/relatoriosPluviometricos">Pluviométrico</a>
                            </li>
                            <li>
                                <a href="/sgih/app/relatoriosProducaoCampo">Produção de campo</a>
                            </li>
                            <li>
                                <a href="/sgih/app/relatoriosQualidadeAgua">Qualidade de água</a>
                            </li>
                            <li>
                                <a href="/sgih/app/relatoriosVeiculares">Veicular</a>
                            </li>
                            <li>
                                <a href="/sgih/app/relatoriosPCD">PCD</a>
                            </li>
                        </ul>
                    </li>
                </>}
                <li type="separator" />
                <li>
                  Sobre
                  <ul style={{ width: "200px !important" }}>
                    <li>
                      Créditos
                      <ul style={{ width: "200px !important" }}>
                        <li>
                          <a href="#Institucionais" >Institucionais</a>
                        </li>
                        <li>
                          <a href="#Tecnicos" >Técnicos</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="https://youtube.com/playlist?list=PLhUEW6fPG6Omt1x8MnaGFxJ0TVPOK5dyc" target="_blank" rel="noopener noreferrer">Tutoriais</a>
                    </li>
                  </ul>
                </li>

                <li type="separator" />
                <li>
                  <a href="/sgih/app/logout">Sair</a>
                </li>
              </ul>
            </JqxMenu>
          )}
        </div>
      </OutsideClick>

       <Modal
          tamanho="xl"
          aberto={this.state.modalInstitucionalAberto}
          aoFechar={this.fecharModal}
          titulo="Institucionais"
          scrollable={true}
          align="center"
          comNavBar={true}
          navbar={navbar}
          comFooter={true}
          footer={footer}
        >
          <table border="0" cellSpacing="0" cellPadding="0" width="100%">
            <tbody><tr><td valign="top">

                <p align="center"><font color="#082767"><strong>MINISTÉRIO DE MINAS E ENERGIA</strong></font></p>

                <div className="nome">Bento Albuquerque</div>
                <div className="cargo">Ministro de Estado</div>

                <p align="center"><font color="#082767"><strong>SECRETARIA DE GEOLOGIA, MINERAÇÃO E TRANSFORMAÇÃO MINERAL</strong></font></p>

                <div className="nome">Alexandre Vidigal De Oliveira</div>
                <div className="cargo">Secretário</div>

                <p align="center"><font color="#082767"><strong>SERVIÇO GEOLÓGICO DO BRASIL - CPRM</strong></font></p>

                <div className="nome">Esteves Pedro Colnago</div>
                <div className="cargo">Diretor-Presidente</div>

                <div className="nome">Alice Silva de Castilho</div>
                <div className="cargo">Diretor de Hidrologia e Gestão Territorial</div>

                <div className="nome">Frederico Cláudio Peixinho</div>
                <div className="cargo">Chefe do Departamento de Hidrologia</div>

                <div className="nome">Ana Carolina Zoppas Costi</div>
                <div className="cargo">Chefe da Divisão de Hidrologia Básica</div>

                <div className="nome">Edgar Shinzato</div>
                <div className="cargo">Chefe do Departamento de Informações Institucionais</div>

                <div className="nome">Hiran Silva Dias</div>
                <div className="cargo">Chefe da Divisão de Geoprocessamento</div>

                <div className="nome">Vanildo Almeida Mendes</div>
                <div className="cargo">Superintendente Regional – Recife</div>

                <div className="nome">Robson de Carlo da Silva</div>
                <div className="cargo">Gerente de Hidrologia e Gestão Territorial da Superintendência Regional de Recife</div>

                <div className="nome">Fábio Araújo da Costa</div>
                <div className="cargo">Supervisor de Hidrologia da Superintendência Regional de Recife</div>

              </td></tr>
            </tbody>
          </table>
        </Modal>

        <Modal
          tamanho="xl"
          aberto={this.state.modalTecnicosAberto}
          aoFechar={this.fecharModal}
          titulo="Técnicos"
          scrollable={true}
          comNavBar={true}
          navbar={navbar}
          comFooter={true}
          footer={footer}
        >
          <table border="0" cellSpacing="0" cellPadding="0" width="100%">
            <tbody>
              {/* <tr>
                <td width="100%" valign="top">
                  <div className="caixa_lov nome" align="center">
                    <h2 align="center"><font color="#082767"><strong>Equipe Técnica</strong></font></h2>
                  </div><br/>
                </td>
              </tr> */}
            <tr>
              <td width="100%" valign="top">
                <div className="caixa_lov nome" align="center">
                  <h2>Coordenação Geral e desenvolvimento</h2>
                  <div className="nome">Rodrigo T. Diniz Bezerra de Albuquerque</div>
                  <div className="cargo">Técnico em geociências/SUREG Recife</div>
                </div><br/>
              </td>
            </tr>
            <tr>
              <td width="100%" valign="top">
                <div className="caixa_lov nome" align="center">
                  <h2>Desenvolvimento e Banco de Dados (SGB/CPRM)</h2>

                  <div className="nome">Ana Paula Naziozeno Ferreira</div>
                  <div className="cargo">Analista de sistemas - Administração de Dados/Banco de Dados – DIINFO</div>

                  <div className="nome">Isis Couto Pernambuco</div>
                  <div className="cargo">Analista de sistemas - Administração de Dados/Banco de Dados – DIINFO</div>

                  <div className="nome">Sandro Braga Maia</div>
                  <div className="cargo">Analista de sistemas - Desenvolvedor - Divisão de Geoprocessamento – DIGEOP</div>

                  <div className="nome">Paulo Roberto Barreto da Silva</div>
                  <div className="cargo">Técnico em geociências  - Administração de Dados/Banco de Dados – DIINFO</div>

                </div><br/>
              </td>
            </tr>
            <tr>
                <td width="100%" valign="top">
                  <div className="caixa_lov nome" align="center">
                    <h2 align="center"><font color="#082767"><strong>Colaboradores</strong></font></h2>

                    <h4 style={{marginTop: "20px"}}>DIVISÃO DE HIDROLOGIA BÁSICA - DIHIBA</h4>

                    <div className="nome">Arthur Abreu</div>
                    <div className="cargo">Engenheiro hidrólogo</div>

                    <h4 style={{marginTop: "20px"}}>SUREG-BH</h4>
                    <div className="nome">Alexandre Henrique</div>
                    <div className="cargo">Técnico em geociências</div>

                    <h4 style={{marginTop: "20px"}}>SUREG-RE</h4>
                    <div className="nome">Anderson Luiz de A. Pereira</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Carlos Eduardo de Oliveira Dantas</div>
                    <div className="cargo">Coordenador Executido da SUPLAN</div>

                    <div className="nome">George Araújo</div>
                    <div className="cargo">Engenheiro hidrólogo</div>

                    <div className="nome">Helton Gouveia</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Jackson Almeida Silva</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Fernando José Barros Filho</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Kleverson Holland Lima Rocha</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">George Araújo</div>
                    <div className="cargo">Engenheiro hidrólogo</div>

                    <div className="nome">Rafael Diego Diniz B. de Albuquerque</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Renato Pereira</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Rodrigo Rosa</div>
                    <div className="cargo">Técnico em geociências</div>

                    <div className="nome">Solange Cavalcanti de Melo</div>
                    <div className="cargo">Técnica em geociências</div>

                    <div className="nome">Vinícius Barros da Costa</div>
                    <div className="cargo">Assistente Administrativo</div>

                    <div className="nome">Wênil Nascimento</div>
                    <div className="cargo">Técnico em geociências</div>

                  </div><br/>
                </td>
              </tr>

          </tbody></table>
        </Modal>

      </>

    );
  }
}

export default connect(
  (state) => {
    const permissoes = extrairPermissoes(state);
    return {
      exibirRelatorios: permissoes.permitirVisualizarRelatorio,
      exibirCadastros: permissoes.permitirVisualizarCadastro,
      exibirPagamentos: permissoes.permitirVisualizarPagamento,
      idPerfil: permissoes.idPerfil
    };
  }
)(MenuLateral)
