import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import createServiceFor from "../../service/Service";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification } from "../../util/FormUtils";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

class PaginaFormBacia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
    };
  }

  componentDidMount() {
    const promises = [];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/baciasHidrograficas").get(this.props.match.params.id)
      );

      this.setState({
        emEdicao: true
      });
    }

    const promise = Promise.all(promises)
      .then(([bacia = {}]) => {
        if (bacia.data) {
          this.setState({
            initialValues: bacia.data,
          });
        }

        return {
          initialValues: bacia.data
            ? {
                ...bacia.data,
              }
            : {},
        };
      })
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/baciasHidrograficas")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Bacia salva com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      nome: this.state.initialValues?.nome,
    });

    const formattedValues = {
      nome: limitText(sanitizedValues?.nome, 70),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state.initialValues?.id
                ? `<div class="text">Bacia hidrográfica</div> - <div class="text">Código: </div>${this.state.initialValues.id}`
                : ''
            }
            ${
              formattedValues?.nome
                ? ` - <div class="text">Nome:</div> ${formattedValues.nome}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Bacias hidrográficas"
        baseUrl="/sgih/app/baciasHidrograficas"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[]}
                mutators={{}}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados da bacia hidrográfica">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="id"
                                  label="Código"
                                  readOnly
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={10}>
                                <Field
                                  component={TextField}
                                  name="nome"
                                  label="Nome"
                                  maxLength={80}
                                  validate={requiredWithNotification("Nome")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormBacia;
