export const PERENE = {
  viewName: "Perene", serializedName: "PERENE",
};

export const INTERMITENTE = {
  viewName: "Intermitente", serializedName: "INTERMITENTE",
};

export const EFEMERO = {
  viewName: "Efêmero", serializedName: "EFEMERO",
};

export default [
  EFEMERO, INTERMITENTE, PERENE
];