import React, { Component } from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from '../../model/UnidadeRegional'

class PaginaListaObservadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais)=>{
                this.setState({
                    regionais
                }, ()=>{
                    const source = {
                        date : 'dd/MM/yyyy',
                        datafields: [
                            { name: 'id', type: 'number', map: 'id' },
                            { name: 'nome', type: 'string' },
                            { name: 'idade', type: 'number', map: 'idade' },
                            { name: 'tipo', type: 'string', map: 'tipo' },
                            { name: 'estacaoPluviometrica', type: 'string', map: 'estacaoPluviometrica' },
                            { name: 'estacaoFluviometrica', type: 'string', map: 'estacaoFluviometrica' },
                            { name: 'roteiro', type: 'number', map: 'roteiro' },
                            { name: 'codigoPluviometrico', type: 'string', map: 'codigoPluviometrico' },
                            { name: 'codigoFluviometrico', type: 'string', map: 'codigoFluviometrico' },

                            { name: 'valorTotal', type: 'number', map: 'valorTotal' },
                            { name: 'iss', type: 'number', map: 'iss' },
                            { name: 'status', type: 'string', map: 'status' },
                            { name: 'tipoPagamento', type: 'string', map: 'tipoPagamento' },
                            { name: 'dataFimLeitura', type: 'date' },
                            { name: 'dataInicioLeitura', type: 'date'  },
                            { name: 'unidadeRegional', type: 'string', map: 'unidadeRegional' },

                        ],
                        id: 'id',
                    };

                    const columns = [
                        // { text: 'Regional', dataField: 'unidadeRegional', width: '7%' },
                        { text: 'Regional', dataField: 'unidadeRegional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                        { text: 'Nome', dataField: 'nome' },
                        { text: 'Idade', dataField: 'idade', width: "3%", cellsalign: 'right'},
                        { text: 'Tipo', dataField: 'tipo', width: "3%" },
                        { text: 'Roteiro', dataField: 'roteiro', width: "4%" },
                        { text: 'Código PLU', dataField: 'codigoPluviometrico', width: "6%" },
                        { text: 'Estação PLU', dataField: 'estacaoPluviometrica', width: "11%" },
                        { text: 'Código FLU', dataField: 'codigoFluviometrico', width: "6%" },
                        { text: 'Estação FLU', dataField: 'estacaoFluviometrica', width: "11%" },
                        { text: 'Valor total', dataField: 'valorTotal', width: "5%", cellsalign: 'right', cellsformat: 'c2'
                        //  cellsrenderer(row, columnfield, value) {
                        //     const val = value ? "R$" + value.toFixed(2).replace(/\./g, ',')
                        //       : '';
                        //     return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;">${val}</div>`
                        // }
                        },
                        { text: 'ISS (%)', dataField: 'iss', width: "4%", cellsalign: 'right', cellsformat: 'f2'
                        //  cellsrenderer(row, columnfield, value) {
                        //     const val = value ? value.toFixed(2).replace(/\./g, ',')
                        //         : '';
                        //     return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;">${val}</div>`
                        // }
                        },
                        { text: 'Status', dataField: 'status', width: "5%", filtertype: 'checkedlist', filteritems: ["Ativo", "Inativo", "Suspenso"]
                            // , cellsrenderer(row, columnfield, value) {
                            //     if (value === 13) {
                            //         return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"> Ativo</div>`
                            //     } else if(value === 14){
                            //         return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"> Inativo</div>`
                            //     } else if(value === 15){
                            //         return `<div class="jqx-grid-cell-left-align" style="margin-top: 8.5px;"> Suspenso</div>`
                            //     }
                            //     return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;"> </div>`
                            // }
                         },
                        { text: 'Pagamento', dataField: 'tipoPagamento', width: "5%" },
                        { text: 'Data inicial', dataField: 'dataInicioLeitura', width: "5%", cellsformat: 'dd/MM/yyyy', filtertype: 'date' },
                        { text: 'Data final', dataField: 'dataFimLeitura', width: "5%", cellsformat: 'dd/MM/yyyy', filtertype: 'date' }
                    ];

                    this.tableConfig = { source, columns }
                    this.setState({
                        exibir:true
                    })
                })

            }
        )

    }

    render() {
        return this.state ? this.state.exibir ?
            <CrudListPageScaffold
                title="Observadores"
                tableConfig={this.tableConfig}
                baseUrl="/observadores"
                history={this.props.history}
                formLinkTitle="Novo cadastro"
                tableName="observadores"
                canPrintSimpleVerion={true}
            />
            : <></>
            : <></>
    }
}

export default PaginaListaObservadores;
