import OrigemRelatorio from 'model/OrigemRelatorio';
import StatusRelatorio from 'model/StatusRelatorio';
import React, {Component} from 'react';
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
import TipoSensor from 'model/TipoSensor';

class PaginaListaRelatoriosPCD extends Component {
    constructor(props) {
        super(props);

        this.state = {
            exibir:false
        };

        UNIDADEREGIONAL(
            (regionais) => {
                this.setState({ regionais },
                    StatusRelatorio(
                        (statusRelatorio) => {
                            this.setState(
                                { statusRelatorio },
                                TipoSensor(
                                    (tiposSensores) => {
                                        this.setState(
                                            { tiposSensores },
                                            () => {
                                                const source = {
                                                    date : 'dd/MM/yyyy',
                                                    datafields: [
                                                        { name: 'id', type: 'number', map: 'id' },
                                                        { name: 'nomeEstacao', type: 'string', map: 'nomeEstacao' },
                                                        { name: 'dataVisita', type: 'date', map: 'dataVisita' },
                                                        { name: "campanha", type: "date", map: "campanha" },
                                                        { name: 'nomeMarca', type: 'string', map: 'nomeMarca' },
                                                        { name: 'nomeTipoSensor', type: 'string', map: 'nomeTipoSensor' },
                                                        { name: 'tipoEstacao', type: 'string', map: 'tipoEstacao' },
                                                        { name: 'tipoVisita', type: 'string', map: 'tipoVisita' },
                                                        { name: 'codigoEstacao', type: 'string', map: 'codigoEstacao' },
                                                        { name: 'roteiro', type: 'number', map: 'roteiro' },
                                                        { name: 'houveAlteracao', type: 'string', map: 'houveAlteracao' },
                                                        { name: 'regional', type: 'string', map: 'regional' },
                                                        { name: 'origem', type: 'string', map: 'origem' },
                                                        { name: 'equipe', type: 'string', map: 'equipe' },
                                                        { name: 'equipeFormatada', type: 'string', map: 'equipeFormatada' },
                                                        { name: 'perfilUltimaAlteracaoFluxo', type: 'string', map: 'perfilUltimaAlteracaoFluxo' },
                                                    ],
                                                    id: 'id',
                                                };

                                                const columns = [
                                                    { text: 'ID', dataField: 'id', width: '4%' },
                                                    { text: 'Status Atual', dataField: 'perfilUltimaAlteracaoFluxo', width: '9%', filtertype: 'checkedlist', filteritems: this.state ? this.state.statusRelatorio : [] },
                                                    { text: 'Origem', dataField: 'origem', width: '5%' , filtertype: 'checkedlist', filteritems: OrigemRelatorio},
                                                    { text: 'Roteiro', dataField: 'roteiro',filtertype:'number', width: '5%' },
                                                    { text: 'Regional', dataField: 'regional', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
                                                    { text: 'Código', dataField: 'codigoEstacao', width: '7%' },
                                                    { text: 'Estação', dataField: 'nomeEstacao', width: '9%'  },
                                                    { text: 'Tipo da estação', dataField: 'tipoEstacao', width: '7%' },
                                                    { text: 'Data', dataField: 'dataVisita', filtertype: 'date', cellsformat: 'dd/MM/yyyy', width: '6%' },
                                                    { text: 'Campanha', filtertype: 'date', dataField: 'campanha', width: '6%', cellsformat: 'MM/yyyy' },
                                                    { text: 'Tipo da visita', dataField: 'tipoVisita', width: '6%' },
                                                    { text: 'Alteração', dataField: 'houveAlteracao', width: '5%' },
                                                    { text: 'Marca PCD', dataField: 'nomeMarca', width: '7%' },
                                                    { text: 'Tipo de Sensor', dataField: 'nomeTipoSensor', width: '7%', filtertype: 'checkedlist', filteritems: this.state ? this.state.tiposSensores : [] },
                                                    {
                                                        text: 'Equipe', dataField: 'equipe', sortable: false,
                                                        cellsrenderer: function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
                                                            return `<div className="jqx-grid-cell-left-align" style="margin-top: 8.5px;" title="${rowdata.equipeFormatada}">${value}</div>`;
                                                        },
                                                    },
                                                ];

                                                this.tableConfig = { source, columns };
                                                this.setState({
                                                    exibir:true
                                                })
                                            }
                                        )
                                    }
                                )
                            )

                        }
                    )
                )
            }
    )


    }

    render() {
        return this.state ? this.state.exibir ?
            <CrudListPageScaffold
                title="Relatórios PCD"
                tableConfig={this.tableConfig}
                baseUrl="/relatoriosPCD"
                history={this.props.history}
                formLinkTitle="Novo relatório"
                tableName="relatoriosPCD"
                shouldAddReportFlowButton={true}
                shouldAddRefreshButton={true}
                ehRelatorio={true}
                tipoRelatorio="PCD"
                urlBaseFluxoRelatorio="/sgih/app/fluxoRelatorios/pcd"
            />
            : <></>
            : <></>
    }
}

export default PaginaListaRelatoriosPCD;
