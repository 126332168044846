import createServiceFor from "../service/Service";

export const TipoSensor =  (callBack, tiposSensores = []) => {
    Promise.resolve(createServiceFor("/tipos-sensores").query())
        .then(
                (response) => {
                tiposSensores = response.data.map(tipoSensor => tipoSensor.nome)
                if (callBack) {
                    callBack(tiposSensores)
                }
            }
        )
        .catch(e => {
            console.error(e)
        })

}

export default TipoSensor;