export const PCD = {
    viewName: "PCD", serializedName: "PCD",
};

export const PLUVIOMETRICO = {
    viewName: "Pluviométrico", serializedName: "PLUVIOMETRICO",
};

export const FLUVIOMETRICO = {
    viewName: "Fluviométrico", serializedName: "FLUVIOMETRICO",
};

export const TipoRelatorioMap = {
    [PCD.serializedName]: PCD.viewName,
    [PLUVIOMETRICO.serializedName]: PLUVIOMETRICO.viewName,
    [FLUVIOMETRICO.serializedName]: FLUVIOMETRICO.viewName,
};

export default [
    FLUVIOMETRICO, PCD, PLUVIOMETRICO
];
