import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import createServiceFor from "../../service/Service";
import { setValue as changeValue } from "../../util/mutators/ChangeValue";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification } from "../../util/FormUtils";
import SelectField from "../../components/form/field/SelectField";
import TextAreaField from "../../components/form/field/TextAreaField";
import NumberField from "../../components/form/field/NumberField";
import voltarParaGrid from "util/VoltarParaGrid";
import { sanitizeStringValuesFromObject } from "util/security/sanitizer";
import { limitText } from "util/strings/text-limiter";

const labelResolver = (e) => e.nome;
const valueResolver = (e) => e.id;

class PaginaFormRios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emEdicao: false,
      subBacias: [],
    };
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/regioesHidrograficas/simples").query(),
      createServiceFor("/subBacias/simples").query(),
    ];

    if (this.props.match.params.id) {
      promises.push(createServiceFor("/rios").get(this.props.match.params.id));

      this.setState({
        emEdicao: true,
      });
    }

    const promise = Promise.all(promises)
      .then(([regioesHidrograficas, subbacias, subBacia = {}]) => {
        if (subBacia.data) {
          this.setState({
            initialValues: subBacia.data
          });
        }

        return {
          regioesHidrograficas: regioesHidrograficas.data,
          subbacias: this.props.match.params.id ? subbacias.data : [],
          initialValues: subBacia.data,
        };
      })
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/rios")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Rio salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (
          e.response.data &&
          e.response.data.message &&
          e.response.data.message.includes("rio")
        ) {
          store.dispatch(
            showGlobalNotification({
              message: e.response.data.message,
            })
          );
        } else {
          store.dispatch(
            showGlobalNotification({
              message: "Ocorreu um erro ao tentar salvar",
            })
          );
        }
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  alterarRegiaoHidrograficaSelecionada(changeValue) {
    return (idRegiao) => {
      createServiceFor("/subBacias/porRegiaoHidrografica")
        .customURL("GET", idRegiao)
        .then((res) => {
          console.log(res.data);
          this.setState(
            {
              subBacias: res.data,
            },
            () => {
              changeValue("idSubBacia", "");
              changeValue(
                "TRUQUE_PARA_FORCAR_O_REBUILD_FAVOR_N_REPRODUZIR",
                new Date().getTime()
              );
            }
          );
        })
        .catch((e) => {
          console.error(e);
          store.dispatch(stopGlobalLoading());
          store.dispatch(
            showGlobalNotification({
              message: "Ocorreu um erro ao tentar salvar",
            })
          );
        });
    };
  }

  getCustomInfoSubtitle() {
    const sanitizedValues = sanitizeStringValuesFromObject({
      nome: this.state.initialValues?.nome,
    });

    const formattedValues = {
      nome: limitText(sanitizedValues?.nome, 70),
    };

    return this.state.emEdicao
      ? `
      <div class="content">
        ${this.state?.initialValues
          ? `
            ${
              this.state?.initialValues?.id
                ? `<div class="text">Rio</div> - <div class="text">Código: </div>${this.state.initialValues.id}`
                : ''
            }
            ${
              formattedValues?.nome
                ? ` - <div class="text">Nome:</div> ${formattedValues.nome}`
                : ''
            }
          `
          : ''
        }
      </div>
    `
    : null
  }

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    const customInfoSubtitle = this.getCustomInfoSubtitle();

    return (
      <CrudFormPageScaffold
        baseTitle="Rios"
        baseUrl="/sgih/app/rios"
        history={this.props.history}
        match={this.props.match}
        customInfo={customInfoSubtitle}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[]}
                mutators={{ changeValue }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form,
                  form: {
                    mutators: { changeValue },
                  },
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados do rio">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component="input"
                                  type="hidden"
                                  name="id"
                                />
                                <Field
                                  component={NumberField}
                                  name="codigo"
                                  label="Código"
                                  step={1}
                                  validate={requiredWithNotification("Código")}
                                  required
                                  max={999999999}
                                  disabled={disableAllFields || estaEditando}
                                />
                              </Col>
                              <Col className="mt-3" md={10}>
                                <Field
                                  component={TextField}
                                  name="nome"
                                  label="Nome"
                                  validate={requiredWithNotification("Nome")}
                                  required
                                  maxLength={200}
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={SelectField}
                                  elements={data.regioesHidrograficas}
                                  labelResolver={labelResolver}
                                  valueResolver={valueResolver}
                                  name="idRegiaoHidrografica"
                                  label="Região hidrográfica"
                                  onChange={this.alterarRegiaoHidrograficaSelecionada(
                                    changeValue
                                  ).bind(this)}
                                  validate={requiredWithNotification(
                                    "Região hidrográfica"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Field name="TRUQUE_PARA_FORCAR_O_REBUILD_FAVOR_N_REPRODUZIR">
                                  {() => {
                                    return (
                                      <Field
                                        component={SelectField}
                                        // elements={this.state.subBacias}
                                        elements={
                                          this.state.subBacias.length === 0
                                            ? data.subbacias
                                            : this.state.subBacias
                                        }
                                        key={this.state.subBacias.length}
                                        labelResolver={labelResolver}
                                        valueResolver={valueResolver}
                                        name="idSubBacia"
                                        label="Sub-bacia"
                                        validate={requiredWithNotification(
                                          "Sub-bacia"
                                        )}
                                        required
                                        maxLength={2000}
                                        disabled={disableAllFields}
                                      />
                                    );
                                  }}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={12}>
                                <Field
                                  component={TextAreaField}
                                  maxLength={2000}
                                  name="potamografia"
                                  label="Potamografia"
                                  validate={requiredWithNotification(
                                    "Potamografia"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormRios;
