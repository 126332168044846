import React from "react";
import PropTypes from "prop-types";
import { Field } from 'react-final-form';

const defaultRender = ({ meta: { error, touched, dirty } }, mostrarMensagemOnBlur) =>
    error && (mostrarMensagemOnBlur ? dirty : touched) ? <small className="text-danger text-sm">{error}</small> : null;


// From https://codesandbox.io/s/lm4p3m92q
const Error = ({ name, render = defaultRender, mostrarMensagemOnBlur }) => (
    <Field name={name} subscription={{ error: true, touched: true, dirty: true }}>
        {(props) => render(props, mostrarMensagemOnBlur)}
    </Field>
);

Error.propTypes = {
    name: PropTypes.string.isRequired,
    render: PropTypes.func,
    mostrarMensagemOnBlur: PropTypes.bool,
};

Error.defaultProps = {
    mostrarMensagemOnBlur: false,
};

export default Error;