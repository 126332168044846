import axios from 'axios';
import { ACCESS_TOKEN } from '../constants';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_URL,
    'Authorization': `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
  timeout: 300000000
});
