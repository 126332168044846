import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormMunicipios from "./PaginaFormMunicipios";
import PaginaListaMunicipios from "./PaginaListaMunicipios";

class PaginaMunicipios extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/municipios"}
            component={PaginaListaMunicipios}
          />
          <Route
            exact
            path={"/sgih/app/municipios/criar"}
            component={PaginaFormMunicipios}
          />
          <Route
            path={"/sgih/app/municipios/:id"}
            component={PaginaFormMunicipios}
          />
        </Switch>
      </>
    );
  }
}

export default PaginaMunicipios;
