import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Row, Col } from "react-bootstrap";

import { SecaoFormulario } from "components/SecaoFormulario/SecaoFormulario";
import { PermissaoFuncionalidadePerfil } from "./PermissaoFuncionalidadePerfil";
import BotaoPadrao from "../../components/botao/BotaoPadrao";
import BotaoIconeExclusao from "components/botao/BotaoIconeExclusao/BotaoIconeExclusao";
import CheckboxField from "components/form/field/CheckboxField";
import SelectField from "components/form/field/SelectField/SelectField";
import { requiredWithNotification, WhenFieldChanges } from "util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";

const resolverLabelEValorDePerfil = (e) => e.nome;
const resolverValorEValorDePerfil = (e) => e.id;

const resolverLabelEValorDeEfeito = (e) => e.rotulo;
const resolverValorEValorDeEfeito = (e) => e.nome;

function AbaPermissoesRelatorios({
  desabilitarTodosCampos,
  elementosEscopo,
  elementosPerfil,
  elementosEfeito,
  pushMutator,
}) {

  const perfisFiltrado = elementosPerfil.filter(e => e.id != 15);

  return (
    <>
      <FormSectionWrapper title="Tem permissão relacionada a relatórios para">
        {/* Visualizar Relatórios */}
        <WhenFieldChanges
          field="permitirInserirRelatorio"
          becomes={true}
          set="permitirVisualizarRelatorio"
          to={true}
        />
        <WhenFieldChanges
          field="permitirAlterarRelatorio"
          becomes={true}
          set="permitirVisualizarRelatorio"
          to={true}
        />
        <WhenFieldChanges
          field="permitirExcluirRelatorio"
          becomes={true}
          set="permitirVisualizarRelatorio"
          to={true}
        />
        <WhenFieldChanges
          field="permitirImprimirListagemRelatorio"
          becomes={true}
          set="permitirVisualizarRelatorio"
          to={true}
        />
        <WhenFieldChanges
          field="permitirDownloadZIPRelatorio"
          becomes={true}
          set="permitirVisualizarRelatorio"
          to={true}
        />
        <PermissaoFuncionalidadePerfil
          rotuloFuncionalidade="Visualizar"
          nomeCheckbox="permitirVisualizarRelatorio"
          desabilitarTodosCampos={desabilitarTodosCampos}
          nomeSelectEscopo="escopoVisualizarRelatorio"
          nomeSelectPerfis="perfilVisualizarRelatorio"
          elementosEscopo={elementosEscopo}
          elementosPerfil={perfisFiltrado}
        ></PermissaoFuncionalidadePerfil>
        {/* Imprimir Listagem de Relatórios */}
        <PermissaoFuncionalidadePerfil
          rotuloFuncionalidade="Imprimir listagem"
          nomeCheckbox="permitirImprimirListagemRelatorio"
          desabilitarTodosCampos={desabilitarTodosCampos}
          nomeSelectEscopo="escopoImprimirListagemRelatorio"
          nomeSelectPerfis="perfilImprimirListagemRelatorio"
          elementosEscopo={elementosEscopo}
          elementosPerfil={perfisFiltrado}
        ></PermissaoFuncionalidadePerfil>
        {/* Download ZIP de Relatórios */}
        <PermissaoFuncionalidadePerfil
          rotuloFuncionalidade="Download ZIP"
          nomeCheckbox="permitirDownloadZIPRelatorio"
          desabilitarTodosCampos={desabilitarTodosCampos}
          nomeSelectEscopo="escopoDownloadZIPRelatorio"
          nomeSelectPerfis="perfilDownloadZIPRelatorio"
          elementosEscopo={elementosEscopo}
          elementosPerfil={elementosPerfil}
        ></PermissaoFuncionalidadePerfil>
        {/* Download ZIP de Relatórios */}
        <PermissaoFuncionalidadePerfil
          rotuloFuncionalidade="Alterar"
          nomeCheckbox="permitirAlterarRelatorio"
          desabilitarTodosCampos={desabilitarTodosCampos}
          nomeSelectEscopo="escopoAlterarRelatorio"
          nomeSelectPerfis="perfilAlterarRelatorio"
          elementosEscopo={elementosEscopo}
          elementosPerfil={elementosPerfil}
        ></PermissaoFuncionalidadePerfil>
        {/* Download ZIP de Relatórios */}
        <PermissaoFuncionalidadePerfil
          rotuloFuncionalidade="Excluir"
          nomeCheckbox="permitirExcluirRelatorio"
          desabilitarTodosCampos={desabilitarTodosCampos}
          nomeSelectEscopo="escopoExcluirRelatorio"
          nomeSelectPerfis="perfilExcluirRelatorio"
          elementosEscopo={elementosEscopo}
          elementosPerfil={elementosPerfil}
        ></PermissaoFuncionalidadePerfil>
        {/* Fluxo de Relatórios */}
        <Row>
          <Col className="mt-3" md={3}>
            <Field
              component={CheckboxField}
              name="permitirEnviarRelatoriosEmBloco"
              label="Enviar relatórios em blocos"
              disabled={desabilitarTodosCampos}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" md={3}>
            <Field
              component={CheckboxField}
              name="permitirVisualizarAprovacaoRapida"
              label="Aprovação rápida"
              disabled={desabilitarTodosCampos}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" md={3}>
            <Field
              component={CheckboxField}
              name="permitirFluxoRelatorio"
              label="Fluxo de relatórios"
              disabled={desabilitarTodosCampos}
            />
          </Col>
        </Row>
        {/* Inserir Relatórios */}
        <Row>
          <Col className="mt-3" md={3}>
            <Field
              component={CheckboxField}
              name="permitirInserirRelatorio"
              label="Inserir"
              disabled={desabilitarTodosCampos}
            />
          </Col>
        </Row>
      </FormSectionWrapper>
      {/* Seção de permissões de troca de status de relatórios */}
      <Field name="permitirFluxoRelatorio" subscription={{ value: true }}>
        {({ input: { value } }) => {
          const visivel = value;
          if (visivel) {
            return (
              <SecaoFormulario title="Tem permissão para alterar o status de relatórios para">
                {!desabilitarTodosCampos && <BotaoPadrao
                  className="mb-3"
                  onClick={() => {
                    if(!desabilitarTodosCampos){
                      pushMutator("permissoesMudancaStatusRelatorio");
                    }
                  }}
                >
                  Inserir
                </BotaoPadrao>}
                <FieldArray name="permissoesMudancaStatusRelatorio">
                  {({ fields, meta }) => (
                    <>
                      {fields &&
                        fields.length > 0 &&
                        fields.map((nome, idx) => {
                          return (
                            <Row key={idx} className="mb-3">
                              <Col md={3}>
                                <Field
                                  emptyOptionLabel="Selecione o status origem permitido..."
                                  component={SelectField}
                                  elements={elementosPerfil}
                                  labelResolver={resolverLabelEValorDePerfil}
                                  valueResolver={resolverValorEValorDePerfil}
                                  name={`${nome}.de`}
                                  disabled={desabilitarTodosCampos}
                                  required
                                  validate={requiredWithNotification(
                                    "Status de origem"
                                  )}
                                />
                              </Col>
                              <Col md={3}>
                                <Field
                                  emptyOptionLabel="Selecione o status destino permitido..."
                                  component={SelectField}
                                  elements={elementosPerfil}
                                  labelResolver={resolverLabelEValorDePerfil}
                                  valueResolver={resolverValorEValorDePerfil}
                                  name={`${nome}.para`}
                                  disabled={desabilitarTodosCampos}
                                  required
                                  validate={requiredWithNotification(
                                    "Status de destino"
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Field
                                  emptyOptionLabel="Selecione o efeito da mudança..."
                                  component={SelectField}
                                  elements={elementosEfeito}
                                  name={`${nome}.efeito`}
                                  labelResolver={resolverLabelEValorDeEfeito}
                                  valueResolver={resolverValorEValorDeEfeito}
                                  disabled={desabilitarTodosCampos}
                                  required
                                  validate={requiredWithNotification("Efeito")}
                                />
                              </Col>
                              <Col className="mt-3" md="auto">
                                <Field
                                  component={CheckboxField}
                                  name={`${nome}.fluxoPadrao`}
                                  label="Fluxo padrão"
                                  disabled={desabilitarTodosCampos}
                                />
                              </Col>
                              <Col className="pr-1" md="auto">
                                {desabilitarTodosCampos ? null : (
                                  <BotaoIconeExclusao
                                    onClick={() => fields.remove(idx)}
                                  />
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                      {!fields ||
                        (0 === fields.length && (
                          <p>
                            Este perfil ainda não tem permissões para mudar o
                            status de relatórios. Clique em "Incluir" para
                            adicionar permissões.
                          </p>
                        ))}
                    </>
                  )}
                </FieldArray>
              </SecaoFormulario>
            );
          } else {
            return <></>;
          }
        }}
        {/* ./Seção de permissões de troca de status de relatórios */}
      </Field>
    </>
  );
}

export { AbaPermissoesRelatorios };
