import React from "react";
import { Route, Switch } from "react-router-dom";
import PaginaFormUnidadesRegionais from "./PaginaFormUnidadesRegionais";
import PaginaListaUnidadesRegionais from "./PaginaListaUnidadesRegionais";

class PaginaUnidadesRegionais extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route
            exact
            path={"/sgih/app/unidadesRegionais"}
            component={PaginaListaUnidadesRegionais}
          />
          <Route
            exact
            path={"/sgih/app/unidadesRegionais/criar"}
            component={PaginaFormUnidadesRegionais}
          />
          <Route path={"/sgih/app/unidadesRegionais/:id"} component={PaginaFormUnidadesRegionais} />
        </Switch>
      </>
    );
  }
}

export default PaginaUnidadesRegionais;
