import React from "react";
import { Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";

import CheckboxField from "components/form/field/CheckboxField";
import { WhenFieldChanges } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";

function AbaPermissoesRecibo({ desabilitarTodosCampos }) {
  return (
    <FormSectionWrapper title="Tem permissão relacionada a pagamentos para">
      {/* Visualizar Cadastros */}
      <WhenFieldChanges
        field="permitirInserirPagamento"
        becomes={true}
        set="permitirVisualizarPagamento"
        to={true}
      />
      <WhenFieldChanges
        field="permitirAlterarPagamento"
        becomes={true}
        set="permitirVisualizarPagamento"
        to={true}
      />
      <WhenFieldChanges
        field="permitirExcluirPagamento"
        becomes={true}
        set="permitirVisualizarPagamento"
        to={true}
      />
      <WhenFieldChanges
        field="permitirImprimirListagemPagamento"
        becomes={true}
        set="permitirVisualizarPagamento"
        to={true}
      />
      <WhenFieldChanges
        field="permitirDownloadZIPPagamento"
        becomes={true}
        set="permitirVisualizarPagamento"
        to={true}
      />
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirVisualizarPagamento"
            label="Visualizar"
            disabled={desabilitarTodosCampos}
          />
        </Col>
        <Col className="mt-3" md={4}>
          <Field
            name="permitirVisualizarPagamento"
            subscription={{ value: true }}
          >
            {({ input: { value } }) => {
              console.log(value)
              if(value && value === true){
                return (
                  <>
                    <Field
                      component={CheckboxField}
                      name="permitirVisualizarTodosParametros"
                      label="Visualizar todos parâmetros"
                      disabled={desabilitarTodosCampos}
                    />
                  </>
                );
              }
              return <> </>
            }}
          </Field>
        </Col>
        <Col className="mt-3" md={4}>
          <Field
            name="permitirVisualizarPagamento"
            subscription={{ value: true }}
          >
            {({ input: { value } }) => {
              console.log(value)
              if(value && value === true){
                return (
                  <>
                    <Field
                      component={CheckboxField}
                      name="permitirVisualizarTodosRecibos"
                      label="Visualizar todos recibos"
                      disabled={desabilitarTodosCampos}
                    />
                  </>
                );
              }
              return <> </>
            }}
          </Field>
        </Col>
      </Row>
      {/* Imprimir Listagem de Pagamentos */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirImprimirListagemPagamento"
            label="Imprimir listagem"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Download ZIP de Pagamentos */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirDownloadZIPPagamento"
            label="Download ZIP"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Alterar Pagamentos */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirAlterarPagamento"
            label="Alterar"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Excluir Pagamentos */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirExcluirPagamento"
            label="Excluir"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Inserir Pagamentos */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirInserirPagamento"
            label="Inserir"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
    </FormSectionWrapper>
  );
}

export { AbaPermissoesRecibo };
