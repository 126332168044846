import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";

class PaginaListaTiposObservadores extends Component {
  constructor(props) {
    super(props);

    const source = {
      datafields: [
        { name: "id", type: "number", map: "id" },
        {
          name: "siglaTipoObservador",
          type: "string",
          map: "siglaTipoObservador",
        },
        {
          name: "valorGratificacaoTipo",
          type: "number",
          map: "valorGratificacaoTipo",
        },
        { name: "tipoObservador", type: "string", map: "tipoObservador" },
      ],
      id: "id",
    };

    const columns = [
      { text: "Código", dataField: "id", width: "5%" },
      { text: "Tipo de observador", dataField: "siglaTipoObservador", width: "9%"  },
      { text: "Valor da observação (R$)", dataField: "valorGratificacaoTipo", width: "11%", cellsrenderer(row, columnfield, value) {
          const val = value ? value.toFixed(2).replace(/\./g, ",") : '';
          return `<div className="jqx-grid-cell-right-align" style="margin-top: 8.5px;">R$ ${val}</div>`
      }},
      { text: "Descrição", dataField: "tipoObservador"},
    ];

    this.tableConfig = { source, columns };
  }

  render() {
    return (
      <CrudListPageScaffold
        title="Tipos de observadores"
        tableConfig={this.tableConfig}
        baseUrl="/tiposObservadores"
        history={this.props.history}
        formLinkTitle="Novo"
        tableName="tiposObservadores"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
      />
    );
  }
}

export default PaginaListaTiposObservadores;
