import http from "../service/http-commons";
import axios from "axios";
import {ACCESS_TOKEN, USER} from "../constants";

const PREFIXO = "auth/";

/**
 * action types
 */
export const SUCESSO_AO_LOGAR = PREFIXO + "SUCESSO_AO_LOGAR";
export const ERRO_AO_LOGAR = PREFIXO + "ERRO_AO_LOGAR";
export const SUCESSO_AO_SAIR = PREFIXO + "SUCESSO_AO_SAIR";
export const SUCESSO_LOGIN_OAUTH = PREFIXO + "SUCESSO_LOGIN_OAUTH";

/*
 * action creators
 */

export const efetuarLogin = ({ usuario, senha }) => (dispatch) => {
    const formData = new FormData();
    formData.append("usuario", usuario)
    formData.append("senha", senha)

    return axios({
        method: 'post',
        data: formData,
        url: process.env.REACT_APP_API_URL + '/loginViaAD'
    })
        .then(res => {
            const data = res.data;
            if (data.erro) {
                removerTokenAutenticacao()
                dispatch(erroAoLogar({
                    erro: res.data.erro,
                }));
            } else if (data.token) {
                persistirTokenAutenticacao(data.token)
                persistirNameUsuario(data.name)
                dispatch(sucessoAoLogar({
                    tokenAutenticacao: res.data.token,
                    permissoes: res.data.permissoes,
                    name: res.data.name
                }))
            }
        })
        .catch(res => {
            removerTokenAutenticacao()
            dispatch(erroAoLogar({
                erro: "Ocorreu um erro ao logar",
            }));
        })
};

export const efetuarLoginOAuth = ({ token }) => (dispatch) => {
    return axios({
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/refetchPermissoes',
        headers: { 'Authorization': `Bearer ${token}` },
    })
        .then(res => {
            console.log(res)
            const data = res.data;
            if (data.erro) {
                removerTokenAutenticacao()
                dispatch(erroAoLogar({
                    erro: res.data.erro,
                }));
            } else if (data.token) {
                persistirTokenAutenticacao(data.token)
                persistirNameUsuario(data.name)
                dispatch(sucessoAoLogar({
                    tokenAutenticacao: res.data.token,
                    permissoes: res.data.permissoes
                }))
            }
        })
        .catch(res => {
            console.error(res)
            removerTokenAutenticacao()
            dispatch(erroAoLogar({
                erro: "Acesso expirado",
            }));
        })
};

export const efetuarLoginOAuasdth = ({ token }) => ({
    type: SUCESSO_LOGIN_OAUTH, payload: { token }
});

export const efetuarLogoff = () => (dispatch) => {
    return http.post('/logout')
        .then(_ => {
            removerTokenAutenticacao()
            dispatch(sucessoAoSair())
        })
        .catch(res => {
            removerTokenAutenticacao()
            console.log(res)
            dispatch(sucessoAoSair())
        });
};

const sucessoAoLogar = (params) => ({
    type: SUCESSO_AO_LOGAR, payload: params
});

const erroAoLogar = ({erro}) => ({
    type: ERRO_AO_LOGAR, payload: {erro}
});

const sucessoAoSair = (params) => ({
    type: SUCESSO_AO_SAIR, payload: params
});

const persistirTokenAutenticacao = (token) => {
    localStorage.setItem(ACCESS_TOKEN, token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
const persistirNameUsuario = (name) => [
    localStorage.setItem(USER, name)
]
const removerTokenAutenticacao = () => {
    localStorage.removeItem(ACCESS_TOKEN)
    delete axios.defaults.headers.common['Authorization'];
}

/**
 * selectors
 */
export const extrairPermissoes = (state) => {
    if (!state || !state.auth || !state.auth.permissoes) return {};
    // eu poderia simplesmente retornar state.auth.permissoes, mas dessa forma fica mais simples alterar o nome de qlqr permissão :)
    return {
        permitirAlterarCadastro: state.auth.permissoes.permitirAlterarCadastro,
        permitirGerenciarCadastroUsuario: state.auth.permissoes.permitirGerenciarCadastroUsuario,
        permitirAlterarRelatorio: state.auth.permissoes.permitirAlterarRelatorio,
        permitirDownloadZIPCadastro: state.auth.permissoes.permitirDownloadZIPCadastro,
        permitirDownloadZIPRelatorio: state.auth.permissoes.permitirDownloadZIPRelatorio,
        permitirExcluirCadastro: state.auth.permissoes.permitirExcluirCadastro,
        permitirExcluirRelatorio: state.auth.permissoes.permitirExcluirRelatorio,
        permitirFluxoRelatorio: state.auth.permissoes.permitirFluxoRelatorio,
        permitirImprimirListagemCadastro: state.auth.permissoes.permitirImprimirListagemCadastro,
        permitirImprimirListagemRelatorio: state.auth.permissoes.permitirImprimirListagemRelatorio,
        permitirInserirCadastro: state.auth.permissoes.permitirInserirCadastro,
        permitirInserirRelatorio: state.auth.permissoes.permitirInserirRelatorio,
        permitirVisualizarCadastro: state.auth.permissoes.permitirVisualizarCadastro,
        permitirVisualizarRelatorio: state.auth.permissoes.permitirVisualizarRelatorio,
        permitirVisualizarAprovacaoRapida: state.auth.permissoes.permitirVisualizarAprovacaoRapida,
        nomePerfil: state.auth.permissoes.nomePerfil,
        idPerfil: state.auth.permissoes.idPerfil,
        idPerfilFluxoPadrao: state.auth.permissoes.idPerfilFluxoPadrao,
        permitirEnviarRelatoriosEmBloco: state.auth.permissoes.permitirEnviarRelatoriosEmBloco,

        permitirAlterarPagamento: state.auth.permissoes.permitirAlterarPagamento,
        permitirDownloadZIPPagamento: state.auth.permissoes.permitirDownloadZIPPagamento,
        permitirExcluirPagamento: state.auth.permissoes.permitirExcluirPagamento,
        permitirImprimirListagemPagamento: state.auth.permissoes.permitirImprimirListagemPagamento,
        permitirInserirPagamento: state.auth.permissoes.permitirInserirPagamento,
        permitirVisualizarPagamento: state.auth.permissoes.permitirVisualizarPagamento,
    }
}


/**
 * reducers
 */

export const authReducer = (state = {}, acao) => {
    switch (acao.type) {
        case SUCESSO_AO_LOGAR: return {
            tokenAutenticacao: acao.payload.tokenAutenticacao,
            permissoes: acao.payload.permissoes,
            autenticado: true
        };
        case ERRO_AO_LOGAR: return {
            autenticado: false,
            erro: acao.payload.erro,
        };
        case SUCESSO_AO_SAIR: return {
            autenticado: false,
        }
        default: return state;
    }
};
