import React from 'react'
import { Card, Container } from 'react-bootstrap';

const FormSectionWrapper = ({ title, children, titleStyle, cardStyle = {} }) => {
    return (
      <div className="mt-3" >
        <Container fluid >
          <Card style={cardStyle}>
            <Card.Body >
              {title && <Card.Title style={titleStyle}>{title}</Card.Title>}
              {children}
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  };

export default FormSectionWrapper