import React from "react";
import BlockUi from "react-block-ui";
import {connect} from "react-redux";

class Loader extends React.Component {
    render() {
        return <BlockUi tag="div" blocking={this.props.isBlocking} style={this.props.style}>
            {this.props.children}
        </BlockUi>
    }
}

export const createLocalLoader = (mapStateToProps) => connect(
    mapStateToProps,
    () => ({})
)(Loader);

const globalStateLoaderMapper = state => {
    return {
        isBlocking: state.global.isLoading,
        loadingMessage: state.global.loadingMessage,
    }
};

export const GlobalLoader = createLocalLoader(globalStateLoaderMapper);
