import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import React from "react";

const SecaoFormulario = ({ title, children, titulo = title }) => {
    return (
        <div className="mt-3">
            <Container>
                <Card>
                    <Card.Body>
                        {titulo && <Card.Title>{titulo}</Card.Title>}
                        {children}
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export {SecaoFormulario};