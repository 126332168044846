import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaFormParametrosRecibo from "./PaginaFormParametrosRecibo";
import PaginaListaParametrosRecibos from "./PaginaListaParametrosRecibos";

class PaginaParametrosRecibos extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/recibos/parametros"} component={PaginaListaParametrosRecibos} />
                <Route exact path={"/sgih/app/recibos/parametros/criar"} component={PaginaFormParametrosRecibo} />
                <Route path={"/sgih/app/recibos/parametros/:id"} component={PaginaFormParametrosRecibo} />
            </Switch>
        </>;
    }
}

export default PaginaParametrosRecibos;