import React from "react";
import { Row, Col } from "react-bootstrap";
import { requiredWithNotification } from "util/FormUtils";
import { Field } from "react-final-form";
import TextAreaField from "components/form/field/TextAreaField";
import TextField from "components/form/field/TextField";
import FormSectionWrapper from "components/form/FormSectionWrapper";

function AbaDadosBasicos({desabilitarTodosCampos}) {
  return (
    <FormSectionWrapper>
      <Row>
        <Col className="mt-3" md={12}>
          <Field
            component={TextField}
            name="nome"
            label="Nome do perfil"
            validate={requiredWithNotification("Nome do perfil")}
            required
            maxLength={200}
            disabled={desabilitarTodosCampos}
          />
        </Col>
        <Col className="mt-3" md={12}>
          <Field
            component={TextAreaField}
            name="descricao"
            label="Descrição"
            rows={5}
            maxLength={500}
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
    </FormSectionWrapper>
  );
}

export { AbaDadosBasicos };
