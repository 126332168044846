export const RETILINEA = {
  viewName: "Retilínea", serializedName: "RETILINEA",
};

export const CURVO = {
  viewName: "Curvo", serializedName: "CURVO",
};

export const ANASTOMOSADA = {
  viewName: "Anastomosada", serializedName: "ANASTOMOSADA",
};

export const MEANDRANTE = {
  viewName: "Meandrante", serializedName: "MEANDRANTE",
};

export default [
  ANASTOMOSADA, CURVO, MEANDRANTE, RETILINEA
];