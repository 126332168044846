import { showGlobalNotification } from "reducers";
import store from "store";

const dispatchGlobalNotification = (options) => {
    if (options) {
        store.dispatch(
            showGlobalNotification(options)
        );
    }
}

export { dispatchGlobalNotification };