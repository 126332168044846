import React from "react";
import {
    Route,
    Switch
} from 'react-router-dom';
import PaginaListaRelatoriosPCD from "./PaginaListaRelatoriosPCD";
import PaginaFormRelatoriosPCD from "./PaginaFormRelatoriosPCD";

class PaginaRelatoriosPCD extends React.Component {
    render() {
        return <>
            <Switch>
                <Route exact path={"/sgih/app/relatoriosPCD"} component={PaginaListaRelatoriosPCD} />
                <Route exact path={"/sgih/app/relatoriosPCD/criar"} component={PaginaFormRelatoriosPCD} />
                <Route path={"/sgih/app/relatoriosPCD/:id"} component={PaginaFormRelatoriosPCD} />
            </Switch>
        </>;
    }
}

export default PaginaRelatoriosPCD;