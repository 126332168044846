import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

class FieldLabel extends React.Component {
    render() {
        return <Form.Label>
            {this.props.label}
            {this.props.label && this.props.required && <span style={{ color: 'red' }}>*</span>}
        </Form.Label>
    }
}

FieldLabel.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
};

FieldLabel.defaultProps = {
    required: false,
};

export default FieldLabel;
