import React, {
} from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageScaffold from "./PageScaffold";
import Table from "../table/Table";
import { Modal } from "../modal/Modal";
import JqxButton from "jqwidgets-scripts/jqwidgets-react-tsx/jqxbuttons";

import swal from "sweetalert";

import createServiceFor, { objetoParaParams } from "../../service/Service";
import { showGlobalNotification, startGlobalLoading, stopGlobalLoading } from "reducers";
import { connect } from "react-redux";

import FileSaver from "file-saver";
import { extrairPermissoes } from "../../reducers/authReducers";
import { ACCESS_TOKEN } from "../../constants";
import store from "store";

import { createTooltipWrapperElement } from "web-components/tooltip/wrapper";
import "./../../web-components/tooltip/wrapper/style.css";

const reportFlowSgihTooltip = document.createElement('sgih-tooltip');
reportFlowSgihTooltip.id = 'reportFlowSgihTooltip';

document.body.appendChild(reportFlowSgihTooltip);

function create_UUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

/**
 *
 * @param {string[]} iconClasses
 * @param {Function} onClick
 * @param {*} ref
 * @param {Function} botaoAtivo
 * @param {*} nomePerfil
 * @param {{ enabled: boolean options: {}}} tooltipOptions
 * @param {Map<string, Function>} extraEventHandlersMap
 * @returns
 */
const createIconButton = (
  tooltipOptions,
  // dataField,
  iconClasses,
  onClick,
  ref,
  botaoAtivo,
  nomePerfil,
  extraEventHandlersMap = new Map()
) => (row, column, value, htmlElement) => {
  try {
    if (htmlElement.firstChild) {
      htmlElement.firstChild.remove();
    }

    const button = document.createElement("button");

    button.classList.add("btn");

    const iconEl = document.createElement("i");

    //Verifica se é o botão de alterar fluxo
    if (iconClasses[0] === "fa-stream") {
      // A linha muda para o índice da linha após o primeiro renderizacao.
      var statusAtual = row;
      if (typeof row === "object") {
        statusAtual = row.bounddata.perfilUltimaAlteracaoFluxo;
      } else if (typeof row === "number") {
        statusAtual = ref.current.getcellvalue(row, "perfilUltimaAlteracaoFluxo");
      } else {
        console.warn(`Unknown id for entity [row type=${typeof row}]`);
      }
      //Verifica se o status atual é igual ao perfil do usuario logado
      if (statusAtual === nomePerfil) {
        //altera a classe para danger
        iconEl.classList.add("fas", "fa-stream", "text-danger");
      } else {
        // segue o padrao anterior
        iconEl.classList.add("fas", ...iconClasses);
      }
    } else {
      //segue o padrao anterior
      iconEl.classList.add("fas", ...iconClasses);
    }

    // Row changes to the line index after the first redraw. we need to fix this.
    let id = row;
    if (typeof row === "object") {
      id = row.bounddata.id;
    } else if (typeof row === "number") {
      id = ref.current.getcellvalue(row, "id");
    } else {
      console.warn(`Unknown id for entity [row type=${typeof row}]`);
    }

    const linha = ref.current.getrowdatabyid(value);
    const deveAtivar = !botaoAtivo || botaoAtivo(linha);

    button.appendChild(iconEl);

    if (deveAtivar && tooltipOptions?.enabled) {
      const tooltipWrapper = createTooltipWrapperElement('reportFlowSgihTooltip');

      tooltipWrapper.appendChild(button)
      htmlElement.appendChild(tooltipWrapper);
    } else {
      htmlElement.appendChild(button);
    }

    if (deveAtivar) {
      button.addEventListener("click", () => onClick(id));

      // Note: Simplifying to add new event handlers on the icon button
      if (extraEventHandlersMap instanceof Map) {
        for (const [event, handler] of extraEventHandlersMap.entries()) {
          button.addEventListener(event, (e) => {
            handler(e, { id, row, column, value, htmlElement });
          });
        }
      }
    } else {
      iconEl.classList.add("text-disabled");
    }
  }
  catch(error) {}
};

/**
 * @param {Function} onClick
 * @param {string[]} iconClasses
 * @param {string} dataField
 * @param {*} ref
 * @param {Function} botaoAtivo
 * @param {*} nomePerfil
 * @param {{ enabled: boolean options: {}}} tooltip
 * @param {Map<string, Function>} extraEventHandlersMap
 * @returns {*}
 */
const createControlColumn = (
  onClick,
  iconClasses,
  dataField,
  ref,
  botaoAtivo,
  nomePerfil,
  tooltip,
  extraEventHandlersMap = new Map()
) => ({
  text: "",
  menu: false,
  width: 40,
  dataField: dataField,
  createwidget: createIconButton(
    tooltip,
    // dataField,
    iconClasses,
    onClick,
    ref,
    botaoAtivo,
    nomePerfil,
    extraEventHandlersMap
  ),
  initwidget: createIconButton(
    tooltip,
    // dataField,
    iconClasses,
    onClick,
    ref,
    botaoAtivo,
    nomePerfil,
    extraEventHandlersMap
  ),
  sortable: false,
  resizable: false,
});

class CrudListPageScaffold extends React.Component {
  constructor(props) {
    super(props);

    document.title = `SGIH - ${this.props.title}`;

    // permissões
    const permitirDownloadZip = this.props.ehRelatorio
      ? this.props.permissoes.permitirDownloadZIPRelatorio
      : this.props.ehPagamento ?
        this.props.permissoes.permitirDownloadZIPPagamento
        : this.props.permissoes.permitirDownloadZIPCadastro;

    const permitirDownloadListagem = this.props.ehRelatorio
      ? this.props.permissoes.permitirImprimirListagemRelatorio
      : this.props.ehPagamento ?
        this.props.permissoes.permitirImprimirListagemPagamento
        : this.props.permissoes.permitirImprimirListagemCadastro;

    const permitirInsercao = this.props.ehRelatorio
      ? this.props.permissoes.permitirInserirRelatorio
      : this.props.ehPagamento ?
        this.props.permissoes.permitirInserirPagamento
        : this.props.permissoes.permitirInserirCadastro;

    const permitirEdicao = (() => {
      if (this.props.ehRelatorio) {
        return this.props.permissoes.permitirAlterarRelatorio
      }

      if (this.props.ehPagamento) {
        return this.props.permissoes.permitirAlterarPagamento;
      }

      if (this.props.tableName === "usuarios") {
        return this.props.permissoes.permitirGerenciarCadastroUsuario;
      }

      return this.props.permissoes.permitirAlterarCadastro;
    })();

    const permitirExclusao = this.props.ehRelatorio
      ? this.props.permissoes.permitirExcluirRelatorio
      : this.props.ehPagamento ?
        this.props.permissoes.permitirExcluirPagamento
        : this.props.permissoes.permitirExcluirCadastro;

    const permitirFluxo = this.props.permissoes.permitirFluxoRelatorio;

    // const nomePerfil = this.props.permissoes.nomePerfil;

    this.baseApiUrl = process.env.REACT_APP_API_URL + this.props.baseUrl;
    this.baseAppUrl = "/sgih/app" + this.props.baseUrl;

    this.state = {
      isPrintModalOpen: false,
      currentId: null,
      printSimple: false,
      key: create_UUID(),
    };

    this.shouldCreateFormLink =
      this.props.showFormLink && this.props.formLinkTitle;

    this.source = this.props.tableConfig.source || {};
    this.source.cache = false;
    this.source.type = "POST";
    this.source.root = "dados";
    this.source.contentType = "application/json; charset=utf-8";
    this.source.formatData = (data) => {
      return JSON.stringify(data);
    };

    this.source.beforeprocessing = (data) => {
      // https://www.jqwidgets.com/jquery-widgets-documentation/documentation/asp.net-integration/asp.net-grid-server-side-paging.htm
      // https://www.jqwidgets.com/jquery-widgets-demo/demos/php/serverpaging.htm?arctic
      // https://www.jqwidgets.com/jquery-widgets-demo/demos/php/serverfiltering_paging_and_sorting.htm?arctic
      this.source.totalrecords = data.resultadosTotais;
      this.source.statusRelatorio = data.statusRelatorio;
    };

    this.source.filter = () => {
      if (this.props.tableRef.current.isBindingCompleted()) {
        this.props.tableRef.current.updatebounddata("filter");
      }
    };
    this.source.sort = () => {
      if (this.props.tableRef.current.isBindingCompleted()) {
        this.props.tableRef.current.updatebounddata("sort");
      }
    };
    this.source.url = this.source.url || this.baseApiUrl + "/lista";
    this.source.datatype = this.source.datatype || "json";
    this.source.datafields = this.source.datafields || [];
    this.source.datafields.push(
      { name: "edit_id", map: "id" },
      { name: "delete_id", map: "id" },
      { name: "report_flow_id", map: "id" },
      { name: "print_id", map: "id" },
      { name: "view_id", map: "id" },
      { name: "permitirEdicao", map: "permitirEdicao" },
      { name: "permitirExclusao", map: "permitirExclusao" },
      { name: "mapeamento", map: "mapeamento" }
    );

    // this.preActions = this.props.preActions || [];
    this.preActions = Array.from(new Set(this.props.preActions)) || [];

    if (this.props.shouldAddRefreshButton) {
      this.preActions.unshift({
        title: "Atualizar",
        onClick: () => this.refreshTableData(),
      });
    }
    if (this.props.permissoes.permitirInserirPagamento === true && this.props.tableName === "gerenciamentoRecibo") {
      this.preActions.unshift({
        title: "Gerar recibos de um roteiro",
        onClick: () => this.gerarRecibosForm(),
      });
    }

    if (this.props.shouldAddZipButton && permitirDownloadZip) {
      this.preActions.unshift({
        title: "Baixar ZIP",
        onClick: () => this.abrirModalImpressaoZIP(),
      });
    }

    if(this.props.permissoes.permitirVisualizarAprovacaoRapida === true){

    if(this.props.permissoes.permitirFluxoRelatorio && this.props.ehRelatorio) {
      if(this.props.tipoRelatorio === "PRODUCAO_CAMPO"){
        if(!this.props.permissoes.permitirEnviarRelatoriosEmBloco){
          this.preActions.unshift({
            title: "Aprovação rápida",
            onClick: () => this.aprovacaoRapida(),
          });
        }
      }
      else {
        this.preActions.unshift({
          title: "Aprovação rápida",
          onClick: () => this.aprovacaoRapida(),
        });
      }

    }}

    if (this.props.shouldAddPrintGridButton && permitirDownloadListagem) {
      this.preActions.unshift({
        title: "Imprimir listagem",
        onClick: () => this.printVisibleData(this.props.tableName),
      });
    }

    if (this.props.shouldAddOnePDF) {
      this.preActions.unshift({
        title: "Imprimir recibos da listagem",
        className:"botaoEditado",
        onClick: () => this.gerarRecibosPDFUnico(this.props.tableName),
      });
    }

    this.preActions.unshift({
      title: "Limpar filtros",
      onClick: () => this.clearFilters(this.props.tableName),
    });

    if (this.shouldCreateFormLink && permitirInsercao) {
      this.preActions.unshift({
        title: this.props.formLinkTitle,
        onClick: () => this.props.history.push(`${this.baseAppUrl}/criar`),
      });
    }

    this.actions = this.props.actions || [];
    this.columns = [...this.props.tableConfig.columns];

    if (this.props.shouldAddDeleteButton) {
      this.columns.unshift(
        createControlColumn(
          this.deleteEntity.bind(this),
          ["fa-trash", "text-danger"],
          "delete_id",
          this.props.tableRef,
          (linha) =>
            (permitirExclusao &&
              linha &&
              "undefined" === typeof linha.permitirExclusao) ||
            linha.permitirExclusao
        )
      );
    }

    if (this.props.shouldAddEditButton) {
      this.columns.unshift(
        createControlColumn(
          this.editEntity.bind(this),
          ["fa-pencil-alt", "text-warning"],
          "edit_id",
          this.props.tableRef,
          (linha) =>
            (permitirEdicao &&
              linha &&
              "undefined" === typeof linha.permitirEdicao) ||
            linha.permitirEdicao
        )
      );
    }

    this.reportFlowEventHandlersMap = new Map();

    this.reportFlowTimer = null;

    if (this.props.shouldAddReportFlowButton) {
      const mapaTiposRelatorio = {
          // pluviometrico: 'PLUVIOMETRICO',
          // fluviometrico: 'FLUVIOMETRICO',
          producao: 'PRODUCAO_CAMPO',
          qualidadeAgua: 'QUALIDADE_AGUA',
          // veicular: 'VEICULAR',
          // pcd: 'PCD',
      };

      const tipo = this.props.tipoRelatorio;
      const tipoMapeado = mapaTiposRelatorio[tipo] || tipo.toUpperCase();

      this.reportFlowEventHandlersMap.set('mouseenter', async (event, { id }) => {
        this.reportFlowTimer = setTimeout(async () => {
          const loadingReportFlowTooltipUpdateEvent = new CustomEvent("sgihtooltipupdate", {
            bubbles: true,
            detail: {
              content: 'Carregando...',
            },
          });

          event.target.dispatchEvent(loadingReportFlowTooltipUpdateEvent);

          const reportId = id;

          const requestConfig = {
            params: {
              tipoRelatorio: tipoMapeado,
              id: reportId,
            },
          };

          const getReportFlowData = async (config) => {
            const response = await createServiceFor('/fluxoRelatorios')
              .request('GET', '/paraRelatorio', config);

            if (response.status === 200) {
              const { data } = response;

              return data;
            }

            return null;
          }

          const data = await getReportFlowData(requestConfig);

          const dadosUltimoFluxo = data.alteracoesRelatorio.slice().pop();

          const reportFlowTooltipUpdateEvent = new CustomEvent("sgihtooltipupdate", {
            bubbles: true,
            detail: {
              content: `<b>Última descrição do fluxo:</b> ${dadosUltimoFluxo?.descricao || '(vazio)'}
              <br><b>Usuário:</b> ${dadosUltimoFluxo?.usuario || '(vazio)'}
              <br><b>Perfil destino:</b> ${dadosUltimoFluxo?.perfil || '(vazio)'}
              <br><b>Data: </b>${dadosUltimoFluxo?.dataAtualizacao || '(vazio)'}`
            }
          });

          event.target.dispatchEvent(reportFlowTooltipUpdateEvent);
        }, 1500);
      });

      const resetScheduleTooltipShow = (e) => {
        if (this.reportFlowTimer) {
          clearTimeout(this.reportFlowTimer);
          this.reportFlowTimer = null;
        }
      }

      this.reportFlowEventHandlersMap.set('mouseleave', resetScheduleTooltipShow);

      this.reportFlowEventHandlersMap.set('wheel', resetScheduleTooltipShow);

      const tooltipConfig = {
        enabled: true,
        options: {
          content: 'Carregando...',
        }
      };

      this.columns.unshift(
        createControlColumn(
          this.abrirPaginaFluxo.bind(this),
          ["fa-stream", "text-success"],
          "report_flow_id",
          this.props.tableRef,
          () => permitirFluxo,
          this.props.permissoes.nomePerfil,
          tooltipConfig,
          this.reportFlowEventHandlersMap
        )
      );
    }

    if (this.props.shouldAddPrintButton) {
      this.columns.unshift(
        createControlColumn(
          this.openPrintModal.bind(this),
          ["fa-print", "text-info"],
          "print_id",
          this.props.tableRef
        )
      );
    }

    if (this.props.shouldAddViewButton) {
      this.columns.unshift(
        createControlColumn(
          this.viewEntity.bind(this),
          ["fa-eye", "text-secondary"],
          "view_id",
          this.props.tableRef
        )
      );
    }
  }

  renderGridRows = (obj) => obj.data;

  componentDidMount() {
    this.exibirMensagemAoAbrir();
  }

  render() {
    return (
      <PageScaffold title={this.props.title}>
        <Table
          key={this.state.key}
          name={this.props.tableName}
          statusRelatorio={this.source.statusRelatorio}
          source={this.source}
          columns={this.columns}
          actions={this.actions}
          preActions={this.preActions}
          wrappedComponentRef={this.props.tableRef}
          aoTrocarFiltros={this.props.aoTrocarFiltrosTabela}
          aoCarregarTabela={this.props.aoCarregarTabela}
          modoVirtual={true}
          nomePerfil={this.props.permissoes.nomePerfil}
          renderGridRows={this.renderGridRows}
          pagesizeoptions={this.props.pagesizeoptions}
          pagesize={this.props.pagesize}
        />

        <Modal
          aberto={this.state.isPrintModalOpen}
          aoFechar={this.closePrintModal.bind(this)}
          titulo="Imprimir relatório"
        >
          <p>Definir formato de relatório</p>
          <Row>
            <Col className="mt-3 d-flex justify-content-center">
              <JqxButton
                theme="material"
                width={100}
                className="py-2 float-right"
                onClick={() => this.printReportAs("pdf")}
              >
                PDF
              </JqxButton>
            </Col>
            <Col className="mt-3 d-flex justify-content-center">
              <JqxButton
                theme="material"
                width={100}
                className="py-2 float-right"
                onClick={() => this.printReportAs("tiff")}
              >
                TIFF
              </JqxButton>
            </Col>
          </Row>
        </Modal>

        {this.props.children}
      </PageScaffold>
    );
  }

  /**
   * Open a link in another tab in same window with provided data (passed as encrypted/encoded query params).
   * @param {string} url
   * @param {object=} dataToTransfer
   * @returns
   */
  openInNewTab(url, dataToTransfer = {}) {
    if (dataToTransfer) {
      const json = JSON.stringify({
        ...dataToTransfer,
        closeTabOnFinish: true
      });
      const encryptedData = window.btoa(json);
      const encodedData = window.encodeURIComponent(encryptedData);

      const urlWithParams = `${url}?d=${encodedData}`;

      const newWindowTab = window.open(urlWithParams, '_blank');
      newWindowTab.focus();

      return newWindowTab;
    }

    const newWindowTab = window.open(url, '_blank');
    newWindowTab.focus();
  }

  viewEntity(id) {
    const linhasSelecionadas = this.props.tableRef?.current?.getrowdatabyid(id);

    const dataToTransfer = {
      data: linhasSelecionadas,
      allowEdit: false,
      visualizar: true,
    };

    const url = `${this.baseAppUrl}/${id}`;

    this.openInNewTab(url, dataToTransfer);

    // this.props.history.push({
    //   pathname: `${this.baseAppUrl}/${id}`,
    //   state: {
    //     data: linhasSelecionadas,
    //     allowEdit: false,
    //     visualizar: true, // para compatibilidade com forms antigos
    //   },
    // });
  }

  editEntity(id) {
    const linhasSelecionadas = this.props.tableRef?.current?.getrowdatabyid(id);

    const dataToTransfer = {
      data: linhasSelecionadas,
      allowEdit: true,
      visualizar: false,
    };

    const url = `${this.baseAppUrl}/${id}`;

    this.openInNewTab(url, dataToTransfer);

    // this.props.history.push({
    //   pathname: `${this.baseAppUrl}/${id}`,
    //   state: {
    //     data: linhasSelecionadas,
    //     allowEdit: true,
    //     visualizar: false, // para compatibilidade com forms antigos
    //   },
    // });
  }

  deleteEntity(id) {
    swal({
      title: "Tem certeza que deseja excluir esse registro?",
      icon: "warning",
      buttons: {
        cancel: "Não, cancelar",
        confirm: {
          text: "Sim, desejo excluir!",
          className: "btn-danger",
        },
      },
    }).then((result) => {
      if (result) {
        createServiceFor(this.baseApiUrl)
          .delete(id)
          .then(() => {
            this.props.dispararNotificacao({
              message: "O registro foi excluído com sucesso!",
              type: "success",
            });
            this.refreshTableData();
          })
          .catch((error) => {
            this.props.dispararNotificacao({
              message:
                this.props.deleteErrorMessage ||
                "Ocorreu um erro ao excluir o registro.",
              type: "error",
            });
          });
      }
    });
  }

  async openPrintModal(id) {
    if (this.props.tableRef.current.isBindingCompleted()) {

      let printSimple = false;

      if(this.props.canPrintSimpleVerion){
       const res = await swal({ title: "Deseja baixar o relatório completo ou o relátorio resumido ?", text: "", icon: "info",
          buttons: { completo: { text: "Completo", className: "btn-primary", }, resumido: { text: "Resumido", className: "btn-primary"}},
          closeOnClickOutside: true,
          className: "w-50"
        });

        if(!res) return;

        printSimple = res === 'resumido';
      }

      this.setState({
        isPrintModalOpen: true,
        currentId: id,
        printSimple
      });
    }
  }

  abrirModalImpressaoZIP() {
    if (this.props.tableRef.current.isBindingCompleted()) {
      const indicesSelecionados = this.props.tableRef.current.getselectedrowindexes();
      if (indicesSelecionados && indicesSelecionados.length > 0) {
        const dados = (this.props.tableRef.current.getboundrows() || []).reduce(
          (arr, atual, i) => {
            if (indicesSelecionados.includes(i)) {
              return [...arr, atual];
            }
            return arr;
          },
          []
        );
        const ids = dados.map((e) => e.id);
        this.setState({
          isPrintModalOpen: true,
          currentIds: ids,
          printSimple: false,
        });
      } else {
        swal({
          title:
            "Selecione os registros da tabela para os quais deseja gerar o ZIP",
          icon: "info",
          buttons: {
            confirm: {
              text: "OK",
              className: "btn-primary",
            },
          },
        });
      }
    }
  }

  abrirPaginaFluxo(id) {
    const url = `${this.props.urlBaseFluxoRelatorio}/${id}`;

    const dataToTransfer = {
      allowEdit: this.props.permissoes.permitirFluxoRelatorio,
    };

    this.openInNewTab(url, dataToTransfer);
    // this.props.history.push(this.props.urlBaseFluxoRelatorio + "/" + id);
  }

  exibirMensagemAoAbrir() {
    const mensagem = localStorage.getItem('MENSAGEM');
    if(mensagem) {
      store.dispatch(showGlobalNotification(JSON.parse(mensagem)));
      localStorage.removeItem('MENSAGEM');
    }
  }

  closePrintModal() {
    if (this.props.tableRef.current.isBindingCompleted()) {
      this.setState({
        isPrintModalOpen: false,
        printSimple: false,
      });
    }
  }

  printReportAs(format) {
    const url = this.state.currentId
      ? `/${this.state.currentId}?${objetoParaParams({
        formato: format.toUpperCase(),
      })}`
      : `?${objetoParaParams({
        formato: format.toUpperCase(),
        ids: this.state.currentIds,
      })}`;

    const link = document.createElement("a");
    link.href = "#";

    const printSimple = this.state.printSimple;

    link.addEventListener("click", (e) => {
      fetch(
        `${process.env.REACT_APP_API_URL}${this.props.baseUrl}/imprimirDetalhes${printSimple ? 'Resumido':''}${url}`,
        {
          credentials: "same-origin",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
            "access-control-expose-headers": "x-nome-arquivo",
          },
        }
      )
        .then(function (response) {
          console.log(response)
          if(response.status === 200){
            const nomeArquivo = response.headers.get("x-nome-arquivo");
            response.blob().then((data) => {
              FileSaver.saveAs(data, nomeArquivo);
            });
          } else {
            store.dispatch(
              showGlobalNotification({
                message:"Ocorreu um erro ao solicitar a impressão",
              })
            )
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
    link.click();
    this.closePrintModal();
  }
  gerarRecibosForm() {
    this.props.history.push('/sgih/app/recibos/gerarRecibos')
}
  gerarRecibosPDFUnico(tableName) {
    if (this.props.tableRef && this.props.tableRef.current) {
      const rows = this.props.tableRef.current.getrows();
      const ids = rows.map((e) => e.id);
      if (ids && ids.length > 0) {

        if (this.props.forcarPOSTListagem) {
          const link = document.createElement("a");
          link.href = "#";
          link.addEventListener("click", (e) => {
            fetch(
              `${process.env.REACT_APP_API_URL}${this.props.baseUrl}/gerarRecibosPDFUnico`,
              {
                credentials: "same-origin",
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
                },
                body: JSON.stringify(ids),
              }
            )
              .then(function (response) {
                return response.blob();
              })
              .then(function (blob) {
                FileSaver.saveAs(
                  blob,
                  // `Consolidado_Recibos_${tableName[0].toUpperCase()}${tableName.slice(1)}.pdf`
                  `Consolidado_Recibos.pdf`
                );
              })
              .catch((error) => {
                console.log(error);
              });
          });
          link.click();
        } else {
          const link = document.createElement("a");
          link.href = `${process.env.REACT_APP_API_URL}${this.props.baseUrl
            }/gerarRecibosPDFUnico?${objetoParaParams({ ids })}`;
          link.click();
        }
      } else {
        swal({
          title:
            "Selecione os registros da tabela para os quais deseja gerar o arquivo",
          icon: "info",
          buttons: {
            confirm: {
              text: "OK",
              className: "btn-primary",
            },
          },
        });
      }

    }
  }

  aprovacaoRapida() {
    if (this.props.tableRef && this.props.tableRef.current && this.props.tableRef.current.isBindingCompleted()) {

      if(!this.props.permissoes.idPerfilFluxoPadrao){
        swal({ title:"Seu perfil de usuário não possui um fluxo padrão definido para o relatório selecionado", icon: "info",
          buttons: { confirm: { text: "OK", className: "btn-primary", }, }, });
        return;
      }

      const linhasSelecionadas = this.props.tableRef?.current?.getselectedrowindexes();

      if(!linhasSelecionadas || !linhasSelecionadas.length){
        swal({ title:"Selecione relatórios da tabela para aprovação rapida", icon: "info",
          buttons: { confirm: { text: "OK", className: "btn-primary", }, }, });
        return;
      }

      const linhas = [];

      linhasSelecionadas.forEach((i) => linhas.push(this.props.tableRef?.current?.getrowdata(i)));

      const listaFiltrada = linhas.filter((i) => i.perfilUltimaAlteracaoFluxo === this.props.permissoes.nomePerfil);
      console.log(listaFiltrada)
      if(!listaFiltrada || !listaFiltrada.length){
        swal({ title:`Selecione somente relatórios com status "${this.props.permissoes.nomePerfil}"`, icon: "info",
          buttons: { confirm: { text: "OK", className: "btn-primary", }, }, });
        return;
      }

      swal({
        title: `Confirma a mudança no status do${listaFiltrada.length === 1 ? '':'s'} relatório${listaFiltrada.length === 1 ? '':'s'}?`,
        icon: "warning",
        buttons: {
          cancel: "Não",
          confirmar: {
            text: "Sim",
            className: "btn-success",
          },
        },
        dangerMode: true,
      }).then(async (resultado) => {
        if(resultado !== 'confirmar') return;
        store.dispatch(startGlobalLoading(`Salvando mudança de status do${listaFiltrada.length === 1 ? '':'s'} relatório${listaFiltrada.length === 1 ? '':'s'}...`));
        let sucesso =0;
        for(let i = 0; i < listaFiltrada.length; i++){
          let item = listaFiltrada[i];
          try {
            let body = {
              codigoEstacaoFluxo: item.codigoEstacao,
              idPerfil: this.props.permissoes.idPerfilFluxoPadrao.toString(),
              idRelatorio: item.id.toString(),
              nomeEstacaoFluxo: item.nomeEstacao,
              tipoRelatorio: this.props.tipoRelatorio,
              descricao: 'Relatório aprovado.'
            };
            console.log("BODY ", body)
            await createServiceFor("/fluxoRelatorios").customURL('POST', '', body);
            sucesso = sucesso + 1;
          }
          catch(err){
            console.log('Erro ao enviar relatorio', err);
            store.dispatch(showGlobalNotification({ message: `Relatório ${item.id} já aprovado.`,type: "warning"}));
          }
        }

        store.dispatch(stopGlobalLoading());
        this.refreshTableData();
        if ( sucesso > 0 ){
          store.dispatch(showGlobalNotification({ message: `Há ${sucesso} relatório${listaFiltrada.length === 1 ? '':'s'} salvo${listaFiltrada.length === 1 ? '':'s'} com sucesso!`,type: "success"}));
        }

      });
    }
  }

  refreshTableData() {
    if (this.props.tableRef) {
      this.props.tableRef.current.updatebounddata();
    }
  }

  clearFilters() {
    if (this.props.tableRef && this.props.tableRef.current.isBindingCompleted()) {
      this.props.tableRef.current.clearfilters();
    }
  }

  async printVisibleData(tableName) {
    if (this.props.tableRef && this.props.tableRef.current && this.props.tableRef.current.isBindingCompleted()) {

      let filter = JSON.parse(localStorage.getItem("FILTER"));
      filter.pagenum = 0;
      filter.pagesize = 999999;

      store.dispatch(showGlobalNotification(
        { message: "O download será realizado assim que a geração do PDF for concluída. Não feche ou recarregue a página.",
          type: "success", duration: 9999}));

      createServiceFor(this.props.baseUrl).customURL("POST", "lista", filter)
      .then((res) => {
        const ids = res.data.dados.map((i) => i.id);

        if (this.props.forcarPOSTListagem) {
          const link = document.createElement("a");
          link.href = "#";
          link.addEventListener("click", async (e) => {

            fetch(
              `${process.env.REACT_APP_API_URL}${this.props.baseUrl}/imprimirListagem`,
              {
                credentials: "same-origin",
                method: "POST",
                headers: {
                  Accept: "application/pdf",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
                },
                body: JSON.stringify(ids),
              }
            )
              .then(function (response) {
                if(response.ok)
                  return response;

                throw new Error('Não foi possivel concluir a impressão');
              })
              .then(function (response) {
                return response.blob();
              })
              .then(function (blob) {
                FileSaver.saveAs(
                  blob,
                  `listagem${tableName[0].toUpperCase()}${tableName.slice(1)}.pdf`
                );
              })
              .catch((error) => {
                console.log("Erro ao solicitar impressão", error);
                this.props.dispararNotificacao({
                message:
                  "Ocorreu um erro ao excluir imprimir os registros.",
                  type: "error",
                });
              });
          });
          link.click();
        } else {
          const link = document.createElement("a");
          link.href = `${process.env.REACT_APP_API_URL}${this.props.baseUrl
            }/imprimirListagem?${objetoParaParams({ ids })}`;
          link.click();
        }
      })
      .catch((err) => {
        console.log("Erro ao pegar registros para impressão", err);
        this.props.dispararNotificacao({
          message:
            "Ocorreu um erro ao excluir imprimir os registros.",
          type: "error",
        });
      });
    }
  }
}

// todo generalize this method
const dependsOnValue = (propName, expectedValue, expectedType) =>
  function (props, dependeePropName, componentName) {
    if (props[propName] !== expectedValue) return;
    if (typeof props[dependeePropName] !== expectedType) {
      return new Error(`${dependeePropName} should be of type ${expectedType}`);
    }
  };

CrudListPageScaffold.propTypes = {
  title: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  children: PropTypes.node,
  actions: PropTypes.array,
  preActions: PropTypes.array,

  baseUrl: PropTypes.string,
  history: PropTypes.object.isRequired,

  showFormLink: PropTypes.bool.isRequired,
  formLinkTitle: dependsOnValue("showFormLink", true, "string"),

  shouldAddViewButton: PropTypes.bool.isRequired,
  shouldAddPrintButton: PropTypes.bool.isRequired,
  shouldAddEditButton: PropTypes.bool.isRequired,
  shouldAddReportFlowButton: PropTypes.bool.isRequired,
  shouldAddDeleteButton: PropTypes.bool.isRequired,
  shouldAddPrintGridButton: PropTypes.bool.isRequired,
  shouldAddZipButton: PropTypes.bool.isRequired,
  shouldAddRefreshButton: PropTypes.bool.isRequired,

  deleteErrorMessage: PropTypes.string,

  forcarPOSTListagem: PropTypes.bool.isRequired,

  tableConfig: PropTypes.shape({
    source: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
  }).isRequired,
  ehRelatorio: PropTypes.bool.isRequired,
  ehPagamento: PropTypes.bool.isRequired,
  urlBaseFluxoRelatorio: PropTypes.string,
  aoTrocarFiltrosTabela: PropTypes.func,
  aoCarregarTabela: PropTypes.func,

  pagesize: PropTypes.number,
  pagesizeoptions: PropTypes.arrayOf(PropTypes.number),

  tableRef: PropTypes.any,
};

CrudListPageScaffold.defaultProps = {
  showFormLink: true,

  shouldAddViewButton: true,
  shouldAddPrintButton: true,
  shouldAddEditButton: true,
  shouldAddReportFlowButton: false,
  shouldAddDeleteButton: true,
  shouldAddPrintGridButton: true,
  shouldAddZipButton: true,
  shouldAddRefreshButton: false,

  forcarPOSTListagem: true,
  ehRelatorio: false,
  ehPagamento: false,
  urlBaseFluxoRelatorio: null,

  pagesize: 20,
  pagesizeoptions: [5, 10, 20, 50, 100],

  tableRef: React.createRef(),
};

export default connect(
  (state) => ({
    permissoes: extrairPermissoes(state),
  }),
  (dispatch) => ({
    dispararNotificacao(config) {
      dispatch(showGlobalNotification(config));
    },
  })
)(CrudListPageScaffold);
