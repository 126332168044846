import React from "react";
import CrudFormPageScaffold from "../../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SelectField from "../../../components/form/field/SelectField/SelectField";
import DateTimeField from "../../../components/form/field/DateTimeField/DateTimeField";
import arrayMutators from "final-form-arrays";
import createServiceFor from "../../../service/Service";
import PromiseLoader from "../../../components/PromiseLoader/PromiseLoader";
import { construirBotoesForm } from "../../../components/scaffold/FormPageScaffoldCommon";
import store from "../../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../../reducers";
import { requiredWithNotification } from "../../../util/FormUtils";

const identifierValueResolver = (e) => e.id || e.codigo || e;
// const roteiroServicoLabelResolver = e => e.numeroRoteiroServico;
const roteiroServicoLabelResolver = (e) => `${e.unidadeRegional} - ${e.numeroRoteiroServico}` || e.id || e;
const parametroLabelResolver = e => `${e.nomeUnidadeRegional}  -  ${e.id}`;

class PaginaFormControleRecibo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { }
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/roteirosServico/paraRecibos").query(),
      createServiceFor("/recibos/parametros/simples").query(),
    ];

    const promise = Promise.all(promises).then(([
        roteirosServico,
        parametros,

      ]) => ({
        roteirosServico : roteirosServico.data,
        parametros : parametros.data,
      })
    )
    .catch((e) => {
      console.log(e);
      store.dispatch(showGlobalNotification({
        message: e.message
      }))
    });

    this.setState({
      promiseDados: promise
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/recibos/gerenciamento/gerarRecibos")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        let url = window.location.href;
        let corte = url.lastIndexOf('/');
        let novaURL = url.substring(0, corte) + '/gerenciamento';
        
        localStorage.setItem('MENSAGEM', JSON.stringify({
          message: "Recibos salvos com sucesso!",
          type: "success",
        }));

        window.location.assign(novaURL);
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        store.dispatch(
          showGlobalNotification({
            message: "Ocorreu um erro ao tentar salvar",
          })
        );
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  getMunicipios = (id, data) => {
    return data.municipiosPorEstado[id];
  };

  render() {
    // const id = this.props.match.params.id;
    const estaEditando = false;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    return (
      <CrudFormPageScaffold
        baseTitle="Gerar recibos"
        baseUrl="/sgih/app/recibos/gerenciamento/gerarRecibos"
        history={this.props.history}
        match={this.props.match}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                mutators={{ ...arrayMutators }}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando, disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Gerar recibos">
                        <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={3}>
                                <Field name="dataFinal" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataInicial"
                                      label="Data inicial"
                                      endDate={value}
                                      maxDate={value}
                                      validate={requiredWithNotification("Data inicial")}
                                      required
                                      popperPlacement='right'
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field name="dataInicial" subscription={{ value: true }} >
                                  {({ input: { value } }) => (
                                    <Field
                                      component={DateTimeField}
                                      name="dataFinal"
                                      label="Data final"
                                      minDate={value}
                                      startDate={value}
                                      validate={requiredWithNotification("Data final")}
                                      required
                                      popperPlacement='right'
                                    />
                                  )}
                                </Field>
                              </Col>
                              <Col className="mt-3" md={3}>
                                  <Field component={SelectField} name='roteiro'
                                      label='Roteiro'
                                      elements={data.roteirosServico}
                                      valueResolver={identifierValueResolver}
                                      labelResolver={roteiroServicoLabelResolver}
                                      validate={requiredWithNotification('Roteiro')}
                                      required
                                  />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field component={SelectField}
                                  name='codigoParametro'
                                  label='Parâmetro do recibo'
                                  elements={data.parametros}
                                  disabled={disableAllFields}
                                  valueResolver={identifierValueResolver}
                                  labelResolver={parametroLabelResolver}
                                  validate={requiredWithNotification("Parâmetros do recibo")}
                                  required
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormControleRecibo;
