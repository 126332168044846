export const SOl = {
    viewName: "Sol", serializedName: "Sol",
};

export const CHUVA = {
    viewName: "Chuva", serializedName: "Chuva",
};

export const NUBLADO = {
    viewName: "Nublado", serializedName: "Nublado",
};

export default [
    CHUVA, NUBLADO, SOl
];