import React, { Component } from "react";
import CrudListPageScaffold from "../../components/scaffold/CrudListPageScaffold";
import UNIDADEREGIONAL from 'model/UnidadeRegional';
class PaginaListaVeiculos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibir:false
    };

    UNIDADEREGIONAL(
      (regionais) => {
        this.setState(
          {
            regionais
          },
          () => {
            const source = {
              datafields: [
                { name: "id", type: "number", map: "id" },
                { name: "placa", type: "string", map: "placa" },
                { name: "marca", type: "string", map: "marca" },
                { name: "modelo", type: "string", map: "modelo" },
                { name: "combustivel", type: "string", map: "combustivel" },
                { name: "kmAtual", type: "number", map: "kmAtual" },
                { name: "siglaLocalUasg", type: "string", map: "siglaLocalUasg" },
              ],
              id: "id",
            };

            const columns = [
              { text: "Regional", dataField: "siglaLocalUasg",  filtertype: 'checkedlist', filteritems: this.state ? this.state.regionais : [] },
              { text: "Placa", dataField: "placa" },
              { text: "Marca", dataField: "marca" },
              { text: "Modelo", dataField: "modelo" },
              { text: "Combustível", dataField: "combustivel" },
              { text: "KM atual", dataField: "kmAtual", cellsalign: 'right' },
            ];

            this.tableConfig = { source, columns };
            this.setState({
              exibir:true
            })
          }
        );
      }
    );
  }

  render() {
    return this.state ? this.state.exibir ?
      <CrudListPageScaffold
        title="Veículos"
        tableConfig={this.tableConfig}
        baseUrl="/veiculos"
        history={this.props.history}
        formLinkTitle="Novo veículo"
        tableName="veiculos"
        shouldAddPrintButton={false}
        shouldAddZipButton={false}
      />
      : <></>
      : <></>
  }
}

export default PaginaListaVeiculos;
