import React from "react";
import { Field } from "react-final-form";
import { Row, Col } from "react-bootstrap";

import CheckboxField from "components/form/field/CheckboxField";
import { WhenFieldChanges } from "../../util/FormUtils";
import FormSectionWrapper from "components/form/FormSectionWrapper";

function AbaPermissoesCadastros({ desabilitarTodosCampos }) {
  return (
    <FormSectionWrapper title="Tem permissão relacionada a cadastros para">
      {/* Visualizar Cadastros */}
      <WhenFieldChanges
        field="permitirInserirCadastro"
        becomes={true}
        set="permitirVisualizarCadastro"
        to={true}
      />
      <WhenFieldChanges
        field="permitirAlterarCadastro"
        becomes={true}
        set="permitirVisualizarCadastro"
        to={true}
      />
      <WhenFieldChanges
        field="permitirExcluirCadastro"
        becomes={true}
        set="permitirVisualizarCadastro"
        to={true}
      />
      <WhenFieldChanges
        field="permitirImprimirListagemCadastro"
        becomes={true}
        set="permitirVisualizarCadastro"
        to={true}
      />
      <WhenFieldChanges
        field="permitirDownloadZIPCadastro"
        becomes={true}
        set="permitirVisualizarCadastro"
        to={true}
      />
      <WhenFieldChanges
        field="permitirAlterarCadastro"
        becomes={false}
        set="permitirGerenciarCadastroUsuario"
        to={false}
      />
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirVisualizarCadastro"
            label="Visualizar"
            disabled={desabilitarTodosCampos}
          />
        </Col>
        <Col className="mt-3" md={4}>
          <Field
            name="permitirVisualizarCadastro"
            subscription={{ value: true }}
          >
            {({ input: { value } }) => {
              console.log(value)
              if(value && value === true){
                return (
                  <>
                    <Field
                      component={CheckboxField}
                      name="permitirVisualizarTodosCadastros"
                      label="Visualizar todos cadastros"
                      disabled={desabilitarTodosCampos}
                    />
                  </>
                );
              }
              return <> </>
            }}
          </Field>
        </Col>
      </Row>
      {/* Imprimir Listagem de Cadastros */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirImprimirListagemCadastro"
            label="Imprimir listagem"
            disabled={desabilitarTodosCampos}
          />
        </Col>
        <Col className="mt-3" md={3}>
          <Field
            name="permitirAlterarCadastro"
            subscription={{ value: true }}
          >
            {({ input: { value } }) => (
              value && (
                <Field
                  component={CheckboxField}
                  name="permitirGerenciarCadastroUsuario"
                  label="Gerenciar cadastro de usuários"
                  disabled={desabilitarTodosCampos}
                />
              )
            )}
          </Field>
        </Col>
      </Row>
      {/* Download ZIP de Cadastros */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirDownloadZIPCadastro"
            label="Download ZIP"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Alterar Cadastros */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirAlterarCadastro"
            label="Alterar"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Excluir Cadastros */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirExcluirCadastro"
            label="Excluir"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
      {/* Inserir Cadastros */}
      <Row>
        <Col className="mt-3" md={3}>
          <Field
            component={CheckboxField}
            name="permitirInserirCadastro"
            label="Inserir"
            disabled={desabilitarTodosCampos}
          />
        </Col>
      </Row>
    </FormSectionWrapper>
  );
}

export { AbaPermissoesCadastros };
