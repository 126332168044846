import PropTypes from "prop-types";

export const propTypes = {
    required: PropTypes.bool,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    showError: PropTypes.bool,
};

export const defaultProps = {
    required: false,
    disabled: false,
    readOnly: false,
    showError: true,
};