import Index from "../../views/Index";
import React from "react";
import {connect} from "react-redux";
import {efetuarLogoff} from "../../reducers/authReducers";

const Logoff = ({logoff}) => {
    logoff();
    return <Index />
};

export default connect(
    null,
    (dispatch) => ({
        logoff() {
            dispatch(efetuarLogoff())
        }
    })
)(Logoff)