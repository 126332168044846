import React from "react";
import PropTypes from "prop-types";
import { defaultProps, propTypes } from "../formFieldCommonProps";
import FieldLabel from "../FieldLabel";
import Error from "../../Error";
import DatePicker, { registerLocale } from "react-datepicker";

import buildLocalizeFn from "date-fns/locale/_lib/buildLocalizeFn/index.js";
import { ptBR } from "date-fns/locale";

import "./DateTimeField.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import InputMask from 'react-input-mask'

import _ from 'lodash';

// Altera o locale ptBR, adicionado '-feira' nos nomes dos dias das semana.
// Isso é feito recriando a função em ptBR.localize.day, que utiliza os nomes dos dias (node_modules/date-fns/locale/pt-BR/_lib/localize/index.js:31)
// Não gera problemas com o código do locale que faz o parse, pois a regex da lib já prevê o '-feira' (node_modules/date-fns/locale/pt-BR/_lib/match/index.js:46)
const dayValues = {
  narrow: ['do', '2ª', '3ª', '4ª', '5ª', '6ª', 'sá'],
  short: ['do', '2ª', '3ª', '4ª', '5ª', '6ª', 'sá'],
  abbreviated: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  wide: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado']
};
ptBR.localize.day = (0, buildLocalizeFn)({ // https://stackoverflow.com/questions/40967162/what-is-the-meaning-of-this-code-0-function-in-javascript#comment69143819_40967162
  values: dayValues,
  defaultWidth: 'wide'
});

registerLocale("pt-BR", ptBR);

class DateTimeField extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.onChange = (date) => {
      const value = date
        ? this.props.persistValueAsDate
          ? date
          : moment(date).format(this.momentFormat)
        : null;

      if (props.input) {
        props.input.onChange(value);
      }
      if (props.onChange) {
        props.onChange(value);
      }
    };

    switch (this.props.datePickerType) {
      case "date":
        this.dateFormat = props.dateFormat || "dd/MM/yyyy";
        this.momentFormat = "DD/MM/YYYY";
        break;
      case "hour":
        this.dateFormat = props.dateFormat || "HH:mm";
        this.momentFormat = "HH:mm";
        this.showTimeSelect = true;
        break;
      case "yearMonth":
        this.dateFormat = props.dateFormat || "MM/yyyy";
        this.momentFormat = "MM/YYYY";
        this.showMonthYearPicker = true;
        break;
      case "dateAndDayWeek":
        this.dateFormat = props.dateFormat || "dd/MM/yyyy  - eeee";
        this.momentFormat = "DD/MM/YYYY";
        break;
      default:
        this.dateFormat = "dd/MM/yyyy HH:mm:ss";
        break;
    }
  }

  render() {
    let {
      name,
      label,
      required,
      disabled,
      readOnly,
      input,
      meta,
      value,
      endDate,
      startDate,
      minDate,
      maxDate,
      maxDaysSinceStart,
      maxDaysUntilEnd,
      ...rest
    } = this.props;

    const computedRestProps = _.omit(rest, [
      'selectsStart',
      'selectsEnd',
      'persistValueAsDate',
      'datePickerType',
    ]);

    const formatChars = {
      '9': '[0-9]',
      'a': '[A-Za-z]',
      '*': '[A-Za-z0-9]',
      'A': '[A-Z]',
      'Z': '[A-Za-z0-9]',
      '1': '[0-9]',
    };

    name = name || (input && input.name);
    value = value || (input && input.value);

    // WE CANNOT send '' to ReactDatePicker. I wasted a lot of time figuring this e.e
    if (value === "") {
      value = null;
    }

    // console.log(this.props)
    // console.log(value, minDate, maxDate, endDate, startDate)

    if (!this.props.persistValueAsDate && value) {
      value = moment(value, this.momentFormat).toDate();
    }

    if (this.props.selectsStart) {
      startDate = value;
      maxDate = endDate;
    } else if (this.props.selectsEnd) {
      endDate = value;
      minDate = startDate;
    }

    if ("string" === typeof endDate)
      endDate = moment(endDate, this.momentFormat).toDate();
    if ("string" === typeof startDate)
      startDate = moment(startDate, this.momentFormat).toDate();
    if ("string" === typeof minDate)
      minDate = moment(minDate, this.momentFormat).toDate();
    if ("string" === typeof maxDate)
      maxDate = moment(maxDate, this.momentFormat).toDate();

    if (this.props.selectsEnd && maxDaysSinceStart > -1) {
      maxDate = moment(minDate).add(maxDaysSinceStart, "days").toDate();
    } else if (this.props.selectsStart && maxDaysUntilEnd > -1) {
      minDate = moment(maxDate)
        .add(maxDaysUntilEnd * -1, "days")
        .toDate();
    }

    return (
      <>
        {this.props.label && <FieldLabel label={label} required={required} />}
          <DatePicker
            disabled={disabled}
            readOnly={readOnly}
            onChange={this.onChange}
            name={name}
            dateFormat={this.dateFormat}
            showMonthYearPicker={this.showMonthYearPicker}
            popperPlacement={this.placement || "right"}
            showTimeSelect={this.showTimeSelect}
            showTimeSelectOnly={this.showTimeSelect}
            selected={value}
            locale="pt-BR"
            minDate={minDate}
            startDate={startDate}
            endDate={endDate}
            className="dateTimeField"
            maxDate={maxDate}
            popperModifiers={{
              flip: {
                enabled: false
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false
              }
            }}
            yearDropdownItemNumber={this.props.yearDropdownItemNumber}
            showYearDropdown = {this.showYearDropdown ? true : false}
            scrollableYearDropdown = {this.scrollableYearDropdown ? true : false}
            customInput={
              <InputMask {...computedRestProps} type='text' name={name} onChange={this.onChange} value={value}
                formatChars={formatChars} mask={this.resolveMask(this.props.datePickerType)}/>
            }
            {...computedRestProps}
          />

        <Error name={name} />
      </>
    );
  }

  // https://github.com/insin/inputmask-core#pattern
  resolveMask(mask) {
    if (!mask) return null;
    switch (mask) {
      case "date":
        return "11/11/1111";
      case "hour":
        return "11:11";
      case "yearMonth":
        return "11/1111";
      case "dateAndDayWeek":
        return null;
      default:
        return mask;
    }
}

}

DateTimeField.propTypes = {
  ...propTypes,

  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func,

  maxDaysSinceStart: PropTypes.number,
  maxDaysUntilEnd: PropTypes.number,

  persistValueAsDate: PropTypes.bool,
  dateFormat: PropTypes.string,

  datePickerType: PropTypes.oneOf(["date", "hour", "yearMonth", "dateAndDayWeek"]),
  mask: PropTypes.string,
};

DateTimeField.defaultProps = {
  ...defaultProps,
  datePickerType: "date",
  persistValueAsDate: false,
  mask: "date",

  maxDaysSinceStart: -1,
  maxDaysUntilEnd: -1,
};

export default DateTimeField;
