import createServiceFor from "../service/Service";

export const STATUSRELATORIO =  (callBack, statusRelatorio = []) =>{
    Promise.resolve(createServiceFor("/perfis/combo").query())
                .then(
                     (perfis) => {
                        statusRelatorio = perfis.data.map(perfis => perfis.nome.toString().toUpperCase())
                       if (callBack) {
                            callBack(statusRelatorio)
                       }
                    }          
                )
                .catch(e => {
                    console.error(e)
                })
 
} 

export default STATUSRELATORIO;