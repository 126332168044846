import React from "react";
import CrudFormPageScaffold from "../../components/scaffold/CrudFormPageScaffold";
import { Field, Form } from "react-final-form";
import Tab from "../../components/tab/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextField from "../../components/form/field/TextField";
import createServiceFor from "../../service/Service";
import { construirBotoesForm } from "../../components/scaffold/FormPageScaffoldCommon";

import store from "../../store";
import {
  showGlobalNotification,
  startGlobalLoading,
  stopGlobalLoading,
} from "../../reducers";

import PromiseLoader from "components/PromiseLoader/PromiseLoader";

import { requiredWithNotification } from "../../util/FormUtils";
import SelectField from "components/form/field/SelectField";
import DateTimeField from "components/form/field/DateTimeField/DateTimeField";
import createDecorator from "final-form-calculate";
import moment from "moment";
import voltarParaGrid from "util/VoltarParaGrid";

const formCondutorDecorator = createDecorator({
  field: 'categoriaCNH',
  updates: value => ({
    categoriaCNH: !!value ? String(value).replace(/\d/, '').trim().toUpperCase() : value
  })
},{
  field: 'numeroCNH',
  updates: value => ({
    numeroCNH: !!value ? String(value).replace(/[^\d]/, '').trim() : value
  })
})

const identifierValueResolver = (e) => e.id || e.codigo;
const descriptionLabelResolver = (e) => e.nome || e.descricao || e.codigo;

class PaginaFormCondutores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const promises = [
      createServiceFor("/usuarios/simples").query()
    ];

    if (this.props.match.params.id) {
      promises.push(
        createServiceFor("/condutores").get(this.props.match.params.id)
      );
    }

    const promise = Promise.all(promises)
      .then(([usuarios, condutor = {}]) => ({
        usuarios: usuarios.data,
        initialValues: condutor.data
          ? {
              ...condutor.data,
            }
          : {},
      }))
      .catch((e) => {
        store.dispatch(
          showGlobalNotification({
            message: e.message,
          })
        );
      });

    this.setState({
      promiseDados: promise,
    });
  }

  submitForm(values) {
    store.dispatch(startGlobalLoading("Salvando..."));
    createServiceFor("/condutores")
      .post(values)
      .then(() => {
        store.dispatch(stopGlobalLoading());
        voltarParaGrid({
          message: "Condutor salvo com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        store.dispatch(stopGlobalLoading());
        if (e.response.data && e.response.data.message) {
          store.dispatch(showGlobalNotification({
              message: e.response.data.message
          }));
        } else {
            store.dispatch(showGlobalNotification({
                message: 'Ocorreu um erro ao tentar salvar'
            }));
        }
      });
  }

  // Criado para permitir validações personalizadas a cada clique em
  // 'Salvar'/'Atualizar'.
  formWrapper = (form) => ({
    submit: () => {
      form.getRegisteredFields().forEach((field, i) => {
        if (!field.includes(".")) {
          let error = form.getFieldState(field).error;
          if (error && error.length > 0) {
            if ("object" === typeof error) {
              if (Array.isArray(error)) {
                error.forEach((errorItem) => {
                  if ("object" === typeof errorItem) {
                    Object.values(errorItem).forEach((value) => {
                      store.dispatch(
                        showGlobalNotification({
                          message: value,
                        })
                      );
                    });
                  }
                });
              }
            } else {
              store.dispatch(
                showGlobalNotification({
                  message: error,
                })
              );
            }
          }
        } else {
          console.log("NÃO VALIDANDO ", field);
        }
      });
      form.submit();
    },
  });

  render() {
    const id = this.props.match.params.id;
    const estaEditando = !!id;
    const disableAllFields =
      this.props.history.location &&
      this.props.history.location.state &&
      !this.props.history.location.state.allowEdit;

    return (
      <CrudFormPageScaffold
        baseTitle="Condutores"
        baseUrl="/sgih/app/condutores"
        history={this.props.history}
        match={this.props.match}
      >
        <PromiseLoader
          promise={this.state.promiseDados}
          render={(data) => {
            return (
              <Form
                onSubmit={this.submitForm.bind(this)}
                decorators={[formCondutorDecorator]}
                mutators={{}}
                initialValues={data.initialValues}
                render={({
                  handleSubmit,
                  form: {
                    mutators: { push },
                  },
                  pristine,
                  form,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Tab.Container
                        className="pb-2"
                        afterTabContent={construirBotoesForm(
                          this.formWrapper(form),
                          estaEditando,
                          disableAllFields,
                          this.props.history
                        )}
                      >
                        <Tab title="Dados do condutor">
                          <Col className="mt-3" md={12}>
                            <Row>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="id"
                                  label="Código"
                                  disabled={true}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={SelectField}
                                  name="idUsuario"
                                  valueResolver={identifierValueResolver}
                                  labelResolver={descriptionLabelResolver}
                                  label="Usuário"
                                  elements={data.usuarios}
                                  buscavel={true}
                                  validate={requiredWithNotification(
                                    "Usuário"
                                  )}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={3}>
                                <Field
                                  component={TextField}
                                  name="numeroCNH"
                                  label="CNH"
                                  minLength={11}
                                  maxLength={11}
                                  validate={requiredWithNotification("CNH")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={TextField}
                                  name="categoriaCNH"
                                  label="Tipo"
                                  maxLength={5}
                                  validate={requiredWithNotification("Tipo")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                              <Col className="mt-3" md={2}>
                                <Field
                                  component={DateTimeField}
                                  name="dataValidadeCNH"
                                  label="Validade"
                                  minDate={moment().format('DD/MM/YYYY')}
                                  validate={requiredWithNotification("Validade")}
                                  required
                                  disabled={disableAllFields}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Tab>
                      </Tab.Container>
                    </form>
                  );
                }}
              />
            );
          }}
        />
      </CrudFormPageScaffold>
    );
  }
}

export default PaginaFormCondutores;
