import './style.css';

class SGIHTooltip extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.setAttribute("role", "tooltip");
        this.setAttribute("inert", true);
    }
}

customElements.define('sgih-tooltip', SGIHTooltip);